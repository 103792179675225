import React from "react";

const Mandatorydiscloser = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            AICTE APPROVAL LETTERS
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <a href="http://saispurthi.ac.in/AICTE_APPROVAL_LETTERS/EOAReport2023-24.pdf"
         target="blank">
          AICTE Extension of Approval for the academic year 2023-24
        </a>
      </div>
      <br />

      <div className="container1">
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <a href="http://saispurthi.ac.in/AICTE_APPROVAL_LETTERS/EOAReport2022-23.pdf"
         target="blank">
          Extension of approval for the academic year 2022-23
        </a>
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <a href="http://saispurthi.ac.in/AICTE_APPROVAL_LETTERS/EOAReport2021-22.pdf"
         target="blank">
          Extension of approval for the academic year 2021-22
        </a>
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <a href="http://saispurthi.ac.in/AICTE_APPROVAL_LETTERS/EOAReport2020-21.pdf"
         target="blank">
          Extension of approval for the academic year 2020-21
        </a>
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <a href="http://saispurthi.ac.in/AICTE_APPROVAL_LETTERS/EOAReport2019-20.pdf"
         target="blank">
          Extension of approval for the academic year 2019-20
        </a>
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
        <a href="http://saispurthi.ac.in/AICTE_APPROVAL_LETTERS/EOAReport2018-19.pdf"
         target="blank">
          Extension of approval for the academic year 2018-19
        </a>
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
        <a href="http://saispurthi.ac.in/AICTE_APPROVAL_LETTERS/EOAReport2017-18.pdf"
         target="blank">
          Extension of approval for the academic year 2017-18
        </a>
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
        <a href="http://saispurthi.ac.in/AICTE_APPROVAL_LETTERS/EOAReport2016-17.pdf"
         target="blank">
          Extension of approval for the academic year 2016-17
        </a>
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
        <a href="http://saispurthi.ac.in/AICTE_APPROVAL_LETTERS/EOAReport2015-16.pdf"
         target="blank">
          Extension of approval for the academic year 2015-16
        </a>
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
        <a href="http://saispurthi.ac.in/AICTE_APPROVAL_LETTERS/EOAReport2014-15.pdf"
         target="blank">
          Extension of approval for the academic year 2014-15
        </a>
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
        <a href="http://saispurthi.ac.in/AICTE_APPROVAL_LETTERS/EOAReport2013-14.pdf"
         target="blank">
          Extension of approval for the academic year 2013-14
        </a>
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
        <a href="http://saispurthi.ac.in/AICTE_APPROVAL_LETTERS/EOAReportfrom2001-2012.pdf"
         target="blank">
          AICTE approvalletters from Inception of the college upto academic year
          2012-13
        </a>
      </div>
    </div>
  );
};

export default Mandatorydiscloser;
