import React from "react";
import "./Notificationcss.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';



const Notification = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            NOTIFICATIONS
          </a>
          <Navbar  data-bs-theme="dark">
        <Container>
          
          <Nav className="me-auto">
            <Nav.Link style={{color:"#fff"}} href="/pressnote">PRESS NOTES </Nav.Link>
            <Nav.Link style={{color:"#fff"}}  href="/notification">NOTIFICATIONS </Nav.Link>
            <Nav.Link  style={{color:"#fff"}}  href="/events">EVENTS</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
        </div>
      </div>
      <br />

      <div className="table_container">
        <table className="table_main">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Issued By</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>EXAM</td>
              <td>
                {" "}
                <a href="http://saispurthi.ac.in/notify/23-11-17/6556fbb11f8a1.jpg">
                  4-1 Regular and Supply Exam Notification
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>College</td>
              <td>
                {" "}
                <a href="http://saispurthi.ac.in/notify/23-11-17/6556fd01efe05.jpg">
                  2nd,3rd,4th B.Tech, MBA and Diploma students should submit
                  their Scholarship Applications along
                  <br /> with the Enclosures at office from 15/11/2023 to
                  25/11/2023.
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Placement</td>
              <td>
                {" "}
                <a href="http://saispurthi.ac.in/notify/23-11-17/6556fdc6b437d.jpg">
                  Training and placement cell organizing onCampus Drive on
                  9/11/2023 @9:30Am.
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Notification;
