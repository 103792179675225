import React from "react";
import cat from "../../assets/cat-1.jpg";
import cat1 from "../../assets/cat-2.jpg";
import StarIcon from "@mui/icons-material/Star";

const Incentive = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            INCENTIVES
          </a>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "100px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid rounded position-absolute w-40 h-40"
                  src={cat}
                  alt="principal-img"
                  //   style={{ objectFit: "cover" }}
                />
                <br />
                <p className="mb-0" style={{ marginTop: "40%" }}>
                  <h5>For Students</h5>
                  <p>
                  <StarIcon />
                  Total tution fee(Rs.70,000/-) will be provided to the students
                  as scholarship who secured 950 marks and above in 10+2 and
                  below 12,000 rank in EAMCET while seeking the admission into
                  SSIT and scholarship will be continued for entire four years,
                  when they got 80% in subsequent years.
                  </p>
                  <p>
                    <StarIcon />
                    50% Concession in tution fee (Rs.35,000/-) will be provided
                    to the students as scholarship who secured 900 marks and
                    above in 10+2 and 25,000 rank in EAMCET while seeking the
                    admission into SSIT.
                  </p>
                  <p>
                    <StarIcon />
                    16,000 Concession in tution fee will be provided to the
                    students as scholarship who secured 850 marks and above in
                    10+2 and 40,000 rank in EAMCET while seeking the admission
                    into SSIT.
                  </p>
                  <p>
                    <StarIcon />
                    10,000 Concession in tution fee will be provided to the
                    students as scholarship who secured 800 marks and above in
                    10+2 and 50,000 rank in EAMCET while seeking the admission
                    into SSIT.
                  </p>
                </p>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="position-relative h-100">
                <div className="position-relative h-100">
                  <img
                    className="img-fluid rounded position-absolute w-40 h-40"
                    src={cat}
                    alt="principal-img"
                    //   style={{ objectFit: "cover" }}
                  />
                  <br />
                  <p className="mb-0" style={{ marginTop: "40%" }}>
                    <h5>For Faculty</h5>
                    <p>
                      <StarIcon />
                      Registration fee, TA & DA for attending seminars/workshops
                      /conferences to present technical papers anywhere in the
                      country/abroad to encourage the research activity.
                    </p>
                    <p>
                      <StarIcon />
                      Rs.500/- cash award to every faculty per student in 1:20
                      counseling process who motivate the student towards
                      successful career.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Incentive;
