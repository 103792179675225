import React from 'react'
import StarRateIcon from "@mui/icons-material/StarRate";
import BOSFunctions from './BOSFunctions';
import "../GoverningBody/GoverningBody.css"

const ECE = () => {
    const ece = [
        {
            member: "Chairperson",
            designation: "Dr.M.Nageswara Rao Professor of ECE Dept. & HOD"
        },
        {
            member: "University Nominee",
            designation: "Dr. B.T.Krishna, Professor of ECE, UCEK,JNTU Kakinada"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Professor, Department of ECE KLU (Deemed to be University) 9490442715 mvgr03@kluniversity.in"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr.K.Venkata Ramanaiah M.Tech., Ph.D Professor, Department of ECE Yogivemana University,YSR Engineering College of Y.V.U ramanaiahkota@gmail.com"
        },
        {
            member: "Representative from Industry",
            designation: "Dr.P.Lova Kumar Sub Divisional Engineer, CNTX South BSNL HYDERABAD.lovakumarbsnl@gmail.com 9490427439"
        },
        {
            member: "Alumni Member",
            designation: "Mr.Mareboina Sankar B.Tech.,Senior Verification Engineer,Analog Devices India Pvt.Ltd Shankar.mareboina@gmail.com 7799220248"
        },
        {
            member: "Member",
            designation: "Mr.Chejarla Raghunatha Babu, Assoc.Prof., SMCE"
        },
        {
            member: "Member",
            designation: "Mr.M. Parameshwara Rao, Asst.Prof., SMCE"
        },
        {
            member: "Member",
            designation: "Mr.B.Hanish Chaitanya, Asst.Prof., SMCE"
        },
        {
            member: "Member",
            designation: "Ms.B.Bhavani, Asst.Prof., SMCE"
        },
        {
            member: "Member",
            designation: "Mr.K.Tirupathaiah, Asst.Prof., SMCE"
        },
        {
            member: "Member",
            designation: "Mrs.K.Thanuja, Asst.Prof., SMCE"
        },
    ]
    

    return (
        <>
            <h4 class="_Top">SRI MITTAPALLI COLLEGE OF ENGINEERING <br />
                Department of Electronics and Communication Engineering <br />
                Composition of Board of Studies
            </h4 >
            <h3 class="A">
                A.Y.: 2023-24
            </h3>
            <div>

                <table className='gb_table'>
                    <thead>
                        <tr>
                            <th>
                                S.NO
                            </th>
                            <th>
                                MEMBERS
                            </th>
                            <th>
                                NAME & DESIGNATION
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {ece.map((each, i) => <tr className='trs'>
                            <td>
                                {i + 1}
                            </td>
                            <td>
                                {each.member}
                            </td>
                            <td>
                                {each.designation}
                            </td>
                        </tr>)}
                    </tbody>
                </table>

            </div >
            <div className='bottom'>
                <h3 className='_chairperson'>Chairperson</h3>
                <p>BoS- Electronics and Communication Engineering</p>
            </div>
            <BOSFunctions/>
            `                   
        </>
    )
}

export default ECE
