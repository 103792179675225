import React from 'react'
import "./Notificationcss.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


const PressNote = () => {
  return (
    <div> 
<div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
          PRESS NOTES 
          </a>

          <Navbar  data-bs-theme="dark">
        <Container>
          
          <Nav className="me-auto">
            <Nav.Link style={{color:"#fff"}} href="/pressnote">PRESS NOTES </Nav.Link>
            <Nav.Link style={{color:"#fff"}}  href="/notification">NOTIFICATIONS </Nav.Link>
            <Nav.Link  style={{color:"#fff"}}  href="/events">EVENTS</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
          </div>
          </div><br/>

        
        <div className="table_container">
    <table className="table_main">
      <thead>
        <tr>
          <th>S.no</th>
          <th>Printed Date</th>
          <th>Paper Name</th>
          <th>Description</th>
          <th>Images</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>	2023-11-20</td>
          <td>
          sakshi </td>
          <td>bathukamma</td>
          <td>
            <h6>View Gallery</h6>
            <a href='http://saispurthi.ac.in/press/23-11-20/press_f1ca8da_1.jpg'>image_1</a><br/>
          <a href='http://saispurthi.ac.in/press/23-11-20/press_f1ca8da_2.jpg'>image_2</a></td>
        </tr>
      </tbody>
    </table>
       </div>
  </div>
  )
}

export default PressNote