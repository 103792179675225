import React from "react";
import "./Addmission.css";
import KeyboardDoubleArrowRightSharpIcon from "@mui/icons-material/KeyboardDoubleArrowRightSharp";

const AdmissionProcedur = () => {
  return (
    <div>
      <h2 className="addmision_heading">Admission procedure</h2>
      <div className="procedur_cntr">
        <br />
        <div className="procedur_p_text">
          <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp;
          <p>
            Sri Mittapalli College of Engineering was founded in 2006 under Sri
            Mittapalli Trust . The college is approved by the AICTE – a
            statutory body and the national-level council for technical
            education in India. It is permanently affiliated to Jawaharlal Nehru
            Technological University – Kakinada (JNTU-K).
          </p>
        </div>
        <div className="procedur_p_text">
          <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp;
          <p>
            SMCE offers B.Tech in Computer Science & Engineering, Computer
            Science & Engineering (Artificial Intelligence), Computer Science &
            Engineering (Data Science), Information Technology and Electronics &
            Communication Engineering with the total intake of 420 students.
          </p>
        </div>
        <div className="procedur_p_text">
          <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp;
          <p>
            The institute also offers M.Tech in Computer Science and
            Engineering, VLSI and Embedded systems 36; and MBA 120 respectively.
          </p>
        </div>
        <h4 className="addmision_heading_sub">Admission Criteria</h4>
        <div className="procedur_three_cntr">
          <h4 className="addmision_heading_sub_inside">B.Tech</h4>
          <div className="procedur_three_cntr_inside">
            <ul>
              <li>
                Pass 10+2 with the minimum of 45% aggregate with Physics and
                Mathematics as mandatory subjects
              </li>
              <li>
                70% admissions done on the basis of AP EAMCET ranks via online
                counselling
              </li>
              <li>
                30% admissions done under “B-Category” by management, based on
                marks in qualifying examination (10+2 or equivalent), as per the
                guidelines of the ANDHRA PRADESH STATE COUNCIL OF HIGHER
                EDUCATION – APSCHE
              </li>
            </ul>
          </div>
        </div>
        <div className="procedur_three_cntr">
          <h4 className="addmision_heading_sub_inside">M.Tech</h4>
          <div className="procedur_three_cntr_inside">
            <ul>
              <li>
                Have a BE/B.Tech degree in the relevant discipline from a
                recognised university, with the minimum of 50% marks
              </li>
              <li>
                70% of the admissions done on the basis of GATE /PGECET ranks
                via online counselling
              </li>
              <li>
                30% admissions done under our “B-Category” by management based
                on the merit in qualifying examination (BE/B.Tech/equivalent),
                as per the guidelines of the ANDHRA PRADESH STATE COUNCIL OF
                HIGHER EDUCATION –APSCHE
              </li>
            </ul>
          </div>
        </div>
        <div className="procedur_three_cntr">
          <h4 className="addmision_heading_sub_inside">MBA</h4>
          <div className="procedur_three_cntr_inside">
            <ul>
              <li>Any Bachelor degree with the minimum of 50% marks</li>
              <li>
                70% of the admissions done on the basis of APICET ranks via
                online counselling
              </li>
              <li>
                30% of admissions done under “B-Category” by management based on
                the merit in qualifying examination as per the guidelines of the
                ANDHRA PRADESH STATE COUNCIL OF HIGHER EDUCATION – APSCHE
              </li>
            </ul>
          </div>
        </div>
        <br />
        <br />
        <div className="procedur_p_text">
          <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp;
          <p>
            For the admissions in convener quota (70%), a notification will be
            issued by the APSCHE for online counselling. Any vacant seats under
            convener quota after last phase of counselling will be filled by the
            college under spot admissions.
          </p>
        </div>

        <div className="procedur_p_text">
          <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp;
          <p>
            For admission into “B-Category” for any course, a notification will
            be issued by the college. Candidates can apply online as well.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdmissionProcedur;
