import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/WebsiteTable";
import StarRateIcon from "@mui/icons-material/StarRate";

const Committees = () => {
  const website = [
    {
      title:"Identify strategic initiatives for the website and present it to  higher management for review and updating."
     
    },
    {
      title:"Develop a priority listing of desired design and functionality enhancements."
      
    },
    {
      title:"Identify to update and enhance website design and content."
    },
    {
      title:"Coordinating with other committees in posting relevant information."
      
    },
    {
      title:"Guide the faculty and students for effective usage of ICT tools."
    },
    {
      title:"To monitor all sub-committee related to internet, web site, repair and maintenance of computers."
      
    },
    {
      title:"To install software when and where required."
    },
    {
      title:"To monitor online examinations to conduct smoothly. To update the data in ECAP, Vidyawaan, website etc."
      
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            WEBSITE/ICT/INTERNET COMMITTEE
          </a>

        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
          Delivering the information about the college to the outside world. To create the awareness 
          about various activities and programmes like faculty development programmes, seminars and 
          workshops that are conducted by the organization.
          </p>
          <p>Website/ICT/Internet Committee is formed with the following members:</p>
          
        </div>
      </div>
      <div className="container">
        <Table />
      </div>
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {website.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
