import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import { PageHeader } from "../../Header/PageHeader";
import "../Departments.css";
import 'animate.css'

// Lab Images

import lab3 from "../../../assets/lab1.jpeg";
import lab2 from "../../../assets/lab3.jpeg";
import lab4 from "../../../assets/lab4.jpeg";
import lab1 from "../../../assets/lab6.jpeg";
import lab5 from "../../../assets/lab2.jpeg";
import lab6 from "../../../assets/lab4.jpeg";
import lab7 from "../../../assets/lab5.jpeg";
import lab8 from "../../../assets/lab6.jpeg";
import lab9 from "../../../assets/lab2.jpeg";
import lab10 from "../../../assets/lab4.jpeg";
import lab11 from "../../../assets/lab5.jpeg";
import lab12 from "../../../assets/lab3.jpeg";

const Labs = () => {
  const [key, setKey] = useState("All");

  return (
    <div className="container">
      <PageHeader name="Laboratories" />
      <Card style={{ width: "100%", margin: "20px 0px" }}>
        <Card.Header>CSE Laboratories</Card.Header>
        <Card.Body>
          <Card.Title>We have Fully Equipped Laboratories</Card.Title>
          <Card.Text>
            we have the several Computer Labs equipped with latest software's
            and workstations. It is connected to internet through a lease line
            to facilitate high speed internet access. All the computers are
            connected through a structured network. Printer and copier
            facilities are also available in the lab. Our dedicated faculty and
            staff are proud of their impressive credentials, which are
            comparable to those of the best career instructors.
          </Card.Text>
        </Card.Body>
      </Card>

      <div className="content">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="All" title="All">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab3}
                    alt="lab-img"
                  />
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab4}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab5}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab6}
                    alt="lab-img"
                  />
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab7}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab8}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab9}
                    alt="lab-img"
                  />
                  <br />
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab10}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab11}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab12}
                    alt="lab-img"
                  />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="COMMON INTERNET CENTER" title="COMMON INTERNET CENTER">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab10}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab11}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab12}
                    alt="lab-img"
                  />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="COMPUTER LAB1" title="COMPUTER LAB1">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab7}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab8}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab9}
                    alt="lab-img"
                  />
                  <br />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="COMPUTER LAB2" title="COMPUTER LAB2">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab3}
                    alt="lab-img"
                  />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="COMPUTER LAB3" title="COMPUTER LAB3">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab7}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab8}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab9}
                    alt="lab-img"
                  />
                  <br />
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Labs;
