import React from 'react'
import './Ict.css'
import "../GoverningBody/GoverningBody.css"

const Ict = () => {

    return (
        <div>
            <h2 className="ict_header">ICT Enabled Teaching Learning</h2>
            <div className='ict_container'>
                <div>
                    <h5 className='ict_header_2'>ICT - Enabled Tools for effective Teaching and Learning Process:</h5>
                    <p>
                        The Institute follows ICT enabled teaching in addition to the traditional classroom education. Subsequent efforts are taken by the institute to provide e-learning atmosphere in the classroom:
                    </p>
                    <p>1. In addition to chalk and talk method of teaching, the faculty members are using the IT enabled learning tools such as PPT, Video clippings , Audio system, online sources, to expose the students for advanced knowledge and practical learning.</p>
                    <p>2. Classrooms are fully furnished with LCD/OHP/Computers</p>
                    <p>3. Most of the faculty use interactive methods for teaching. The major emphasis is on classroom interaction in terms of research paper presentations, seminars, debates, group discussions, assignments, quiz/tests/viva and laboratory work.</p>
                </div>
                <div>
                    <h5 className='ict_header_2'>Institute premises are Wi-Fi enabled:</h5>
                    <p>1. Specialized computer laboratory with an internet connection has been provided to promote independent learning. MAC-ID based Wi-Fi facility for access of internet is provided on individual laptop and mobile devices.</p>
                    <p>2. Well security is provided to Wi-Fi users. Its access is controlled by the system administrator.</p>
                    <table className='ict_table'>
                        <tr>
                            <th>1</th>
                            <th>ICT Tools and Resources Available</th>
                            <td>LCD, OHP, e-Books, e-Journals,
                                Internet, Wifi-Campus, Interactive Board,
                                Statistical Softwares, Videos
                            </td>
                        </tr>
                        <tr>
                            <th>2</th>
                            <th>E-Resources and Techniques Used</th>
                            <td>e-Books, e-Journals, Wifi - Campus, Online and Offline Videos, Educational CDs, Animation Templates</td>
                        </tr>
                        <tr>
                            <th>3</th>
                            <th>Number of ICT Enabled Classrooms</th>
                            <td>10</td>
                        </tr>
                        <tr>
                            <th>4</th>
                            <th>Number of Smart Classrooms</th>
                            <td>3</td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default Ict
