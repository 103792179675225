import React from "react";
import { Link } from "react-router-dom";
import company1 from "../../assets/Companies/ACCENTURE.jpg";
import company2 from "../../assets/Companies/Allsee.jpg";
import company3 from "../../assets/Companies/Applied-ME-Services.jpg";
import company4 from "../../assets/Companies/Blaze-Automation.jpg";
import company5 from "../../assets/Companies/CSS-Corp.jpg";
import company6 from "../../assets/Companies/DECCAN-SOFT.jpg";
import company7 from "../../assets/Companies/HCL.jpg";
import company8 from "../../assets/Companies/Hetero-Drugs.jpg";
import company9 from "../../assets/Companies/I9-info-com.jpg";
import company10 from "../../assets/Companies/igenieses_final.jpg";
import company11 from "../../assets/Companies/Impact-Informatics.jpg";
import company12 from "../../assets/Companies/Infosys.jpg";
import company13 from "../../assets/Companies/it.jpg";
import company14 from "../../assets/Companies/PRITHVI-SOLUTIONS.jpg";
import company15 from "../../assets/Companies/RAJ-SOFT-SOLUTIONS.jpg";
import company16 from "../../assets/Companies/SRAM-INTERFACE-Pvt.Ltd.jpg";
import company17 from "../../assets/Companies/SUNDARAM FINANCE.jpg";
import company18 from "../../assets/Companies/WIPRO.jpg";
import "./Companies.css";

const Companies = () => {
  return (
    <div>
      <div className="page_header">
        <h1>Companies</h1>
      </div>
      <div className="companies_img_cnt">
        <div className="photo-container mb-5">
          <marquee behavior="scroll" direction="left" scrollamount="10">
            <div className="d-flex flex-row gap-5">
              <img
                className="img-fluid rounded"
                src={company1}
                alt="company1-img"
              />
              <img
                className="img-fluid rounded"
                src={company2}
                alt="company2-img"
              />
              <img
                className="img-fluid rounded"
                src={company3}
                alt="company3-img"
              />
              <img
                className="img-fluid rounded"
                src={company4}
                alt="company4-img"
              />
              <img
                className="img-fluid rounded"
                src={company5}
                alt="company5-img"
              />
              <img
                className="img-fluid rounded"
                src={company6}
                alt="company6-img"
              />
            </div>
          </marquee>
        </div>
        <div className="photo-container mb-5">
          <marquee behavior="scroll" direction="right" scrollamount="10">
            <div className="d-flex flex-row gap-5">
              <img
                className="img-fluid rounded"
                src={company7}
                alt="company7-img"
              />
              <img
                className="img-fluid rounded"
                src={company8}
                alt="company8-img"
              />
              <img
                className="img-fluid rounded"
                src={company9}
                alt="company9-img"
              />
              <img
                className="img-fluid rounded"
                src={company10}
                alt="company10-img"
              />
              <img
                className="img-fluid rounded"
                src={company11}
                alt="company11-img"
              />
              <img
                className="img-fluid rounded"
                src={company12}
                alt="company12-img"
              />
            </div>
          </marquee>
        </div>
        <div className="photo-container mb-5">
          <marquee behavior="scroll" direction="left" scrollamount="15">
            <div className="d-flex flex-row gap-5">
              <img
                className="img-fluid rounded"
                src={company13}
                alt="company13-img"
              />
              <img
                className="img-fluid rounded"
                src={company14}
                alt="company14-img"
              />
              <img
                className="img-fluid rounded"
                src={company15}
                alt="company15-img"
              />
              <img
                className="img-fluid rounded"
                src={company16}
                alt="company16-img"
              />
              <img
                className="img-fluid rounded"
                src={company17}
                alt="company17-img"
              />
              <img
                className="img-fluid rounded"
                src={company18}
                alt="company18-img"
              />
            </div>
          </marquee>
        </div>
      </div>
    </div>
  );
};

export default Companies;
