import React from 'react'
import 'animate.css'

const Team = () => {
  return (
    <div className="w-100 d-flex justify-content-center animate__animated animate__zoomIn">
      <h3 style={{}}>No Team Found !</h3>
    </div>
  )
}

export default Team
