import React from 'react'
import Greviencecommitteetable from '../../Components/GrevienceCommittee/GreviencecommitteeTable';

const GrevienceCommittee = () => {
  return (
    <div> 
         <div class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <a class="navbar-brand" href="#">
      INSTITUTE LEVEL COMMITTEES
      </a>
    </div>
  </div>
 <Greviencecommitteetable/>
</div>
  )
}

export default GrevienceCommittee