import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/HostelTable";
import StarRateIcon from "@mui/icons-material/StarRate";



const Committees = () => {
  const hostels =[
    {
      title:"To make surprise visits to the hostels."
    },
    {
     title:"To make suggestions for improvements in the functioning of the hostel and mess in order to maintain cleanliness and discipline."
    },
    {
      title:"To properly maintain hygiene conditions in the hostel."
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
          HOSTEL ADVISORY COMMITTEE
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
          Hostel and Mess committee is responsible for the smooth functioning of the hostel. Mess facilities at Sri Mittapalli College of Engineering.


          </p>
          
        </div>
      </div>
      <div className="container">
        <Table />
      </div>
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {hostels.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
     
    </div>
  );
};

export default Committees;
