  import React, { useState } from "react";
  import { Link } from "react-router-dom";
  import "./Contactus.css";
  import axios from "axios";
  import { resources } from "../Resourses/Resources";

  const Contactus = () => {
    const [contactUs,setContactUs] = useState({
      name:"",
      email:"",
      message:""
    })
    const handleChangeContact = (e)=>{
      const {name,value} = e.target
      setContactUs({
        ...contactUs,
        [name]:value
      })

    }
    const handleSubmitContact  = async (e) =>{
      e.preventDefault();

      try {
  
        const response = await axios.post(
          resources.APPLICATION_URL + "saveContactUs",
        contactUs)
        console.log("response==>",response)
      }catch(e){
        console.log(e)
      }
    }
    return (
      <div>
        <h2 className="contact_head">Contact US</h2>
      <div className="contact_main">
        <div className="contact_container">
          <h4>smce</h4><br/>
          <p><span className="contact_span">Principal :-</span> 123456789</p>
          <p><span className="contact_span">Director :-</span> 123456789</p><br/>
          <p>
          <span className="contact_span">Address :</span><br/>
a.p          </p>
        </div>
        <div className="contact_container">
          <h4>Mail US</h4>
          <div className="contact_inp_container">
            <label>Name</label><br/>
            <input value={contactUs.name} name="name" onChange={(e)=>handleChangeContact(e)} placeholder="Enter Your Full Name" type="text"/>
          </div>
          <div className="contact_inp_container">
            <label>Email</label><br/>
            <input value={contactUs.email} name="email" onChange={(e)=>handleChangeContact(e)} placeholder="Enter Your Email" type="email"/>
          </div>
          <div className="contact_inp_container">
            <label>Message</label><br/>
            <textarea value={contactUs.message} onChange={(e)=>handleChangeContact(e)} name="message" placeholder="Enter Your Message"/>
          </div>
          <div className="contact_inp_container"><button onClick={handleSubmitContact}>Send</button></div>
          
        </div>
      </div>
      </div>
    );
  };

  export default Contactus;
