import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/R&DconsultantTable";
import StarRateIcon from "@mui/icons-material/StarRate";

const Committees = () => {
  const RDConsultant = [
    {
      title:"Identifies the thrust areas for research and helps in preparing the related documents to apply for funding."
    },
    {
      title:"Encourages the faculty members and the students to involve in research by providing suitable environment."
    },
    {
      title:"Recognizes the areas for consultancy works and approves the proposals."
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            RESEARCH & DEVELOPMENT AND CONSULTANCY COMMITTEE
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            The R & D committee comprises of faculty members from various
            departments in the Institution. This committee oversees the smooth
            and efficient co-ordination of research and development activities
            in the Institution, thus fostering overall growth. The members will
            contribute towards enhancing research and development at Sri
            Mittapalli College of Engineering.
          </p>
          <p>Research & Development and Consultancy Committee is formed with the following members:</p>
          
        </div>
      </div>
      <div className="container">
        <Table />
      </div>
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {RDConsultant.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
