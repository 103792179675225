import React from 'react'
import "./Addmission.css"

const AddmisionContact = () => {
    return (
        <div>
          <h2 className='addmision_heading'>Contact Details</h2>
        <div className="contact_main">
          <div className="contact_container">
            <h4>SRI MITTAPALLI COLLEGE OF ENGINEERING</h4><br/>
            <p  className="contact_span">
            Dr. M. Nageswara Rao , Controller of Examinations<br/>
            Mobile No:- +91 9121214729<br/>
            E-Mail:- cemittapalli@gmail.com
            </p><br/>
            <p  className="contact_span">
            Mr. P. Pradeep , Asst. Controller of Examination<br/>
            Mobile No:- +91 9121214729<br/>
            E-Mail :- smce.examsincharge@gmail.com
            </p>
            <p>
            <span className="contact_span">Address :</span>
            NH-16, Tummalapalem (Village), Guntur (Dist),<br/>Andhra Pradesh – 522 233.
            </p>
          </div>
          <div className="contact_container">
            <iframe 
              className="position-relative rounded w-100 h-100"
              src="https://maps.google.com/maps?q=Sri%20Mittapalli%20College%20Of%20Engineering%2C%20Tummalapalem%2C%20NH16%2C%20Guntur%2C%20Andhra%20Pradesh%20522233&t=m&z=10&output=embed&iwloc=near"
              frameborder="0"
              style={{ minHeight: "200px", border: "0" }}
              allowfullscreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
        </div>
        </div>
      );
}

export default AddmisionContact
