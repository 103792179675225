import React from 'react'
import { RotatingLines } from 'react-loader-spinner'


const RotatingSpinner = () => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%", height: "100%",
            display: "flex", justifyContent: "center",
            alignItems: "center", position: "absolute", zIndex: 100, top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(0,0,0,0.7)"
        }}>
            <RotatingLines
                visible={true}
                height="100"
                width="100"
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
            <p style={{color:"#fff",fontSize:"20px",fontWeight:300,marginTop:"20px", textAlign:"center", fontFamily:"Arial",letterSpacing:"2px"}}>Please Wait</p>
        </div>
    )
}

export default RotatingSpinner
