import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import Check from '@mui/icons-material/Check';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export default function Card1() {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))',
        gap: 3,
       
      }}
    >
      <Card size="lg" variant="outlined">
        <Chip size="sm" variant="outlined" color="neutral">
        Duration: 3 Years
        </Chip>
        <Typography level="h2">Diploma</Typography>
        <Divider inset="none" />
        <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Counselling
          </ListItem>
          <CardActions>
          <Typography level="title-lg" sx={{ mr: 'auto' }}>
          ₹14900{' '}
            <Typography fontSize="sm" textColor="text.tertiary">
              / Year
            </Typography>
          </Typography>
          <Button
            variant="soft"
            color="neutral"
            endDecorator={<KeyboardArrowRight />}
          >
            Start now
          </Button>
        </CardActions>
        <Divider inset="none" />
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            NRI/Management 
          </ListItem>
          <CardActions>
          <Typography level="title-lg" sx={{ mr: 'auto' }}>
          ₹14900{' '}
            <Typography fontSize="sm" textColor="text.tertiary">
              / Year
            </Typography>
          </Typography>
          <Button
            variant="soft"
            color="neutral"
            endDecorator={<KeyboardArrowRight />}
          >
            Start now
          </Button>
        </CardActions>
         
          
        </List>
        <Divider inset="none" />
       
      </Card>

      <Card size="lg" variant="outlined">
        <Chip size="sm" variant="outlined" color="neutral">
        Duration: 4 Years
        </Chip>
        <Typography level="h2">Under Graduation</Typography>
        <Divider inset="none" />
        <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Counselling
          </ListItem>
          <CardActions>
          <Typography level="title-lg" sx={{ mr: 'auto' }}>
          ₹70000{' '}
            <Typography fontSize="sm" textColor="text.tertiary">
              / Year
            </Typography>
          </Typography>
          <Button
            variant="soft"
            color="neutral"
            endDecorator={<KeyboardArrowRight />}
          >
            Start now
          </Button>
        </CardActions>
        <Divider inset="none" />
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            NRI/Management 
          </ListItem>
          <CardActions>
          <Typography level="title-lg" sx={{ mr: 'auto' }}>
          ₹70000{' '}
            <Typography fontSize="sm" textColor="text.tertiary">
              / Year
            </Typography>
          </Typography>
          <Button
            variant="soft"
            color="neutral"
            endDecorator={<KeyboardArrowRight />}
          >
            Start now
          </Button>
        </CardActions>
         
          
        </List>
        <Divider inset="none" />
       
      </Card>

      <Card size="lg" variant="outlined">
        <Chip size="sm" variant="outlined" color="neutral">
        Duration: 2 Years
        </Chip>
        <Typography level="h2">Post Graduation</Typography>
        <Divider inset="none" />
        <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Counselling
          </ListItem>
          <CardActions>
          <Typography level="title-lg" sx={{ mr: 'auto' }}>
          ₹54000{' '}
            <Typography fontSize="sm" textColor="text.tertiary">
              / Year
            </Typography>
          </Typography>
          <Button
            variant="soft"
            color="neutral"
            endDecorator={<KeyboardArrowRight />}
          >
            Start now
          </Button>
        </CardActions>
        <Divider inset="none" />
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            NRI/Management 
          </ListItem>
          <CardActions>
          <Typography level="title-lg" sx={{ mr: 'auto' }}>
          ₹54000{' '}
            <Typography fontSize="sm" textColor="text.tertiary">
              / Year
            </Typography>
          </Typography>
          <Button
            variant="soft"
            color="neutral"
            endDecorator={<KeyboardArrowRight />}
          >
            Start now
          </Button>
        </CardActions>
         
          
        </List>
        <Divider inset="none" />
       
      </Card>
     
    </Box>
  );
}
