import React from "react";
import Chairman1 from "../assets/directormi-1.jpg";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import "./CheairmanText.css";

const DirectorMessage = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="frame">
                <img
                  className="DM_img"
                  src={Chairman1}
                  alt="principal-img"
                 
                /><br/><br/>
                <p style={{textAlign:"center"}}>
                    <h4><b>Mr.M.S.Chakravarthi</b> </h4>
                    <h6><b> M.S. (USA) Director</b></h6>
                    <h6><b>  Director</b></h6>
                    </p> 
                
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h4 className="section-title bg-white text-start text-primary pe-3">
                Director Message
              </h4><br/>
              <p style={{textAlign:"justify"}}>
                <p >
                  Sri Mittapalli College of Engineering, with more than a decade
                  of its, has contributed to technical education in a big way
                  and leaping ahead to carry out the legacy
                </p>

                <p className=" font_st mb-2">
                  Endowed with a well-laid-out Campus, well-qualified faculty,
                  and a good academic ambience, the college endeavours to be a
                  top-ranking higher educational institution in the surroundings
                  of Guntur and Chilakaluripet. A large number of initiations
                  like hobby clubs, several outreach programs, and a
                  well-disciplined student community distinguishes SMCE as a
                  centre for quality education. Our placements have been
                  reasonably good.
                </p>

                <p className=" font_st mb-2">
                  We aim to impart education combined with the creation,
                  dissemination, and application of knowledge. As we strive for
                  excellence in what we do, the Institute is constantly making
                  endeavours to scale new heights by developing synergy between
                  academic knowledge and technical skillset in line with
                  industry needs. The Institute fosters and nurtures leaders
                  capable of making difference in the dimensions of Engineering
                  and Management. It inculcates human values and professional
                  ethics in the students, which help them to make decisions and
                  create path that can enhance their career. We welcome all
                  students to join us to make SMCE a place to grow and hone
                  their skills.
                </p>
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectorMessage;
