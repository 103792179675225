import React, { useState } from "react";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import { toast } from "react-toastify";
import RotatingSpinner from "../Resourses/RotatingSpinner";

const dept = ["ECE", "CSE", "EEE", "MECH", "CIVIL", "CSE"];
const reg = ["R16", "R17", "R18", "R19", "R20"];
const academicYear = [];
const year = new Date().getFullYear();
for (let i = year - 5; i <= year; i++) {
  academicYear.push(i);
}
for (let i = year + 1; i <= year + 5; i++) {
  academicYear.push(i);
}

const successMessage = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "success1",
    className: "success",
  });
};
const errorMessage = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "error1",
    className: "error",
  });
};

const EmployeeFeedback = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [EmployeeFeedback, setEmployeeFeedback] = useState({
    employerName: "",
    designation: "",
    organiztion: "",
    academicYear: "",
    industrialRequirements: "",
    significanceOfGlobalEmployement: "",
    leaderShipSkills: "",
    workQualities: "",
    technologicalAdvancements: "",
    scopeOfEmployabilty: "",
    ictTools: "",
    passionWork: "",
    creativity: "",
    aspirationsInStudents: "",
    anySuggestions: "",
  });

  const handleEmployeeFeedbackDetails = (e) => {
    const { value, name } = e.target;
    setEmployeeFeedback({ ...EmployeeFeedback, [name]: value });
  };

  const handleSubmitDataToBackend = async () => {
    console.log(EmployeeFeedback);
    setIsLoading(true);
    try {
      const response =await axios.post(
        resources.APPLICATION_URL + "saveEmployeeFeedBackDetails",
        EmployeeFeedback
      );
      console.log("response after success===>",response)

      if (response.data==="Successfully added feedBack") {
        console.log("response after success===>",response)
        setEmployeeFeedback({
          employerName: "",
          designation: "",
          organiztion: "",
          academicYear: "",
          industrialRequirements: "",
          significanceOfGlobalEmployement: "",
          leaderShipSkills: "",
          workQualities: "",
          technologicalAdvancements: "",
          scopeOfEmployabilty: "",
          ictTools: "",
          passionWork: "",
          creativity: "",
          aspirationsInStudents: "",
          anySuggestions: "",
        })
        successMessage("Thank you for your valuable feedback");
      } else {
        errorMessage("Something went wrong. Please try again later");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
console.log("EmployeeFeedback==>",EmployeeFeedback)
  return (
    <>
      {isLoading ? (
        <RotatingSpinner />
      ) : (
        <div style={{ padding: "50px" }}>
          <div className="teacher_feedback_heading_container">
            <h1 className="teacher_form_heading">
              Employer Feedback on Academic Performance and Institution Ambience
            </h1>
          </div>
          <form className="teacher_feedback_form_main_container">
            <div className="teacher_feedback_form_container">
              <label htmlFor="name">1.Name of the Employer</label>
              <input
                onChange={(e) => handleEmployeeFeedbackDetails(e)}
                type="text"
                id="employerName"
                name="employerName"
                value={EmployeeFeedback.employerName}
                required
                placeholder="Enter employerName"
              />
            </div>
            <div className="teacher_feedback_form_container">
              <label htmlFor="name">2.Designation</label>
              <input
                onChange={(e) => handleEmployeeFeedbackDetails(e)}
                type="email"
                id="designation"
                name="designation"
                value={EmployeeFeedback.designation}
                required
                placeholder="Enter Designation"
              />
            </div>
            <div className="teacher_feedback_form_container">
              <label htmlFor="name">3.Organization</label>
              <input
                onChange={(e) => handleEmployeeFeedbackDetails(e)}
                type="text"
                id="organiztion"
                name="organiztion"
                required
                placeholder="Enter Designation"
              />
            </div>
            <div className="teacher_feedback_form_container">
              <label>4.Academic Year</label>
              <select
                id="academicYear"
                name="academicYear"
                value={EmployeeFeedback.academicYear}
                              onChange={(e) => handleEmployeeFeedbackDetails(e)}
              
              >
                <option>Please select...</option>
                {academicYear
                  .filter((each) => each !== "")
                  .map((each) => (
                    <option key={each}>{each}</option>
                  ))}
              </select>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                5.How efficient does the curriculum cater to the Industrial
                Requirements
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                       <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="courseContent"
                  value="Excellent"
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  // checked={EmployeeFeedback.industrialRequirements == "Excellent"}
                  />


                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="courseContent"
                  id="courseContent"
                  value="Very Good"

                  // checked={EmployeeFeedback.industrialRequirements == "Very Good"}

                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="courseContent"
                  id="courseContent"
                  value="Good"

                  // checked={EmployeeFeedback.industrialRequirements == "Good"}

                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="courseContent"
                  id="courseContent"
                  value="Average"

                  // checked={EmployeeFeedback.industrialRequirements == "Average"}

                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="courseContent"
                  id="courseContent"
                  value="Below Average"

                  // checked={EmployeeFeedback.industrialRequirements == "Below Average"}

                />
                <span>Below Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>6.Significance of courses towards global employment</label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="significanceOfGlobalEmployement"
                  id="significanceOfGlobalEmployement"
                  value="Excellent"

                  // checked={EmployeeFeedback.significanceOfGlobalEmployement == "Excellent"}

                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="significanceOfGlobalEmployement"
                  id="significanceOfGlobalEmployement"
                  value="Very Good"

                  // checked={EmployeeFeedback.significanceOfGlobalEmployement == "Very Good"}

                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="significanceOfGlobalEmployement"
                  id="significanceOfGlobalEmployement"
                  value="Good"

                  // checked={EmployeeFeedback.significanceOfGlobalEmployement == "Good"}

                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="significanceOfGlobalEmployement"
                  id="significanceOfGlobalEmployement"
                  value="Average"

                  // checked={EmployeeFeedback.significanceOfGlobalEmployement == "Average"}

                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="significanceOfGlobalEmployement"
                  id="significanceOfGlobalEmployement"
                  value="Below Average"

                  // checked={EmployeeFeedback.significanceOfGlobalEmployement == "Below Average"}

                />
                <span>Below Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>7.Organization promotes leadership skills</label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="leaderShipSkills"
                  value="Excellent"

                  id="leaderShipSkills"
                  // checked={EmployeeFeedback.leaderShipSkills == "Excellent"}

                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="leaderShipSkills"
                  value="Very Good"

                  // checked={EmployeeFeedback.leaderShipSkills == "Very Good"}

                  id="leaderShipSkills"
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="leaderShipSkills"
                  id="leaderShipSkills"
                  value="Good"

                  // checked={EmployeeFeedback.leaderShipSkills == "Good"}

                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="leaderShipSkills"
                  id="leaderShipSkills"
                  value="Average"

                  // checked={EmployeeFeedback.leaderShipSkills == "Average"}

                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="leaderShipSkills"
                  id="leaderShipSkills"
                  value="Below Average"

                  // checked={EmployeeFeedback.leaderShipSkills == "Below Average"}

                />
                <span>Below Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                8.Inculcation of professional ethics and team work qualities
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="workQualities"
                  id="workQualities"
                  value="Excellent"

                  // checked={EmployeeFeedback.workQualities == "Excellent"}

                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="workQualities"
                  value="Very Good"

                  id="workQualities"
                  // checked={EmployeeFeedback.workQualities == "Very Good"}

                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="workQualities"
                  id="workQualities"
                  value="Good"

                  // checked={EmployeeFeedback.workQualities == "Good"}

                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="workQualities"
                  id="workQualities"
                  value="Average"

                  // checked={EmployeeFeedback.workQualities == "Average"}

                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="workQualities"
                  id="workQualities"
                  value="Below Average"

                  // checked={EmployeeFeedback.workQualities == "Below Average"}

                />
                <span>Below Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                9.Inter- Disciplinary subjects offered are up to mark regarding
                technological advancements
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="technologicalAdvancements"
                  id="technologicalAdvancements"
                  // checked={EmployeeFeedback.technologicalAdvancements == "Excellent"}
                  value="Excellent"

                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="technologicalAdvancements"
                  id="technologicalAdvancements"
                  value="Very Good"

                  // checked={EmployeeFeedback.technologicalAdvancements == "Very Good"}

                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="technologicalAdvancements"
                  id="technologicalAdvancements"
                  // checked={EmployeeFeedback.technologicalAdvancements == "Good"}
                  value="Good"

                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="technologicalAdvancements"
                  id="technologicalAdvancements"
                  // checked={EmployeeFeedback.technologicalAdvancements == "Average"}
                  value="Average"

                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="technologicalAdvancements"
                  id="technologicalAdvancements"
                  // checked={EmployeeFeedback.technologicalAdvancements == "Below Average"}
                  value="Below Average"

                />
                <span>Below Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>10.Provides vast scope for Employability</label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="scopeOfEmployabilty"
                  id="scopeOfEmployabilty"
                  value="Excellent"

                  // checked={EmployeeFeedback.scopeOfEmployabilty == "Excellent"}

                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="scopeOfEmployabilty"
                  id="scopeOfEmployabilty"
                  // checked={EmployeeFeedback.scopeOfEmployabilty == "Very Good"}
                  value="Very Good"

                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="scopeOfEmployabilty"
                  id="scopeOfEmployabilty"
                  // checked={EmployeeFeedback.scopeOfEmployabilty == "Good"}
                  value="Good"

                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="scopeOfEmployabilty"
                  id="scopeOfEmployabilty"
                  // checked={EmployeeFeedback.scopeOfEmployabilty == "Average"}
                  value="Average"

                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="scopeOfEmployabilty"
                  id="scopeOfEmployabilty"
                  // checked={EmployeeFeedback.scopeOfEmployabilty == "Below Average"}
                  value="Below Average"

                />
                <span>Below Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>11.Practical exposure to ICT tools</label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="ictTools"
                  id="ictTools"
                  // checked={EmployeeFeedback.ictTools == "Excellent"}
                  value="Excellent"


                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="ictTools"
                  id="ictTools"
                  // checked={EmployeeFeedback.ictTools == "Very Good"}
                  value="Very Good"

                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="ictTools"
                  id="ictTools"
                  // checked={EmployeeFeedback.ictTools == "Good"}
                  value="Good"

                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="ictTools"
                  id="ictTools"
                  // checked={EmployeeFeedback.ictTools == "Average"}
                  value="Average"

                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="ictTools"
                  id="ictTools"
                  // checked={EmployeeFeedback.ictTools == "Below Aaverage"}
                  value="Below Average"

                />
                <span>Below Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>12.Builds passion towards work</label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="passionWork"
                  id="passionWork"
                  value="Excellent"

                  // checked={EmployeeFeedback.passionWork == "Excellent"}

                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="passionWork"
                  value="Very Good"

                  id="passionWork"
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="passionWork"
                  id="passionWork"
                  value="Good"

                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="passionWork"
                  id="passionWork"
                  value="Average"

                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="passionWork"
                  value="Below Average"

                  id="passionWork"
                />
                <span>Below Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                13.Flexibility towards innovativeness and creativity
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="creativity"
                  id="creativity"
                  value="Excellent"

                  onChange={(e) => handleEmployeeFeedbackDetails(e)}

                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="creativity"
                  value="Very Good"

                  id="creativity"
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}

                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="creativity"
                  id="creativity"
                  value="Good"

                  onChange={(e) => handleEmployeeFeedbackDetails(e)}

                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="creativity"
                  id="creativity"
                  value="Average"

                  onChange={(e) => handleEmployeeFeedbackDetails(e)}

                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="creativity"
                  value="Below Average"

                  id="creativity"
                  onChange={(e) => handleEmployeeFeedbackDetails(e)}

                />
                <span>Below Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                14.Induction of research zeal and future aspirations in students
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="aspirationsInStudents"
                  id="aspirationsInStudents"
                  value="Excellent"

                  onChange={(e) => handleEmployeeFeedbackDetails(e)}

                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="aspirationsInStudents"
                  id="aspirationsInStudents"
                  value="Very Good"

                  onChange={(e) => handleEmployeeFeedbackDetails(e)}

                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="aspirationsInStudents"
                  id="aspirationsInStudents"
                  value="Good"

                  onChange={(e) => handleEmployeeFeedbackDetails(e)}

                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="aspirationsInStudents"
                  id="aspirationsInStudents"
                  value="Average"

                  onChange={(e) => handleEmployeeFeedbackDetails(e)}

                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="aspirationsInStudents"
                  id="aspirationsInStudents"
                  value= "Below Average"

                  onChange={(e) => handleEmployeeFeedbackDetails(e)}

                />
                <span>Below Average</span>
              </div>
            </div>
            <div className="teacher_feedback_form_container">
              <label htmlFor="name">Any Suggestions</label>
              <textarea
                cols={45}
                rows={5}
                type="text"
                onChange={(e) => handleEmployeeFeedbackDetails(e)}
                id="anySuggestions"
                name="anySuggestions"
                value={EmployeeFeedback.anySuggestions}

                required
                placeholder="Enter Any Suggestions"
              />
            </div>
            <button
              onClick={() => handleSubmitDataToBackend()}
              className="submit_btn"
              type="button"
            >
              Submit
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default EmployeeFeedback;
