import React from 'react'
import "./BOS.css"
import BOSFunctions from './BOSFunctions'

const IT = () => {
    const it = [
        {
            member: "Chairperson",
            designation: "Mrs.SD.Reshma,Assoc.Professor in IT Dept. & HOD Cell: 8125544167 EMAIL:syedreshma17@gmail.com"
        },
        {
            member: "University Nominee",
            designation: "Dr. A.Krishna Mohan,Professor of CSE, UCEK,JNTU Kakinada"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr C. Nagaraju,M.Tech., Ph.D.Dept.of CSE Dr YSR Engineering college Yogivemana University ,Proddatoor Kadapa District Cell: 9949218570 Email: cnrcse@yahoo.com"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr Shaheda Akthar Assoc Professor Dept of the Computer Science Govt College for women (A) Guntur.9885834601 Shahedaakthar76@gmail.com"
        },
        {
            member: "Representative from Industry",
            designation: "Devineni Ramana Reddy/Prasad Reddy Technical Manager Sarja Technologies , Hyd hello@sarjatechnologies.com 6281137108"
        },
        {
            member: "Alumni Member",
            designation: "Ms. Kolla Priyanka B.Tech.,System Engineer,Tata Consultancy Services Pvt. Ltd., 8977103203 kolla.priyanka@tcs.com"
        },
        {
            member: "Member",
            designation: "Mr.M. Ashok Naga Sai, Asst.Prof.,SMCE"
        },
        {
            member: "Member",
            designation: "Mr.J Ramesh, Asst.Prof.,SMCE"
        },

    ]

    return (
        <>
            <h4 class="_Top">SRI MITTAPALLI COLLEGE OF ENGINEERING <br />
                Department of Information Technology<br />
                Composition of Board of Studies
            </h4 >
            <h3 class="A">
                A.Y.: 2023-24
            </h3>
            <div>
                <table className='gb_table'>
                    <thead>
                        <tr>
                            <th>
                                S.NO
                            </th>
                            <th>
                                MEMBERS
                            </th>
                            <th>
                                NAME & DESIGNATION
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {it.map((each, i) => <tr>
                            <td>
                                {i + 1}
                            </td>
                            <td>
                                {each.member}
                            </td>
                            <td>
                                {each.designation}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
            <div className='bottom'>
                <h3 className='_chairperson'>Chairperson</h3>
                <p>BoS-Information Technology</p>
            </div>
            <BOSFunctions />
        </>
    )
}

export default IT
