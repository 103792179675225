import React from "react";
import "../Academics/Admissins/Addmission.css";
import KeyboardDoubleArrowRightSharpIcon from "@mui/icons-material/KeyboardDoubleArrowRightSharp";

const Coe = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            CONTROLLER OF EXAMINATIONS
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="container">
          <div>
            <div className="procedur_cntr">
              <br />
              <div className="prg_content">
                <h2 className="addmision_heading">About: </h2>

                <p>
                  Sri Mittapalli College of Engineering being Autonomous has
                  established an examination cell headed by a Controller of
                  Examinations for conducting examinations, result processing,
                  issue of certificates etc. The Controller of Examinations
                  shall report to the Principal periodically on the execution &
                  performance of duties.
                </p>
              </div>
              {/* <div className="procedur_p_text">
          <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp;
          <p>
            SMCE offers B.Tech in Computer Science & Engineering, Computer
            Science & Engineering (Artificial Intelligence), Computer Science &
            Engineering (Data Science), Information Technology and Electronics &
            Communication Engineering with the total intake of 420 students.
          </p>
        </div> */}

              <h4 className="addmision_heading_sub">Responsibilities:</h4>
              <div className="procedur_three_cntr">
                <div className="procedur_three_cntr_inside">
                  <ul>
                    <KeyboardDoubleArrowRightSharpIcon />{" "}
                    &nbsp;&nbsp;&nbsp;He/she shall be responsible for the
                    conduct of all examinations and it shall be his/her duty to
                    arrange for the preparation, scheduling, evaluation and
                    reporting of all examinations and for the payment of
                    remuneration to question paper setters and examiners and all
                    other contingent matters connected with examinations.
                    <br />
                    <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp;
                    Direct superintending control over the examination wing
                    including examination sections, examination confidential
                    wing, examination stores, examination computer section and
                    records.
                    <br />
                    <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp;
                    Making necessary arrangements for the safe custody of
                    office files connected with the conduct of examinations of
                    the university, documents, certificates etc. by the officers
                    under whom such documents are kept.
                    <br />
                    <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp;
                    He/she shall convene meetings and issue notices to the
                    Boards of Examiners and committees appointed by the
                    examination cell and conduct official communications
                    thereof.
                    <br />
                    <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp;
                    Taking special care to see that secrecy and confidentiality
                    are kept in connection with all examinations conducted by
                    the university.
                    <br />
                    <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp;
                    Exercising control over the space allotted for the
                    examination wing including that for centralized valuation.
                    Further he/she should ensure that the rooms, building,
                    laboratories, stores etc are well in order / prepared to
                    conduct the examinations.
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coe;
