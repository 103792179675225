import React, { useState } from 'react';
import './SmceStudentGrivanceCell.css';
import Introduction from './Introduction';
import Objective from './Objective';
import Mechanism from './Mechanism';
import GrievanceCommitee from './GrievanceCommitee';
import GrievanceContact from './GrievanceContact';
import OnlineSubmission from './onlineSubmission';

const SmceStudentGrivanceCell = () => {
    const [show, setShow] = useState("");

    return (
        <div>
            <div className='SmceStudentGrivanceCell'>
                <div className='gr_btn_cntr'>
                    <button
                        className={show === "" ? "active" : "gr_btn"}
                        onClick={() => setShow("")}
                    >
                        Introduction
                    </button>
                    <button
                        className={show === "Objective" ? "active" : "gr_btn"}
                        onClick={() => setShow("Objective")}
                    >
                        Objectives
                    </button>
                    <button
                        className={show === "Mechanism" ? "active" : ""}
                        onClick={() => setShow("Mechanism")}
                    >
                        Mechanism to resolve and disposal of grievances
                    </button>
                    <button
                        className={show === "GrievanceCommitee" ? "active" : ""}
                        onClick={() => setShow("GrievanceCommitee")}
                    >
                        Grievance Redressal Committee
                    </button>
                    <button
                        className={show === "GrievanceContact" ? "active" : ""}
                        onClick={() => setShow("GrievanceContact")}
                    >
                        Grievance Contact Information
                    </button>
                    <button
                        className={show === "onlineSubmission" ? "active" : ""}
                        onClick={() => setShow("onlineSubmission")}
                    >
                        Online Grievance Submission
                    </button>
                </div>

                <div className='gr_body_cntr'>
                    {show === "" && <Introduction />}
                    {show === "Objective" && <Objective />}
                    {show === "Mechanism" && <Mechanism />}
                    {show === "GrievanceCommitee" && <GrievanceCommitee />}
                    {show === "GrievanceContact" && <GrievanceContact />}
                    {show === "onlineSubmission" && <OnlineSubmission />}
                </div>
            </div>
        </div>
    );
}

export default SmceStudentGrivanceCell;
