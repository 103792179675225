import React from "react";
import service from "../../assets/HEALTHDAY.jpg";
import service1 from "../../assets/PLANTATION.jpg";
import service2 from "../../assets/SWATCHBHARATH.jpg";
import StarIcon from "@mui/icons-material/Star";
// import carousal from "../../Components/Socialservice/carousal";
import Slider from "react-slick";


let carousal_images = [
  {
    image: service,
  },
  {
    image: service1,
  },
  {
    image: service2,
  },
];

const Socialservice = () => {
  var settings = {
    dots: true,
    infinite: true,
    // slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            Social Service
          </a>
        </div>
      </div><br />

      {/* <div className="col-lg-12 align center col-md-12">

        <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img rounded bg-light p-10"
                    src={service}
                    alt="service-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img rounded bg-light p-10"
                    src={service1}
                    alt="service1-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img  rounded bg-light p-10 "
                    // style={{height:"1000px"}}
                    src={service2}
                    alt="service2-img"
                  />
                </div>
                
                
                     
              </div>
            </div><br/>

      </div>
      <br /> */}



      



      <div className="carousal_bg_cnt">
        <Slider {...settings}>
          {carousal_images.map((item, index) => {
            return (
              <div key={index} style={{ width: "100%" }}>
                <div className="card-body">
                  <img
                    className="img-fluid carousal_img"
                    src={item.image}
                    alt="carousel1-img"
                  />
                </div>
              </div>
            );
          })}
         
        </Slider>
      </div>


     

      <div class="content content-expand-lg content-dark bg-light">
        <div class="container text-center">
          <h4> Social Service </h4>

          <p>
            Sai Spurthi Institute of Technology is always been involved in
            Social Activities under the banner of SAI SPURTHI STUDENTS SEVA
            SAMITHI (SSSSS). <br />
            The trust work hard for upliftment of the Rural and Backward section
            of the Society and feel delight to serve the needy.
          </p>
        </div>
        <div className="service text-left">
          <h4>
            The Trust regularly involves in the following Social Service
            Activities:
          </h4>
          <p>
            <StarIcon />
            Blood Donation & Free Eye Checkup Camps.
          </p>
          <p>
            <StarIcon />
            Health Checkup Camps & Free Medicine Distribution.
          </p>
          <p>
            <StarIcon />
            Donate Monthly Ration & Clothes to needy, widows & orphans.
          </p>

          <p>
            <StarIcon />
            Donations to various Charitable Trusts, which work for Needy &
            Handicapped peoples.
          </p>
          <p>
            <StarIcon />
            And various Motivational Training Camps to unemployed youth of the
            rural and backward areas.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Socialservice;
