import React from "react";
import img1 from "../../../assets/aimlimg.jpg";
import img2 from "../../../assets/dl1c.jpg";
import img3 from "../../../assets/aimlimg.jpg";
import img4 from "../../../assets/dl1c.jpg";
import Slider from "react-slick";
import "./Campousphotos.css";
import sws1 from "../../../assets/StudentWorkShop/1.jpg";
import sws2 from "../../../assets/StudentWorkShop/2.jpg";
import sws3 from "../../../assets/StudentWorkShop/3.jpg";
import sws4 from "../../../assets/StudentWorkShop/4.jpg";
import sws5 from "../../../assets/StudentWorkShop/5.jpg";
import sws6 from "../../../assets/StudentWorkShop/6.jpg";
import sws7 from "../../../assets/StudentWorkShop/7.jpg";
import sws8 from "../../../assets/StudentWorkShop/8.jpg";
import sws9 from "../../../assets/StudentWorkShop/9.jpg";
import sws10 from "../../../assets/StudentWorkShop/10.jpg";
import sws11 from "../../../assets/StudentWorkShop/11.jpg";
import sws12 from "../../../assets/StudentWorkShop/12.jpg";
import sws13 from "../../../assets/StudentWorkShop/13.jpg";
import sws14 from "../../../assets/StudentWorkShop/14.jpg";
import sws15 from "../../../assets/StudentWorkShop/15.jpg";
import sws16 from "../../../assets/StudentWorkShop/16.jpg";
import sws17 from "../../../assets/StudentWorkShop/17.jpg";
import sws18 from "../../../assets/StudentWorkShop/18.jpg";
import sws19 from "../../../assets/StudentWorkShop/19.jpg";
import sws20 from "../../../assets/StudentWorkShop/20.jpg";
import sws21 from "../../../assets/StudentWorkShop/21.jpg";
import sws22 from "../../../assets/StudentWorkShop/22.jpg";
import sws23 from "../../../assets/StudentWorkShop/23.jpg";
import sws24 from "../../../assets/StudentWorkShop/24.jpg";
import sws25 from "../../../assets/StudentWorkShop/25.jpg";
import sws26 from "../../../assets/StudentWorkShop/26.jpg";
import sws27 from "../../../assets/StudentWorkShop/27.jpg";
import sws28 from "../../../assets/StudentWorkShop/28.jpg";
import sws29 from "../../../assets/StudentWorkShop/29.jpg";
import sws30 from "../../../assets/StudentWorkShop/30.jpg";
import sws31 from "../../../assets/StudentWorkShop/31.jpg";
import sws32 from "../../../assets/StudentWorkShop/32.jpg";
import sws33 from "../../../assets/StudentWorkShop/33.jpg";
import sws34 from "../../../assets/StudentWorkShop/34.jpg";
import sws35 from "../../../assets/StudentWorkShop/35.jpg";
import sws36 from "../../../assets/StudentWorkShop/36.jpg";
import sws37 from "../../../assets/StudentWorkShop/37.jpg";
import sws38 from "../../../assets/StudentWorkShop/38.jpg";
import sws39 from "../../../assets/StudentWorkShop/39.jpg";
import sws40 from "../../../assets/StudentWorkShop/40.jpg";
import sws41 from "../../../assets/StudentWorkShop/41.jpg";
import sws42 from "../../../assets/StudentWorkShop/42.jpg";
import sws43 from "../../../assets/StudentWorkShop/43.jpg";
import sws44 from "../../../assets/StudentWorkShop/44.jpg";


const carousal_images = [
  {
    image: sws1,
  },
  {
    image: sws2,
  },
  {
    image: sws3,
  },
  {
    image: sws4,
  },
  {
    image: sws5,
  },
  {
    image: sws6,
  },
  {
    image: sws7,
  },
  {
    image: sws8,
  },
  {
    image: sws9,
  },
  {
    image: sws10,
  },
  {
    image: sws11,
  },
  {
    image: sws12,
  },
  {
    image: sws13,
  },
  {
    image: sws14,
  },
  {
    image: sws15,
  },
  {
    image: sws16,
  },
  {
    image: sws17,
  },
  {
    image: sws18,
  },
  {
    image: sws19,
  },
  {
    image: sws20,
  },
  {
    image: sws21,
  },
  {
    image: sws22,
  },
  {
    image: sws23,
  },
  {
    image: sws24,
  },
  {
    image: sws25,
  },
  {
    image: sws26,
  },
  {
    image: sws27,
  },
  {
    image: sws28,
  },
  {
    image: sws29,
  },
  {
    image: sws30,
  },
  {
    image: sws31,
  },
  {
    image: sws32,
  },
  {
    image: sws33,
  },
  {
    image: sws34,
  },
  {
    image: sws35,
  },
  {
    image: sws36,
  },
  {
    image: sws37,
  },
  {
    image: sws38,
  },
  {
    image: sws39,
  },
  {
    image: sws40,
  },
  {
    image: sws41,
  },
  {
    image: sws42,
  },
  {
    image: sws43,
  },
  {
    image: sws44,
  },
];


const StudentWorkShop = () => {
  var settings = {
    dots: true,
    infinite: true,
    // slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  return (
    <div className="Main_container_allumini_work">
      <h1 className="alumini_style">Student Work shop</h1>
      <div className="carousal_alumini">
        <Slider {...settings}>
          {carousal_images.map((item, index) => {
            return (
              <div key={index}>
                <img
                  className="img_alumini"
                  src={item.image}
                  alt="carousel1-img"
                  style={{ marginRight: "3px", marginLeft: "3px" }}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default StudentWorkShop;
