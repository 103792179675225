import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/Sportstable";
import StarRateIcon from "@mui/icons-material/StarRate";

const Committees = () => {
  const sports = [
    {
      title:"To plan, conduct all sports in the college including competitions."
    },
    {
      title:"Train students for inter college and inter university, state and  national level competitions."
     
    },
    {
      title:"Monitor and maintain the discipline in student players."
    },
    {
      title:"For scheduling all the related activities without effecting the class/Lab work, examination schedules."
   
    },
    {
      title:"To give System of development of sports and extra curricular activities."
      
    },
    {
       title:"To plan for all the infrastructural facilities required as per norms through Professor In charge resources."
      
    },
    {
      title:"To plan and monitor the maintenance of all the infrastructural facilities related to sports and games."
      
    },
    {
      title:"To Organize competitions of Intramural, Republic Day Cup,Fresher’s Day Cup, Independence Day cup etc."
      
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            SPORTS COMMITTEE
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            Committee provides all the necessary facilities in the college. To
            Plan & organize the cultural and Sports activities in the college,
            on different occasions. Selection of teams to represent the college
            in inter-college tournaments and also in the intramural tournaments.
          </p>
          <p>Sports Committee is formed with the following members:</p>
          
        </div>
      </div>
      <div className="container">
        <Table />
      </div>
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {sports.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
