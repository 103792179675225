import React, { useState } from "react";
import RotatingSpinner from "../Resourses/RotatingSpinner";
import { resources } from "../Resourses/Resources";
import { toast } from "react-toastify";
import axios from "axios";

const dept = ["ECE", "CSE", "EEE", "MECH", "CIVIL", "CSE"];
const reg = ["R16", "R17", "R18", "R19", "R20"];
const academicYear = [];
const year = new Date().getFullYear();
for (let i = year - 5; i <= year; i++) {
  academicYear.push(i);
}
for (let i = year + 1; i <= year + 5; i++) {
  academicYear.push(i);
}



const successMessage = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "success1",
    className: "success",
  });
};
const errorMessage = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "error1",
    className: "error",
  });
};

const TeacherFeedback = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [TeacherFeedback, setTeacherFeedback] = useState({
    email: "",
    name: "",
    designation: "",
    department: "",
    academicYear: "",
    regulation: "",
    course: "",
    encouragement: "",
    support: "",
    privilege: "",
    oppotunities: "",
    scopeForFinancial: "",
    availability: "",
    scopeForSensitizing: "",
    objectives: "",
    institute: "",
    anySuggestions: "",
  });

  const handleTeacherFeedbackDetails = (e) => {
    const { value, name } = e.target;
    setTeacherFeedback({ ...TeacherFeedback, [name]: value });
  };

  const handleSubmitDataToBackend = async () => {
    console.log(JSON.stringify(TeacherFeedback));
    setIsLoading(true);
    try {
      const res = await axios.post(
        resources.APPLICATION_URL + "saveTeacherFeedbackDetails",
        TeacherFeedback
      );
      if (res.data) {
        successMessage("Data Submitted Successfully");
      } else {
        errorMessage("Data Submission Failed");
      }
    } catch (e) {
      alert(e)
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <RotatingSpinner />
      ) : (
        <div style={{ padding: "50px" }}>
          <div className="teacher_feedback_heading_container">
            <h1 className="teacher_form_heading">
              Teacher Feedback on Academic Performance and Institution Ambience
            </h1>
          </div>
          <form className="teacher_feedback_form_main_container">
            <div className="teacher_feedback_form_container">
              <label htmlFor="name">1.Name of the Teacher</label>
              <input
                onChange={(e) => handleTeacherFeedbackDetails(e)}
                type="text"
                value={TeacherFeedback.name}
                id="name"
                name="name"
                required
                placeholder="Enter Name"
              />
            </div>
            <div className="teacher_feedback_form_container">
              <label htmlFor="name">2.Email</label>
              <input
                onChange={(e) => handleTeacherFeedbackDetails(e)}
                value={TeacherFeedback.email}
                type="email"
                id="email"
                name="email"
                required
                placeholder="Enter Email"
              />
            </div>
            <div className="teacher_feedback_form_container">
              <label htmlFor="name">3.Designation</label>
              <input
                onChange={(e) => handleTeacherFeedbackDetails(e)}
                value={TeacherFeedback.designation}
                type="text"
                id="designation"
                name="designation"
                required
                placeholder="Enter Designation"
              />
            </div>

            <div className="teacher_feedback_form_container">
              <label>4.Department</label>
              <select
                name="department"
                value={TeacherFeedback.department}
                onChange={(e) => handleTeacherFeedbackDetails(e)}
              >
                <option>Please select...</option>
                {dept
                  .filter((each) => each !== "")
                  .map((each) => (
                    <option key={each}>
                      {each}
                    </option>
                  ))}
              </select>
            </div>
            <div className="teacher_feedback_form_container">
              <label>5.Academic Year</label>
              <select
                name="academicYear"
                value={TeacherFeedback.academicYear}
                onChange={(e) => handleTeacherFeedbackDetails(e)}
              >
                <option>Please select...</option>
                {academicYear
                  .filter((each) => each !== "")
                  .map((each) => (
                    <option key={each}>{each}</option>
                  ))}
              </select>
            </div>

            <div className="teacher_feedback_form_container">
              <label>6.Regulation</label>
              <select
                name="regulation"
                value={TeacherFeedback.regulation}
                onChange={(e) => handleTeacherFeedbackDetails(e)}
              >
                <option>Please select...</option>
                {reg
                  .filter((each) => each !== "")
                  .map((each) => (
                    <option key={each}>{each}</option>
                  ))}
              </select>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                7.Course content is apt to day-to-day needs of the industry
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  value="Excellent"
                  type="radio"
                  name="course"
                  id="course"
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  value="Very Good"
                  type="radio"
                  name="course"
                  id="course"
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="course"
                  id="course"
                  value="Good"
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="course"
                  id="course"
                  value="Average"
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="course"
                  id="course"
                  value="Bellow Average"
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>8.Encouragement for Industry-Academic interaction</label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Excellent"
                  name="encouragement"
                  id="encouragement"
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Very Good"
                  name="encouragement"
                  id="encouragement"
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Good"
                  name="encouragement"
                  id="encouragement"
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Average"
                  name="encouragement"
                  id="encouragement"
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="encouragement"
                  id="encouragement"
                  value="Bellow Average"
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>9.Support to Research and innovative abilities</label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="support"
                  value="Excellent"
                  id="support"
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="support"
                  id="support"
                  value="Very Good"
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="support"
                  id="support"
                  value="Good"
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="support"
                  id="support"
                  value="Average"
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="support"
                  id="support"
                  value="Bellow Average"
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                10.Privilege for a teacher to adopt pioneering technologies and
                ICT tools
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="privilege"
                  id="privilege"
                  value="Excellent"
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="privilege"
                  id="privilege"
                  value="Very Good"
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="privilege"
                  value="Good"
                  id="privilege"
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="privilege"
                  value="Average"
                  id="privilege"
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="privilege"
                  id="privilege"
                  value="Bellow Average"
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                11.Opportunities to organize professional development programs
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Excellent"
                  name="oppotunities"
                  id="oppotunities"
                 
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="oppotunities"
                  value="Very Good"
                  id="oppotunities"
                  
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="oppotunities"
                  value="Good"
                  id="oppotunities"
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="oppotunities"
                  value="Average"
                  id="oppotunities"
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="oppotunities"
                  value="Bellow Average"
                  id="oppotunities"
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                12.Scope for financial assistance to attend programs in other
                institutions
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Excellent"
                  name="scopeForFinancial"
                  id="scopeForFinancial"
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Very Good"
                  name="scopeForFinancial"
                  id="scopeForFinancial"
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="scopeForFinancial"
                  value="Good"
                  id="scopeForFinancial"
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="scopeForFinancial"
                  value="Average"
                  id="scopeForFinancial"
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="scopeForFinancial"
                  value="Bellow Average"
                  id="scopeForFinancial"
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                13.Availability of Wi-Fi and Internet facility with sufficient
                bandwidth
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Excellent"
                  name="availability"
                  id="availability"
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Very Good"
                  name="availability"
                  id="availability"
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Good"
                  name="availability"
                  id="availability"
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Average"
                  name="availability"
                  id="availability"
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Bellow Average"
                  name="availability"
                  id="availability"
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                14.Scope for sensitizing students to social issues for their
                holistic development
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Excellent"
                  name="scopeForSensitizing"
                  id="scopeForSensitizing"
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Very Good"
                  name="scopeForSensitizing"
                  id="scopeForSensitizing"
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Good"
                  name="scopeForSensitizing"
                  id="scopeForSensitizing"
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Average"
                  name="scopeForSensitizing"
                  id="scopeForSensitizing"
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  value="Bellow Average"
                  name="scopeForSensitizing"
                  id="scopeForSensitizing"
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                15.Objectives set by the Add-on programs enabled in improving
                inter and intrapersonal skills
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="objectives"
                  value="Excellent"
                  id="objectives"
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="objectives"
                  id="objectives"
                  value="Very Good"
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="objectives"
                  id="objectives"
                  value="Good"
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="objectives"
                  id="objectives"
                  value="Average"
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="objectives"
                  id="objectives"
                  value="Bellow Average"
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                16.Institute facilitates career growth and exposure to recent
                technologies
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="institute"
                  id="institute"
                  value="Excellent"
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="institute"
                  id="institute"
                  value="Very Good"
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="institute"
                  id="institute"
                  value="Good"
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="institute"
                  id="institute"
                  value="Average"
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  onChange={(e) => handleTeacherFeedbackDetails(e)}
                  style={{ width: "15px" }}
                  type="radio"
                  name="institute"
                  id="institute"
                  value="Bellow Average"
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label htmlFor="name">Any Suggestions</label>
              <textarea
                cols={45}
                rows={5}
                type="text"
                onChange={(e) => handleTeacherFeedbackDetails(e)}
                id="anySuggestions"
                name="anySuggestions"
                value={TeacherFeedback.anySuggestions}
                required
                placeholder="Enter Any Suggestions"
              />
            </div>

            <button
              onClick={() => handleSubmitDataToBackend()}
              className="submit_btn"
              type="button"
            >
              Submit
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default TeacherFeedback;
