import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PlacementRecords from "./PlacementRecords";
import Recruiters from "./Recruiters";
import Team from "./Team";
import 'animate.css'

const Placement = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box className="animate__animated animate__zoomIn" sx={{ width: "100%", typography: "body1", margin: "auto" }}>
      <TabContext
        value={value}
        sx={{ width: "fit-content", textAlign: "center", margin: "auto" }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            textAlign: "center",
          }}
        >
          <TabList
            centered
            onChange={handleChange}
            sx={{ width: "100%", textAlign: "center" }}
            aria-label="lab API tabs example"
          >
            <Tab label="PLACEMENTS RECORDS" value="1" />
            <Tab label="PLACEMENT TEAM" value="2" />
            <Tab label="OUR RECRUITERS" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <PlacementRecords />
        </TabPanel>
        <TabPanel value="2">
          <Team />
        </TabPanel>
        <TabPanel value="3">
          <Recruiters />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Placement;
