import React from "react";
import img from "../../assets/drive_1.jpg";
import img1 from "../../assets/drive_2.jpg";
import img2 from "../../assets/drive_3.jpg";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


const Events = () => {
  return (
    <div>


<div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
          EVENTS
          </a>

          <Navbar  data-bs-theme="dark">
        <Container>
          
          <Nav className="me-auto">
            <Nav.Link style={{color:"#fff"}} href="/pressnote">PRESS NOTES </Nav.Link>
            <Nav.Link style={{color:"#fff"}}  href="/notification">NOTIFICATIONS </Nav.Link>
            <Nav.Link  style={{color:"#fff"}}  href="/events">EVENTS</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
          </div>
          </div><br/>


      <div className="table_container">
        <table className="table_main">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Date</th>
              <th>Title</th>
              <th>Images</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td> 2023-11-17</td>
              <td>
                Event Name: onCampus_drive Event Description: MainTech onCampus
                Drive{" "}
              </td>
              <td>
                 <img src={img} alt='drive Img' 
                 style={{width:'80px'}}/> &nbsp; &nbsp;
                 <img src={img1} alt='drive Img'
                 style={{width:'80px'}} /><br/><br/>
                  <img src={img2} alt='drive Img'
                 style={{width:'80px'}} />
                 </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Events;
