import React from "react";

export function IcRoundNotificationsActive(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3em"
      height="3em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="red"
        d="M18 16v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.68-1.5-1.51-1.5S10.5 3.17 10.5 4v.68C7.63 5.36 6 7.92 6 11v5l-1.3 1.29c-.63.63-.19 1.71.7 1.71h13.17c.89 0 1.34-1.08.71-1.71zm-6.01 6c1.1 0 2-.9 2-2h-4a2 2 0 0 0 2 2M6.77 4.73c.42-.38.43-1.03.03-1.43a1 1 0 0 0-1.39-.02a10.424 10.424 0 0 0-3.27 6.06c-.09.61.38 1.16 1 1.16c.48 0 .9-.35.98-.83a8.44 8.44 0 0 1 2.65-4.94M18.6 3.28c-.4-.37-1.02-.36-1.4.02c-.4.4-.38 1.04.03 1.42c1.38 1.27 2.35 3 2.65 4.94c.07.48.49.83.98.83c.61 0 1.09-.55.99-1.16c-.38-2.37-1.55-4.48-3.25-6.05"
      ></path>
    </svg>
  );
}

export function FluentHatGraduationSparkle28Filled(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4em"
      height="4em"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fill="#0d6efd"
        d="M18.171 6.829a3.157 3.157 0 0 1 .761 1.238l.498 1.53a.605.605 0 0 0 1.14 0l.498-1.53a3.15 3.15 0 0 1 1.998-1.996l1.53-.497a.605.605 0 0 0 0-1.14l-.03-.008l-1.531-.497a3.154 3.154 0 0 1-1.998-1.996L20.54.403a.604.604 0 0 0-1.14 0l-.498 1.53l-.013.038a3.152 3.152 0 0 1-1.955 1.958l-1.53.497a.605.605 0 0 0 0 1.14l1.53.497c.467.156.89.418 1.237.766m8.65 3.529l.918.298l.019.004a.362.362 0 0 1 0 .684l-.919.299a1.892 1.892 0 0 0-1.198 1.197l-.299.918a.363.363 0 0 1-.684 0l-.299-.918a1.891 1.891 0 0 0-1.198-1.202l-.919-.298a.362.362 0 0 1 0-.684l.919-.299a1.892 1.892 0 0 0 1.18-1.197l.299-.918a.363.363 0 0 1 .684 0l.298.918a1.89 1.89 0 0 0 1.199 1.197M11.768 3.496a4.25 4.25 0 0 1 4.036-.232l-.715.236a1.57 1.57 0 0 0-.79.59a1.58 1.58 0 0 0 0 1.85c.187.273.457.48.77.59l1.56.5c.312.11.595.287.83.52c.066.064.126.135.18.21a2 2 0 0 1 .34.64l.5 1.53a1.7 1.7 0 0 0 .59.8a1.64 1.64 0 0 0 1.86 0l.13-.11A1.338 1.338 0 0 0 21 11c.003.282.09.557.25.79a1.3 1.3 0 0 0 .66.49l.94.31a.851.851 0 0 1 .34.21l.013.012l-6.876 4.497a4.25 4.25 0 0 1-4.652 0L3 11.637v6.113a.75.75 0 0 1-1.5 0V10.5c0-.043.004-.085.01-.125a.75.75 0 0 1 .346-.763zM6 15.39l4.853 3.173a5.75 5.75 0 0 0 6.294 0L22 15.391v4.859c0 .299-.162.479-.353.666c-.095.092-.231.22-.409.372a11.31 11.31 0 0 1-1.54 1.105C18.366 23.193 16.421 24 14 24c-2.42 0-4.366-.807-5.698-1.607a11.31 11.31 0 0 1-1.54-1.105a8.555 8.555 0 0 1-.41-.372c-.19-.187-.352-.367-.352-.666z"
      ></path>
    </svg>
  );
}

export function MdiAccountGraduationOutline(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4em"
      height="4em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#0d6efd"
        d="M18 10.5V6l-2.11 1.06A3.999 3.999 0 0 1 12 12a3.999 3.999 0 0 1-3.89-4.94L5 5.5L12 2l7 3.5v5zM12 9l-2-1c0 1.1.9 2 2 2s2-.9 2-2zm2.75-3.58L12.16 4.1L9.47 5.47l2.6 1.32zM12 13c2.67 0 8 1.33 8 4v3H4v-3c0-2.67 5.33-4 8-4m0 1.9c-3 0-6.1 1.46-6.1 2.1v1.1h12.2V17c0-.64-3.13-2.1-6.1-2.1"
      ></path>
    </svg>
  );
}

export function BxlInternetExplorer(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20.154 8.514c.85-2.039.906-3.738-.113-4.757c-1.133-1.132-3.852-.792-6.737.565h-.341c-2.037 0-4.02.738-5.492 2.039c-1.244 1.133-2.15 2.605-2.547 4.246c.283-.337 1.869-2.207 3.68-3.226c.058 0 .51-.283.51-.283c-.057 0-.905.85-1.074 1.02c-3.965 4.076-6.285 10.248-4.473 12.059c1.188 1.189 3.34.906 5.831-.452c1.075.51 2.265.735 3.565.735c1.699 0 3.285-.453 4.643-1.359c1.416-.906 2.436-2.319 3.059-3.962h-4.416c-.566 1.075-1.869 1.812-3.227 1.812c-1.926 0-3.512-1.586-3.568-3.397v-.169h11.551v-.17c0-.283.057-.623.057-.848c-.001-1.362-.341-2.664-.908-3.853M4.811 19.838c-.906-.906-.621-2.662.453-4.756c.51 1.416 1.359 2.662 2.435 3.567c.341.282.735.565 1.133.792c-1.814.963-3.285 1.133-4.021.397m11.606-8.606H9.51v-.057c.113-1.756 1.754-3.285 3.681-3.285c1.812 0 3.284 1.416 3.397 3.285v.057zm3.454-3.171a6.948 6.948 0 0 0-1.303-1.585a8.445 8.445 0 0 0-2.718-1.699c1.813-.849 3.341-.961 4.134-.169c.623.736.567 1.981-.113 3.453c0 .057 0 .057 0 0c0 .057 0 .057 0 0"
      ></path>
    </svg>
  );
}


export function FluentMdl2RecruitmentManagement(props) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 2048 2048" {...props}><path fill="currentColor" d="M2048 1280v768H1024v-768h256v-256h512v256zm-640 0h256v-128h-256zm512 384h-128v128h-128v-128h-256v128h-128v-128h-128v256h768zm0-256h-768v128h768zm-355-512q-54-61-128-94t-157-34q-80 0-149 30t-122 82t-83 123t-30 149q0 92-41 173t-116 136q45 23 84 53t73 68v338q0-79-30-149t-82-122t-123-83t-149-30q-80 0-149 30t-122 82t-83 123t-30 149H0q0-73 20-141t57-129t90-108t118-81q-74-54-115-135t-42-174q0-79 30-149t82-122t122-83t150-30q92 0 173 41t136 116q38-75 97-134t135-98q-74-54-115-135t-42-174q0-79 30-149t82-122t122-83t150-30q79 0 149 30t122 82t83 123t30 149q0 92-41 173t-116 136q68 34 123 85t93 118zM512 1408q53 0 99-20t82-55t55-81t20-100q0-53-20-99t-55-82t-81-55t-100-20q-53 0-99 20t-82 55t-55 81t-20 100q0 53 20 99t55 82t81 55t100 20m512-1024q0 53 20 99t55 82t81 55t100 20q53 0 99-20t82-55t55-81t20-100q0-53-20-99t-55-82t-81-55t-100-20q-53 0-99 20t-82 55t-55 81t-20 100"></path></svg>);
}



export function EpPromotion(props) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 1024 1024" {...props}><path fill="currentColor" d="m64 448l832-320l-128 704l-446.08-243.328L832 192L242.816 545.472zm256 512V657.024L512 768z"></path></svg>);
}



export function FlatColorIconsRules(props) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 48 48" {...props}><path fill="#42a5f5" d="M39 45H9s-3-.1-3-8h36c0 7.9-3 8-3 8"></path><path fill="#90caf9" d="M8 3h32v34H8z"></path><path fill="#1976d2" d="M18 15h16v2H18zm0 4h16v2H18zm0 4h16v2H18zm0 4h16v2H18zm0 4h16v2H18zm-4-16h2v2h-2zm0 4h2v2h-2zm0 4h2v2h-2zm0 4h2v2h-2zm0 4h2v2h-2z"></path></svg>);
}



export function OcticonCodeOfConduct16(props) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" {...props}><path fill="currentColor" d="M8.048 2.241c.964-.709 2.079-1.238 3.325-1.241a4.616 4.616 0 0 1 3.282 1.355c.41.408.757.86.996 1.428c.238.568.348 1.206.347 1.968c0 2.193-1.505 4.254-3.081 5.862c-1.496 1.526-3.213 2.796-4.249 3.563l-.22.163a.749.749 0 0 1-.895 0l-.221-.163c-1.036-.767-2.753-2.037-4.249-3.563C1.51 10.008.007 7.952.002 5.762a4.614 4.614 0 0 1 1.353-3.407C3.123.585 6.223.537 8.048 2.24Zm-1.153.983c-1.25-1.033-3.321-.967-4.48.191a3.115 3.115 0 0 0-.913 2.335c0 1.556 1.109 3.24 2.652 4.813C5.463 11.898 6.96 13.032 8 13.805c.353-.262.758-.565 1.191-.905l-1.326-1.223a.75.75 0 0 1 1.018-1.102l1.48 1.366c.328-.281.659-.577.984-.887L9.99 9.802a.75.75 0 1 1 1.019-1.103l1.384 1.28c.295-.329.566-.661.81-.995L12.92 8.7l-1.167-1.168c-.674-.671-1.78-.664-2.474.03c-.268.269-.538.537-.802.797c-.893.882-2.319.843-3.185-.032c-.346-.35-.693-.697-1.043-1.047a.75.75 0 0 1-.04-1.016c.162-.191.336-.401.52-.623c.62-.748 1.356-1.637 2.166-2.417m7.112 4.442c.313-.65.491-1.293.491-1.916v-.001c0-.614-.088-1.045-.23-1.385c-.143-.339-.357-.633-.673-.949a3.111 3.111 0 0 0-2.218-.915c-1.092.003-2.165.627-3.226 1.602c-.823.755-1.554 1.637-2.228 2.45l-.127.154l.562.566a.755.755 0 0 0 1.066.02l.794-.79c1.258-1.258 3.312-1.31 4.594-.032c.396.394.792.791 1.173 1.173Z"></path></svg>);
}



export function FluentShifts30Minutes24Filled(props) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="orange" d="M15.5 5.5a3.5 3.5 0 1 0-7 0a3.5 3.5 0 0 0 7 0M8.75 10a2.25 2.25 0 0 0-2.25 2.25v.5c0 .414.336.75.75.75h9.5a.75.75 0 0 0 .75-.75v-.5A2.25 2.25 0 0 0 15.25 10zm-2.253 6.999a.999.999 0 1 1-1.997 0a.999.999 0 0 1 1.997 0M5.5 21.497a.999.999 0 1 0 0-1.997a.999.999 0 0 0 0 1.997m4.613-4.97a1.841 1.841 0 0 0-.668.329a.75.75 0 1 1-.924-1.182c.267-.209.71-.48 1.246-.606c.553-.13 1.24-.113 1.887.327c.782.53 1.044 1.469.919 2.236a2.126 2.126 0 0 1-.3.79c.174.2.306.429.392.672c.248.704.133 1.581-.475 2.187c-.595.591-1.393.766-2.08.73c-.68-.036-1.377-.282-1.851-.7a.75.75 0 0 1 .991-1.125c.172.152.526.305.938.327c.404.021.736-.089.944-.296a.605.605 0 0 0 .118-.623c-.07-.202-.225-.343-.5-.343h-.5a.75.75 0 1 1 0-1.5h.5c.117 0 .175-.036.214-.075a.523.523 0 0 0 .128-.287c.05-.307-.072-.611-.28-.752c-.218-.148-.448-.168-.7-.108M16.5 15c-.869 0-1.56.372-1.987 1.083c-.387.644-.514 1.497-.514 2.417c0 .92.127 1.773.514 2.418C14.94 21.628 15.63 22 16.5 22c.864 0 1.558-.365 1.986-1.076c.388-.644.514-1.498.514-2.424c0-.925-.126-1.78-.514-2.423C18.058 15.365 17.364 15 16.5 15m-1.001 3.5c0-.83.124-1.352.3-1.645c.136-.226.32-.355.7-.355c.386 0 .568.128.702.35c.175.291.299.812.299 1.65c0 .839-.124 1.36-.299 1.65c-.134.222-.316.35-.701.35c-.382 0-.565-.128-.701-.355c-.176-.293-.3-.815-.3-1.645"></path></svg>);
}


export function UitCircuit(props) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="orange" d="M10 12.5a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m0 2a.5.5 0 1 1 0-1a.5.5 0 0 1 0 1m0-6a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m0 2a.5.5 0 1 1 0-1a.5.5 0 0 1 0 1m11.5 2a.5.5 0 0 0 0-1H19v-3h2.5a.5.5 0 0 0 0-1H19A2.502 2.502 0 0 0 16.5 5V2.5a.5.5 0 0 0-1 0V5h-3V2.5a.5.5 0 0 0-1 0V5h-3V2.5a.5.5 0 0 0-1 0V5A2.502 2.502 0 0 0 5 7.5H2.5a.5.5 0 0 0 0 1H5v3H2.5a.5.5 0 0 0 0 1H5v3H2.5a.5.5 0 0 0 0 1H5A2.502 2.502 0 0 0 7.5 19v2.5a.5.5 0 1 0 1 0V19h3v2.5a.5.5 0 1 0 1 0V19h3v2.5a.5.5 0 1 0 1 0V19a2.502 2.502 0 0 0 2.5-2.5h2.5a.5.5 0 0 0 0-1H19v-3zm-3.5 4a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 6 16.5v-9A1.5 1.5 0 0 1 7.5 6h9A1.5 1.5 0 0 1 18 7.5zm-4-8a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m0 2a.5.5 0 1 1 0-1a.5.5 0 0 1 0 1m0 2a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m0 2a.5.5 0 1 1 0-1a.5.5 0 0 1 0 1"></path></svg>);
}


export function StreamlineBugAntivirusDebugging2(props) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14" {...props}><g fill="orange" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"><path d="m3.5 10.5l1.3-1.3M4.5 7H3m7.5-3.5L9.2 4.8M9.5 7H11m-4.5-.5h3M.5 7a6.5 6.5 0 1 0 13 0a6.5 6.5 0 1 0-13 0m11.096 4.596L2.404 2.404"></path><path d="M4.804 4.804A2.5 2.5 0 0 1 9.5 6v2a2.5 2.5 0 0 1-.304 1.196M4.5 7v1a2.5 2.5 0 0 0 3.172 2.408"></path></g></svg>);
}


export function StreamlineBugAntivirusDebuggingSolid(props) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14" {...props}><path fill="orange" fillRule="evenodd" d="M1.594 7a5.38 5.38 0 0 1 1.09-3.256l1.849 1.85v.001L8.736 9.8l.001-.001l1.519 1.518A5.406 5.406 0 0 1 1.594 7m6.003 3.428L3.442 6.273a.75.75 0 0 0 .183 1.477H4.5V8c0 .138.011.274.033.407l-.956.956a.75.75 0 0 0 1.06 1.06l.626-.625a2.492 2.492 0 0 0 2.334.63M5.263 4.202L3.744 2.684a5.406 5.406 0 0 1 7.572 7.572l-1.849-1.85C9.49 8.274 9.5 8.138 9.5 8v-.25h.875a.75.75 0 0 0 0-1.5H9.5V6c0-.138-.011-.274-.033-.407l.956-.956a.75.75 0 1 0-1.06-1.06l-.626.625A2.492 2.492 0 0 0 7 3.5a2.49 2.49 0 0 0-1.737.702M13.906 7c0 1.903-.77 3.626-2.014 4.875l-.008.008l-.009.009A6.906 6.906 0 1 1 13.905 7Z" clipRule="evenodd"></path></svg>);
}



export function MdiPresentation(props) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="orange" d="M2 3h8a2 2 0 0 1 2-2a2 2 0 0 1 2 2h8v2h-1v11h-5.75L17 22h-2l-1.75-6h-2.5L9 22H7l1.75-6H3V5H2zm3 2v9h14V5z"></path></svg>);
}



export function FoundationBurstNew(props) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 100 100" {...props}><path fill="currentColor" d="M88.558 49.96c0-.885-.435-1.663-1.097-2.151l.014-.024l-9.324-5.383l5.367-9.296l-.018-.011a2.666 2.666 0 0 0-.127-2.408a2.667 2.667 0 0 0-2.025-1.314v-.026H70.58V18.61h-.022a2.667 2.667 0 0 0-1.314-2.022a2.662 2.662 0 0 0-2.412-.125l-.013-.023l-9.481 5.474l-5.25-9.094l-.019.011a2.668 2.668 0 0 0-2.149-1.094c-.885 0-1.664.435-2.151 1.097l-.024-.014l-5.337 9.244l-9.19-5.306l-.011.019a2.666 2.666 0 0 0-2.408.127a2.666 2.666 0 0 0-1.315 2.025h-.027v10.674H18.845v.021a2.667 2.667 0 0 0-2.022 1.314a2.667 2.667 0 0 0-.126 2.41l-.023.014l5.246 9.087l-9.394 5.424l.011.019a2.668 2.668 0 0 0-1.094 2.149c0 .885.435 1.664 1.097 2.151l-.014.024l9.324 5.383l-5.367 9.296l.018.01a2.666 2.666 0 0 0 .127 2.408a2.667 2.667 0 0 0 2.025 1.314v.027H29.42V81.39h.022c.092.816.549 1.58 1.314 2.022a2.665 2.665 0 0 0 2.412.125l.013.023l9.481-5.474l5.25 9.094l.019-.011a2.668 2.668 0 0 0 2.149 1.094c.885 0 1.664-.435 2.151-1.096l.023.013l5.337-9.244l9.191 5.306l.011-.019a2.666 2.666 0 0 0 2.408-.127a2.666 2.666 0 0 0 1.315-2.025h.027V70.398h10.613v-.021a2.667 2.667 0 0 0 2.022-1.314a2.67 2.67 0 0 0 .126-2.411l.023-.013l-5.246-9.087l9.394-5.424l-.011-.019a2.666 2.666 0 0 0 1.094-2.149M43.715 61.355l-9.846-4.35l4.345 7.525l-2.456 1.418l-6.662-11.537l2.525-1.459l9.53 4.162l-4.185-7.248l2.457-1.418l6.66 11.537zm4.652-2.686l-6.661-11.538l8.165-4.713l1.248 2.162l-5.709 3.295l1.398 2.422l5.587-3.225l1.248 2.16l-5.587 3.227l1.518 2.629l5.709-3.295l1.248 2.162zm18.906-10.915L60.675 41l2.567 9.08l-2.611 1.508l-9.965-9.629l2.75-1.588l6.838 7.168l-2.617-9.605l1.92-1.108l6.993 7.079l-2.79-9.506l2.75-1.588l3.375 13.436z"></path></svg>);
}