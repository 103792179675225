import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import RotatingSpinner from "../Resourses/RotatingSpinner";
import { toast } from "react-toastify";

const successMessage = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "success1",
    className: "success",
  });
};
const errorMessage = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "error1",
    className: "error",
  });
};

const AdmissionForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [admissionFormDetails, setAdmissionFormDetails] = useState({
    course: "",
    nameOfCandidate: "",
    nameOfFather: "",
    dateOfBirth: "",
    nationalityAndReligion: "",
    gender: "",
    category: "",
    subCategory: "",
    correspondingAddress: "",
    permanentAddress: "",
    phoneNumber: "",
    email: "",
    jeeHallTicketNumber: "",
    jeeRank: "",
    eamcetHallTicketNumber: "",
    eamcetRank: "",
    diploma: "",
    inter: "",
    yearOfPass: "",
    reference1: "",
    reference2: "",
    reference3: "",
    reference4: "",
    reference5: "",
    reference6: "",
  });

  const handleAdmissionFormDetails = (e) => {
    const { value, name } = e.target;
    setAdmissionFormDetails({ ...admissionFormDetails, [name]: value });
  };
  const branchesList = [
    "COMPUTER SCIENCE AND ENGINEERING",
    "COMPUTER SCIENCE AND MACHINE LEARNING",
    "ARTIFICIAL INTELLIGENCE & DATA SCIENCE",
    "ELECTRICAL AND ELECTRONIC ENGINEERING",
    "ELECTRICAL AND COMMUNICATION ENGINEERING",
    "MECHANICAL ENGINEERING",
  ];

  const handlesubmitAdmissionForm = async () => {
    console.log(admissionFormDetails);
    setIsLoading(true);
    try {
      const res = await axios.post(
        resources.APPLICATION_URL + "save/admissionform",admissionFormDetails
      );
      if (res.data) {
        toast.success("Thank you for your valuable feedback");
        alert("Thank you for your valuable feedback");
        setAdmissionFormDetails({ course: "",
        nameOfCandidate: "",
        nameOfFather: "",
        dateOfBirth: "",
        nationalityAndReligion: "",
        gender: "",
        category: "",
        subCategory: "",
        correspondingAddress: "",
        permanentAddress: "",
        phoneNumber: "",
        email: "",
        jeeHallTicketNumber: "",
        jeeRank: "",
        eamcetHallTicketNumber: "",
        eamcetRank: "",
        diploma: "",
        inter: "",
        yearOfPass: "",
        reference1: "",
        referance2: "",
        reference3: "",
        reference4: "",
        reference5: "",
        reference6: "",})
      } else {
        toast.error("Something went wrong. Please try again later");
        alert("Something went wrong. Please try again later");
      }
    } catch (e) {
      alert(e)
      console.log("error", e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <RotatingSpinner />
      ) : (
        <div className="admission_main">
          <div className="admission_header">
            <h1>APPLICATION FORM FOR ADMISSION TO 1ST YEAR OF B.TECH</h1>
            <lable>FOR THE ACADEMIC YEAR 2024</lable>
            <div className="admission_btn_container">
              <Navbar bg="transparent" data-bs-theme="light">
                <Container>
                  <Nav className="me-auto">
                    <Nav.Link style={{ color: "#fff" }} href="#features">
                      Registration Form
                    </Nav.Link>
                    <Nav.Link style={{ color: "#fff" }} href="#pricing">
                      View The Records
                    </Nav.Link>
                    <Nav.Link style={{ color: "#fff" }} href="/gallery/login">
                      Login
                    </Nav.Link>
                  </Nav>
                </Container>
              </Navbar>
            </div>
          </div>
          <hr />
          <h1 className="admission_heading" style={{ alignText: "center" }}>
            Registration Form
          </h1>
          <hr />
          <div style={{ display: "flex" }}>
            <ol className="admission_form_container">
              <li>
                <div className="admission_form_inp_container">
                  <label htmlFor="course" className="admission_label">
                    Name Of The Course
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    value={admissionFormDetails.course}
                    name="course"
                    placeholder="Course"
                    id="course"
                    type="text"
                    className="admission_inp"
                  />
                </div>
              </li>
              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">
                    Name of the Candidate
                    <br />
                    (As per SSC in Block Letters)
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    value={admissionFormDetails.nameOfCandidate}
                    name="nameOfCandidate"
                    id="nameOfCandidate"
                    placeholder="Name of the Candidate"
                    type="text"
                    className="admission_inp"
                  />
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">
                    Name of the father/guardian
                    <br />
                    (As per SSC in Block Letters)
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    value={admissionFormDetails.nameOfFather}
                    name="nameOfFather"
                    id="nameOfFather"
                    placeholder="Name of the Father"
                    type="text"
                    className="admission_inp"
                  />
                </div>
              </li>
              <li>
                <div className="admission_form_inp_container">
                  <label htmlFor="dateOfBirth" className="admission_label">
                    Date of birth
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    value={admissionFormDetails.dateOfBirth}
                    name="dateOfBirth"
                    id="dateOfBirth"
                    type="date"
                    className="admission_inp"
                  />
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">Gender</label>
                  <div style={{ display: "flex" }}>
                    <span className="span_ele">Male</span>
                    <input
                      onChange={(e) => handleAdmissionFormDetails(e)}
                      value="Male"
                      id="gender"
                      type="radio"
                      name="gender"
                      style={{ width: "15px" }}
                    />
                    <span className="span_ele">Female</span>
                    <input
                      onChange={(e) => handleAdmissionFormDetails(e)}
                      value="Female"
                      id="gender"
                      type="radio"
                      name="gender"
                      style={{ width: "15px" }}
                    />
                    <span className="span_ele">others</span>
                    <input
                      onChange={(e) => handleAdmissionFormDetails(e)}
                      value="Others"
                      id="gender"
                      type="radio"
                      name="gender"
                      style={{ width: "15px" }}
                    />
                  </div>
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label htmlFor="phone" className="admission_label">
                    {" "}
                    Phone Number
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    id="phone"
                    name="phoneNumber"
                    value={admissionFormDetails.phoneNumber}
                    type="number"
                    className="admission_inp"
                  />
                </div>
              </li>
              <li>
                <div className="admission_form_inp_container">
                  <label htmlFor="email" className="admission_label">
                    Email ID
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    id="email"
                    name="email"
                    type="email"
                    value={admissionFormDetails.email}
                    className="admission_inp"
                  />
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label htmlFor="nationality" className="admission_label">
                    Nationality & Religion
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    value={admissionFormDetails.nationalityAndReligion}
                    id="nationality"
                    name="nationalityAndReligion"
                    type="text"
                    className="admission_inp"
                  />
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">Category</label>
                  <select
                    name="category"
                    value={admissionFormDetails.category}
                    onChange={(e) => handleAdmissionFormDetails(e)}
                  >
                    <option value="">Please select</option>
                    <option value="General">General</option>
                    <option value="OBC">OBC</option>
                    <option value="SC">SC</option>
                    <option value="ST">ST</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </li>
              <li>
                <div className="admission_form_inp_container">
                  <label htmlFor="subCategory" className="admission_label">
                    Sub Category
                  </label>
                  <input
                  onChange={(e) => handleAdmissionFormDetails(e)}
                    value={admissionFormDetails.subCategory}
                    id="subCategory"
                    name="subCategory"
                    type="text"
                    className="admission_inp"
                  />
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label htmlFor="permanent" className="admission_label">
                    {" "}
                    Address for Correspondence
                  </label>
                  <textarea
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    value={admissionFormDetails.addressForCorrespondence}
                    name="correspondingAddress"
                    cols={35}
                    rows={5}
                    type="text"
                    className="admission_inp"
                  />
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label htmlFor="permanent" className="admission_label">
                    Permanent address
                  </label>
                  <textarea
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    value={admissionFormDetails.permanentAddress}
                    name="permanentAddress"
                    cols={35}
                    rows={5}
                    type="text"
                    className="admission_inp"
                  />
                </div>
              </li>
            </ol>

            <ol className="admission_form_container">
              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">
                    JEE-Mains rank(All India Rank)-2022
                  </label>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <span style={{ padding: "10px" }}>H.T.No</span>
                      <input
                        onChange={(e) => handleAdmissionFormDetails(e)}
                        value={admissionFormDetails.jeeHallTicketNumber}
                        name="jeeHallTicketNumber"
                        type="text"
                        className="admission_inp"
                      />
                    </div>
                    <hr />
                    <div style={{ display: "flex" }}>
                      <span style={{ padding: "10px" }}>Rank</span>
                      <input
                        onChange={(e) => handleAdmissionFormDetails(e)}
                        value={admissionFormDetails.jeeRank}
                        name="jeeRank"
                        type="text"
                        className="admission_inp"
                      />
                    </div>
                    <hr />
                  </div>
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">EAMCET rank-2023</label>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <span style={{ padding: "10px" }}>H.T.No</span>
                      <input
                        onChange={(e) => handleAdmissionFormDetails(e)}
                        name="eamcetHallTicketNumber"
                        value={admissionFormDetails.eamcetHallTicketNumber}
                        type="text"
                        className="admission_inp"
                      />
                    </div>
                    <hr />
                    <div style={{ display: "flex" }}>
                      <span style={{ padding: "10px" }}>Rank</span>
                      <input
                        onChange={(e) => handleAdmissionFormDetails(e)}
                        name="eamcetRank"
                        value={admissionFormDetails.eamcetRank}
                        type="text"
                        className="admission_inp"
                      />
                    </div>
                    <hr />
                  </div>
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">
                    Percentage of Marks in intermediate/qualifying exam
                  </label>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <span style={{ padding: "10px" }}>Group %(diploma)</span>
                      <input
                        onChange={(e) => handleAdmissionFormDetails(e)}
                        name="diploma"
                        value={admissionFormDetails.diploma}
                        type="text"
                        className="admission_inp"
                      />
                    </div>
                    <hr />
                    <div style={{ display: "flex" }}>
                      <span style={{ padding: "10px" }}>inter marks</span>
                      <input
                        onChange={(e) => handleAdmissionFormDetails(e)}
                        name="inter"
                        value={admissionFormDetails.inter}
                        type="text"
                        className="admission_inp"
                      />
                    </div>
                    <hr />
                  </div>
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">
                    Month & Year of Passing of Qualifying Exam
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    name="yearOfPass"
                    value={admissionFormDetails.yearOfPass}
                    type="date"
                    className="admission_inp"
                  />
                </div>
              </li>
            </ol>
          </div>
          <hr />
          <h1 className="admission_heading">
            Select the branch according to your preference
          </h1>
          <hr />
          <ol className="admission_form_container">
            <li>
              <div className="admission_form_inp_container">
                <label className="admission_label">Preference 1</label>
                <select
                  name="reference1"
                  onChange={(e) => handleAdmissionFormDetails(e)}
                >
                  <option>Please Select...</option>
                  {branchesList.map((branch) => (
                    <option>{branch}</option>
                  ))}
                </select>
              </div>
            </li>

            <li>
              <div className="admission_form_inp_container">
                <label className="admission_label">Preference 2</label>
                <select
                  name="reference2"
                  onChange={(e) => handleAdmissionFormDetails(e)}
                >
                  <option>Please Select...</option>
                  {branchesList.map((branch) => (
                    <option>{branch}</option>
                  ))}
                </select>
              </div>
            </li>

            <li>
              <div className="admission_form_inp_container">
                <label className="admission_label">Preference 3</label>
                <select
                  name="reference3"
                  onChange={(e) => handleAdmissionFormDetails(e)}
                >
                  <option>Please Select...</option>
                  {branchesList.map((branch) => (
                    <option>{branch}</option>
                  ))}
                </select>
              </div>
            </li>

            <li>
              <div className="admission_form_inp_container">
                <label className="admission_label">Preference 4</label>
                <select
                  name="reference4"
                  onChange={(e) => handleAdmissionFormDetails(e)}
                >
                  <option>Please Select...</option>
                  {branchesList.map((branch) => (
                    <option>{branch}</option>
                  ))}
                </select>
              </div>
            </li>

            <li>
              <div className="admission_form_inp_container">
                <label className="admission_label">Preference 5</label>
                <select
                  value={admissionFormDetails.reference5}
                  name="reference5"
                  onChange={(e) => handleAdmissionFormDetails(e)}
                >
                  <option>Please Select...</option>
                  {branchesList.map((branch) => (
                    <option>{branch}</option>
                  ))}
                </select>
              </div>
            </li>

            <li>
              <div className="admission_form_inp_container">
                <label className="admission_label">Preference 6</label>
                <select
                  name="reference6"
                  onChange={(e) => handleAdmissionFormDetails(e)}
                >
                  <option>Please Select...</option>
                  {branchesList.map((branch) => (
                    <option>{branch}</option>
                  ))}
                </select>
              </div>
            </li>
          </ol>

          <button
            className="submit_btn"
            onClick={() => handlesubmitAdmissionForm()}
            style={{ marginLeft: "20px" }}
          >
            Submit
          </button>
        </div>
      )}
    </>
  );
};

export default AdmissionForm;
