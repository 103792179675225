import React from "react";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import RotatingSpinner from "../../Resourses/RotatingSpinner";
import { resources } from "../../Resourses/Resources";
import Faculty from "../../Departments/CSE(AI&ML)/Faculty";

const FacultyForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [facultyAicteFeedback,setFacultyAicteFeedback] = useState({
    
    facultyId:"",
    facultyName:"",
    facultyGender:"",
    facultyPrimaryEmail:"",
    facultyAdhar:"",
    mobileNumber:"",
    facultyQualification:"",
    typeOfEmployement:"",
    coursesHandledperWeek:"",
    aictePermanentId:"",
    facultyinstituteName:"",
    facultyInstituteAddress:""

 })

 const handleFacultyAicteFeedbackDetails = (e) => {
 
  const {value , name} = e.target;
  setFacultyAicteFeedback({...facultyAicteFeedback,[name] : value})
  console.log(name,value)
 }
const handleSubmitAictFeedbackData = async () => {
  setIsLoading(true)
  try{
const res = await axios.post(
  resources.APPLICATION_URL + "saveFacultyAicteFeedBackDetails",facultyAicteFeedback
 
)
if(res.data){
   alert("successfully Submitted") 
}else{
  alert("please Try agian")
}
  }catch(e){
console.log(e);
  }finally{
setIsLoading(false)
  }
}

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
    <h1 className="student_faculty_form_heading">Basic Details of Faculty</h1>
  <form className="student_faculty_form_main_container">
    <div className="student_faculty_form_sub_container">
      <div className="student_faculty_form_container">
        <label htmlFor="Name">Faculty Id:</label>
        <input
        onChange={(e) => handleFacultyAicteFeedbackDetails(e)}
        name="facultyId"
          type="text"
          placeholder="Faculty Id"
          id="facultyId"
          name="facultyId"
          required
        />
      </div>
      <div className="student_faculty_form_container">
        <label htmlFor="nameOfTheStudent">Faculty Name:</label>
        <input
        onChange={(e) => handleFacultyAicteFeedbackDetails(e)}
          type="text"
          name="facultyName"
          placeholder="Faculty Name"
          id="facultyName"
          required
        />
      </div>
      <div className="student_faculty_form_container">
        <label>Gender</label>
        <div style={{ display: "flex" }}>
          <span className="span_ele">male</span>
          <input value="male" type="radio" id="male" name="gender" 
          onChange={(e) => handleFacultyAicteFeedbackDetails(e)}/>
          <span className="span_ele">Female</span>
          <input value="female" type="radio" id="female" name="gender"
          onChange={(e) => handleFacultyAicteFeedbackDetails(e)} />
          <span className="span_ele">Other</span>
          <input value="other" type="radio" id="other" name="gender" 
          onChange={(e) => handleFacultyAicteFeedbackDetails(e)}/>
        </div>
      </div>
      <div className="student_faculty_form_container">
        <label htmlFor="Email">Primary Email Address:</label>
   
        <input type="email" placeholder="Email" id="Email" required name="facultyPrimaryEmail" 
        onChange={(e) => handleFacultyAicteFeedbackDetails(e)}/>
      </div>
      <div className="student_faculty_form_container">
        <label htmlFor="AadhaarNo">Aadhaar No</label>
        <input type="number" placeholder="Aadhaar No" id="AadhaarNo"  name="facultyAdhar"
        onChange={(e) => handleFacultyAicteFeedbackDetails(e)}/>
      </div>
      <div className="student_faculty_form_container">
        <label htmlFor="Contact">Mobile No</label>
        <input type="number" placeholder="Mobile No" id="Contact" required name="mobileNumber" 
        onChange={(e) => handleFacultyAicteFeedbackDetails(e)}/>
      </div>

      <div className="student_faculty_form_container">
        <label htmlFor="Contact">Qualification:</label>
       <select name="facultyQualification"
       onChange={(e) => handleFacultyAicteFeedbackDetails(e)}>
        <option>Please Select..</option>
        <option>B.Tech</option>
        <option>M.Tech</option>
        <option>Phd</option>
       </select>
      </div>


      <div className="student_faculty_form_container">
        <label htmlFor="Contact">Type of Employement:</label>
       <select name="typeOfEmployement"
       onChange={(e) => handleFacultyAicteFeedbackDetails(e)}>
        <option>Please Select..</option>
        <option>Regular</option>
        <option>Contact</option>
        <option>Adhoc</option>
       </select>
      </div>


      <div className="student_faculty_form_container">
        <label htmlFor="Contact">Courses Handled per week:</label>
       <select name="coursesHandledperWeek"
       onChange={(e) => handleFacultyAicteFeedbackDetails(e)}>
        <option>Please Select..</option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
       </select>
      </div>
      
    </div>
  </form>

  <form className="student_faculty_form_main_container">
    <div className="student_faculty_form_sub_container">
   
      <div className="student_faculty_form_container">
        <label htmlFor="Name">AICTE Permanent Id:</label>
        <textarea
          cols={40}
          rows={5}
          type="text"
          placeholder="Student Id"
          id="AICTEPermanentId:"
          name="AICTEPermanentId:"
          required
          onChange={(e) => handleFacultyAicteFeedbackDetails(e)}
        />
      </div>
      <div className="student_faculty_form_container">
        <label htmlFor="nameOfTheStudent">Institute Name:</label>
        <textarea
          cols={40}
          rows={5}
          type="text"
          name="InstituteName"
          placeholder="Institute Name"
          id="InstituteName"
          required
          onChange={(e) => handleFacultyAicteFeedbackDetails(e)}
        />
      </div>
      
      <div className="student_faculty_form_container">
        <label htmlFor="AadhaarNo">Institute Address</label>
        <textarea
          cols={40}
          rows={5}
          type="text"
          placeholder="Institute Address"
          id="InstituteAddress"
          name="facultyInstituteAddress"
          onChange={(e) => handleFacultyAicteFeedbackDetails(e)}
        />
      </div>
    </div>
  </form>
  <div style={{display:'flex',justifyContent:'flex-end',width:'100%'}}>
          <button type="button" className="aicte_submit_btn"
          onClick={() => handleSubmitAictFeedbackData()}
          >Submit</button>
        </div>
</div>
  );
};

export default FacultyForm;
