import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/Nsstable";
import StarRateIcon from "@mui/icons-material/StarRate";

const Committees = () => {
  const nss =[
    {
      title:" Making education more relevant to the present situation to meet the felt needs of the community and supplement the education of the university/college students by bringing them face to face with the rural situation."
    },
    {
      title:"  Providing opportunities to the students to play their role inplanning and executing development projects which would not onlyhelp in creating durable community assets in rural areas and urban slums but also results in the improvement of quality of life of the economically and socially weaker sections of the community."
    },
    {
      title:"Developing the civic and social responsibility. Preparing the students to acquire leadership qualities and democratic attitudes."
    },
    {
      title:"Developing the strengths to meet emergencies and natural disasters."
    },
    {
      title:"Encouraging students and non-students to work together along with the adults in rural areas."
     
    },
    {
      title:"Emphasizing dignity of labour and self-help and the need for combining physical work with intellectual pursuits."
      
    },
    {
     title:"Encouraging youth to participate enthusiastically in the process of national development and promote national integration, through corporate living and cooperative action."
    
    },
    
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            N.S.S. CELL{" "}
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            The motto of the National Service Scheme (N.S.S.) is “Not Me, But
            You”. It expresses the essence of democratic living and upholds the
            need for selfless services and appreciation of another man’s point
            of view and also show consideration towards fellow human beings. It
            underlines the welfare of an individual most necessary as is
            ultimately leads to the welfare of the society as a whole. Therefore
            the N.S.S. should aim to demonstrate this motto in its day-to-day
            activities.
          </p>
          <p>N.S.S. Cell is formed with the following members:</p>
        </div>
      </div>
      <div className="container">
        <Table />
      </div>
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {nss.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
