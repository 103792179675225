import React from 'react'
import BOSFunctions from './BOSFunctions'

const MBA = () => {
    const mba = [
        {
            member: "Chairperson",
            designation: "Dr.SK.J.Shareef,Professor of  MBA Dept. & HOD"
        },
        {
            member: "University Nominee",
            designation: "Dr.A.Krishna Mohan,Director,SMS, JNTU Kakinada krishna.ankala@jntucek.ac.in Mobile No.9640027540"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr.N.Udaya Bhaskar,HOD & Chairman,Board of Studies,Adikavi Nannaya University, Rajamahendravaram.Mobile No: 9490450510. Email:nudaybhaskar@gmail.com"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr.S.Venkata Ramana,Professor of MBA,KL Deemed to be University,Cell : 9494413410 Email: svramana@kluniversity.in"
        },
        {
            member: "Representative from Industry",
            designation: "G.Shashidhar General Manager, Mittapalli Spinners Limited, Narasaraopet,Mobile No: 9581442701.Email: gm@mittapallispinners.com"
        },
        {
            member: "Alumni Member",
            designation: "Mr.Yasodarao Bakkamanthula HDFC Bank, Manager (Merchant Relationship Manager) ,Guntur & Prakasam Districts,Mobile No: 7331149635.Email: yasodarao.bakkamanthula@hdfcbank.com"
        },
        {
            member: "Member",
            designation: "Mrs.M.Naga Lakshmi, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Mrs.M. Saraswathi, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Mr.Yamarapu Mohana Rao, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Mr.G.V.Sai Raghunath, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Mr.K.Tirupathaiah, Asst.Prof., SMCE"
        },
        {
            member: "Member",
            designation: "Mrs.K.Thanuja, Asst.Prof., SMCE"
        },
    ]

    return (
        <>
            <h4 class="_Top">SRI MITTAPALLI COLLEGE OF ENGINEERING <br />
                Department of Master of Business Administration<br />
                Composition of Board of Studies
            </h4 >
            <h3 class="A">
                A.Y.: 2023-24
            </h3>
            <div>
                <table className='gb_table'>
                    <thead>
                        <tr>
                            <th>
                                S.NO
                            </th>
                            <th>
                                MEMBERS
                            </th>
                            <th>
                                NAME & DESIGNATION
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {mba.map((each, i) => <tr>
                            <td>
                                {i + 1}
                            </td>
                            <td>
                                {each.member}
                            </td>
                            <td>
                                {each.designation}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
            <div className='bottom'>
                <h3 className='_chairperson'>Chairperson</h3>
                <p>BoS- MBA</p>
            </div>
            <BOSFunctions />
        </>
    )
}

export default MBA
