import React from "react";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import "./Academic.css";
import Btech202223 from "../../Pdf/Academics/2022-23-Btech.pdf";
import Mtech202223 from "../../Pdf/Academics/2022-23-Mtech.pdf";
import Mba202223 from "../../Pdf/Academics/2022-23-mba.pdf";
import Btech202122 from "../../Pdf/Academics/2021-22-Btech.pdf";
import Mtech202122 from "../../Pdf/Academics/2021-22-Mtech.pdf";
import mba202122 from "../../Pdf/Academics/2021-22-mba.pdf";
import Btech202021 from "../../Pdf/Academics/2020-21-Btech.pdf";
import Mtech202021 from "../../Pdf/Academics/2020-21-Mtech.pdf";
import mba202021 from "../../Pdf/Academics/2020-21-mba.pdf";
import Btech201920 from "../../Pdf/Academics/2019-20-Btech.pdf";
import Mtech201920 from "../../Pdf/Academics/2019-20-Mtech.pdf";
import mba201920 from "../../Pdf/Academics/2019-20-mba.pdf";
import Btech201819 from "../../Pdf/Academics/2018-19-Btech.pdf";
import Mtech201819 from "../../Pdf/Academics/2018-19-Mtech.pdf";
import mba201819 from "../../Pdf/Academics/2018-19-mba.pdf";
import Btech201718 from "../../Pdf/Academics/2017-18-Btech.pdf";
import Mtech201718 from "../../Pdf/Academics/2017-18-Mtech.pdf";
import mba201718 from "../../Pdf/Academics/2017-18-mba.pdf";
import mtech1202324 from "../../assets/pdfsmittapalli/2023 - 24/PG/AC_II MBA, MCA, IMBA.pdf";
import mtech2202324 from "../../assets/pdfsmittapalli/2023 - 24/PG/MR23_M.Tech Academic Calendar.pdf";
import mba202324 from "../../assets/pdfsmittapalli/2023 - 24/PG/MR23_MBA Academic Calendar.pdf";
import Btech202324 from "../../assets/pdfsmittapalli/2023 - 24/UG/UG 2023-24 Academic Calendars.pdf";


const AcademicCalender = () => {
  const Year = [
    {
      year: "2023 - 2024",
      mtech1: mtech1202324,
      mtech2: mtech1202324,
      btech: Btech202324,
      mba: mba202324,
      pannel: "panel1",
    },
    {
      year: "2022 – 2023",
      mtech: Mtech202223,
      btech: Btech202223,
      mba: Mba202223,
      pannel: "panel2",
    },
    {
      year: "2021  –  2022",
      mtech: Mtech202122,
      btech: Btech202122,
      mba: mba202122,
      pannel: "panel3",
    },
    {
      year: "2020  –  2021",
      mtech: Mtech202021,
      btech: Btech202021,
      mba: mba202021,
      pannel: "panel4",
    },
    {
      year: "2019 – 2020",
      mtech: Mtech201920,
      btech: Btech201920,
      mba: mba201920,
      pannel: "panel5",
    },
    {
      year: "2018  –  2019",
      mtech: Mtech201819,
      btech: Btech201819,
      mba: mba201819,
      pannel: "panel6",
    },
    {
      year: "2017 – 2018",
      mtech: Mtech201718,
      btech: Btech201718,
      mba: mba201718,
      pannel: "panel7",
    },
  ];

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div>
      <h2 className="addmision_heading">Academic Calender</h2>
      <div className="academic_calender">
        <br />
        {Year.map((each) => (
          <>
            <Accordion
              className="library_accordion_AC"
              expanded={expanded === each.pannel}
              onChange={handleChange(each.pannel)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {each.year}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="calender_btn_container">
                    <a target="_blank" href={each.btech}>
                      <button>B.Tech</button>
                    </a>
                    <a target="_blank" href={each.mtech1}>
                      {" "}
                      <button>M.Tech</button>
                    </a>
                    <a target="_blank" href={each.mtech2}>
                      {" "}
                      <button>M.Tech2</button>
                    </a>
                    <a target="_blank" href={each.mba}>
                      {" "}
                      <button>MBA</button>
                    </a>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <br />
            <br />
          </>
        ))}
      </div>
    </div>
  );
};

export default AcademicCalender;
