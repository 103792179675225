import React from "react";
import "../Amenities/Classroom.css";
import LibraryImg from "./../../assets/classroomMi.jpg";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import staffImg from "../../assets/carousal_images/InteractionwithChairman.jpeg";

const Eclassroom = () => {
  const facilities = [
    {
      title: "JST",
      course: "J- GATE SCIENCE AND TECHNOLOGY",
    },
    {
      title: "DELNET",
      course: "E-journals",
    },
    {
      title: "NDL",
      course: "NDL India",
    },
    {
      title: "SHODHA SINDHU",
      course: "ONLINE E- RESOURCE REUISITION SYSTEMS",
    },
  ];
  const staff = [
    {
      name: "G.UPENDRA RAO",
      qualification: "MA.B.Ed.,MLISc,PGDCA	",
      designation: "LIBRARIAN",
    },
    {
      name: "V.VENKATESWER RAO",
      qualification: "BA,B.LISc.",
      designation: "Library Asst",
    },
    {
      name: "O.VENKATA KRISHNA",
      qualification: "BSc COMPUTERS.",
      designation: "BOOK KEEPER",
    },
    {
      name: "M.MAHA LAKSHMI",
      qualification: "SSC",
      designation: "ATTENDER",
    },
  ];
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <div className="classroom">
        <img src={LibraryImg} alt="LibraryImg" />
        <div>
          <h3 className="h3"> Class Rooms</h3>
          <ul className="align">
            <li>ICT Enabled class room with wifi facility. </li>
            <li>Well furnished class rooms.</li>
            <li>Seating arrangement with capacity of 70 students.</li>
          </ul>
        </div>
      </div>
     
    </div>
  );
};

export default Eclassroom;
