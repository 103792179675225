import React, { useState } from 'react';

const OnlineSubmission = () => {
    const [data, setData] = useState({
        fullName: "",
        program: "",
        email: "",
        mobile: "",
        message: ""
    });

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData({ ...data, [name]: value });
    };

    const sendEmail = () => {
        window.Email.send({
            SecureToken : "0d5ed22f-6988-4ef7-903f-336a241f7022",
            To: 'gangipakaprasannakumar@gmail.com', // Recipient's email address
            From: "adhikritnirf@gmail.com", // Sender's email address (user's email)
            Subject: `New Submission from ${data.fullName}`,
            Body: `
                Name: ${data.fullName} <br/>
                Program: ${data.program} <br/>
                Email: ${data.email} <br/>
                Mobile: ${data.mobile} <br/>
                Message: ${data.message}
            `
        }).then(
            message => alert("Mail sent successfully!"),
            error => alert("Failed to send mail.")
        );
    };

    const inp = [
        {
            name: "Full Name*",
            type: "text",
            placeholder: "Enter Name",
            value: data.fullName,
            key: "fullName"
        },
        {
            name: "Program*",
            type: "text",
            placeholder: "Enter Program",
            value: data.program,
            key: "program"
        },
        {
            name: "Email*",
            type: "email", // Corrected to "email"
            placeholder: "Enter Email",
            value: data.email,
            key: "email"
        },
        {
            name: "Mobile*",
            type: "number",
            placeholder: "Enter Mobile Number",
            value: data.mobile,
            key: "mobile"
        },
    ];

    return (
        <div>
            <p className='gr_header'>
                Online Grievance Submission
            </p>
            <br />
            <div style={{ width: "50%", margin: "auto", boxShadow: "0px 0px 10px 1px black", padding: "1cm", borderRadius: "10px" }}>
                {inp.map((each, i) => (
                    <div key={i}>
                        <label style={{ marginTop: "10px", fontWeight: "bold" }}>
                            {each.name}
                        </label>
                        <input
                            style={{ width: "100%", height: "1cm", borderRadius: "5px", paddingLeft: "20px", outline: "none", border: "1px solid gray" }}
                            type={each.type}
                            name={each.key}
                            placeholder={each.placeholder}
                            value={each.value}
                            onChange={handleChange}
                        />
                    </div>
                ))}
                <div>
                    <label style={{ marginTop: "10px", fontWeight: "bold" }}>
                        Message
                    </label>
                    <textarea
                        style={{ width: "100%", minHeight: "2cm", borderRadius: "5px", paddingLeft: "20px", paddingTop: "10px", outline: "none" }}
                        type='text'
                        name='message'
                        placeholder='Message'
                        value={data.message}
                        onChange={handleChange}
                    />
                </div>
                <button
                    style={{ width: "100%", height: "1cm", borderRadius: "5px", border: "none", marginTop: "10px", backgroundColor: "#272d66", color: "white", fontWeight: "bold" }}
                    onClick={sendEmail}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default OnlineSubmission;
