import React from 'react'
import "../GoverningBody/GoverningBody.css"
import StarRateIcon from "@mui/icons-material/StarRate";

const BOSFunctions = () => {

    const bosFunctions = [
        {
            title:"Prepare syllabi for various courses keeping in view the objectives of the college, interest of the stakeholders and national requirement for consideration and approval of the Academic Council."
        },
        {
            title:"Suggest methodologies for innovative teaching and evaluation techniques"
        },
        {
            title:"Suggest panel of names to the Academic Council for appointment of examiners"
        },
        {
            title:"Coordinate research, teaching, extension and other academic activities in the department/college"
        }
    ]
  return (
    <div className='governing_content'>
    <p>
        <span className="governing_sidehead">Functions :</span>
    </p>
    {bosFunctions.map((item) => <div style={{ display: "flex" }}>
        <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
        <p style={{textAlign:"start"}}>
            {" "}
            {item.title}
        </p>
    </div>)}
</div>
  )
}

export default BOSFunctions
