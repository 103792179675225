import React from 'react'
import Table from '@mui/joy/Table';
import { PageHeader } from '../../Header/PageHeader';


const Faculty = () => {
    const facultyList = [
        // {
        //     sno: 1,
        //     name: "Mittapalli",
        //     designation: "HOD",
        //     qualification: "M.Tech",
        //     experience: "5",
        //     dateOfJoining: "2021",
        // },
        // {
        //     sno: 2,
        //     name: "Mittapalli",
        //     designation: "HOD",
        //     qualification: "M.Tech",
        //     experience: "5",
        //     dateOfJoining: "2021",
        // },
        // {
        //     sno: 3,
        //     name: "Mittapalli",
        //     designation: "HOD",
        //     qualification: "M.Tech",
        //     experience: "5",
        //     dateOfJoining: "2021",
        // },
        // {
        //     sno: 4,
        //     name: "Mittapalli",
        //     designation: "HOD",
        //     qualification: "M.Tech",
        //     experience: "5",
        //     dateOfJoining: "2021",
        // },
        // {
        //     sno: 5,
        //     name: "Mittapalli",
        //     designation: "HOD",
        //     qualification: "M.Tech",
        //     experience: "5",
        //     dateOfJoining: "2021",
        // },
        // {
        //     sno: 6,
        //     name: "Mittapalli",
        //     designation: "HOD",
        //     qualification: "M.Tech",
        //     experience: "5",
        //     dateOfJoining: "2021",
        // }
    ]
  return (
    <div className="container">
        <PageHeader name="FACULTY" />
       <Table borderAxis="both">
      <caption>List of all CSE faculty</caption>
      <thead>
        <tr>
          <th>S.NO</th>
          <th>Faculty Name</th>
          <th>Designation</th>
          <th>Qualification</th>
          <th>Experience</th>
          <th>Date of Joining</th>
        </tr>
      </thead>
      <tbody>
        {facultyList.map((each,i) => (
          <tr key={i}>
            <td>{each.sno}</td>
            <td>{each.name}</td>
            <td>{each.designation}</td>
            <td>{each.qualification}</td>
            <td>{each.experience}</td>
            <td>{each.dateOfJoining}</td>

          </tr>
        ))}
      </tbody>
      
    </Table>
    </div>
  )
}

export default Faculty





