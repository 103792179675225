import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, branch1, branch2, branch3, branch4,branch5,branch6) {
  return { name, branch1, branch2, branch3, branch4,branch5,branch5,branch6 };
}

const rows = [
  createData('1st prefered Branch', "CSE", "CSE (AI&ML)", "AI&DS", "ECE","ECE","MECH"),
  createData('2st prefered Branch', "CSE", "CSE (AI&ML)", "AI&DS", "ECE","ECE","MECH"),
  createData('3st prefered Branch', "CSE", "CSE (AI&ML)", "AI&DS", "ECE","ECE","MECH"),
  createData('4st prefered Branch', "CSE", "CSE (AI&ML)", "AI&DS", "ECE","ECE","MECH"),
  createData('5st prefered Branch', "CSE", "CSE (AI&ML)", "AI&DS", "ECE","ECE","MECH"),
  createData('6st prefered Branch', "CSE", "CSE (AI&ML)", "AI&DS", "ECE","ECE","MECH"),
];

export default function BasicTable( {setStudentRegistrationData}) {

  const handleAppendData = (e) => {
    const { name, value } = e.target;
    setStudentRegistrationData((prevData) => ({
        ...prevData,
        [name]: value
    }));
}

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">CSE</TableCell>
            <TableCell align="right">CSE (AI&ML)</TableCell>
            <TableCell align="right">AI&DS</TableCell>
            <TableCell align="right">ECE</TableCell>
            <TableCell align="right">ECE</TableCell>
            <TableCell align="right">MECH</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,index) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right"><input type="radio" onChange={(e) => handleAppendData(e)} value={row.branch1} name="firstPreferedBranch" /></TableCell>
              <TableCell align="right"><input type="radio" onChange={(e) => handleAppendData(e)} value={row.branch2} name="secundPreferedBranch"/></TableCell>
              <TableCell align="right"><input type="radio" onChange={(e) => handleAppendData(e)} value={row.branch3} name="thirdPreferedBranch" /></TableCell>
              <TableCell align="right"><input type="radio" onChange={(e) => handleAppendData(e)} value={row.branch4} name="fourthPreferedBranch" /></TableCell>
              <TableCell align="right"><input type="radio" onChange={(e) => handleAppendData(e)} value={row.branch5} name="fifthPreferedBranch" /></TableCell>
              <TableCell align="right"><input type="radio" onChange={(e) => handleAppendData(e)} value={row.branch6} name="sixthPreferedBranch;+"/></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}