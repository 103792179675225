import React from "react";
import mous from "../../assets/images/ss-mous.jpg";
import { Link } from "react-router-dom";
import Vision from "../../assets/elementor1.jpg";
import Mission from "../../assets/elementorMIT.jpg";
import QualityPolicy from "../../assets/elementorMITA.jpg";
import student from "../../assets/student banner.png";
import "../VisionMision.css";
const VisionMision = () => {
  return (
    <div>
      <div className="font">
        <h1 className="premium-title-text">Wonder, Why Premium Mega Menu?</h1>
        <h2 className="h2css">BUILD STANDOUT MEGA MENUS</h2>
        <h2 className="h2">ON YOUR ELEMENTOR WORDPRESS-BASED WEBSITE</h2>
        <p className="p">
          We have worked hard to deliver you the top Mega Menu features to give
          you the ability to find all you need just in one single widget
          Elementor Mega Menu Widget. Create, build, and insert your Mega Menu
          right on your Elementor Website.
        </p>
      </div>
      <div className="color">
        <div className="card_cntr1">
          <div className="background" data-wow-delay="0.1s">
            <div className="">
              <div className="p-4">
                {/* <i className="fa fa-3x  text-primary mb-4"></i> */}
                {/* <h5 className="mb-3">Vision</h5> */}
                <img
                  className="img"
                  src={Vision}
                  alt="Description of the image"
                />
                <br />
                <h6 className="css">2 MENU TYPES TO CHOOSE FROM</h6>
                <p>
                  Choose whether if you want to build your Main Menu using the
                  regular WordPress Menu settings or you can choose "Custom
                  Menu" to build a full navigation menu using the Elementor
                  Editor.
                </p>
              </div>
            </div>
          </div>
          <div className=" background" data-wow-delay="0.3s">
            <div className=" pt-3">
              <div className="p-4">
                {/* <i className="fa fa-3x fa-globe text-primary mb-4"></i>
              <h5 className="mb-3">Mission</h5> */}
                <img
                  className="img"
                  src={Mission}
                  alt="Description of the image"
                />
                <br />
                <h6 className="cssh6">SELECT FROM 4 MENU LAYOUTS</h6>
                <p>
                  Feel free to choose from 4 navigation menu layouts;
                  Horizontal, Vertical, Dropdown, and Slide. All of those
                  layouts are available for both Desktop and Mobile menu to
                  match your needs.
                </p>
              </div>
            </div>
          </div>
          <div className=" background" data-wow-delay="0.5s">
            <div className="pt-3">
              <div className="p-4">
                {/* <i className="fa fa-3x fa-home text-primary mb-4"></i>
              <h5 className="mb-3">Quality Policy</h5> */}
                <img src={Mission} alt="Description of the image" />
                <br />
                <h6>HANDPICKED RESPONSIVE CONTROLS</h6>
                <p>
                  A standout navigation menu means excellent responsive
                  controls. We kept in mind and considered all your needs to get
                  an outstanding navigation menu on your Elementor website.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <img className="name" src={student} alt="Description of the image" />
        <br />
        <p className="visionp">
          Sri Mittapalli College of Engineering, a premier technical institution
          was founded by towering personality of impeccable credentials, a
          notable academician, with grand mission and noble vision
          Sri.M.V.Koteswara Rao in the year 2006. The college is approved by All
          India Council for Technical Education (AICTE) affiliated to JNTU
          Kakinada and functions as a private self- financing institution under
          the auspices of Sri Mittapalli Trust. Sri Mittapalli College of
          Engineering is a tantamount in offering quality education to its
          students by enhancing their employability skills and technical
          know-how. It strives to instigate its students’ fresh perceptiveness,
          inventive thinking and firm conviction to achieve true success. With
          an aim to foster an equitable and productive growth, SMCE seeks to
          nurture the competencies of students as per industrial requirements.
          At SMCE we also focus on developing traits of students by providing an
          absolute education there by concentrating on all the aspects of
          professional building. We have been incessantly in the course of
          improving the quality of teaching by executing various activities like
          seminars by eminent personalities, language development and training
          in soft skills and communication skills. SMCE believe in fostering
          innovative practices and sustainable development programs to its
          students. Our main motto is student discipline, and enthusing the
          students by encouraging them to participate in extracurricular
          activities like sports NSS, NCC on par with their academics. With a
          high calibre faculty and an excellent infrastructure, we promote
          academic excellence, absolute discipline and sound practical exposure,
          in this way we impart the best education to create ambience of
          excellence. We at SMCE deem that honesty, hard work, and discipline
          collectively form the ladder for success. On whole SMCE is an abode of
          centre of excellence for creating holistic citizens inculcated with
          ethical, moral and social values in diverse culture.
        </p>
      </div>

      {/* <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-130"
                  src={mous}
                  alt="about-img"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                OUR MOUs
              </h6>
              <h1 className="mb-4">Memorandum Of Understanding</h1>
              <p className="mb-4">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit.
                Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit.
              </p>
              <p className="mb-4">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit.
                Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit,
                sed stet lorem sit clita duo justo magna dolore erat amet
              </p>
              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Microsoft Windows
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    CISCO Networking Academy
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    TASK(Telangana Academy for Skill and Knowledge)
                  </p>
                </div>
                <br />
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Wipro
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    ESwetcha
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Campus Connect
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Oracle Academy
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Mission 10X
                  </p>
                </div>
              </div>

              
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default VisionMision;
