import React from 'react'
import "../GoverningBody/GoverningBody.css"
import StarRateIcon from "@mui/icons-material/StarRate";
import BOSFunctions from './BOSFunctions';
const Chemistry = () => {
    const chem = [
        {
            member: "Chairperson",
            designation: "Dr.V.Madhuri,Professor of AS&H Dept. & HOD"
        },
        {
            member: "University Nominee",
            designation: "Dr.S.Satya Veni,Professor of Chemistry, UCEK,JNTU Kakinada"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr D. Nagaraju,M.Sc., Ph.D.Professor of chemistry,Vignan university, Guntur.9030784939 naag1977@gmail.com"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr K. Lakshmi M.Sc., Ph.D.,Assoc. professor of chemistry,Bapatla Engineering college, Bapatla,9949793215 lakshmisrinivas41@gmail.com"
        },
        {
            member: "Representative from Industry",
            designation: "Dr.P.Lova Kumar Sub Divisional Engineer,CNTX South BSNL HYDERABAD.lovakumarbsnl@gmail.com 9490427439"
        },
        {
            member: "Alumni Member",
            designation: "R.Bhuvaneswari,Asst.Prof., Dept.of CSE,SMCE.bhuvana.rayapudi111@gmail.com 9398788846"
        },
        {
            member: "Member",
            designation: "Mrs.G.Mery Janeefa, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Mr.G.Raja Kumar, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Mr.M.Sujan Kumar, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Mr.Sk. Ameer Fareed Basha, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Mrs.A.Naga Mani, Asst.Prof. SMCE"
        },
    ]

    const bosFunctions = [
        {
            title:"Prepare syllabi for various courses keeping in view the objectives of the college, interest of the stakeholders and national requirement for consideration and approval of the Academic Council."
        },
        {
            title:"Suggest methodologies for innovative teaching and evaluation techniques"
        },
        {
            title:"Suggest panel of names to the Academic Council for appointment of examiners"
        },
        {
            title:"Coordinate research, teaching, extension and other academic activities in the department/college"
        }
    ]

    return (
        <>
            <h4 class="_Top">SRI MITTAPALLI COLLEGE OF ENGINEERING <br />
                Department of Chemistry <br />
                Composition of Board of Studies
            </h4 >
            
            <h3 class="A">
                A.Y.: 2023-24
            </h3>
            <div>
                <table className='gb_table'>
                    <thead>
                        <tr>
                            <th>
                                S.NO
                            </th>
                            <th>
                                MEMBERS
                            </th>
                            <th>
                                NAME & DESIGNATION
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {chem.map((each, i) => <tr>
                            <td>
                                {i + 1}
                            </td>
                            <td>
                                {each.member}
                            </td>
                            <td>
                                {each.designation}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
            <div className='bottom'>
                <h3 className='_chairperson'>Chairperson</h3>
                <p>BoS-Chemistry</p>
            </div>
<BOSFunctions/>
        </>
    )
}

export default Chemistry
