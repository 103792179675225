import React from "react";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

const ServiceRules = () => {
  return (
    <div className="container">
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <title>Microsoft Word - Service Rules of NCET wef August 2012</title>
      <meta name="author" content="Principal" />
      <style
        type="text/css"
      
      />
      <p
        className="s1"
        style={{
          paddingTop: "4pt",
          paddingLeft: "119pt",
          textIndent: "-24pt",
          lineHeight: "119%",
          textAlign: "center",
        }}
      >
        SRI MITTAPALLI COLLEGE OF ENGINEERING SERVICE RULES AND REGULATIONS
      </p>
      <h3
        style={{
          paddingLeft: "212pt",
          textIndent: "0pt",
          lineHeight: "15pt",
          textAlign: "left",
        }}
      >
        
      </h3>
      <p
        className="s1"
        style={{ paddingTop: "2pt", textIndent: "0pt", textAlign: "center" }}
      >
        ADMINISTRATIVE MANUAL
      </p>
      <p style={{ paddingTop: "6pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <div className="row">
        <div className="col-12">
          <h3
            style={{
              paddingLeft: "30pt",
              textIndent: "0pt",
              textAlign: "center",
              width: "100%",
              margin: "auto",
            }}
          >
            INDEX
          </h3>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginLeft: "6.034pt",
            }}
            cellSpacing={0}
          >
            <tbody>
              <tr style={{ height: "26pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "6pt",
                      paddingRight: "3pt",
                      textIndent: "0pt",
                      textAlign: "center",
                    }}
                  >
                    Annexure
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "69pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    Details
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "3pt",
                      textIndent: "0pt",
                      textAlign: "center",
                    }}
                  >
                    Page No.
                  </p>
                </td>
              </tr>
              <tr style={{ height: "26pt" }}>
                <td
                  style={{
                    width: "390pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                  colSpan={2}
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    Vision, Mission and Quality Policy
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingLeft: "3pt",
                      paddingRight: "2pt",
                      textIndent: "0pt",
                      lineHeight: "14pt",
                      textAlign: "center",
                    }}
                  >
                    2 - 4
                  </p>
                </td>
              </tr>
              <tr style={{ height: "21pt" }}>
                <td
                  style={{
                    width: "390pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                  colSpan={2}
                >
                  <p
                    className="s2"
                    style={{
                      textIndent: "0pt",
                      lineHeight: "14pt",
                      textAlign: "center",
                    }}
                  >
                    SERVIVE RULES AND ADMINISTRATIVE POLICIES
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                  </p>
                </td>
              </tr>
              <tr style={{ height: "26pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    1
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "7pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    Etiquettes and Code of Conduct
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingLeft: "3pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    5 - 8
                  </p>
                </td>
              </tr>
              <tr style={{ height: "26pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    2
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "7pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    General Guidelines
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingLeft: "3pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    9 -10
                  </p>
                </td>
              </tr>
              <tr style={{ height: "26pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    3
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "7pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    Guidelines for Faculty
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingLeft: "3pt",
                      paddingRight: "1pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    11 – 18
                  </p>
                </td>
              </tr>
              <tr style={{ height: "26pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    4
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "7pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    General Scholarship Policy
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingLeft: "3pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    19
                  </p>
                </td>
              </tr>
              <tr style={{ height: "26pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    5
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "7pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    Institution Merit Scholarship Policy
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingLeft: "3pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    20
                  </p>
                </td>
              </tr>
              <tr style={{ height: "26pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    6
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "7pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    Recruitment Policies
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingLeft: "3pt",
                      paddingRight: "1pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    21 – 22
                  </p>
                </td>
              </tr>
              <tr style={{ height: "26pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    7
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "7pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    Employee Appraisal Policies
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingLeft: "3pt",
                      paddingRight: "1pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    23 – 26
                  </p>
                </td>
              </tr>
              <tr style={{ height: "26pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    8
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "7pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    Compensation Policy
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingLeft: "3pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    27
                  </p>
                </td>
              </tr>
              <tr style={{ height: "26pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    9
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "7pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    Research Policy
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingLeft: "3pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    28
                  </p>
                </td>
              </tr>
              <tr style={{ height: "21pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "7pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    10
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "7pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "left",
                    }}
                  >
                    Leave Policies
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "3pt",
                      paddingRight: "1pt",
                      textIndent: "0pt",
                      textAlign: "center",
                    }}
                  >
                    29 – 32
                  </p>
                </td>
              </tr>
              <tr style={{ height: "21pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "7pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    11
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "7pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "left",
                    }}
                  >
                    Duties and Responsibilities of Assistant Professor
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "3pt",
                      textIndent: "0pt",
                      textAlign: "center",
                    }}
                  >
                    33
                  </p>
                </td>
              </tr>
              <tr style={{ height: "21pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "14pt",
                      textAlign: "center",
                    }}
                  >
                    12
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      lineHeight: "14pt",
                      textAlign: "left",
                    }}
                  >
                    Duties and Responsibilities of Associate Professor
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "3pt",
                      textIndent: "0pt",
                      textAlign: "center",
                    }}
                  >
                    34
                  </p>
                </td>
              </tr>
              <tr style={{ height: "21pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "14pt",
                      textAlign: "center",
                    }}
                  >
                    13
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      lineHeight: "14pt",
                      textAlign: "left",
                    }}
                  >
                    Duties and Responsibilities of a Professor
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "3pt",
                      paddingRight: "1pt",
                      textIndent: "0pt",
                      textAlign: "center",
                    }}
                  >
                    35 – 36
                  </p>
                </td>
              </tr>
              <tr style={{ height: "21pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "1pt",
                      textIndent: "0pt",
                      lineHeight: "14pt",
                      textAlign: "center",
                    }}
                  >
                    14
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      lineHeight: "14pt",
                      textAlign: "left",
                    }}
                  >
                    Duties of Principal
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "3pt",
                      paddingRight: "1pt",
                      textIndent: "0pt",
                      textAlign: "center",
                    }}
                  >
                    37 – 39
                  </p>
                </td>
              </tr>
              <tr style={{ height: "21pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    15
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "left",
                    }}
                  >
                    Duties and Responsibilities of Administration Staff
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "3pt",
                      textIndent: "0pt",
                      textAlign: "center",
                    }}
                  >
                    40
                  </p>
                </td>
              </tr>
              <tr style={{ height: "21pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    16
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "5pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "left",
                    }}
                  >
                    Duties and Responsibilities of HOD‟s
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "3pt",
                      textIndent: "0pt",
                      textAlign: "center",
                    }}
                  >
                    41 - 42
                  </p>
                </td>
              </tr>
              <tr style={{ height: "21pt" }}>
                <td
                  style={{
                    width: "461pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                  colSpan={3}
                >
                  <p
                    className="s2"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "2pt",
                      textIndent: "0pt",
                      textAlign: "center",
                    }}
                  >
                    Annexure
                  </p>
                </td>
              </tr>
              <tr style={{ height: "21pt" }}>
                <td
                  style={{
                    width: "65pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      paddingLeft: "6pt",
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "center",
                    }}
                  >
                    I
                  </p>
                </td>
                <td
                  style={{
                    width: "325pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s2"
                    style={{
                      textIndent: "0pt",
                      lineHeight: "13pt",
                      textAlign: "left",
                    }}
                  >
                    Self Appraisal Form
                  </p>
                </td>
                <td
                  style={{
                    width: "71pt",
                    borderTopStyle: "solid",
                    borderTopWidth: "1pt",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1pt",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1pt",
                    borderRightStyle: "solid",
                    borderRightWidth: "1pt",
                  }}
                >
                  <p
                    className="s3"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "3pt",
                      textIndent: "0pt",
                      textAlign: "center",
                    }}
                  >
                    43 - 47
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <h1
        style={{
          paddingTop: "3pt",
          paddingLeft: "34pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        SRI MITTAPALLI COLLEGE OF ENGINEERING
      </h1>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h4 style={{ paddingLeft: "23pt", textIndent: "0pt", textAlign: "left" }}>
        VISION
      </h4>
      <ul id="l1">
        <li data-list-text="">
          <h4
            style={{
              paddingTop: "7pt",
              paddingLeft: "59pt",
              textIndent: "-18pt",
              lineHeight: "149%",
              textAlign: "left",
            }}
          >
            To be a Top-Notch Institute in Fostering Visionary Ethical
            Technocrats with Global Standards to contribute the prosperity of
            society,
          </h4>
          <h4
            style={{
              paddingTop: "9pt",
              paddingLeft: "23pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            MISSION
          </h4>
        </li>
        <li data-list-text="">
          <p
            className="s4"
            style={{
              paddingTop: "7pt",
              paddingLeft: "58pt",
              textIndent: "-18pt",
              lineHeight: "147%",
              textAlign: "left",
            }}
          >
            To provide Outcome-Based Quality Technical Education with Civic
            Sense by well-qualified and committed faculty.
          </p>
        </li>
        <li data-list-text="">
          <h4
            style={{
              paddingLeft: "58pt",
              textIndent: "-18pt",
              lineHeight: "147%",
              textAlign: "left",
            }}
          >
            To <span className="s4">maneuver </span>the Potential Resources
            viz., Human, Finance and Technology to the prosperity of student
            Centric Academics.
          </h4>
        </li>
        <li data-list-text="">
          <p
            className="s4"
            style={{
              paddingLeft: "58pt",
              textIndent: "-18pt",
              lineHeight: "148%",
              textAlign: "left",
            }}
          >
            To impart necessary skills required to make students Globally
            Employable with the contemporary teaching methodologies.
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s4"
            style={{
              paddingLeft: "58pt",
              textIndent: "-17pt",
              textAlign: "left",
            }}
          >
            To empower the aspirants of Higher Education with appropriate
            abilities.
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s4"
            style={{
              paddingTop: "7pt",
              paddingLeft: "58pt",
              textIndent: "-17pt",
              textAlign: "left",
            }}
          >
            To establish &amp; maintain a constant &amp; strong relation with
            the industry alumni and academia.
          </p>
        </li>
      </ul>
      <p style={{ paddingTop: "2pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h4 style={{ paddingLeft: "23pt", textIndent: "0pt", textAlign: "left" }}>
        QUALITY POLICY
      </h4>
      <ul id="l2">
        <li data-list-text="">
          <h4
            style={{
              paddingTop: "7pt",
              paddingLeft: "51pt",
              textIndent: "-18pt",
              lineHeight: "149%",
              textAlign: "justify",
            }}
          >
            Sri Mittapalli College of Engineering is striving relentlessly by
            transforming consistently to the trends in the fraternity of
            Technical Education through Transparent and Effective System in
            accordance with the standards of International Accreditation.
          </h4>
        </li>
      </ul>
      <h3
        style={{
          paddingTop: "3pt",
          paddingLeft: "52pt",
          textIndent: "0pt",
          lineHeight: "16pt",
          textAlign: "center",
        }}
      >
        SERVICE RULES AND ADMINISTRATION POLICIES
      </h3>
      <ol id="l3">
        <li data-list-text={1}>
          <h3
            style={{
              paddingLeft: "36pt",
              textIndent: "-13pt",
              lineHeight: "16pt",
              textAlign: "left",
            }}
          >
            General:
          </h3>
          <p
            style={{
              paddingTop: "2pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            <br />
          </p>
          <p
            style={{
              paddingLeft: "39pt",
              textIndent: "62pt",
              lineHeight: "150%",
              textAlign: "left",
            }}
          >
            These rules shall be called “
            <b>SRI MITTAPALLI COLLEGE OF ENGINEERING SERVICE RULES”</b>. These
            rules shall apply to all employees, whether ad-hoc, temporary, part-
            time, contractual or permanent, in the service of SRI MITTAPALLI
            COLLEGE OF ENGINEERING and at any other Higher Education Institute,
            by whatsoever name called, which is or may hereafter be established
            or managed, anywhere in India, by SRI MITTAPALLI TRUST. The{" "}
            <b>“Governing Council” </b>of “
            <b>SRI MITTAPALLI COLLEGE OF ENGINEERING</b>”, hereafter called{" "}
            <b>“GC” </b>reserves the exclusive right, at any time hereafter and
            without any notice, to amend, alter, modify, add or delete any
            provision(s) to all or any of these service rules, from time to time
            and, to bring such amendments, alterations, modifications,
            additions, deletions into effect from a date to be notified by the
            “GC”. Such amendments, alterations, modifications, additions,
            deletions if any, shall become binding on all employees covered by
            these service rules from the date of their notification by the “GC”.
            The Chairman of “GC” shall have the exclusive power to clarify any
            issue or to remove any doubt relating to these “Service Rules” and /
            or its implementation.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <li data-list-text={2}>
          <h3
            style={{
              paddingLeft: "36pt",
              textIndent: "-13pt",
              textAlign: "left",
            }}
          >
            Definitions
          </h3>
        </li>
      </ol>
      <p
        style={{
          paddingTop: "13pt",
          paddingLeft: "41pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        In these rules, unless there is anything repugnant to the subject or
        context, The <b>“SMT” </b>means <b>“SRI MITTAPALLI TRUST” </b>
        registered under the AP Societies Registrations Act of 1960, which is
        responsible for the establishment and management of The SRI MITTAPALLI
        COLLEGE OF ENGINEERING(SMCE).
      </p>
      <p
        style={{
          paddingTop: "13pt",
          paddingLeft: "41pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        The <b>“Institute” </b>means
        <b>“SRI MITTAPALLI COLLEGE OF ENGINEERING” </b>and its sections, wings,
        departments or centers at any other location in India, established or
        managed by SMCE
      </p>
      <p
        style={{
          paddingTop: "12pt",
          paddingLeft: "41pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        The <b>“GC” </b>means the Governing Council of “
        <b>SRI MITTAPALLI COLLEGE OF ENGINEERING</b>” which has been constituted
        in accordance with the provisions laid in this respect by All India
        Council for Technical Education.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "41pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        The <b>“Management” </b>or <b>“Employer” </b>means the
        President/Chairman/Secretary/CEO of SMT and the Principal of the
        institute.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "41pt",
          textIndent: "0pt",
          textAlign: "justify",
        }}
      >
        The <b>“Principal” </b>means the person appointed by the “GC” who shall
        be the chief academic officer of the Institute and who shall be
        responsible for the proper and efficient administration of the Institute
        in accordance with the rules and guidelines laid down in this respect by
        the “GC”.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        className="s5"
        style={{
          paddingLeft: "30pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        “Employee”
        <span className="p">
          includes all employees, without exception performing whatsoever
          function assigned
        </span>
      </p>
      <p
        style={{
          paddingTop: "3pt",
          paddingLeft: "41pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        to him/her and having the whole or part-time employment in the
        Institute, whether such employment is probationary, ad-hoc, temporary,
        permanent or contractual.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        className="s5"
        style={{ paddingLeft: "30pt", textAlign: "left" }}
      >
        “AICTE”
        <span className="p">
          means the All India Council for Technical Education, which is an
          autonomous body of Ministryof HRD, Government of India.
        </span>
      </p>
      <p style={{ paddingTop: "2pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        className="s5"
        style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}
      >
        “Affiliating University”
        <span className="p">
          means the university responsible for according affiliation to
          institutions run by SMCE.
        </span>
      </p>
      <h3
        style={{
          paddingTop: "3pt",
          paddingLeft: "52pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        ETIQUETTES OR CODE OF CONDUCT
      </h3>
      <p
        style={{
          paddingTop: "13pt",
          paddingLeft: "39pt",
          textIndent: "0pt",
          lineHeight: "150%",
          textAlign: "justify",
        }}
      >
        The provisions contained in this schedule shall apply, without
        exception, to all employees of the Institute whether, ad-hoc,
        probationary, temporary, contractual, part-time or permanent who shall
        be governed by the Code of Conduct, as specified herein and every
        employee, without exception shall be liable to strict disciplinary
        action, including suspension and/or termination, for the breach of any
        provision(s) of the code ofconduct.
      </p>
      <p style={{ paddingTop: "6pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "19pt",
          textIndent: "0pt",
          lineHeight: "150%",
          textAlign: "justify",
        }}
      >
        The Principal shall initiate disciplinary proceedings for non-compliance
        of the code of conduct/service rules/leave rules or any other
        rules/regulations of the Institute that may be in force from time to
        time; by any employee and shall have the power to appoint an enquiry
        officer(s) to conduct an inquiry into the charges against such an
        employee.
      </p>
      <p style={{ paddingTop: "5pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        className="s4"
        style={{
          paddingLeft: "10pt",
          textIndent: "20pt",
          lineHeight: "150%",
          textAlign: "justify",
        }}
      >
        It is expected that every employee abides by the rules  regulations
        of the college that are in force from time to time and always acts in
        such a way as to enhance the reputation of the college. Following are
        some good procedures that lay a strong imprint on one’s performance.
      </p>
      <ol id="l4">
        <li data-list-text={1}>
          <h4
            style={{
              paddingTop: "5pt",
              paddingLeft: "22pt",
              textIndent: "-17pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            Apply CL in advance
            <span className="s4">
              : Please apply Casual Leave in advance, adjusting your work load,
              and get it granted. Also, please make a note that though you are
              entitled to avail the CL, but you should not take it for granted
              and should not feel it as a right. Depending on the circumstances,
              your leave may or may not be granted. It is your duty to ensure
              that your leave has been granted and then avail the leave. You are
              advised to apply the leave in advance at the earliest, need not to
              wait till the last minute, by adjusting your work load. In case of
              emergency, you may inform the same to you HOD.
            </span>
          </h4>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <li data-list-text={2}>
          <p
            className="s4"
            style={{
              paddingLeft: "23pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            <b>Do not avail excess CLs</b>: At any point of time, you should not
            avail excess CLs than the actual CLs accumulated by that time, at
            the rate of 1 CL per month. Finally, at the end of the year, the CLs
            should not be more than 12. Of course, it is not that you need to
            utilize all of your 12CLs. Having <i>Loss of Pay </i>(LoP) is a
            serious derogatory remark and it is more likely, you can expect the
            lowest increment in the year.
          </p>
          <p
            style={{
              paddingTop: "5pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            <br />
          </p>
        </li>
        <li data-list-text={3}>
          <h4
            style={{
              paddingLeft: "17pt",
              textIndent: "-17pt",
              textAlign: "left",
            }}
          >
            Should be in the College by 8.50 am:
            <span className="s4">
              Also note that all staff are expected to be in the campus by
            </span>
          </h4>
          <p
            className="s4"
            style={{
              paddingTop: "6pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            9.00 am, failing which a circle will be marked against their names
            indicating their late arrival. You are
          </p>
          <p
            className="s4"
            style={{
              paddingTop: "3pt",
              paddingLeft: "23pt",
              textIndent: "0pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            supposed to sign in the circle only after personally meeting the
            undersigned and giving the explanation for their late arrival. This
            is required to start the first periods by sharp 9.00 am. Also, you
            should be present in the campus till 4.30 pm.
          </p>
        </li>
        <li data-list-text={4}>
          <h4
            style={{
              paddingLeft: "23pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            Be in time to the Class:
            <span className="s4">
              You are required to be present at the class at least 5 min. ahead
              of your period and at the same time, you need to continue your
              class even the period is over till the next faculty arrives on. In
              simple, no class should be seen without a faculty. In any class is
              found without a faculty, both faculty of the preceding and the
              succeeding faculty are made responsible and will be construed as
              non-satisfactory performance of their duties.
            </span>
          </h4>
        </li>
        <li data-list-text={5}>
          <h4
            style={{
              paddingTop: "14pt",
              paddingLeft: "23pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            Be present till the end of function
            <span className="s4">
              : As a committed employee of the organization, you are supposed to
              take very active part in organizing &amp; maintaining discipline
              during any function/ programme and for its smooth conduct; and
              should be present till the end of that function. In fact, that is
              an opportunity to show your belongingness towards the institution.
            </span>
          </h4>
        </li>
        <li data-list-text={6}>
          <h4
            style={{
              paddingTop: "8pt",
              paddingLeft: "23pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            Should be pro-active in additional responsibilities:
            <span className="s4">
              Always be at forefront to take up additional responsibilities,
              organize various events and assist the HOD in various
              administrative and academic tasks.
            </span>
          </h4>
        </li>
        <li data-list-text={7}>
          <h4
            style={{
              paddingTop: "8pt",
              paddingLeft: "23pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            Be a Mentor and Role-Model:
            <span className="s4">
              The prime duty of a teacher is to mould a student as a good
              citizen, in addition to making him/her very strong in the subject.
              You need to regularly monitor/watch and put them in right path.
              Please, do not think, putting them in the right track is the
              responsibility of the Principal or HOD. It is the collective
              responsibility to make our student, a proud citizen of India. You
              should be a Mentor and Role-Model for students through your acts,
              behavior, attitude and commitment.
            </span>
          </h4>
        </li>
        <li data-list-text={8}>
          <h4
            style={{
              paddingTop: "7pt",
              paddingLeft: "23pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            Should attend any outside official duty with permission:
            <span className="s4">
              You should and must get the permission of the undersigned to take
              up any official assignments in any other college, in particular or
              out of our college, in general. Official assignment means any
              task, may be as an External Examiner or Observer or Interview
              board member or guest or in simple, anything that you participate
              by virtue of your identity here or being a faculty in SMCE. For
              such a task, the permission and/or authorization letter from the
              undersigned is a must.
            </span>
          </h4>
        </li>
        <li data-list-text={9}>
          <h4
            style={{
              paddingTop: "8pt",
              paddingLeft: "23pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            Should satisfy the norms:
            <span className="s4">
              You ought to take the following specified minimum no. of classes
              in a semester per each subject as per the norms.
            </span>
          </h4>
          <p
            className="s4"
            style={{
              paddingTop: "4pt",
              paddingLeft: "77pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            5 hr./week : 75 periods/ subject
          </p>
          <p
            className="s4"
            style={{
              paddingTop: "5pt",
              paddingLeft: "77pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            4 hr./week : 60 periods/ subject
          </p>
          <p
            className="s4"
            style={{
              paddingTop: "13pt",
              paddingLeft: "41pt",
              textIndent: "6pt",
              textAlign: "justify",
            }}
          >
            That means, the above are the minimum no. of classes you are
            supposed to take to satisfy the norms and you are expected to take
            some additional remedial classes, over and above these, exclusively
            for poor and average students to improve their performance and the
            result.
          </p>
          <p
            className="s4"
            style={{
              paddingTop: "13pt",
              paddingLeft: "41pt",
              textIndent: "0pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            Not even this, in any work, like coverage of the syllabus;
            maintenance of attendance registers; conduction of lab classes; no.
            of experiments/ programs covered in the respective labs as per the
            syllabus; evaluation of Mid-exam, internal / external lab exams
            marks, etc.,
          </p>
          <p
            style={{
              paddingTop: "7pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            <br />
          </p>
          <h4
            style={{
              paddingLeft: "261pt",
              textIndent: "-187pt",
              lineHeight: "149%",
              textAlign: "left",
            }}
          >
            Everything should be TRANSPARENT and satisfy the norms &amp;
            procedures of the University,
          </h4>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            className="s4"
            style={{
              paddingLeft: "41pt",
              textIndent: "2pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            So that you should not give any scope or chance during the
            inspection to any member of JNTU/ AICTE or any committee to comment
            or blame on us in respect of at least norms-wise- commenting on
            quality aspect being the second step, failing which you are solely
            responsible for that.
          </p>
          <p
            style={{
              paddingTop: "7pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            <br />
          </p>
          <p
            className="s4"
            style={{
              paddingLeft: "41pt",
              textIndent: "2pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            In simple, it is your prime and minimum duty to satisfy the norms
            and over and above, you are expected to do more for the benefit of
            the students.
          </p>
          <p
            style={{
              paddingTop: "13pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            <br />
          </p>
          <p
            className="s4"
            style={{
              paddingLeft: "41pt",
              textIndent: "0pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            Your day-to-day performance is recorded in your confidential report
            by the HOD. At the same time, your sincerity, regularity, active
            involvement in the college programmes, attending department and
            college staff meetings without fail, proper usage of CLs, no. of
            student’s paper presentations guided, no of prizes won for your
            guided papers, no. of papers published, guidance in the project
            works, no. of power point presentations given by you in every
            subject, your positive feedback, pass percentage in your subject,
            guidance and training given to the students for their higher
            studies, etc., all carry a lot of positive weight and are also
            mentioned in the confidential report. All these will have a strong
            impact on your increments, promotions, in-chargeships, benefits etc.
            and ofcourse, as a whole on your career.
          </p>
          <p
            className="s4"
            style={{
              paddingTop: "3pt",
              paddingLeft: "41pt",
              textIndent: "0pt",
              lineHeight: "150%",
              textAlign: "left",
            }}
          >
            Finally, a good <b>CONTRIBUTION </b>and <b>VALUE ADDITION </b>to the
            growth of the college is expected from your end and you should
            become an <b>ASSET </b>to the organization.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            className="s4"
            style={{
              paddingLeft: "12pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Copy to (PRINCIPAL)
          </p>
          <p
            className="s4"
            style={{
              paddingTop: "7pt",
              paddingLeft: "12pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Individuals
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h3
            style={{
              paddingLeft: "52pt",
              textIndent: "0pt",
              textAlign: "center",
            }}
          >
            GENERAL GUIDELINES
          </h3>
          <ul id="l5">
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "15pt",
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Every employee shall at all times maintain absolute integrity
                and devotion to duty and also be strictly honest and impartial
                in his / her official dealings.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                An employee should at all times be courteous in his / her
                dealings with other members of the staff, students and members
                of the public.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-17pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                The scheduled working hours usually will be from 09.00AM to
                04.30PM, 6 days a week. Unless otherwise stated specifically in
                terms of appointment, any employee may be called upon to perform
                such duties as may be assigned to him/her by the Principal or
                his/her higher officer, beyond scheduledworking hours and on
                holidays / Sundays, if the exigencies of work so demand and such
                instructions shall be complied with. These duties
                <i>inter alia </i>shall include attendance at meetings of
                committees to which he/she may be appointed by the Institute.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "13pt",
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                An employee shall be required to observe the scheduled hours of
                work, as may be notified from time to time, during which he/she
                must be present at the place of his/her duty.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Except for valid reasons and/or unforeseen contingencies, no
                employee shall absent himself/herself from duty without prior
                permission of the designated authority.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                No employee shall leave station except with the prior permission
                of the Principal and in his/her absence, the Head of Department.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Whenever leaving the station, an employee shall inform the
                principal and in his/her absence, the Head of the Department to
                which he/she is attached, the address where he/she would be
                available during the period of his/her absence from station.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                All employees are expected to be exemplary in their public as
                well as private life. Their loyalty, senseof dedication and
                integrity should at all times be a model and inspiration to the
                youth and other learners.
              </p>
              <p
                style={{
                  paddingTop: "3pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                All employees are expected to behave according to the ideals of
                national integration showing love, concern, respect to all
                without any discrimination whatsoever of caste, creed or
                community. Any act or speech against this rule will be
                considered as a serious breach of discipline and will invite
                strict disciplinary action including suspension and / or
                termination from service.
              </p>
              <p
                style={{
                  paddingTop: "3pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Every employee shall strive to instill in the students under
                his/her care a high sense of values, social conscientiousness
                and pride in their Institute and loyalty to the country. It is
                the sacred duty of all the employees to work for the
                intellectual, moral, social and physical development of all
                students.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Any association, active or passive by any employee with any
                unlawful organization is strictly forbidden.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Consumption or distribution of alcohol, drugs or any other
                intoxicants including smoking by whatsoever name called, by an
                employee within the Institute premises, is strictly prohibited.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "46pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                All correspondence addressed, to an employee or by him/her or by
                the Institute including press
              </p>
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "47pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                and other such copies of correspondence, all vouchers, books
                including all notebooks containing all notes or records or other
                data and apparatus, samples and/or other goods belonging to the
                Institute, circulars and all other papers and document of any
                nature whatsoever, relating to the Institute‟s affairs which
                shall come into his/her possession in the course of his/her
                employment, shall be the absolute property of the Institute and
                he/she shall, at anytime, during service or termination thereof
                or upon his/her leaving the services of the Institute for any
                reason whatsoever, deliver the same to the Institute on demand
                and without claiming any lieu thereon.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                An employee shall receive all correspondence sent to him/her by
                SRI MITTAPALLI COLLEGE OF ENGINEERING and shall not refuse to
                receive it. Any such correspondence shall be deemed to have been
                served to him in the event of refusal or non-availability. It is
                the responsibility of the employeeto ensure that the home
                address/telephone number etc. are kept up to date in the office
                of the Institute by suitably informing them.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Employees shall only be relieved from their duties upon
                resignation at the end of the semester orupon fulfillment of
                their notice period whichever is later. For this purpose, end of
                semester shall be defined as the time when attendance, marks,
                project evaluations etc have been evaluated and submitted to the
                appropriate office.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                All cases of grievances/ anomalies/ requests/ complaints from
                the employees shall be addressed to the Principal only.
                Bypassing the Principal in these matters shall be treated as
                violation of code of conduct.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                No employee shall organize or participate in any demonstration
                in the premises of the institute / campus which is prejudicial
                to the interests of the institute or public order, decency or
                morality or which involves defamation or contempt of Court. He
                shall also not resort to or in any way instigate, incite or abet
                any form of strike or stoppage of work.
              </p>
            </li>
          </ul>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h3
            style={{
              paddingLeft: "52pt",
              textIndent: "0pt",
              textAlign: "center",
            }}
          >
            GUIDELINES FOR FACULTY MEMBERS
          </h3>
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "29pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            No Faculty Member (Teacher) shall,
          </p>
          <ul id="l6">
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "46pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Knowingly or willfully due neglect his/her duties.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "146%",
                  textAlign: "left",
                }}
              >
                Propagate through his/her teaching lesson otherwise, a communal
                or sectarian outlook or incite orallow any student to indulge in
                communal or sectarian activities.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "147%",
                  textAlign: "left",
                }}
              >
                Discriminate between students or any other persons, on grounds
                of caste, creed, sex, language, placeof origin, social and
                cultural background etc.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "147%",
                  textAlign: "left",
                }}
              >
                Indulge in or encourage any form of malpractices connected with
                the examination or any otheractivity of the Institute.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "46pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Show does not negligence in correcting internal assessments or
                exam work of the students.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "11pt",
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "149%",
                  textAlign: "justify",
                }}
              >
                While being present at the Institute, absent himself/herself,
                except with the prior permission of the Principal, from class
                which he/she is required to take, provided that where such
                absence without leaveor without the prior permission of the
                Principal is due to reasons beyond the control of teacher,
                itshall not be deemed to be a breach of discipline and/or the
                code of conduct, if on return to duty, the teacher has applied
                for and obtained, ex-post-facto; the necessary sanction for the
                leave availed.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "146%",
                  textAlign: "justify",
                }}
              >
                Accept or give private tuition to any student of the Institute
                or any other person without the written permission of the
                Principal.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "146%",
                  textAlign: "justify",
                }}
              >
                Prepare or publish any book(s) commonly known as guides or
                assist, in their publication other than under authority of the
                Institute, obtained through the Chairman of “GC”.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "146%",
                  textAlign: "justify",
                }}
              >
                Enter into any monetary transactions with any student or parent;
                nor shall he/she conduct his/her personal matters in such a
                manner that he/she has to incur a debt beyond his/her means to
                repay.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "149%",
                  textAlign: "justify",
                }}
              >
                Accept or permit any member of his/her family or any other
                person acting on his/her behalf to accept any gift or receive
                undue advantage from any student, parent or any person(s) with
                whom he/she has come in contact by virtue of his/her being in
                the employment of the Institute (Note: A casual meal, lift or
                other social hospitality of a casual nature shall not be
                considered as a gift. On occasions such asweddings,
                anniversaries, funerals or religious functions when the offering
                of a gift is in conformity with the prevailing religious or
                social customs, a teacher may accept a gift from
                students/parents).
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "149%",
                  textAlign: "justify",
                }}
              >
                Hesitate to provide individual attentions to a student(s), if
                required. Besides academic problems, teachers are encouraged to
                solve personal and behavioral problems of student(s) as well. If
                in any event, student(s) does not show any improvement despite
                counseling and if the teacher is convinced that the student(s)
                needs special help, he/she must immediately bring this fact to
                the notice of the Head of Department and the Principal.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "46pt",
                  textIndent: "-17pt",
                  textAlign: "justify",
                }}
              >
                Not be unreasonable in his/her demands from student(s) and
                should avoid favoritism of any
              </p>
            </li>
          </ul>
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "47pt",
              textIndent: "0pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            kind. Whilst the teacher may be firm and consistent with his/her
            student(s), every teacher must remember that student(s) have their
            self-respect and they must not be subjected to any threat, ridicule,
            sarcasmor unreasonable punishment.
          </p>
          <ol id="l7">
            <li data-list-text="a.">
              <h3
                style={{
                  paddingTop: "13pt",
                  paddingLeft: "36pt",
                  textIndent: "-13pt",
                  textAlign: "left",
                }}
              >
                Every Employee Shall:
              </h3>
              <ul id="l8">
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "15pt",
                      paddingLeft: "47pt",
                      textIndent: "-18pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    Be punctual in attendance and in respect of his/her
                    specified works and also for any other work connected with
                    the duties assigned to him/her by the Principal/Head of
                    Department.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-18pt",
                      lineHeight: "151%",
                      textAlign: "justify",
                    }}
                  >
                    Abide by the rules and regulations of the institute and also
                    show due respect to his/her higher authority.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-18pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    On every working day, sign the staff attendance register or
                    punch his card at the time of arrival and atthe time of
                    leaving the Institute, located at the gate or elsewhere as
                    the case may be and be ready at least, five minutes before
                    the commencement of classes. Overwriting on the attendance
                    register or marking attendance wrongfully either the
                    previous day subsequently, impersonation in attendance or
                    not punching the card is a serious violation of the rules.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-18pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    Be responsible for the discipline of students even outside
                    the classrooms. Since supervision during the break or off
                    time is vital for the protection of the property of the
                    Institute and its students, employees may, from time to
                    time, be allotted such duties to maintain discipline and
                    orderliness atthe Institute.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-18pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    Be responsible for the proper care, operation and
                    maintenance of all equipment and machinery, learning aids,
                    furniture and fixtures and sports equipment etc. under their
                    care. The appropriate authority should immediately be
                    informed of any damage caused or any repairs / replacements
                    that may be required.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-18pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    Train students to make extensive use of the Library
                    facilities and to take care of the property of the institute
                    including books, journals, equipment, machinery, furniture,
                    fixtures and fittings.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "3pt",
                      paddingLeft: "47pt",
                      textIndent: "-18pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    Ensure that no student(s) indulge in ragging, canvassing
                    and/or cause harassment of any kind to other student(s) by
                    whatsoever name called, as it is strictly prohibited under
                    law.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-18pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    Not, through any act of omission, disturb or vitiate, in any
                    manner whatsoever, the peace, dignity and harmony of the
                    Institute.
                  </p>
                  <p
                    style={{
                      paddingTop: "7pt",
                      textIndent: "0pt",
                      textAlign: "left",
                    }}
                  >
                    <br />
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="b.">
              <h3
                style={{
                  paddingLeft: "37pt",
                  textIndent: "-14pt",
                  textAlign: "left",
                }}
              >
                Taking part in Politics and Elections
              </h3>
              <ul id="l9">
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "13pt",
                      paddingLeft: "47pt",
                      textIndent: "-17pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    No employee shall take part in politics or be associated
                    with any party or organization which takes part in political
                    activity, nor shall subscribe and aid or assist in any
                    manner any political
                  </p>
                  <p
                    style={{
                      paddingTop: "3pt",
                      paddingLeft: "47pt",
                      textIndent: "0pt",
                      textAlign: "justify",
                    }}
                  >
                    movement oractivity.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "47pt",
                      textIndent: "-17pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    No employee shall canvass or otherwise interfere or use his
                    influence in connection with or take part in any election to
                    a legislative body or local authority.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-17pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    Provided that an employee of the institute is qualified to
                    vote at such elections, may exercise his right to vote but
                    where he does so, he shall give no indication of the manner
                    in which he proposes to voteor has voted.
                  </p>
                </li>
              </ul>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="c.">
              <h3
                style={{
                  paddingLeft: "36pt",
                  textIndent: "-13pt",
                  textAlign: "left",
                }}
              >
                Connection with Media (television, press or radio)
              </h3>
              <ul id="l10">
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "15pt",
                      paddingLeft: "47pt",
                      textIndent: "-17pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    No employee shall, except with the prior sanction of the
                    Principal, own wholly or in part or conduct or participate
                    in the editing or managing of any news paper or other
                    periodical publications.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-17pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    No employee shall, except with the prior sanction of the
                    Principal or any other authority empowered by him/her,
                    participate in a radio broadcast or contribute any article
                    or write any letter either anonymously or in his/her own
                    name or in the name of any other person to any newspaper or
                    periodical.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-17pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    Provided that no such sanction shall be required if such
                    broadcast or such contribution is, as a purely literary,
                    artistic or scientific nature.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-17pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    NOTE: Members of the staff are at liberty however, without
                    any sanction, to publish their original technical /
                    scientific works in journals of repute in India and abroad.
                    If, however, they wish to indicate their official
                    designations in the article they want to publish, prior
                    sanction of the Principal will be necessary. Such articles
                    must be strictly confined to purely technical/ scientific
                    and should nottouch upon administrative matters related to
                    the Institute. They shall be free from all political
                    leanings. Publications of articles relating to India‟s
                    boundary areas and the tribal population in such areas is
                    prohibited without prior permission of the Principal and if
                    necessary, of the Government of India. If during the term of
                    his/her employment, an employee shall make any inventions or
                    improvements relating to the manufacture of any materials or
                    product, all rights in respect of such invention or
                    improvement shall belong to the Institute and he/she will,
                    if required, at the cost of the Institute cause such
                    inventions or improvement to be patented in such a country
                    as the Institute may desire.
                  </p>
                </li>
              </ul>
              <p
                style={{
                  paddingTop: "2pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
            </li>
            <li data-list-text="d.">
              <h3
                style={{
                  paddingLeft: "37pt",
                  textIndent: "-14pt",
                  textAlign: "left",
                }}
              >
                Criticism of the Institute
              </h3>
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "47pt",
                  textIndent: "0pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                No employee shall, in any radio/TV broadcast or in any document
                published anonymously or in his/her own name or in the name of
                any person or in any communication to the press or in any public
                utterance, make any statement of fact or opinion: which has the
                effect of an adverse criticism of any current or recent policy
                or action of the Institute; or which is capable of embarrassing
                the relations between the Institute and the Central Government
                or any State Government including any of their agencies or any
                other Institute or Organization or members of the public.
              </p>
            </li>
            <li data-list-text="e.">
              <h3
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "36pt",
                  textIndent: "-13pt",
                  textAlign: "justify",
                }}
              >
                Evidence before Committee or any Other Authority
              </h3>
              <ul id="l11">
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "13pt",
                      paddingLeft: "47pt",
                      textIndent: "-13pt",
                      lineHeight: "149%",
                      textAlign: "left",
                    }}
                  >
                    No employee shall, except with the prior sanction of the
                    Principal, give evidence in connection with any inquiry
                    conducted by any person, committee or authority. Where any
                    sanction has been accorded,no employee giving such evidence
                    shall criticize the policy or any action of the Institute or
                    the Central Government or any State Government or their
                    respective agencies. Nothing in this paragraph shall apply
                    to:
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-18pt",
                      lineHeight: "146%",
                      textAlign: "left",
                    }}
                  >
                    Evidence given at any inquiry before any authority appointed
                    by the Institute, by Parliament or by aState Legislature
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "46pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                    }}
                  >
                    Evidence given in any judicial enquiry
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "46pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                    }}
                  >
                    Evidence given in any departmental enquiry ordered by the
                    Principal of the Institute.
                  </p>
                </li>
              </ul>
              <p
                style={{
                  paddingTop: "7pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
            </li>
            <li data-list-text="f.">
              <h3
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-11pt",
                  textAlign: "left",
                }}
              >
                Unauthorized Communication of Information
              </h3>
              <ul id="l12">
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "15pt",
                      paddingLeft: "47pt",
                      textIndent: "-17pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    No employee shall, except in accordance with any general or
                    special order of the Principal or in the performance in good
                    faith of the duties assigned to him/her, communicate,
                    directly or indirectly, any official document or information
                    to any person to whom he/she is not authorized to
                    communicate such document or information.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-17pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    During the term of his/her employment with the Institute or
                    at anytime thereafter, an employee shall not use or disclose
                    to any other society, firm, organization or person any
                    information concerning the secrets, activities or affairs of
                    the Institute, nor countenance any act prejudicial to the
                    interest of the Institute.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-17pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    By virtue of his/her assignment with the Institute, an
                    employee will acquire technical know-how and gain access to
                    secrets relating to the Institute‟s activities. It is
                    therefore, distinctly understood that in the event of
                    his/her relinquishing his/her employment with the Institute,
                    he/she will under no circumstances, divulge the technical
                    know-how and secrets of the Institute
                  </p>
                </li>
              </ul>
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "47pt",
                  textIndent: "0pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                or use these in any mannerdetrimental to the Institute. In the
                event of an employee violating this condition, the Institute
                will be entitled to claim damages at his/her cost and take any
                action, as it may deem fit against him/her.
              </p>
            </li>
            <li data-list-text="g.">
              <h3
                style={{
                  paddingTop: "10pt",
                  paddingLeft: "36pt",
                  textIndent: "-13pt",
                  textAlign: "justify",
                }}
              >
                Gift &amp; Private Trades
              </h3>
              <p
                style={{
                  paddingTop: "9pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
              <ul id="l13">
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-18pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    No employee shall, except with the prior sanction of the
                    Principal, accept or permit his wife or any other member of
                    his family to accept from any person any gift of more than
                    trifling value. The interpretation of term „trifling value‟
                    shall be the same as laid down in the Government Servants
                    Conduct Rules.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-18pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    No employee shall, except with the prior permission of the
                    Chairman of “GC”, engage directly or indirectly, in any
                    trade or business or any private tuition or undertake any
                    employment outside his official assignments.
                  </p>
                </li>
              </ul>
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "0pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Provided that the above restriction shall not apply to academic
                work and consultative practice undertaken with the prior
                permission of the Principal
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="h.">
              <h3
                style={{
                  paddingLeft: "37pt",
                  textIndent: "-14pt",
                  textAlign: "justify",
                }}
              >
                Investments, Lending &amp; Borrowing
              </h3>
              <ul id="l14">
                <li data-list-text="">
                  <p
                    style={{
                      paddingTop: "15pt",
                      paddingLeft: "47pt",
                      textIndent: "-17pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    No employee shall speculate in any business nor shall he/she
                    make or permit his wife or any member of his/her family to
                    make any investment likely to embarrass or influence him/her
                    in the discharge of his/her official duties.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-17pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    No employee shall lend money at interest to any person nor
                    shall he/she borrow money from any person with whom he/she
                    is likely to have official dealings of any kind whatsoever.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "34pt",
                      textIndent: "-5pt",
                      textAlign: "left",
                    }}
                  ></p>
                </li>
              </ul>
            </li>
            <li data-list-text="i.">
              <h3
                style={{
                  paddingTop: "10pt",
                  paddingLeft: "33pt",
                  textIndent: "-10pt",
                  textAlign: "justify",
                }}
              >
                Insolvency, Habitual Indebtedness and Criminal Proceedings
              </h3>
              <p
                style={{
                  paddingTop: "2pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
              <ul id="l15">
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-18pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    An employee shall so manage his/her private affairs as to
                    avoid habitual indebtedness or insolvency. When an employee
                    is found liable to be arrested for debt or has recourse to
                    insolvency or when it is found that a moiety of his/her
                    salary is becomes the subject of legal proceedings for
                    insolvency shall forthwith report full facts to the
                    Institute.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-18pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    An employee who gets involved in some criminal proceedings
                    shall immediately inform the Principal through the Head of
                    Department to which he/she is attached, irrespective of the
                    fact whether he/she has been released on bail or not.
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    style={{
                      paddingLeft: "47pt",
                      textIndent: "-18pt",
                      lineHeight: "14pt",
                      textAlign: "justify",
                    }}
                  >
                    An employee who is detained in police custody whether on
                    criminal grounds or otherwise, for a
                  </p>
                  <p
                    style={{
                      paddingTop: "3pt",
                      paddingLeft: "47pt",
                      textIndent: "0pt",
                      lineHeight: "150%",
                      textAlign: "justify",
                    }}
                  >
                    period longer than 8 hours shall not join his/her duties at
                    the Institute unless he/she has obtained written permission
                    to that effect the Principal of the Institute.
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="j.">
              <h3
                style={{
                  paddingLeft: "34pt",
                  textIndent: "-11pt",
                  lineHeight: "16pt",
                  textAlign: "justify",
                }}
              >
                Vindication of Acts and Character of Employees
              </h3>
              <p
                style={{
                  paddingTop: "13pt",
                  paddingLeft: "47pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                No employee shall, except with the prior sanction of the
                Principal, have recourse to any court of law or to the press for
                the vindication of any official act which has been the subject
                matter of adverse criticism or an attack of defamatory
                character.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                Provided nothing in this rule shall be deemed to prohibit an
                employee, from vindicating his private character or any act done
                by him/her in his/her private capacity.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="k.">
              <h3
                style={{
                  paddingLeft: "37pt",
                  textIndent: "-14pt",
                  lineHeight: "16pt",
                  textAlign: "justify",
                }}
              >
                Representation
              </h3>
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                Whenever an employee wishes to put forth any claim or seeks
                redress of any grievance or any wrong done to him/her, he/she
                must forward his/her case through proper channel and shall not
                forward such advance copies of his/her application to any higher
                authority, unless the lower authority has rejected the claim or
                refused relief.
              </p>
            </li>
            <li data-list-text="l.">
              <h3
                style={{
                  paddingTop: "13pt",
                  paddingLeft: "33pt",
                  textIndent: "-10pt",
                  textAlign: "left",
                }}
              >
                Jurisdiction
              </h3>
              <p
                style={{
                  paddingTop: "13pt",
                  paddingLeft: "47pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                Dispute(s) if any, arising out of these Service Rules or any
                other rules that may be in force from time to time or the terms
                of employment of any employee shall be subjected to the
                jurisdiction of District Courts only.
              </p>
              <p
                style={{
                  paddingTop: "1pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
            </li>
            <li data-list-text="m.">
              <h3
                style={{
                  paddingLeft: "41pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Identification
              </h3>
              <p
                style={{
                  paddingTop: "13pt",
                  paddingLeft: "47pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                It shall be mandatory for every employee of the Institute to
                carry with him/wear, at all times, when at the Institute and to
                produce the same on demand, an Identity Card/Badge or any other
                mark of identification, as may be prescribed by the “GC” from
                time to time. An employee shall on resigning and/or termination
                from the services of the Institute, immediately hand over such
                identity card and/or mark of identification to the Principal of
                the Institute or any other official designated for the purpose,
                failing which, full and final settlement of his/her account
                shall be withheld.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                The use of such identity card and/or mark of identification
                shall be governed by the terms and conditions of the Institute
                from time to time.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
            <li data-list-text="n.">
              <h3
                style={{
                  paddingLeft: "37pt",
                  textIndent: "-14pt",
                  textAlign: "left",
                }}
              >
                Disciplinary Actions / Suspension
              </h3>
            </li>
          </ol>
        </li>
      </ol>
      <p style={{ paddingTop: "3pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <ul id="l16">
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "147%",
              textAlign: "left",
            }}
          >
            The Principal in consultation with the GC may place a member of the
            staff appointed at the Institute under suspension:
          </p>
          <ol id="l17">
            <li data-list-text="a.">
              <p
                style={{
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Where a disciplinary proceeding against him is contemplated or
                is pending or
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "justify",
                }}
              >
                Where a case against him in respect of any criminal offence is
                under investigation or trial.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingTop: "7pt",
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "148%",
              textAlign: "justify",
            }}
          >
            An employee against whom disciplinary action is proposed or likely
            to be taken shall be given a charge sheet clearly setting forth the
            circumstances appearing against him/her and a date shall be fixed
            for the inquiry.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "149%",
              textAlign: "justify",
            }}
          >
            Sufficient time shall be given to him/her to prepare and give
            his/her explanation, as also to produce any evidence that he/she may
            wish to tender in his/her defense. He/she shall be permitted to
            appear before the officer conducting the inquiry, to cross-examine
            any witness on whose evidence the chargerests. The employee will be
            permitted to produce evidence/witness in his/her defense.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "149%",
              textAlign: "justify",
            }}
          >
            At the conclusion of enquiry, the inquiry officer(s) who shall be
            appointed by the Principal in consultation with the Chairman of “GC”
            shall prepare a report of the inquiry regarding the findings of each
            charge.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "148%",
              textAlign: "justify",
            }}
          >
            The Principal shall consider the findings of the inquiry and propose
            to the Chairman of “GC” to impose a major penalty, a minor penalty
            or no-penalty at all. The action taken by the Principal shall
            necessarily need to be ratified by the Chairman of “GC”.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "146%",
              textAlign: "justify",
            }}
          >
            The following penalties may, for good and sufficient reasons, be
            imposed on any member of the staff:
          </p>
          <ol id="l18">
            <li data-list-text="a.">
              <p
                style={{
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "justify",
                }}
              >
                Written warning / Fine
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "justify",
                }}
              >
                Censure
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Withholding of increments or promotion
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "65pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "left",
                }}
              >
                Recovery from the whole or part of any pecuniary loss caused to
                the Institute by negligence orbreach of orders
              </p>
            </li>
            <li data-list-text="e.">
              <p
                style={{
                  paddingLeft: "65pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "left",
                }}
              >
                Reduction to lower service, grade or post or to a lower
                pay-scale or to a lower stage in the pay-scale
              </p>
            </li>
            <li data-list-text="f.">
              <p
                style={{
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Compulsory retirement
              </p>
            </li>
            <li data-list-text="g.">
              <p
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Removal / Dismissal from service
              </p>
            </li>
          </ol>
        </li>
      </ul>
      <p
        style={{
          paddingTop: "6pt",
          paddingLeft: "41pt",
          textIndent: "0pt",
          lineHeight: "150%",
          textAlign: "left",
        }}
      >
        Notwithstanding the above provisions, it shall not be necessary to
        follow the procedure mentioned above in the following cases:
      </p>
      <ul id="l19">
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "151%",
              textAlign: "left",
            }}
          >
            Where an employee is dismissed or removed or reduced in rank on the
            ground of conduct which has led to his conviction on criminal
            charges(s)
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "left",
            }}
          >
            Where the “GC” is satisfied that, for some reason to be recorded by
            it in writing, it is not reasonably practicable to give that person
            an opportunity of showing cause.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            Pending the outcome of an inquiry, the employee may be suspended and
            he/she may receive a subsistence allowance, as approved by the “GC”,
            from case to case, but shall not be paid salary when under
            suspension.
          </p>
        </li>
      </ul>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h3
        style={{
          paddingLeft: "52pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        GENERAL SCHOLARSHIP POLICY
      </h3>
      <p
        style={{
          paddingTop: "10pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "150%",
          textAlign: "justify",
        }}
      >
        The Fee Reimbursement Scheme (also known as the Post-matric Scholarship
        Scheme) is the student{" "}
        <span style={{ color: "#1f2023" }}>education </span>
        <a
          href="https://en.wikipedia.org/wiki/Aadhaar"
          className="a"
          target="_blank"
        >
          sponsorship programme of the Government of Andhra Pradesh. It supports
          students from lower economic strata in the state. The government made
          the possession of an Aadhaar{" "}
        </a>
        card a mandatory requirement for fee reimbursement procedures for the
        beneficiaries.
        <span className="s9">Any student whose parents annual family</span>
      </p>
      <p
        className="s9"
        style={{
          paddingTop: "3pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "151%",
          textAlign: "justify",
        }}
      >
        income is less than Rs. 2.5 lakh are eligible for
        <span className="p">Fee Reimbursement </span>under Jagananna Vidya
        Deevena Scheme. Those with 10 acres of wetland and 25 acres of dry land
        are also eligible for the benefit.
      </p>
      <p style={{ paddingTop: "5pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        className="s10"
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "150%",
          textAlign: "justify",
        }}
      >
        AP Scholarships
        <span className="s11">
          – The Government of Andhra Pradesh implements a number of{" "}
        </span>
        AP scholarships
        <span className="s11">
          for the students who are domiciled in the state. These scholarships
          generally include the reimbursement of tuition fees, maintenance
          charges and other significant allowances. The key objective behind
          these scholarships is to financially support the students belonging
          SC, ST, BC, EBC, Minority and Disabled welfare community to bring them
          to the mainstream.
        </span>
      </p>
      <h3
        style={{
          paddingTop: "3pt",
          paddingLeft: "52pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        INSTITUTION MERIT SCHOLARSHIP POLICY
      </h3>
      <p
        style={{
          paddingTop: "15pt",
          paddingLeft: "26pt",
          textIndent: "6pt",
          lineHeight: "150%",
          textAlign: "justify",
        }}
      >
        Sri Mittapalli College of Engineering is one of the reputed engineering
        college in Guntur district. The institution gives merit scholarships for
        the students who secured highest marks in every branch as a part of
        motivation and to create a healthy competitive environment among the
        students. The students who participated and won prizes in various
        contests and events conducted by other colleges will be felicitated with
        cash prizes. The institution is student centric organization and
        regularly organizes Quiz Programmes, Paper Presentations, Workshops and
        extra-curricular activities to encourage the students and gain more
        knowledge.
      </p>
      <h3
        style={{
          paddingTop: "3pt",
          paddingLeft: "42pt",
          textIndent: "-19pt",
          textAlign: "left",
        }}
      >
        GENERAL PROCEDURE OF RECRUITMENT (APPOINTMENT RULES) OR RECRUITMENT
        POLICY
      </h3>
      <ul id="l20">
        <li data-list-text="">
          <p
            style={{
              paddingTop: "16pt",
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            All posts at the Institute shall normally and, as far as possible,
            be filled by advertisement; but, the “GC” shall be the exclusive
            power to decide, either on its own or on the recommendations of the
            Principal, that a particular post be filled by invitation or by
            promotion from amongst the members of the staff of the Institute.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            The selection of candidates shall be made by the appropriate
            Selection Committee. The selected candidates may be offered
            employment by way of a letter of offer of appointment.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingTop: "4pt",
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            The appointment letter will be issued after the acceptance by the
            candidate for the offer of appointment.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            The manpower requirement shall be ascertained on the basis of the
            norms prescribed by AICTE/UGC or the appropriate authority from time
            to time. Additional posts may also be created, as required, for the
            extension of specific projects and or research and development
            activities.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "151%",
              textAlign: "justify",
            }}
          >
            Candidates selected for employment shall furnish the Joining Report
            in the prescribed format. He shall also furnish evidence of Date of
            Birth / Proof of Age by any one of the following:
          </p>
          <ol id="l21">
            <li data-list-text="a.">
              <p
                style={{
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  lineHeight: "14pt",
                  textAlign: "justify",
                }}
              >
                Certified extract from Register of Births and Deaths.
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "justify",
                }}
              >
                School Leaving Certificate / Matriculation Certificate.
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "justify",
                }}
              >
                Any other document acceptable to the Management.
              </p>
              <p
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "47pt",
                  textIndent: "0pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                The age of the employee verified with reference to any of the
                above shall be the sole evidence of the age of the employee for
                all purposes concerning his employment including retirement. The
                date of birth once furnished and accepted by the management and
                entered in the Service Register shall befirm and conclusive and
                under no circumstances the request for correction of the same
                will be entertained.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            The Candidates selected for appointment shall also submit the
            photo-copies of the following Certificates along with the originals
            for verification at the time of joining duty:
          </p>
          <ol id="l22">
            <li data-list-text={1}>
              <p
                style={{
                  paddingLeft: "82pt",
                  textIndent: "-17pt",
                  textAlign: "justify",
                }}
              >
                Qualification
              </p>
            </li>
            <li data-list-text={2}>
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "82pt",
                  textIndent: "-17pt",
                  textAlign: "justify",
                }}
              >
                Experience
              </p>
            </li>
            <li data-list-text={3}>
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "82pt",
                  textIndent: "-17pt",
                  textAlign: "justify",
                }}
              >
                Relieving letter from previous Employer, if employed earlier.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingTop: "7pt",
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            It shall be incumbent upon every employee to furnish correct and
            complete bio-data to the AppointingAuthority in the required format.
            He shall promptly notify in writing any subsequent changes in the
            particulars of his bio-data. Any false information in the
            particulars furnished in
          </p>
        </li>
      </ul>
      <p
        style={{
          paddingTop: "3pt",
          paddingLeft: "47pt",
          textIndent: "0pt",
          lineHeight: "150%",
          textAlign: "left",
        }}
      >
        the bio-data either at the time of appointment or subsequently shall
        render the appointment null and void.
      </p>
      <h3 style={{ paddingLeft: "23pt", textIndent: "0pt", textAlign: "left" }}>
        Probationary Period:
      </h3>
      <ul id="l23">
        <li data-list-text="">
          <p
            style={{
              paddingTop: "15pt",
              paddingLeft: "46pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            A person selected as a Teaching staff will be on probation for
            period of one year.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingTop: "7pt",
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "146%",
              textAlign: "left",
            }}
          >
            During probation the employee should carry out all the academic work
            and other work assigned tohim satisfactorily (Annexure-I, II &amp;
            III).
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "146%",
              textAlign: "left",
            }}
          >
            On satisfactory completion of probationary period, the services of
            the employee will be made permanent.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "146%",
              textAlign: "left",
            }}
          >
            If the service is not satisfactory during the probationary period,
            the probationary period may beextended by another 6 months to One
            year.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "46pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            The services may be terminated with one month notice.
          </p>
          <h3
            style={{
              paddingTop: "3pt",
              paddingLeft: "52pt",
              textIndent: "0pt",
              textAlign: "center",
            }}
          >
            EMPLOYEE PERFORMANCE APPRAISAL POLICIES
          </h3>
          <p
            style={{
              paddingTop: "15pt",
              paddingLeft: "47pt",
              textIndent: "0pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            Appraisal of the performance of every employee unless otherwise
            specified will be carried out at the end of the academic year. This
            will normally be done by next senior person in hierarchy
            (HODs/Section Heads) and reviewed by the Head of the institution.
            This report will be the basis for deciding annual increment, special
            increments, promotion or disciplinary action.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h3
            style={{
              paddingLeft: "23pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Increments:
          </h3>
          <ul id="l24">
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "15pt",
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "left",
                }}
              >
                An employee will be entitled to annual increment as per the
                pay-scale during the month of September, after completing 12
                months of reckonable service including the probationary period,
              </p>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "47pt",
                  textIndent: "0pt",
                  lineHeight: "150%",
                  textAlign: "left",
                }}
              >
                provided that his performance and conduct are reported to be
                satisfactory as per Appraisal / confidential Report.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Where an employee is appointed on a consolidated pay and not on
                a scale of pay, ad-hoc lump sum increment may be granted at the
                end of every year at the sole discretion of the management,
                provided his performance and conduct are found satisfactory.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Special increments may be granted in exceptional cases of
                outstanding performance during the service, by the Management.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                The annual increment may be withheld as a disciplinary measure
                by the management. The period for which the increment should be
                withheld will be decided by the institutional authority.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Withholding of the increment for a particular period may be with
                or without cumulative effect. In case of cumulative effect,
                employee will not be entitled to get the increment so withheld
                in future years. In the case, the increment is withheld for a
                particular period without cumulative effect, the employee
                concerned will be granted increment immediately after completion
                of the particular period.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                When an employee working in the lower cadre and scale of pay is
                promoted or appointed to a higher cadre and scale of pay, his
                increment will fall due after he completes one year of service
                in the higher scale of pay.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                The increment due to an employee will be paid to him even if he
                is on leave on the due date, except inthe case of leave on loss
                of pay.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Before granting the Increment / Promotion, a report as in
                appraisal for Teaching faculty and Non-teaching staff should be
                taken.
              </p>
            </li>
          </ul>
          <h3
            style={{
              paddingTop: "3pt",
              paddingLeft: "23pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Promotion:
          </h3>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "47pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            Promotions will be regulated as per vacancy position. However no
            promotion can be claimed as a matter of right. The management is
            under no obligation to promote any one from one post to another even
            when an employee acquires the minimum qualification/ experience
            required for the higher post and vacancy exists.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingTop: "13pt",
              paddingLeft: "47pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            An employee who is under suspension or against whom disciplinary
            proceedings are in progress or likely to be initiated, shall not be
            promoted until he is unconditionally reinstated or exonerated.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h3
            style={{
              paddingLeft: "23pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Transfers:
          </h3>
          <p
            style={{
              paddingTop: "15pt",
              paddingLeft: "47pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            All non-teaching and administrative employees are liable to be
            transferred from on department to another department of the
            institute at the discretion of the management. The wages, grade,
            continuation of service and other conditions of service of the
            employee are not adversely affected by such transfers.
          </p>
          <h3
            style={{
              paddingLeft: "23pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Retirement:
          </h3>
          <ul id="l25">
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "15pt",
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Every member of the teaching and non-teaching staff will retire
                from service on completing 65 years and 60 years of age
                respectively. The management may re-employ a retired member who
                is medicallyfit and whose services are considered necessary and
                beneficial to the institution, on
              </p>
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "0pt",
                  textAlign: "justify",
                }}
              >
                „fixed term contract‟.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                In respect of non teaching staff attaining the age of retirement
                on a day other than the first day of a month, he shall retire on
                the last day of that month. However the teaching faculty shall
                retire on the last working day of the semester.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                In all normal cases, a retiring employee will be permitted to
                avail the earned leave at his credit before the date of his
                retirement. In case where the date of retirement of an employee
                and the day/ days preceding thereto are general holidays, the
                employee may be permitted to hand over charge at the
              </p>
            </li>
          </ul>
          <p
            style={{
              paddingTop: "4pt",
              paddingLeft: "47pt",
              textIndent: "0pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            close of working hours of the last working day before the date of
            such retirement and may be allowedduty pay for the holidays(s).
          </p>
          <h3
            style={{
              paddingLeft: "23pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            Resignation / Termination of Service:
          </h3>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingTop: "15pt",
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "148%",
              textAlign: "justify",
            }}
          >
            An employee desiring to resign must give notice of resignation in
            writing to the head of the institutionthrough proper channel.
            However, members of the teaching staff shall not ordinarily resign
            from theirpost during the middle of a semester.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "46pt",
              textIndent: "-17pt",
              textAlign: "justify",
            }}
          >
            An employee shall give one month (30 days) notice or salary in lieu
            of notice for resigning from
          </p>
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "47pt",
              textIndent: "0pt",
              textAlign: "justify",
            }}
          >
            the service.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingTop: "6pt",
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "148%",
              textAlign: "justify",
            }}
          >
            Till the resignation is submitted by an employee and is accepted by
            the management and relieving certificate / order is issued, he shall
            continue to be in service, unless any other instruction are given
            inwriting by the management.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "46pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            Resignation once submitted by an employee and accepted by the
            management cannot be withdrawn.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingTop: "7pt",
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "146%",
              textAlign: "justify",
            }}
          >
            The shortfall in resignation notice given by a permanent employee
            may be adjusted towards his balance of earned leave at his credit.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "147%",
              textAlign: "justify",
            }}
          >
            The management may at its discretion relieve an employee at any time
            on receipt of notice and beforeexpiry of the period for which the
            notice is given, waiving the balance notice period.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "149%",
              textAlign: "justify",
            }}
          >
            Upon the acceptance of resignation through communication by the
            Management to the employee concerned, he shall settle all his dues
            to institution, hand over documents, cash, equipments and other
            properties held in his custody and surrender / vacate the hostel /
            quarters occupied by him and submit a „No Dues Certificate” to that
            effect. Relieving order will be issued only then. The Management
            reserves the right to recover all such outstanding amounts and value
            of the property of the institute from amount due to the employee or
            in any other manner as the management deems fit.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "149%",
              textAlign: "justify",
            }}
          >
            If an employee remains on unauthorized absence without leave or
            prior permission in writing continuously for 30 days, the management
            may give him a notice at his last known address to report for duty
            within 30 days from the date of receipt of the notice and to give
            satisfactory explanation for his absence. In case he fails to report
            for duty without valid explanations, he shall be treated as
            havingvoluntarily abandoned service. This is without prejudice to
            the right of the management to take appropriate disciplinary action
            against the employee concerned for such absence.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "149%",
              textAlign: "justify",
            }}
          >
            The “GC” shall have the power to terminate the services of any
            employee by giving appropriate notice or on payment of salary for
            the notice period in lieu thereof. However, in the event of any act
            prejudicial to the interest of the Institute by any employee, his/
            her services may be terminated by the Chairman of “GC” without any
            notice or payment in lieu of notice.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "148%",
              textAlign: "justify",
            }}
          >
            Service of an ad-hoc, temporary, contractual or part time employee
            shall come to an end on completion of the specified work for which
            he/she was employed or on the expiry of the period for which he/she
            was appointed, whichever is earlier.
          </p>
        </li>
      </ul>
      <p
        style={{
          paddingTop: "3pt",
          paddingLeft: "47pt",
          textIndent: "3pt",
          lineHeight: "150%",
          textAlign: "justify",
        }}
      >
        But, before the expiry of the said period or work, asthe case may be,
        services of the employee could be terminated by the Chairman of “GC” by
        giving one month‟s notice or one month‟s salary in lieu of notice,
        without assigning any reason whatsoever.
      </p>
      <h3
        style={{
          paddingTop: "3pt",
          paddingLeft: "43pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        COMPENSATION POLICY or SALARIES, ALLOWANCES AND BENEFITS
      </h3>
      <ul id="l26">
        <li data-list-text="">
          <p
            style={{
              paddingTop: "16pt",
              paddingLeft: "50pt",
              textIndent: "-21pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            AICTE guidelines shall be followed for pay fixation in general. At
            present, the Designation, Pay- Band, Academic Grade Pay and Annual
            Increments are fixed in line with 6<span className="s12">th</span>{" "}
            Pay Commission Recommendations, for the teaching staff. Non-teaching
            staff are paid in line with AP State Pay-scale.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "50pt",
              textIndent: "-21pt",
              textAlign: "justify",
            }}
          >
            The management reserves the right to offer
            <b>special pay/increments </b>to deserving employees.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingTop: "10pt",
              paddingLeft: "50pt",
              textIndent: "-21pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            The <b>allowances </b>shall be decided by the management from time
            to time, inline with AP State Government Rules. It includes Dearness
            Allowance, House Rent Allowance &amp; Travel Allowance, as
            applicable.
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s5"
            style={{
              paddingLeft: "50pt",
              textIndent: "-21pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            Medical Insurance / Rural Accidental Benefit Insurance
            <span className="p">
              , to the tune of Rupees 2 Lakh for each employee, is provided.
            </span>
          </p>
          <ul id="l27">
            <li data-list-text="">
              <p
                className="s5"
                style={{
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "justify",
                }}
              >
                Employees‟ Provident Fund
                <span className="p">provisions, as per EPF Act, 1952</span>
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s5"
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "65pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Study leave
                <span className="p">
                  (on loss of pay) for pursuing full time M.Tech / Ph.D Studies
                  in reputed / top ranked institutes.
                </span>
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "65pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Allowing the selected faculty to go for higher studies (M.Tech /
                Ph.D) on <b>part- time/ external registration </b>basis, without
                disturbing the assigned academic works in the institute.
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s5"
                style={{
                  paddingLeft: "65pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Deputation <span className="p">of employees to </span>
                continuing education programs
                <span className="p">
                  like workshops, short term trainingprograms, conferences,
                  seminars etc. on recommendation by the respective HODs /
                  Section Heads, with suitable alternative class / work
                  arrangements made by the employee. Special Casual Leave may be
                  sanctioned to facilitate the same by the Principal, on case to
                  case basis. TA/DA/registration Fee will be sanctioned to those
                  who are presenting papers or directed by the institution to
                  attend. The decision of the Principal is final in this regard.
                </span>
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s5"
                style={{
                  paddingLeft: "65pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Waiver of „Other Fees‟
                <span className="p">
                  to the children of employees admitted to NCET through CET/
                  COMED- K, however, this benefit will be continued only if the
                  student clears all the subjects in first attemptand maintains
                  minimum of 75% marks (on an aggregate) in each year.
                </span>
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "65pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Allowing employees to take up professional
                <b>consultancy works, </b>officially with written consent from
                the Principal. Such employees may be paid as much as 60% of the
                net surplus earnedthrough consultancy.
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s5"
                style={{
                  paddingLeft: "65pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Research Incentives
                <span className="p">for executing </span>externally funded
                research projects
                <span className="p">(5 to 10% of allocated project cost).</span>
              </p>
              <h4
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "170pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                RESEARCH POLICY FOR ENCOURAGING FACULTY
              </h4>
              <h4
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "65pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                The Governing Body accepted the request and decided the
                following reimbursement,
              </h4>
              <p
                style={{
                  paddingTop: "2pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s4"
                style={{
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                UGC care – Rs. 500-1000/-
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s4"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Scopus – Rs. 2000 – 5000/-
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s4"
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "67pt",
                  textIndent: "-20pt",
                  textAlign: "left",
                }}
              >
                SCI – Rs.5000-10,000/-
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s4"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Patent Rs.1000-2000/-
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s4"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                National and International Conferences -3000-5000/-
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s4"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Book chapter Rs.1000-2000/-
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s4"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Book Publication Rs.3000-5000/-
              </p>
            </li>
            <li data-list-text="">
              <p
                className="s4"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Any workshops or conferences or seminars of FDP Rs.500-1000/-
                with OD
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <h2
        style={{
          paddingTop: "2pt",
          paddingLeft: "34pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        LEAVE POLICY OR RULES
      </h2>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <ol id="l28">
        <li data-list-text="a.">
          <h3
            style={{
              paddingLeft: "60pt",
              textIndent: "-13pt",
              textAlign: "left",
            }}
          >
            Holidays
          </h3>
          <ul id="l29">
            <li data-list-text="•">
              <p
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "112pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "left",
                }}
              >
                Weekly holidays: Every employee shall be allowed one holiday per
                week, to be known as “off day”without deduction of wages/salary.
              </p>
            </li>
            <li data-list-text="•">
              <p
                style={{
                  paddingLeft: "112pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Festival holidays: will be as announced by JNTUK / AP State
                Government.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="b.">
          <h3
            style={{
              paddingLeft: "58pt",
              textIndent: "-14pt",
              textAlign: "left",
            }}
          >
            Leave (for Permanent/Probationary/Contact employees only)
          </h3>
          <p
            className="s5"
            style={{
              paddingTop: "8pt",
              textIndent: "20pt",
              textAlign: "left",
            }}
          >
            <u>Type of leave</u> <u>No. of days</u>
          </p>
          <ol id="l30">
            <li data-list-text="a.">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "20pt",
                  textIndent: "8pt",
                  textAlign: "left",
                }}
              >
                Casual leave: 12 days/ year
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "20pt",
                  textIndent: "8pt",
                  textAlign: "left",
                }}
              >
                Earned leave: 03 days / year
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "10pt",
                  textIndent: "18pt",
                  textAlign: "left",
                }}
              >
                Special Casual Leave: For University/Examination related work
                only
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "10pt",
                  textIndent: "18pt",
                  textAlign: "left",
                }}
              >
                Permitted Leave : For Research Work(based on evidence
                submitting)
              </p>
            </li>
          </ol>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <li data-list-text="c.">
          <h3
            style={{
              paddingLeft: "15pt",
              textIndent: "1pt",
              textAlign: "left",
            }}
          >
            General Rules:
          </h3>
        </li>
      </ol>
      <ul id="l31">
        <li data-list-text="">
          <p
            style={{
              paddingTop: "15pt",
              paddingLeft: "75pt",
              textIndent: "-21pt",
              lineHeight: "150%",
              textAlign: "left",
            }}
          >
            Proper alternative arrangements in one‟s absence must be specified
            in the leave application by the teaching faculty and accepted in
            writing by the faculty agreeing to take up the relevant schedule.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "75pt",
              textIndent: "-21pt",
              lineHeight: "14pt",
              textAlign: "left",
            }}
          >
            Depending upon exigencies of service, the competent authority, may
          </p>
          <ol id="l32">
            <li data-list-text="a.">
              <p
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "76pt",
                  textIndent: "-22pt",
                  textAlign: "left",
                }}
              >
                Refuse, postpone, revoke or reduce leave of any description,
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "76pt",
                  textIndent: "-22pt",
                  textAlign: "left",
                }}
              >
                recall any member of staff from leave before it is wholly
                availed,
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "76pt",
                  textIndent: "-22pt",
                  textAlign: "justify",
                }}
              >
                Permit an employee, if he/she so request, to rejoin duty before
                expiry of the leave period.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingTop: "6pt",
              paddingLeft: "75pt",
              textIndent: "-21pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            An employee shall not take up or accept any employment with or
            without remuneration during the period of leave.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "75pt",
              textIndent: "-21pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            Except in the case of Casual leave, it is obligatory for every
            employee to furnish the leave sanctioningauthority, the leave
            address with telephone number if any, before proceeding on leave.
          </p>
        </li>
      </ul>
      <ul id="l33">
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "72pt",
              textIndent: "-31pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            If an employee, who is on leave, seeks extension thereof, he shall
            make an application in writing to the competent authority giving
            reasons. Such application shall be made
          </p>
          <p
            style={{
              paddingTop: "3pt",
              paddingLeft: "72pt",
              textIndent: "0pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            sufficiently in advance so as to enable the office to process the
            application and communicate the decision to the employee before
            expiry of the already sanctioned leave.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "72pt",
              textIndent: "-31pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            No leave or extension of leave shall be deemed to have been granted
            or extended unless it is sanctioned and communicated to the employee
            concerned.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "72pt",
              textIndent: "-31pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            Over-stay of the sanctioned leave shall be treated as leave without
            pay and will constitute break in service. However, before taking
            this action, the competent authority shall verify the
            circumstancesthat led to this situation.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingLeft: "72pt",
              textIndent: "-31pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            Employees applying for leave on medical grounds should produce
            medical certificate from a doctor/hospital which is authorized by
            the Management. An employee on leave on medical grounds shall
            produce a medical certificate of fitness while reporting back to
            duty.
          </p>
        </li>
      </ul>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <ol id="l34">
        <li data-list-text="d.">
          <h3
            style={{
              paddingLeft: "37pt",
              textIndent: "-14pt",
              textAlign: "left",
            }}
          >
            Special Casual Leave (SCL)
          </h3>
          <ul id="l35">
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "15pt",
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Special casual leave may be granted to teaching faculty for:
              </p>
              <ol id="l36">
                <li data-list-text="a.">
                  <p
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "82pt",
                      textIndent: "-17pt",
                      textAlign: "left",
                    }}
                  >
                    Examination related duties / responsibilities
                  </p>
                </li>
                <li data-list-text="b.">
                  <p
                    style={{
                      paddingTop: "6pt",
                      paddingLeft: "82pt",
                      textIndent: "-17pt",
                      textAlign: "left",
                    }}
                  >
                    University related duties / responsibilities
                  </p>
                </li>
              </ol>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "64pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Special casual leave cannot be accumulated or carried forward
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "65pt",
                  textIndent: "-18pt",
                  lineHeight: "146%",
                  textAlign: "left",
                }}
              >
                After availing SCL, the teaching staff should produce copy of
                the attendance certificate from theorganization where he has
                attended to his Examination / University related work.
              </p>
              <p
                style={{
                  paddingTop: "7pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="e.">
          <h3
            style={{
              paddingLeft: "36pt",
              textIndent: "-13pt",
              textAlign: "left",
            }}
          >
            On Official Deputation (OOD)-(or)- On duty
          </h3>
          <p
            style={{
              paddingTop: "15pt",
              paddingLeft: "65pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            OD may be granted to teaching and non teaching staff for:
          </p>
          <ol id="l37">
            <li data-list-text="a.">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "100pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Attending profession oriented continuing education programs
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "100pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Delivering invited talks, as resource persons
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "100pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Any work assigned by the HOD/Principal
              </p>
            </li>
          </ol>
          <p
            style={{
              paddingTop: "7pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            <br />
          </p>
        </li>
        <li data-list-text="f.">
          <h3
            style={{
              paddingLeft: "34pt",
              textIndent: "-11pt",
              textAlign: "left",
            }}
          >
            Earned Leave (EL)
          </h3>
          <ul id="l38">
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "15pt",
                  paddingLeft: "58pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Teaching staff who are permitted to avail vacation at the end of
                the semester, are entitled for 03 days of Earned Leave per year.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "58pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Every non-vacation employee is entitled to 20 days of earned
                leave for every completed year of service from the date of
                confirmation. Leave becomes due only at the end of the 12 months
                of physical service. Subsequent entitlement of earned leave will
                be in proportion to the length
              </p>
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "58pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                of service calculated on monthly basis.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "10pt",
                  paddingLeft: "58pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Earned leave can be accumulated up to a maximum of 240 days.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "58pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                An employee wishing to avail earned leave must apply for the
                same for a minimum period of 1 day at a time. Application should
                be made to the appropriate authority through proper channel in
                the prescribed form.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "58pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Faculty on duty (approved by the Principal) during the vacation
                period will get earned leave @ one day for every 3 days of
                full-time work/duty.
              </p>
            </li>
          </ul>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <li data-list-text="g.">
          <h3
            style={{
              paddingLeft: "36pt",
              textIndent: "-13pt",
              textAlign: "left",
            }}
          >
            Permitted Leave (PL)
          </h3>
          <ul id="l39">
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "16pt",
                  paddingLeft: "58pt",
                  textIndent: "-29pt",
                  textAlign: "left",
                }}
              >
                Permitted Leave will be given to the teachers who are pursuing
                their research.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "56pt",
                  textIndent: "-27pt",
                  lineHeight: "146%",
                  textAlign: "left",
                }}
              >
                Permitted Leave will be a fully paid leave given to teaching
                staff to attend to their course work,Examinations and other
                research activities.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-26pt",
                  textAlign: "left",
                }}
              >
                The total number of days of Permitted Leave shall not exceed 30
                days in a calendar year.
              </p>
              <p
                style={{
                  paddingTop: "7pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="h.">
          <h3
            style={{
              paddingLeft: "37pt",
              textIndent: "-14pt",
              textAlign: "left",
            }}
          >
            Vacation leave
          </h3>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <ul id="l40">
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "146%",
                  textAlign: "justify",
                }}
              >
                Semester-end vacation will be for 60 days in a year (given in
                two parts, at the end of each semester)to the teaching staff
                (vacation employees).
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "46pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Non Teaching staff members are entitled for 5 days of vacation
                in a semester
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "148%",
                  textAlign: "justify",
                }}
              >
                Teaching staff members should have served for the entire
                prevailing semester to become eligible for vacation leave.
                However, faculty on LOP during a semester / those who joined in
                the middle of a semester (after semester re-opening) will be
                eligible for a proportionate vacation leave.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "46pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Vacation leave of current academic year cannot be carried
                forward to next academic year.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "148%",
                  textAlign: "justify",
                }}
              >
                Principal, Vice-Principal, Deans, HODs, Chief-Warden, Library
                staff and Office staff are not eligible for Vacation Leave
                (non-vacation staff). However, they will get earned leave of 20
                days per year.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "148%",
                  textAlign: "justify",
                }}
              >
                A member of the vacation staff shall normally be expected to
                avail himself, the vacation leave or a part thereof unless he
                has been required by general or special order of an appropriate
                authority to forego his vacation or a part thereof.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "46pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                The vacation staff should report to the duty one week prior to
                the re-opening of the semester.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "147%",
                  textAlign: "justify",
                }}
              >
                Vacation leave cannot be combined with earned leave. However the
                Head of the institution may permit such combination on merits of
                the case.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "149%",
                  textAlign: "justify",
                }}
              >
                Grant of vacation leave is subject to the condition that the
                department will continue to function during the vacations.
                Before commencement of the vacation, the Head of the department
                should submit to the Head of the Institution, a proposal
                indicating the persons in each department who would avail the
                vacation leave and the personnel staying back to ensure that the
                department would be functioning during the vacation.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "148%",
                  textAlign: "justify",
                }}
              >
                During the vacation, leave, if any exam related duties/attending
                continuing education programs/ pursuing higher studies take
                place, vacation period will not be extended but the vacation
                staffs need not avail any other leave for such works.
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="i.">
          <h3
            style={{
              paddingLeft: "33pt",
              textIndent: "-10pt",
              textAlign: "justify",
            }}
          >
            Maternity Leave
          </h3>
          <ul id="l41">
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "15pt",
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "left",
                }}
              >
                Every married woman employee, after completion of one year
                service, is entitled to maternity benefits.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "46pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Maternity benefits granted up to two deliveries.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "46pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                The maximum period of entitlement for maternity leave shall be 6
                months.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  textAlign: "left",
                }}
              >
                Leave of any other kind may be granted in continuation of
                maternity leave, if the request for its grantis supported by a
                proper medical certificate.
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="j.">
          <h3
            style={{
              paddingTop: "3pt",
              paddingLeft: "34pt",
              textIndent: "-11pt",
              textAlign: "left",
            }}
          >
            Employee on Probation
          </h3>
          <p
            style={{
              paddingTop: "3pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            <br />
          </p>
          <ul id="l42">
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "147%",
                  textAlign: "left",
                }}
              >
                An employee on probation is entitled for only 12 days of casual
                leave in each calendar year. He/she isnot eligible for availing
                Earned leave. However it will be accumulated.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "46pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Proportionate Vacation leave may be sanctioned, at the
                discretion of the Principal.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  lineHeight: "147%",
                  textAlign: "left",
                }}
              >
                Special Casual leave may be sanctioned, on written
                recommendation by the respective HOD/Sectionhead and on approval
                by the Principal.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingLeft: "46pt",
                  textIndent: "-18pt",
                  textAlign: "left",
                }}
              >
                Principal reserves the right to relax/modify any of the leave
                rules.
              </p>
              <p
                style={{
                  paddingTop: "7pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="k.">
          <h3
            style={{
              paddingLeft: "37pt",
              textIndent: "-14pt",
              textAlign: "left",
            }}
          >
            Leave after Resignation
          </h3>
        </li>
      </ol>
      <ul id="l43">
        <li data-list-text="">
          <p
            style={{
              paddingTop: "15pt",
              paddingLeft: "46pt",
              textIndent: "-18pt",
              textAlign: "justify",
            }}
          >
            No leave other than casual leave shall be granted to an employee
            once notice of resignation is given.
          </p>
        </li>
        <li data-list-text="">
          <p
            style={{
              paddingTop: "6pt",
              paddingLeft: "47pt",
              textIndent: "-18pt",
              lineHeight: "150%",
              textAlign: "justify",
            }}
          >
            In case, an employee is given notice of termination of his services
            by the management, the employee will be permitted to avail whatever
            leave he is entailed, subject to the condition that such leave shall
            be restricted to the period of notice less one day so that the
            employee will be on duty on the last dayof the notice of
            termination.
          </p>
        </li>
      </ul>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "17pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        ****
      </p>
      <h2
        style={{
          paddingTop: "3pt",
          paddingLeft: "35pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        Annexure – I
      </h2>
      <h2
        style={{
          paddingLeft: "35pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        Duties and Responsibilities of Assistant Professor
      </h2>
      <ol id="l44">
        <li data-list-text="1)">
          <p
            className="s5"
            style={{
              paddingTop: "17pt",
              paddingLeft: "26pt",
              textIndent: "-15pt",
              textAlign: "left",
            }}
          >
            DUTIES<span className="p">:</span>
          </p>
          <ol id="l45">
            <li data-list-text="a.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                You will have the responsibility for an efficient, satisfactory
                and economical discharge of the duties entrusted to you from
                time to time.
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                During this period of employment, you shall not secure any other
                employment, engage in any profession or trade or pursue any
                course of study or work part time without prior consent from the
                Management.
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                You will have to behave and conduct yourself in an orderly
                manner and shall not remain absent from the place of work
                without the prior consent in writing.
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Your place of posting would be as follows:
                <b>SRI MITTAPALLI COLLEGE OF ENGINEERING</b>. The management
                reserves the right to transfer you to any of its institutions
                existing at present or which may be established in future.
              </p>
            </li>
            <li data-list-text="e.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "justify",
                }}
              >
                You will be reporting to the Principal in this regard for the
                performance of your duties.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="2)">
          <p
            className="s5"
            style={{
              paddingLeft: "29pt",
              textIndent: "-18pt",
              lineHeight: "14pt",
              textAlign: "left",
            }}
          >
            JOB RESPONSIBILITIES:
          </p>
          <ol id="l46">
            <li data-list-text="a.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  lineHeight: "14pt",
                  textAlign: "left",
                }}
              >
                Teaching &amp; Research
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  lineHeight: "14pt",
                  textAlign: "left",
                }}
              >
                Instruction &amp; guidance in laboratories
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Students‟ assessment and evaluation
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Assisting in consultancy and R &amp; D services
              </p>
            </li>
            <li data-list-text="e.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Developing resources materials and laboratories
              </p>
            </li>
            <li data-list-text="f.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Co-curricular activities
              </p>
            </li>
            <li data-list-text="g.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Work related to administration, examinations etc.,
              </p>
            </li>
            <li data-list-text="h.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Students counselling
              </p>
            </li>
            <li data-list-text="i.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Preparing and uploading softcopy of the course material
              </p>
            </li>
            <li data-list-text="j.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Implementing and practicing Outcome Based Education
              </p>
            </li>
            <li data-list-text="k.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Use of Innovative Teaching and Learning Methods
              </p>
            </li>
            <li data-list-text="l.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Any other duties assigned by principal and your superiors from
                time to time
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="3)">
          <p
            className="s5"
            style={{
              paddingLeft: "23pt",
              textIndent: "-12pt",
              textAlign: "left",
            }}
          >
            SECRECY<span className="p">:</span>
          </p>
          <p
            style={{
              paddingLeft: "42pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            You will not at any time during your employment or thereafter
            divulge any information, plans, know- how, etc, regarding matters or
            affairs of the College.
          </p>
        </li>
        <li data-list-text="4)">
          <p
            className="s5"
            style={{
              paddingLeft: "23pt",
              textIndent: "-12pt",
              textAlign: "left",
            }}
          >
            RESIGNATION/ TERMINATION OF SERVICES<span className="p">:</span>
          </p>
          <ol id="l47">
            <li data-list-text="a.">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Notwithstanding to any of the clauses herein, the Management
                reserves the right to terminate your services without any notice
                and without liability for any compensation during the
                probationary period.
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                If you wish to resign, you are required to serve one month
                notice or surrender/pay one month salary in lieu thereof.
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                You are not supposed to leave the institution during the
                semester. In case you resign during the semester, you have to
                pay one month salary. (During the semester means the interval
                between the first day of the semester &amp; last day of the
                semester).
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                The Management reserves the right to accept your resignation
                with effect from such a date as it may deem fit even before the
                expiry of the period of notice given by you.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <h3
        style={{
          paddingTop: "3pt",
          paddingLeft: "36pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        Annexure – II
      </h3>
      <h3
        style={{
          paddingLeft: "36pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        Duties and Responsibilities of Associate Professor
      </h3>
      <ol id="l48">
        <li data-list-text="1)">
          <p
            className="s5"
            style={{
              paddingTop: "13pt",
              paddingLeft: "26pt",
              textIndent: "-15pt",
              lineHeight: "14pt",
              textAlign: "left",
            }}
          >
            DUTIES<span className="p">:</span>
          </p>
          <ol id="l49">
            <li data-list-text="a.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                You will have the responsibility for an efficient, satisfactory
                and economical discharge of the duties entrusted to you from
                time to time.
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                During this period of employment, you shall not secure any other
                employment, engage in any profession or trade or pursue any
                course of study or work part time without prior consent from the
                Management.
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                You will have to behave and conduct yourself in an orderly
                manner and shall not remain absent from the place of work
                without the prior consent in writing.
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Your place of posting would be as follows:
                <b>SRI MITTAPALLI COLLEGE OF ENGINEERING</b>. The management
                reserves the right to transfer you to any of its institutions
                existing at present or which may be established in future.
              </p>
            </li>
            <li data-list-text="e.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "justify",
                }}
              >
                You will be reporting to the Principal in this regard for the
                performance of your duties.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="2)">
          <p
            className="s5"
            style={{
              paddingLeft: "29pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            JOB RESPONSIBILITIES:
          </p>
          <ol id="l50">
            <li data-list-text="a.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  lineHeight: "14pt",
                  textAlign: "left",
                }}
              >
                Teaching &amp; Research
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  lineHeight: "14pt",
                  textAlign: "left",
                }}
              >
                Instruction &amp; guidance in laboratories
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Students‟ assessment and evaluation
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Involving in consultancy and R &amp; D activities
              </p>
            </li>
            <li data-list-text="e.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Developing resources materials and laboratories
              </p>
            </li>
            <li data-list-text="f.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Co-curricular activities
              </p>
            </li>
            <li data-list-text="g.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Associate with the departmental and college administration.
              </p>
            </li>
            <li data-list-text="h.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Students counseling.
              </p>
            </li>
            <li data-list-text="i.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Supervising and strengthening the student mentoring.
              </p>
            </li>
            <li data-list-text="j.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Preparing and uploading softcopy of the course material
              </p>
            </li>
            <li data-list-text="k.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Implementing and practicing Outcome Based Education
              </p>
            </li>
            <li data-list-text="l.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Use of Innovative Teaching and Learning Methods
              </p>
            </li>
            <li data-list-text="m.">
              <p
                style={{
                  paddingLeft: "42pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Any other duties assigned by principal and your superiors from
                time to time
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="3)">
          <p
            className="s5"
            style={{
              paddingLeft: "23pt",
              textIndent: "-12pt",
              textAlign: "left",
            }}
          >
            SECRECY<span className="p">:</span>
          </p>
          <p
            style={{
              paddingLeft: "42pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            You will not at any time during your employment or thereafter
            divulge any information, plans, know- how, etc, regarding matters or
            affairs of the College.
          </p>
        </li>
        <li data-list-text="4)">
          <p
            className="s5"
            style={{
              paddingLeft: "23pt",
              textIndent: "-12pt",
              textAlign: "left",
            }}
          >
            RESIGNATION/ TERMINATION OF SERVICES<span className="p">:</span>
          </p>
          <ol id="l51">
            <li data-list-text="a.">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Notwithstanding to any of the clauses herein, the Management
                reserves the right to terminate your services without any notice
                and without liability for any compensation during the
                probationary period.
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                If you wish to resign, you are required to serve one month
                notice or surrender/pay one month salary in lieu thereof.
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                You are not supposed to leave the institution during the
                semester. In case you resign during the semester, you have to
                pay one month salary. (During the semester means the interval
                between the first day of the semester &amp; last day of the
                semester).
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style={{
                  paddingLeft: "47pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                The Management reserves the right to accept your resignation
                with effect from such a date as it may deem fit even before the
                expiry of the period of notice given by you.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <h2
        style={{
          paddingTop: "3pt",
          paddingLeft: "62pt",
          textIndent: "139pt",
          textAlign: "left",
        }}
      >
        Annexure – III Duties and Responsibilities of a Professor
      </h2>
      <ol id="l52">
        <li data-list-text="1)">
          <p
            className="s5"
            style={{
              paddingTop: "13pt",
              paddingLeft: "39pt",
              textIndent: "-15pt",
              textAlign: "left",
            }}
          >
            DUTIES<span className="p">:</span>
          </p>
          <ol id="l53">
            <li data-list-text="a.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                You will have the responsibility for an efficient, satisfactory
                and economical discharge of the duties entrusted to you from
                time to time.
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                During this period of employment, you shall not secure any other
                employment, engage in any profession or trade or pursue any
                course of study or work part time without prior consent from the
                Management.
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                You will have to behave and conduct yourself in an orderly
                manner and shall not remain absent from the place of work
                without the prior consent in writing.
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Your place of posting would be as follows:
                <b>SRI MITTAPALLI COLLEGE OF ENGINEERING</b>. The management
                reserves the right to transfer you to any of its institutions
                existing at present or which may be established in future.
              </p>
            </li>
            <li data-list-text="e.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "justify",
                }}
              >
                You will be reporting to the Principal in this regard for the
                performance of your duties.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="2)">
          <p
            className="s5"
            style={{
              paddingLeft: "42pt",
              textIndent: "-18pt",
              lineHeight: "14pt",
              textAlign: "left",
            }}
          >
            JOB RESPONSIBILITIES:
          </p>
          <ol id="l54">
            <li data-list-text="a.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  lineHeight: "14pt",
                  textAlign: "left",
                }}
              >
                Curriculum Design and Development
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  lineHeight: "14pt",
                  textAlign: "left",
                }}
              >
                Teaching &amp; Research work
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Interaction with Industries for Curriculum Design, Training and
                Placement Assistance
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Instruction &amp; guidance in laboratories for the technical
                supporting staff and students
              </p>
            </li>
            <li data-list-text="e.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Students‟ assessment and evaluation
              </p>
            </li>
            <li data-list-text="f.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Developing consultancy and strengthening the R &amp; D
                activities
              </p>
            </li>
            <li data-list-text="g.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Developing learning resources and laboratories
              </p>
            </li>
            <li data-list-text="h.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Co-curricular activities
              </p>
            </li>
            <li data-list-text="i.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Involve and participate in the departmental and college
                administration.
              </p>
            </li>
            <li data-list-text="j.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Students counseling.
              </p>
            </li>
            <li data-list-text="k.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Effective implementation of the student mentoring.
              </p>
            </li>
            <li data-list-text="l.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Guide and help the junior teachers in the effective delivery of
                the curriculum.
              </p>
            </li>
            <li data-list-text="m.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Involve in the establishment of new equipment and laboratories.
              </p>
            </li>
            <li data-list-text="n.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Develop the culture of team work in the department and college.
              </p>
            </li>
            <li data-list-text="o.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Preparing and uploading softcopy of the course material
              </p>
            </li>
            <li data-list-text="p.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Implementing and practicing Outcome Based Education
              </p>
            </li>
            <li data-list-text="q.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Use of Innovative Teaching and Learning Methods
              </p>
            </li>
            <li data-list-text="r.">
              <p
                style={{
                  paddingLeft: "55pt",
                  textIndent: "-17pt",
                  textAlign: "left",
                }}
              >
                Any other duties assigned by principal and your superiors from
                time to time
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="3)">
          <p
            className="s5"
            style={{
              paddingLeft: "36pt",
              textIndent: "-12pt",
              textAlign: "left",
            }}
          >
            SECRECY<span className="p">:</span>
          </p>
          <p
            style={{
              paddingLeft: "55pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            You will not at any time during your employment or thereafter
            divulge any information, plans, know-how, etc, regarding matters or
            affairs of the College.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <li data-list-text="4)">
          <p
            className="s5"
            style={{
              paddingLeft: "36pt",
              textIndent: "-12pt",
              textAlign: "left",
            }}
          >
            RESIGNATION/ TERMINATION OF SERVICES<span className="p">:</span>
          </p>
          <ol id="l55">
            <li data-list-text="a.">
              <p
                style={{
                  paddingLeft: "59pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                Notwithstanding to any of the clauses herein, the Management
                reserves the right to terminate your services without any notice
                and without liability for any compensation during the
                probationary period.
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingLeft: "59pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                If you wish to resign, you are required to serve one month
                notice or surrender/pay one month salaryin lieu thereof.
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "65pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                }}
              >
                You are not supposed to leave the institution during the
                semester. In case you resign during the semester, you have to
                pay one month salary. (During the semester means the interval
                between thefirst day of the semester &amp; last day of the
                semester).
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p
        style={{
          paddingTop: "13pt",
          paddingLeft: "29pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        The Management reserves the right to accept your resignation with effect
        from such a date as it may deem fiteven before the expiry of the period
        of notice given by you.
      </p>
      <h3
        style={{
          paddingTop: "3pt",
          paddingLeft: "17pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        Duties of Principal
      </h3>
      <p style={{ paddingTop: "9pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <ol id="l56">
        <li data-list-text="I.">
          <p
            className="s5"
            style={{
              paddingLeft: "57pt",
              textIndent: "-21pt",
              textAlign: "left",
            }}
          >
            Functions of Principal:
          </p>
          <ul id="l57">
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "11pt",
                  paddingLeft: "71pt",
                  textIndent: "-14pt",
                  textAlign: "left",
                }}
              >
                To conduct the Governing Body meetings as per the stipulated
                guidelines
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "12pt",
                  paddingLeft: "71pt",
                  textIndent: "-14pt",
                  textAlign: "left",
                }}
              >
                To hold Academic Council meetings as per the norms.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "12pt",
                  paddingLeft: "71pt",
                  textIndent: "-14pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                To coordinate and motivate the faculty, administrative
                authorities and the supporting staff.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "9pt",
                  paddingLeft: "71pt",
                  textIndent: "-14pt",
                  lineHeight: "117%",
                  textAlign: "justify",
                }}
              >
                Shall work for the common goal of providing effective technical
                education and guiding to enable the students to carve out
                promising career and lifelong learning.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "9pt",
                  paddingLeft: "71pt",
                  textIndent: "-14pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                Spokesperson of the institution and shall take part in regional,
                national and international conventions in serving the cause of
                development of technical education in particular.
              </p>
            </li>
            <li data-list-text="">
              <p
                style={{
                  paddingTop: "10pt",
                  paddingLeft: "71pt",
                  textIndent: "-14pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                Along with all the staff working under him, is singularly and
                collectively responsible to the Board of Governors, Academic
                Council, University, State Government, AICTE/UGC, students and
                parents for the smooth and effective functioning of the college.
              </p>
            </li>
          </ul>
        </li>
      </ol>
      <p
        style={{
          paddingTop: "9pt",
          paddingLeft: "17pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        The duties of the Principal may be suitably categorized as
      </p>
      <p style={{ paddingTop: "4pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <ol id="l58">
        <li data-list-text="A.">
          <p
            className="s14"
            style={{
              paddingLeft: "71pt",
              textIndent: "-21pt",
              textAlign: "left",
            }}
          >
            Academic Administration:
          </p>
          <ol id="l59">
            <li data-list-text="a.">
              <p
                style={{
                  paddingTop: "10pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                On academic matters the Principal is generally guided by the
                rules and regulation as well as the norms laid down by JNTUK,
                Kakinada, AICTE, UGC, State Government and the Governing Body of
                the college.
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "112%",
                  textAlign: "justify",
                }}
              >
                Will be assisted by various Heads of the departments, Vice
                Principal, Deans Controller of Examinations of the college,
                senior faculty members and various committees mentioned in the
                manual.
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "113%",
                  textAlign: "justify",
                }}
              >
                In matters related to decision implementation, Principal will be
                assisted by the Governing Body and Academic Council of the
                college.
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "112%",
                  textAlign: "justify",
                }}
              >
                In matters related to academic work, he will be assisted by the
                Chairman, Boardof Studies and Heads of the Departments.
              </p>
            </li>
            <li data-list-text="e.">
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                An integrated time table of the entire institution shall be
                prepared and submittedto the Principal. In this endeavor,
                coordinator of time-tables, first year coordinators along with
                the various heads of the departments extend support tothe
                Principal.
              </p>
            </li>
            <li data-list-text="f.">
              <p
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "113%",
                  textAlign: "justify",
                }}
              >
                Shall closely monitor the class work as per the time tables and
                the almanac with assistance of class work coordinators and other
                faculty in-charges.
              </p>
            </li>
            <li data-list-text="g.">
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "113%",
                  textAlign: "justify",
                }}
              >
                Shall closely observe various academic activities like conduct
                of technical fests, conferences, seminars, workshops etc.
              </p>
            </li>
            <li data-list-text="h.">
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "81pt",
                  textIndent: "-17pt",
                  lineHeight: "127%",
                  textAlign: "justify",
                }}
              >
                Shall hold meetings of Heads of departments to review the
                progress of academic work and suggest effective measures to
                achieve desired academic outcome.
              </p>
            </li>
            <li data-list-text="i.">
              <p
                style={{
                  paddingTop: "6pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "113%",
                  textAlign: "justify",
                }}
              >
                If necessary, shall instruct the class work coordinators and
                Heads of the departments to conduct remedial classes
                academically to support the slow learners.
              </p>
            </li>
            <li data-list-text="j.">
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "112%",
                  textAlign: "justify",
                }}
              >
                In matters related to internal examinations, semester end
                examinations (both theory and laboratory), result analysis,
                detained candidates, Principal will be assisted by Controller of
                examinations and additional controllers of the college.
              </p>
            </li>
            <li data-list-text="k.">
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "115%",
                  textAlign: "justify",
                }}
              >
                In matters related to student attendance, drop outs,
                Condonation, Principal shallbe assisted by concerned HoD.
              </p>
            </li>
            <li data-list-text="l.">
              <p
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                The principal shall plan for training need analysis (TNA) of the
                staff and devise training programmes such as refresher courses,
                orientation courses, faculty improvement programmes, quality
                enhancement programmes etc.
              </p>
            </li>
            <li data-list-text="m.">
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  textAlign: "left",
                }}
              >
                Principal shall also ensure quality assurance, assisted by IQAC.
              </p>
            </li>
            <li data-list-text="n.">
              <p
                style={{
                  paddingTop: "10pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "113%",
                  textAlign: "justify",
                }}
              >
                Shall monitor, evaluate research, development and consultancy
                activities. Assisted by Dean, R&amp;D. Shall advice the faculty
                members to get sponsored research projects from various funding
                agencies.
              </p>
            </li>
            <li data-list-text="o.">
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "112%",
                  textAlign: "justify",
                }}
              >
                The Principal shall promote industry-institute interaction for
                better employability of the students.
              </p>
            </li>
            <li data-list-text="p.">
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  textAlign: "left",
                }}
              >
                Shall look after overall welfare of staff and students.
              </p>
            </li>
            <li data-list-text="q.">
              <p
                style={{
                  paddingTop: "10pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "113%",
                  textAlign: "justify",
                }}
              >
                For effective functioning of the college he shall build close
                rapport among staff, students and management.
              </p>
            </li>
            <li data-list-text="r.">
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "113%",
                  textAlign: "justify",
                }}
              >
                Shall ensure perfect order and discipline among all the staff
                concerned and ensureserene academic environment in the campus.
              </p>
            </li>
            <li data-list-text="s.">
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "112%",
                  textAlign: "justify",
                }}
              >
                Shall maintain regular, right and appropriate contacts and
                interaction with government, AICTE, UG, University, State
                Council of technical education, Department of Technical
                Education authorities.
              </p>
            </li>
            <li data-list-text="t.">
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "113%",
                  textAlign: "justify",
                }}
              >
                Shall involve faculty members at different levels for various
                institutional activities.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="B.">
          <p
            className="s15"
            style={{
              paddingTop: "10pt",
              paddingLeft: "71pt",
              textIndent: "-21pt",
              textAlign: "left",
            }}
          >
            General Administration
          </p>
          <p
            style={{
              paddingTop: "10pt",
              paddingLeft: "71pt",
              textIndent: "-1pt",
              lineHeight: "117%",
              textAlign: "left",
            }}
          >
            On general administrative matters Principal shall be assisted by,
            Controller of Examinations, Coordinator IQAC, Heads of Departments,
            functional heads etc.
          </p>
          <ol id="l60">
            <li data-list-text="a.">
              <p
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "114%",
                  textAlign: "left",
                }}
              >
                Shall make proposal for appointment to all posts of all cadres
                including contract,part- time, ad hoc, and daily wage employees
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "114%",
                  textAlign: "left",
                }}
              >
                Shall make regularization of services, declaration of probation,
                and release ofincrements.
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "14pt",
                  textAlign: "left",
                }}
              >
                Arrange performance appraisal of faculty and supporting staff.
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style={{
                  paddingTop: "9pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  textAlign: "left",
                }}
              >
                Shall have power to sanction any kind of leave to others, except
                himself
              </p>
            </li>
            <li data-list-text="e.">
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                Empowered to initiate disciplinary proceedings (with proper
                guidelines such as constitution of enquiry committee etc.,) and
                impose punishments of minor and major character such as warning,
                censure, fine, withholding increments, promotion and recovery
                from pay whole or part of pecuniary loss to the college.
              </p>
            </li>
            <li data-list-text="f.">
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                All such cases requiring arrangements such a suspension,
                removal, dismissal fromthe services shall be referred to the
                Governing Body by the principal.
              </p>
            </li>
            <li data-list-text="g.">
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "78pt",
                  textIndent: "-14pt",
                  textAlign: "left",
                }}
              >
                Campus maintenance cell shall work under the instructions of
                Principal
              </p>
              <p
                style={{
                  paddingTop: "12pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text="C.">
          <p
            className="s15"
            style={{
              paddingLeft: "71pt",
              textIndent: "-35pt",
              textAlign: "left",
            }}
          >
            Financial Administration
          </p>
          <ol id="l61">
            <li data-list-text="a.">
              <p
                style={{
                  paddingTop: "11pt",
                  paddingLeft: "85pt",
                  textIndent: "-21pt",
                  textAlign: "left",
                }}
              >
                Principal is assisted by the Finance committee in financial
                administration.
              </p>
            </li>
            <li data-list-text="b.">
              <p
                style={{
                  paddingTop: "12pt",
                  paddingLeft: "85pt",
                  textIndent: "-21pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                Subject to the budget allocations for a specific area of
                expenditure, Principal is empowered to incur expenditure within
                the stipulated limits and adhering to the related procedure as
                laid down by the Governing Body from time to time.
              </p>
            </li>
            <li data-list-text="c.">
              <p
                style={{
                  paddingTop: "10pt",
                  paddingLeft: "85pt",
                  textIndent: "-21pt",
                  lineHeight: "115%",
                  textAlign: "justify",
                }}
              >
                Empowered to incur recurring contingent expenditure as per the
                norms prescribed by the Governing Body
              </p>
            </li>
            <li data-list-text="d.">
              <p
                style={{
                  paddingTop: "9pt",
                  paddingLeft: "85pt",
                  textIndent: "-21pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                All contracts for and on behalf of the college (except himself
                and the college) when authorized by a resolution of the
                Governing Body, passed in writing and expressed to be made in
                the name of the college shall be executed by the Principal.
              </p>
            </li>
            <li data-list-text="e.">
              <p
                style={{
                  paddingTop: "9pt",
                  paddingLeft: "85pt",
                  textIndent: "-21pt",
                  lineHeight: "115%",
                  textAlign: "justify",
                }}
              >
                The Principal or the officer delegated with such powers shall
                counter sign all kinds of scholarship bills in respect of
                students of the college.
              </p>
            </li>
            <li data-list-text="f.">
              <p
                style={{
                  paddingTop: "9pt",
                  paddingLeft: "85pt",
                  textIndent: "-21pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                Shall have power to sanction the purchase of stationery, library
                books, periodicals, consumables for laboratories, workshops
                etc., subject to the limit of powers delegated in respective
                areas and subject to the prescribed procedures, budget
                provisions under the respective heads of budget.
              </p>
            </li>
            <li data-list-text="g.">
              <p
                style={{
                  paddingTop: "10pt",
                  paddingLeft: "85pt",
                  textIndent: "-21pt",
                  textAlign: "left",
                }}
              >
                Shall countersign T.A. bills.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <h2
        style={{
          paddingTop: "3pt",
          paddingLeft: "17pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        Annexure – IV
      </h2>
      <h2
        style={{
          paddingTop: "18pt",
          paddingLeft: "17pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        Duties &amp; Responsibilities of Administration Staff
      </h2>
      <p style={{ paddingTop: "6pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        className="s16"
        style={{ paddingLeft: "23pt", textIndent: "0pt", textAlign: "left" }}
      >
        Roles &amp; Responsibilities of the Chief Superintendent:
      </p>
      <ul id="l62">
        <li data-list-text="">
          <p
            className="s4"
            style={{
              paddingLeft: "22pt",
              textIndent: "-17pt",
              lineHeight: "14pt",
              textAlign: "left",
            }}
          >
            The Principal is the Chief Superintendent of University Examination.
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s4"
            style={{
              paddingLeft: "22pt",
              textIndent: "-17pt",
              lineHeight: "14pt",
              textAlign: "left",
            }}
          >
            To appoint Examination In-Charge in consultation with the
            Management.
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s4"
            style={{
              paddingLeft: "23pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            To appoint Examination Committee Members in coordination with HOD’s
            and Examination In- Charge.
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s4"
            style={{
              paddingLeft: "23pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            To appoint internal Flying Squad, Invigilators and other human
            resources for smooth conducting of examination in the College in
            coordination with Examination In-Charge.
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s4"
            style={{
              paddingLeft: "22pt",
              textIndent: "-17pt",
              lineHeight: "14pt",
              textAlign: "left",
            }}
          >
            To interact with University for exam related works.
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s4"
            style={{
              paddingLeft: "22pt",
              textIndent: "-17pt",
              lineHeight: "14pt",
              textAlign: "left",
            }}
          >
            To head Unfair Means Committee during examination.
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s4"
            style={{
              paddingLeft: "23pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            To appoint internal and external examiners for paper assessment in
            coordination with HOD’s and Examination In-Charge.
          </p>
          <p
            style={{
              paddingTop: "12pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            <br />
          </p>
          <p
            className="s16"
            style={{
              paddingLeft: "23pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Roles &amp; Responsibilities of the Examination In-charge(s):
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s4"
            style={{
              paddingLeft: "22pt",
              textIndent: "-17pt",
              lineHeight: "14pt",
              textAlign: "left",
            }}
          >
            Responsible for the due custody of the records pertaining to his/her
            work.
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s4"
            style={{
              paddingLeft: "22pt",
              textIndent: "-17pt",
              lineHeight: "14pt",
              textAlign: "left",
            }}
          >
            Shall have administrative control over the members working under
            him/her.
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s4"
            style={{
              paddingLeft: "23pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            Shall conduct the Examinations (College, University, Govt. /Private)
            and therefore make all other arrangements and be responsible for the
            due execution of all processes connected therewith.
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s4"
            style={{
              paddingLeft: "22pt",
              textIndent: "-17pt",
              textAlign: "left",
            }}
          >
            Any other duty/responsibility assigned by the Principal.
          </p>
          <p
            style={{
              paddingTop: "12pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            <br />
          </p>
          <h4
            style={{
              paddingLeft: "23pt",
              textIndent: "0pt",
              textAlign: "left",
            }}
          >
            Roles and Responsibilities of Administrative officer:
          </h4>
          <ol id="l63">
            <li data-list-text={1}>
              <p
                style={{
                  paddingTop: "9pt",
                  paddingLeft: "78pt",
                  textIndent: "-21pt",
                  textAlign: "left",
                }}
              >
                Maintenance of principal‟s office as per principal‟s direction.
              </p>
            </li>
            <li data-list-text={2}>
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "78pt",
                  textIndent: "-21pt",
                  textAlign: "left",
                }}
              >
                Student‟s admission related works.
              </p>
            </li>
            <li data-list-text={3}>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "78pt",
                  textIndent: "-21pt",
                  textAlign: "left",
                }}
              >
                All kinds of scholarships and related work.
              </p>
            </li>
            <li data-list-text={4}>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "78pt",
                  textIndent: "-21pt",
                  lineHeight: "114%",
                  textAlign: "left",
                }}
              >
                Helping the principal in conducting Governing Body / Academic
                Council Meetings.
              </p>
            </li>
            <li data-list-text={5}>
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "78pt",
                  textIndent: "-21pt",
                  lineHeight: "115%",
                  textAlign: "left",
                }}
              >
                Recording the resolutions of the Governing Body / Academic
                Council Meetingand dispatching the same to the members.
              </p>
            </li>
            <li data-list-text={6}>
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "78pt",
                  textIndent: "-21pt",
                  textAlign: "left",
                }}
              >
                Assist the principal for JNTUK affiliation works.
              </p>
            </li>
            <li data-list-text={7}>
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "78pt",
                  textIndent: "-21pt",
                  textAlign: "left",
                }}
              >
                Assist the principal for AICTE / NBA / NAAC related works.
              </p>
            </li>
            <li data-list-text={8}>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "78pt",
                  textIndent: "-21pt",
                  textAlign: "left",
                }}
              >
                Maintenance and purchase of stationery for the stores.
              </p>
            </li>
            <li data-list-text={9}>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "78pt",
                  textIndent: "-21pt",
                  textAlign: "left",
                }}
              >
                Maintenance of leave record of Teaching &amp; Non-Teaching
                staff.
              </p>
            </li>
            <li data-list-text={10}>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "78pt",
                  textIndent: "-21pt",
                  lineHeight: "112%",
                  textAlign: "left",
                }}
              >
                Preparation and submission of number of days for salary to be
                paid, to the accountsection, for the preparation of salary
                bills.
              </p>
            </li>
            <li data-list-text={11}>
              <p
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "81pt",
                  textIndent: "-24pt",
                  textAlign: "left",
                }}
              >
                Maintaining the personal files of staff members.
              </p>
            </li>
            <li data-list-text={12}>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "78pt",
                  textIndent: "-21pt",
                  textAlign: "left",
                }}
              >
                Maintaining the budget files.
              </p>
            </li>
            <li data-list-text={13}>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "78pt",
                  textIndent: "-21pt",
                  lineHeight: "113%",
                  textAlign: "justify",
                }}
              >
                Maintenance of students‟ files &amp; records and issuing
                original certificates to staff &amp; students as directed by the
                Principal from time to time except salary certificate.
              </p>
            </li>
            <li data-list-text={14}>
              <p
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "78pt",
                  textIndent: "-21pt",
                  lineHeight: "115%",
                  textAlign: "justify",
                }}
              >
                Consult the Principal on any other issue which needs principal‟s
                directions andintervention.
              </p>
            </li>
          </ol>
        </li>
      </ul>
      <h3
        style={{
          paddingTop: "3pt",
          paddingLeft: "17pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        Duties of Head Of the Departments
      </h3>
      <ol id="l64">
        <li data-list-text="I.">
          <p
            className="s5"
            style={{
              paddingTop: "12pt",
              paddingLeft: "57pt",
              textIndent: "-21pt",
              textAlign: "left",
            }}
          >
            Roles and Responsibilities of the HoD (Functions of HoD):
          </p>
          <p
            style={{
              paddingTop: "11pt",
              paddingLeft: "57pt",
              textIndent: "-18pt",
              lineHeight: "114%",
              textAlign: "left",
            }}
          >
            Apart from the role of a Professor, HoD has to monitor the following
            administrative activities
          </p>
          <ol id="l65">
            <li data-list-text="i.">
              <p
                style={{
                  paddingTop: "9pt",
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "115%",
                  textAlign: "justify",
                }}
              >
                Allocate the subjects to the faculty members well in advance
                before commencement of the semester/year.
              </p>
            </li>
            <li data-list-text="ii.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                Collect lesson plans from teaching staff before the commencement
                of class work and ensure that the information provided is in
                accordance with the format.
              </p>
            </li>
            <li data-list-text="iii.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                Make sure that the time tables are prepared as per the
                guidelines given by the principal and inform the faculty members
                and students at least one day beforethe commencement of the
                class work.
              </p>
            </li>
            <li data-list-text="iv.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "115%",
                  textAlign: "justify",
                }}
              >
                Send staff attendance register after making necessary entries to
                the principal office by 9.00 A.M every day.
              </p>
            </li>
            <li data-list-text="v.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "115%",
                  textAlign: "justify",
                }}
              >
                Interact with students (Section wise) of their branch once in a
                fortnight, identify the problems and find solutions in
                consultation with the principal.
              </p>
            </li>
            <li data-list-text="vi.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "115%",
                  textAlign: "justify",
                }}
              >
                Verify the student attendance registers maintained by the staff
                members once a week and submit to vice principal for
                verification once in a fort night.
              </p>
            </li>
            <li data-list-text="vii.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                Instruct the faculty members to set the question papers as per
                instructions from the principal and to maintain the
                confidentiality and also to evaluate the scripts promptly to
                meet the dead line given by controller of examination. The
                evaluated answer sheets along with award sheet filled by the
                concerned faculty member have to be submitted to the exam
                section as per the schedule given. Soft
              </p>
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "0pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                / hard copy of the Mid Exams, / Assignments / tests also to be
                submitted to the exam branch as per schedule.
              </p>
            </li>
            <li data-list-text="viii.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "115%",
                  textAlign: "justify",
                }}
              >
                Observe the dress code among students and instruct the
                respective class teachersto implement the dress code among the
                students.
              </p>
            </li>
            <li data-list-text="ix.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                Convene departmental staff meeting once in a week on the day
                allotted and record the minutes of the meeting.
              </p>
            </li>
            <li data-list-text="x.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                Collect the student feedback about the faculty member‟s subject
                wise (for all the subjects taught to the students of their
                branch) and communicate the feedback tothe concerned faculty
                members in the standard format as directed by principal with a
                copy to the principal. If any subject is handled by the other
                department faculty members, communicate one copy to the
                respective HOD. Guide the faculty members to improve their
                performance based on the feedback and also monitor whether the
                faculty members are improving from year/semester
                toyear/semester.
              </p>
            </li>
            <li data-list-text="xi.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "115%",
                  textAlign: "justify",
                }}
              >
                Communicate the attendance particulars and internal marks of
                students to the concerned parents from time to time with the
                help of class teachers.
              </p>
            </li>
            <li data-list-text="xii.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "115%",
                  textAlign: "justify",
                }}
              >
                Counsel the students who are absent for the mid test or
                irregular to the class work.
              </p>
            </li>
            <li data-list-text="xiii.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "14pt",
                  textAlign: "justify",
                }}
              >
                Form the student batches and allot the project guides as per
                guidelines given
              </p>
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "85pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                by the principal.
              </p>
            </li>
            <li data-list-text="xiv.">
              <p
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                Route all the correspondence through the office of the
                principal.
              </p>
            </li>
            <li data-list-text="xv.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "115%",
                  textAlign: "left",
                }}
              >
                Designate faculty member who will be the Head I/c during his/her
                absence and make sure that all files and records are available
                for Head in-charge.
              </p>
              <p
                style={{
                  paddingTop: "5pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
            </li>
            <li data-list-text="xvi.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "115%",
                  textAlign: "left",
                }}
              >
                Allocate the students to the teacher-counselors in the beginning
                of the academicyear. Also verify mentoring files once in
                fortnight.
              </p>
            </li>
            <li data-list-text="xvii.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "115%",
                  textAlign: "left",
                }}
              >
                Inform the concerned authorities of any important information of
                events takingplace in the Department from time to time.
              </p>
            </li>
            <li data-list-text="xviii.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "14pt",
                  textAlign: "left",
                }}
              >
                Arrange special classes if necessary for the benefit of below
                average students.
              </p>
            </li>
            <li data-list-text="xix.">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                Ensure academic discipline in the department.
              </p>
            </li>
            <li data-list-text="xx.">
              <p
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                Follow the guidelines / instructions given by the principal from
                time to time.
              </p>
            </li>
            <li data-list-text="xxi.">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                Maintain and update the files.
              </p>
            </li>
            <li data-list-text="xxii.">
              <p
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "115%",
                  textAlign: "justify",
                }}
              >
                Make arrangements to lock and seal all the laboratories before
                leaving the premises.
              </p>
            </li>
            <li data-list-text="xxiii.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                Plan and conduct the BOS meeting regularly and prepare minutes
                of the meeting, and communicate the minutes of the meeting to
                the members concerned and also to the Principal.
              </p>
            </li>
            <li data-list-text="xxiv.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                Provide necessary inputs to the Principal for conducting
                Academic Council / GBMeeting.
              </p>
            </li>
            <li data-list-text="xxv.">
              <p
                style={{
                  paddingLeft: "85pt",
                  textIndent: "-28pt",
                  lineHeight: "114%",
                  textAlign: "justify",
                }}
              >
                Prepare budget proposals of the department each academic year
                and ensure the expenses are in tune to sanction of the
                Management.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <h2
        style={{
          paddingTop: "3pt",
          paddingLeft: "206pt",
          textIndent: "22pt",
          lineHeight: "138%",
          textAlign: "left",
        }}
      >
        Annexure – V Self Appraisal Form
      </h2>
      <p
        className="s1"
        style={{
          paddingTop: "1pt",
          paddingLeft: "29pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        EMPLOYEE DETAILS:
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <table
        style={{ borderCollapse: "collapse", marginLeft: "34.114pt" }}
        cellSpacing={0}
      >
        <tbody>
          <tr style={{ height: "23pt" }}>
            <td
              style={{
                width: "163pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s17"
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Name of the faculty
              </p>
            </td>
            <td
              style={{
                width: "313pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "24pt" }}>
            <td
              style={{
                width: "163pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s17"
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Department
              </p>
            </td>
            <td
              style={{
                width: "313pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "23pt" }}>
            <td
              style={{
                width: "163pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s17"
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Designation
              </p>
            </td>
            <td
              style={{
                width: "313pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "23pt" }}>
            <td
              style={{
                width: "163pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s17"
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Date of Joining the Institute
              </p>
            </td>
            <td
              style={{
                width: "313pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "23pt" }}>
            <td
              style={{
                width: "163pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s17"
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Gross Salary
              </p>
            </td>
            <td
              style={{
                width: "313pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h4 style={{ paddingLeft: "34pt", textIndent: "0pt", textAlign: "left" }}>
        GUIDELINES:
      </h4>
      <p
        className="s18"
        style={{
          paddingTop: "8pt",
          paddingLeft: "34pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        The period of evaluation shall be 1<span className="s19">st </span>
        July to 30<span className="s19">th </span>June of any year.
      </p>
      <ul id="l66">
        <li data-list-text="">
          <p
            className="s18"
            style={{
              paddingTop: "7pt",
              paddingLeft: "52pt",
              textIndent: "-18pt",
              lineHeight: "113%",
              textAlign: "left",
            }}
          >
            All the information should be provided accurately and clearly.
            Additional information worth a mention maybe provided in separate
            sheets.
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s18"
            style={{
              paddingTop: "6pt",
              paddingLeft: "52pt",
              textIndent: "-18pt",
              lineHeight: "112%",
              textAlign: "left",
            }}
          >
            The faculty must refer the Performance Appraisal Scheme document for
            more details, before filling theappraisal form.
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s18"
            style={{
              paddingTop: "6pt",
              paddingLeft: "52pt",
              textIndent: "-18pt",
              lineHeight: "112%",
              textAlign: "left",
            }}
          >
            Appraisal Committee for Faculty: Evaluating Authority (EA) is the
            HoD and the Reviewing Authority (RA)is The Head of Institution
            (Principal).
          </p>
        </li>
        <li data-list-text="">
          <p
            className="s18"
            style={{
              paddingTop: "6pt",
              paddingLeft: "52pt",
              textIndent: "-18pt",
              textAlign: "left",
            }}
          >
            The EA shall verify all the information, by supporting proofs before
            commenting on the performance.
          </p>
        </li>
      </ul>
      <p style={{ paddingTop: "4pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h4
        style={{
          paddingLeft: "170pt",
          textIndent: "-70pt",
          lineHeight: "158%",
          textAlign: "left",
        }}
      >
        CATEGORY – I : TEACHING, LEARNING &amp; EVALUATION (MINIMUM SCORE
        REQUIRED: 75)
      </h4>
      <table
        style={{ borderCollapse: "collapse", marginLeft: "17.65pt" }}
        cellSpacing={0}
      >
        <tbody>
          <tr style={{ height: "17pt" }}>
            <td
              style={{
                width: "56pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s20"
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "1pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "center",
                }}
              >
                Sr.
              </p>
            </td>
            <td
              style={{
                width: "226pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p
                className="s20"
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "18pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                APIs
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s20"
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "4pt",
                  paddingRight: "4pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "center",
                }}
              >
                API Score
              </p>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s20"
                style={{
                  paddingTop: "3pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "center",
                }}
              >
                Self
              </p>
            </td>
            <td
              style={{
                width: "79pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s20"
                style={{
                  paddingTop: "3pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "center",
                }}
              >
                Evaluation
              </p>
            </td>
          </tr>
          <tr style={{ height: "14pt" }}>
            <td
              style={{
                width: "56pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s20"
                style={{
                  paddingLeft: "1pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "center",
                }}
              >
                No.
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s20"
                style={{
                  paddingLeft: "4pt",
                  paddingRight: "4pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "center",
                }}
              >
                Allotted
              </p>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s20"
                style={{
                  paddingRight: "5pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "center",
                }}
              >
                Evaluation
              </p>
            </td>
            <td
              style={{
                width: "79pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s20"
                style={{
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "center",
                }}
              >
                by EA
              </p>
            </td>
          </tr>
          <tr style={{ height: "23pt" }}>
            <td
              style={{
                width: "56pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "1pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                1.
              </p>
            </td>
            <td
              style={{
                width: "226pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "18pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Lectures, Tutorials, Practicals conducted
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "4pt",
                  paddingRight: "4pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                50
              </p>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "79pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "28pt" }}>
            <td
              style={{
                width: "56pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "1pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                2.
              </p>
            </td>
            <td
              style={{
                width: "226pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "18pt",
                  paddingRight: "9pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                Curriculum Enrichment, additional resources
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "4pt",
                  paddingRight: "4pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                20
              </p>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "79pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "28pt" }}>
            <td
              style={{
                width: "56pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "1pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                3.
              </p>
            </td>
            <td
              style={{
                width: "226pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "18pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                Participatory &amp; Innovative Teaching- Learning
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "11pt",
                  paddingRight: "4pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                20
              </p>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "79pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "15pt" }}>
            <td
              style={{
                width: "56pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "1pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                4.
              </p>
            </td>
            <td
              style={{
                width: "226pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "18pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "left",
                }}
              >
                Methodologies
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p
                className="s22"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "4pt",
                  paddingRight: "4pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                10
              </p>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "79pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "27pt" }}>
            <td
              style={{
                width: "226pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "18pt",
                  paddingRight: "9pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                Examination Duties, Question paper setting
              </p>
            </td>
          </tr>
          <tr style={{ height: "22pt" }}>
            <td
              style={{
                width: "56pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "1pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                5.
              </p>
            </td>
            <td
              style={{
                width: "226pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "18pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Student Feedback
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "4pt",
                  paddingRight: "4pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                20
              </p>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "79pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "21pt" }}>
            <td
              style={{
                width: "56pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "1pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                6.
              </p>
            </td>
            <td
              style={{
                width: "226pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "18pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                HoD's Feedback
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "4pt",
                  paddingRight: "4pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                05
              </p>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "79pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "21pt" }}>
            <td
              style={{
                width: "56pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "226pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s20"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "18pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                TOTAL (I)
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s20"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "4pt",
                  paddingRight: "4pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                125
              </p>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "79pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p style={{ paddingTop: "2pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        className="s23"
        style={{ paddingLeft: "16pt", textIndent: "0pt", textAlign: "left" }}
      >
        (Note: Attach supporting documents to validate the claim)
      </p>
      <h4
        style={{
          paddingTop: "1pt",
          paddingLeft: "54pt",
          textIndent: "-37pt",
          lineHeight: "111%",
          textAlign: "left",
        }}
      >
        Note: *
        <span className="s4">
          Minimum 80 % compliance is mandatory for point 1, below which no
          scores may be assigned
        </span>
      </h4>
      <p
        className="s1"
        style={{
          paddingTop: "4pt",
          paddingLeft: "60pt",
          textIndent: "-31pt",
          lineHeight: "158%",
          textAlign: "left",
        }}
      >
        CATEGORY – II : CO-CURRICULAR, EXTENSION &amp; PROFESSIONAL
        DEVELOPMENT(MINIMUM SCORE REQUIRED: 15)
      </p>
      <table
        style={{ borderCollapse: "collapse", marginLeft: "17.65pt" }}
        cellSpacing={0}
      >
        <tbody>
          <tr style={{ height: "15pt" }}>
            <td
              style={{
                width: "33pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "1pt",
                  paddingRight: "9pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "center",
                }}
              >
                Sr.
              </p>
            </td>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p
                className="s24"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "80pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                APIs
              </p>
            </td>
            <td
              style={{
                width: "68pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingTop: "1pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "center",
                }}
              >
                API Score
              </p>
            </td>
            <td
              style={{
                width: "77pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingTop: "1pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "center",
                }}
              >
                Self
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingTop: "1pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "center",
                }}
              >
                Evaluation
              </p>
            </td>
          </tr>
          <tr style={{ height: "20pt" }}>
            <td
              style={{
                width: "33pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingLeft: "3pt",
                  paddingRight: "8pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                No.
              </p>
            </td>
            <td
              style={{
                width: "68pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{ textIndent: "0pt", textAlign: "center" }}
              >
                Allotted
              </p>
            </td>
            <td
              style={{
                width: "77pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{ textIndent: "0pt", textAlign: "center" }}
              >
                Evaluation
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{ textIndent: "0pt", textAlign: "center" }}
              >
                by EA
              </p>
            </td>
          </tr>
          <tr style={{ height: "20pt" }}>
            <td
              style={{
                width: "33pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={8}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "1pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                1.
              </p>
            </td>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Student Centric Activity
              </p>
            </td>
            <td
              style={{
                width: "68pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={8}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "4pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                20
              </p>
            </td>
            <td
              style={{
                width: "77pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={8}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={8}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "17pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <ul id="l67">
                <li data-list-text="•">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "5pt",
                      paddingLeft: "22pt",
                      textIndent: "-9pt",
                      lineHeight: "11pt",
                      textAlign: "left",
                    }}
                  >
                    Inter-College Level Activity/ Event Convener/
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr style={{ height: "16pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s25"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "24pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Coordinator (20) Member (5)
              </p>
            </td>
          </tr>
          <tr style={{ height: "17pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <ul id="l68">
                <li data-list-text="•">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "22pt",
                      textIndent: "-9pt",
                      lineHeight: "11pt",
                      textAlign: "left",
                    }}
                  >
                    College Level Activity/ Event Convener/
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr style={{ height: "16pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s25"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "24pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Coordinator (10) Member (2)
              </p>
            </td>
          </tr>
          <tr style={{ height: "17pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <ul id="l69">
                <li data-list-text="•">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "22pt",
                      textIndent: "-9pt",
                      lineHeight: "11pt",
                      textAlign: "left",
                    }}
                  >
                    Certificate Course/ Add on Course with
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr style={{ height: "16pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s25"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "24pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                min. 50% teaching (15) with no teaching (5)
              </p>
            </td>
          </tr>
          <tr style={{ height: "21pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <ul id="l70">
                <li data-list-text="•">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "4pt",
                      paddingLeft: "22pt",
                      textIndent: "-9pt",
                      textAlign: "left",
                    }}
                  >
                    Guest Lecture for Students (2 per lecture)
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr style={{ height: "18pt" }}>
            <td
              style={{
                width: "33pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={4}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "1pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                2.
              </p>
            </td>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "left",
                }}
              >
                Academic and Administrative Committees &amp;
              </p>
            </td>
            <td
              style={{
                width: "68pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={4}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "4pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                15
              </p>
            </td>
            <td
              style={{
                width: "77pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={4}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={4}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "15pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Responsibilities
              </p>
            </td>
          </tr>
          <tr style={{ height: "16pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <ul id="l71">
                <li data-list-text="•">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "3pt",
                      paddingLeft: "22pt",
                      textIndent: "-9pt",
                      lineHeight: "11pt",
                      textAlign: "left",
                    }}
                  >
                    College level : Incharge (10) Member (2)
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr style={{ height: "23pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <ul id="l72">
                <li data-list-text="•">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "22pt",
                      textIndent: "-9pt",
                      textAlign: "left",
                    }}
                  >
                    Department level : Incharge (5) Member (1)
                  </p>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <table
        style={{ borderCollapse: "collapse", marginLeft: "20.05pt" }}
        cellSpacing={0}
      >
        <tbody>
          <tr style={{ height: "28pt" }}>
            <td
              style={{
                width: "38pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "12pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Sr.
              </p>
            </td>
            <td
              style={{
                width: "249pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p
                className="s24"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                APIs
              </p>
            </td>
            <td
              style={{
                width: "68pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingTop: "1pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                API Score
              </p>
            </td>
            <td
              style={{
                width: "77pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingTop: "1pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                Self
              </p>
            </td>
            <td
              style={{
                width: "74pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingLeft: "33pt",
                  paddingRight: "7pt",
                  textIndent: "-20pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                Evaluatio n
              </p>
            </td>
          </tr>
          <tr style={{ height: "27pt" }}>
            <td
              style={{
                width: "38pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingLeft: "1pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                No
              </p>
              <p
                className="s24"
                style={{
                  paddingLeft: "1pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "center",
                }}
              >
                .
              </p>
            </td>
            <td
              style={{
                width: "68pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{ textIndent: "0pt", textAlign: "center" }}
              >
                Allotted
              </p>
            </td>
            <td
              style={{
                width: "77pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{ textIndent: "0pt", textAlign: "center" }}
              >
                Evaluation
              </p>
            </td>
            <td
              style={{
                width: "74pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingLeft: "22pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                by EA
              </p>
            </td>
          </tr>
          <tr style={{ height: "19pt" }}>
            <td
              style={{
                width: "38pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={5}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "1pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                3.
              </p>
            </td>
            <td
              style={{
                width: "249pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Professional Development Activities
              </p>
            </td>
            <td
              style={{
                width: "68pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={5}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "4pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                15
              </p>
            </td>
            <td
              style={{
                width: "77pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={5}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "74pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={5}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "15pt" }}>
            <td
              style={{
                width: "249pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s25"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                (attending seminars, conferences, workshops, talks,
              </p>
            </td>
          </tr>
          <tr style={{ height: "15pt" }}>
            <td
              style={{
                width: "249pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s25"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                lectures, dissemination, general articles, invited
              </p>
            </td>
          </tr>
          <tr style={{ height: "15pt" }}>
            <td
              style={{
                width: "249pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s25"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                lecture or talk, keynote talk, etc of duration less than
              </p>
            </td>
          </tr>
          <tr style={{ height: "21pt" }}>
            <td
              style={{
                width: "249pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s25"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                1 week) (3 each)
              </p>
            </td>
          </tr>
          <tr style={{ height: "25pt" }}>
            <td
              style={{
                width: "38pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "249pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s20"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                TOTAL (II)
              </p>
            </td>
            <td
              style={{
                width: "68pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s20"
                style={{
                  paddingTop: "2pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                50
              </p>
            </td>
            <td
              style={{
                width: "77pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "74pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p
        className="s4"
        style={{
          paddingTop: "4pt",
          paddingLeft: "11pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        (Note : Attach supporting documents to validate the claim)
      </p>
      <p
        className="s1"
        style={{
          paddingTop: "3pt",
          paddingLeft: "23pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        CATEGORY – III: RESEARCH &amp; DEVELOPMENT
      </p>
      <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        className="s15"
        style={{
          paddingBottom: "2pt",
          paddingLeft: "40pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        (Minimum Score Required: AP-6K (05), AP-7K (10), AP-8K (15), Assoc. Prof
        (20), Prof. (25)
      </p>
      <table
        style={{ borderCollapse: "collapse", marginLeft: "17.05pt" }}
        cellSpacing={0}
      >
        <tbody>
          <tr style={{ height: "40pt" }}>
            <td
              style={{
                width: "33pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingLeft: "12pt",
                  paddingRight: "5pt",
                  textIndent: "1pt",
                  lineHeight: "113%",
                  textAlign: "left",
                }}
              >
                Sr No
              </p>
            </td>
            <td
              style={{
                width: "334pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingTop: "9pt",
                  paddingRight: "29pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                APIs
              </p>
            </td>
            <td
              style={{
                width: "67pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingLeft: "7pt",
                  paddingRight: "6pt",
                  textIndent: "16pt",
                  lineHeight: "113%",
                  textAlign: "left",
                }}
              >
                Self Evaluation
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingLeft: "16pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Evaluation
              </p>
              <p
                className="s24"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "34pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                by EA
              </p>
            </td>
          </tr>
          <tr style={{ height: "87pt" }}>
            <td
              style={{
                width: "33pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s17"
                style={{
                  paddingTop: "1pt",
                  paddingRight: "15pt",
                  textIndent: "0pt",
                  textAlign: "right",
                }}
              >
                1.
              </p>
            </td>
            <td
              style={{
                width: "334pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Sponsored Research Projects
              </p>
              <ul id="l73">
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "3pt",
                      paddingLeft: "48pt",
                      textIndent: "-17pt",
                      textAlign: "left",
                    }}
                  >
                    30 lakhs and above ( 30 &amp; 20)
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "48pt",
                      textIndent: "-17pt",
                      textAlign: "left",
                    }}
                  >
                    05 to 30 lakhs (20 &amp; 10)
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "48pt",
                      textIndent: "-17pt",
                      textAlign: "left",
                    }}
                  >
                    01 to 05 lakhs (15 &amp; 05)
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "48pt",
                      textIndent: "-17pt",
                      textAlign: "left",
                    }}
                  >
                    Upto 01 lakh (05 &amp; 01)
                  </p>
                </li>
              </ul>
            </td>
            <td
              style={{
                width: "67pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "85pt" }}>
            <td
              style={{
                width: "33pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                style={{
                  paddingTop: "1pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
              <p
                className="s21"
                style={{
                  paddingRight: "18pt",
                  textIndent: "0pt",
                  textAlign: "right",
                }}
              >
                2.
              </p>
            </td>
            <td
              style={{
                width: "334pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                External Funding for Research Activities
              </p>
              <p
                className="s25"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                ( Conf. / WS / Seminar / STTP / FDP etc)
              </p>
              <ul id="l74">
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    05 to 10 lakhs (Maximum 10)
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    01 to 05 lakhs (Maximum 05)
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    0.5 lakh to 01 lakhs (01)
                  </p>
                </li>
              </ul>
            </td>
            <td
              style={{
                width: "67pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "92pt" }}>
            <td
              style={{
                width: "33pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "3pt",
                  paddingRight: "15pt",
                  textIndent: "0pt",
                  textAlign: "right",
                }}
              >
                3.
              </p>
            </td>
            <td
              style={{
                width: "334pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Publications
              </p>
              <ul id="l75">
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    Journal (International - 15 &amp; National- 10)
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    International Conf. Proceedings (full paper with ISBN No.)
                    (10 &amp; 5)
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    National Conf. Proceedings (full paper without ISBN No.) (5
                    &amp; 3)
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    Technical Articles (3)
                  </p>
                </li>
              </ul>
            </td>
            <td
              style={{
                width: "67pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "88pt" }}>
            <td
              style={{
                width: "33pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "3pt",
                  paddingRight: "15pt",
                  textIndent: "0pt",
                  textAlign: "right",
                }}
              >
                4.
              </p>
            </td>
            <td
              style={{
                width: "334pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Invited lectures / Paper Presentation
              </p>
              <ul id="l76">
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    International Conf. (7 per lecture / 5 per paper presented)
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    National Conf. (5 per lecture / 3 per paper presented)
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    State / University level (3 per lecture / 2 per paper
                    presented)
                  </p>
                </li>
              </ul>
            </td>
            <td
              style={{
                width: "67pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "78pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <table
        style={{ borderCollapse: "collapse", marginLeft: "18.13pt" }}
        cellSpacing={0}
      >
        <tbody>
          <tr style={{ height: "38pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingLeft: "15pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "left",
                }}
              >
                Sr.
              </p>
              <p
                className="s24"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                No.
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingTop: "9pt",
                  paddingRight: "53pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                APIs
              </p>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingLeft: "5pt",
                  paddingRight: "12pt",
                  textIndent: "0pt",
                  lineHeight: "114%",
                  textAlign: "left",
                }}
              >
                Self Evaluation
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingLeft: "24pt",
                  paddingRight: "8pt",
                  textIndent: "-11pt",
                  lineHeight: "114%",
                  textAlign: "left",
                }}
              >
                Evaluation by EA
              </p>
            </td>
          </tr>
          <tr style={{ height: "43pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{ textIndent: "0pt", textAlign: "center" }}
              >
                5.
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Student Publications (15 max)
              </p>
              <ul id="l77">
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    Journal (full paper) : 5/ publication
                  </p>
                </li>
              </ul>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "68pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                style={{
                  paddingTop: "10pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
              <p
                className="s21"
                style={{ textIndent: "0pt", textAlign: "center" }}
              >
                6.
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                style={{
                  paddingTop: "1pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
              <p
                className="s21"
                style={{
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Books and Chapters in Books
              </p>
              <ul id="l78">
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "26pt",
                      paddingRight: "23pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    International Publisher : 30 per book &amp; 10/ chapter for
                    single author
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      lineHeight: "12pt",
                      textAlign: "left",
                    }}
                  >
                    National Publisher : 20 per book &amp; 05/ chapter for
                    single author
                  </p>
                </li>
              </ul>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "39pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "11pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                7.
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Patent
              </p>
              <ul id="l79">
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    Overseas (50) Indian (25)
                  </p>
                </li>
              </ul>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "40pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "10pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                8.
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Industrial Training / Online courses (Max: 15 points)
              </p>
              <ul id="l80">
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    More than 2 weeks (10) , Up to 2 weeks (05)
                  </p>
                </li>
              </ul>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "66pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "9pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                9.
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Courses/ Programs Attended (Max: 10 points)
              </p>
              <ul id="l81">
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-17pt",
                      textAlign: "left",
                    }}
                  >
                    International (Overseas) (20)
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-17pt",
                      textAlign: "left",
                    }}
                  >
                    International (India) (10)
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-17pt",
                      textAlign: "left",
                    }}
                  >
                    National/ State/ University (5)
                  </p>
                </li>
              </ul>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "28pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "18pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                10.
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Courses/ Programs Organized for Faculty (Max: 10 points)
              </p>
              <ul id="l82">
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      lineHeight: "12pt",
                      textAlign: "left",
                    }}
                  >
                    International (10) National / State / University (5)
                  </p>
                </li>
              </ul>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "53pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "9pt",
                  paddingLeft: "14pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                11.
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Research Guidance
              </p>
              <ul id="l83">
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "49pt",
                      textIndent: "-17pt",
                      textAlign: "left",
                    }}
                  >
                    Ph.D. (20 &amp; 10)
                  </p>
                </li>
              </ul>
              <ul id="l84">
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    M.Phil &amp; Masters by Research (5)
                  </p>
                </li>
              </ul>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "39pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "9pt",
                  paddingLeft: "14pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                12.
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                Research Evaluation and Review (Max: 10 points)
              </p>
              <ul id="l85">
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    Ph.D. (5), M.E./M.Tech (3) Journal / Conf. (2)
                  </p>
                </li>
              </ul>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "39pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "9pt",
                  paddingLeft: "14pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                13.
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                Collaborative Research
              </p>
              <ul id="l86">
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    International (10) National (5)
                  </p>
                </li>
              </ul>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "30pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "14pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                14.
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                Product / Process Development (10 / 05)
              </p>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "29pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "14pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                15.
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                Collaborations / Donations / Lab. Dev. ( 03 each)
              </p>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "42pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s17"
                style={{
                  paddingLeft: "14pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                16.
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Consultancy Projects:
              </p>
              <ul id="l87">
                <li data-list-text="">
                  <p
                    className="s25"
                    style={{
                      paddingLeft: "24pt",
                      paddingRight: "102pt",
                      textIndent: "-11pt",
                      lineHeight: "13pt",
                      textAlign: "left",
                    }}
                  >
                    <span style={{ color: "#000" }}>
                      Amount mobilized with a minimum of Rs.10 lakhs.
                    </span>
                    (10 for every Rs.10 lakhs)
                  </p>
                </li>
              </ul>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "16pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingLeft: "14pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                17.
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s17"
                style={{
                  paddingLeft: "11pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                MOU / T &amp; P Activities ( 03 each)
              </p>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "101pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s17"
                style={{
                  paddingLeft: "12pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                18.
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s17"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Fellowships / Awards<span className="s27">:</span>
              </p>
              <ul id="l88">
                <li data-list-text="">
                  <p
                    className="s26"
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "26pt",
                      paddingRight: "82pt",
                      textIndent: "-13pt",
                      lineHeight: "113%",
                      textAlign: "left",
                    }}
                  >
                    International Award / Fellowship from academic bodies(15 per
                    Award / 15 per Fellowship).
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s26"
                    style={{
                      paddingLeft: "26pt",
                      paddingRight: "102pt",
                      textIndent: "-13pt",
                      lineHeight: "112%",
                      textAlign: "left",
                    }}
                  >
                    National Award / Fellowship from academic bodies(10 per
                    Award / 10 per Fellowship).
                  </p>
                </li>
                <li data-list-text="">
                  <p
                    className="s26"
                    style={{
                      paddingLeft: "26pt",
                      textIndent: "-13pt",
                      textAlign: "left",
                    }}
                  >
                    State / University level Award from academic bodies
                  </p>
                </li>
              </ul>
              <p
                className="s26"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "26pt",
                  textIndent: "0pt",
                  lineHeight: "11pt",
                  textAlign: "left",
                }}
              >
                (05 per Award).
              </p>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "16pt" }}>
            <td
              style={{
                width: "50pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "317pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "13pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                TOTAL (III)
              </p>
            </td>
            <td
              style={{
                width: "70pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "73pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p
        className="s1"
        style={{
          paddingTop: "4pt",
          paddingLeft: "50pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        EVALUATION SUMMARY:
      </p>
      <p style={{ paddingTop: "8pt", textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <table
        style={{ borderCollapse: "collapse", marginLeft: "48.234pt" }}
        cellSpacing={0}
      >
        <tbody>
          <tr style={{ height: "38pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                style={{
                  paddingTop: "1pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                <br />
              </p>
              <p
                className="s24"
                style={{
                  paddingLeft: "77pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                APIs
              </p>
            </td>
            <td
              style={{
                width: "82pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingLeft: "17pt",
                  paddingRight: "16pt",
                  textIndent: "0pt",
                  lineHeight: "114%",
                  textAlign: "left",
                }}
              >
                Minimum Required
              </p>
            </td>
            <td
              style={{
                width: "71pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingTop: "5pt",
                  paddingLeft: "12pt",
                  paddingRight: "6pt",
                  textIndent: "22pt",
                  lineHeight: "114%",
                  textAlign: "left",
                }}
              >
                Self Evaluation
              </p>
            </td>
            <td
              style={{
                width: "69pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s24"
                style={{
                  paddingLeft: "11pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "left",
                }}
              >
                Evaluation
              </p>
              <p
                className="s24"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "29pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                by EA
              </p>
            </td>
          </tr>
          <tr style={{ height: "16pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "2pt",
                  paddingLeft: "12pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "left",
                }}
              >
                Category – I :
              </p>
            </td>
            <td
              style={{
                width: "82pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "9pt",
                  paddingRight: "4pt",
                  textIndent: "0pt",
                  textAlign: "center",
                }}
              >
                75
              </p>
            </td>
            <td
              style={{
                width: "71pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "69pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "19pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s25"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "12pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Teaching, Learning &amp; Evaluation
              </p>
            </td>
          </tr>
          <tr style={{ height: "17pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "12pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "left",
                }}
              >
                Category – II :
              </p>
            </td>
            <td
              style={{
                width: "82pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
              <p
                className="s21"
                style={{
                  paddingLeft: "21pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                15
              </p>
            </td>
            <td
              style={{
                width: "71pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "69pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "19pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s25"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "12pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Co-Curricular, Extension &amp; Professional Development
              </p>
            </td>
          </tr>
          <tr style={{ height: "17pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s21"
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "12pt",
                  textIndent: "0pt",
                  lineHeight: "12pt",
                  textAlign: "left",
                }}
              >
                Category – III :
              </p>
            </td>
            <td
              style={{
                width: "82pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "71pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "69pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
              rowSpan={2}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
          <tr style={{ height: "18pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s25"
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "12pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Research and Development
              </p>
            </td>
          </tr>
          <tr style={{ height: "18pt" }}>
            <td
              style={{
                width: "247pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p
                className="s28"
                style={{
                  paddingTop: "3pt",
                  paddingRight: "42pt",
                  textIndent: "0pt",
                  lineHeight: "13pt",
                  textAlign: "right",
                }}
              >
                Total
              </p>
            </td>
            <td
              style={{
                width: "82pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "71pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
            <td
              style={{
                width: "69pt",
                borderTopStyle: "solid",
                borderTopWidth: "1pt",
                borderLeftStyle: "solid",
                borderLeftWidth: "1pt",
                borderBottomStyle: "solid",
                borderBottomWidth: "1pt",
                borderRightStyle: "solid",
                borderRightWidth: "1pt",
              }}
            >
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p
        className="s23"
        style={{
          paddingTop: "13pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        (Note: Attach supporting documents to validate the claim)
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
      <p
        className="s29"
        style={{ paddingLeft: "162pt", textIndent: "0pt", textAlign: "left" }}
      >
        ADDITIONAL COMMENTS (if any) by THE FACULTY
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
      <p className="s30" style={{ textIndent: "0pt", textAlign: "right" }}>
        Date: Signature of Faculty
      </p>
      <p
        className="s31"
        style={{ paddingLeft: "31pt", textIndent: "0pt", textAlign: "left" }}
      >
        HOD (EA) PRINCIPAL
      </p>
      <p className="s31" style={{ textIndent: "0pt", textAlign: "right" }}>
        Recommended by approved by
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        className="s32"
        style={{
          paddingLeft: "41pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        47
      </p>
    </div>
  );
};

export default ServiceRules;
