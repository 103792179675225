import React from "react";
import strength from "../../assets/strength.jpg";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import streangths from "../../assets/streangths.jpg";
import "./Strength.css";

const Strength = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            STRENGTHS
          </a>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "100px" }}
            >
              <div className="h-100 d-flex flex-column justify-content-start">
                {/* <div>
                  <img
                    className="img-fluid rounded w-40 h-40"
                    src={streangths}
                    alt="principal-img"
                  />
                </div> */}
                <div className="streangth_left_cnt">
                  <p className="m-4">
                    The college has inherent strength in providing excellent
                    state of-art infrastructure facilities in terms
                    buildings,Labs,staff for all branches of engineering. The
                    teaching staff is highly motivated, conduct classes as per
                    schedule and complete the syllabus in time to enable the
                    students to prepare for the examination. Communication
                    skills are also imparted to the students so that they can
                    attend interviews confidently for placements.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                Description
              </h6>

              <div className="row gy-2 gx-4 mb-4">
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>
                    Only College in Telangana(Except Greater Hyderabad),
                    Accredited by National Assessment and Accreditation Council
                    (NAAC) of UGC as ‘B’ Grade.
                  </p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>
                    Approved by All India Council for Technical Education
                    (AICTE), New Delhi.
                  </p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>ISO 9001 - 2008 Certified Institution.</p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>Affiliated to JNTU Hyderabad,Telangana.</p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p> The faculty student ratio of 1:15</p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>
                    A mentor is allotted for each 20 students to counsel the
                    student in their academic as well as personal problems.
                  </p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>
                    SSIT has a separate Communication & Soft Skills (CSS) dept.
                    with professional trainers.
                  </p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>33 academic laboratories, 3 research laboratories.</p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>
                    44 Mbps hi-speed internet connectivity round the clock in
                    the campus and in the hostels.
                  </p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>
                    Discipline is the hallmark with 100% ragging free
                    environment.
                  </p>
                </div>
                
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>E-Learning mode of teaching is practiced in the campus.</p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>Yoga and meditation classes for the students.</p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>
                    Excellent facilities with international standards for all
                    kinds of indoor & outdoor games & sports with coaches for
                    all major games & sports.
                  </p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>Facilities like PCO/STD/ISD, Fax, and Post Office.</p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>ATM facility from AXIS bank with in the campus.</p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>A guest house in campus.</p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>Research Oriented Problem Solving Techniques.</p>
                </div>
                <div className="mb-0 d-flex align-items-start gap-3">
                  <StarHalfIcon sx={{ marginTop: "3px" }} />
                  <p>
                    100% Placements for registered eligible students during past
                    4 years.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Strength;
