import React from "react";
// import "./AcadamicCalender.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AdministrationMembers = () => {
  function createData(MemberName, Position) {
    return { MemberName, Position };
  }

  const rows = [
    createData("member", 159),
    createData("member", 237),
    createData("member", 262),
    createData("member", 305),
    createData("member", 356),
  ];
  return (
    <div>
      <h1 className="adminstration_heading">ACADEMIC COUNCIL</h1>
      <TableContainer className="adminstration_table" component={Paper}>
        <Table sx={{ maxWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell className="adminstration_table_cell">
                S No
              </StyledTableCell>
              <StyledTableCell className="adminstration_table_cell">
                Member Name
              </StyledTableCell>
              <StyledTableCell className="adminstration_table_cell">
                Position
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow
                className="adminstration_table_row"
                key={row.name}
              >
                <StyledTableCell
                  className="adminstration_table_cell"
                  component="th"
                  scope="row"
                >
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell className="adminstration_table_cell">
                  {row.MemberName}
                </StyledTableCell>
                <StyledTableCell className="adminstration_table_cell">
                  {row.Position}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AdministrationMembers;
