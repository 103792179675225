import {React,useState} from "react";
import "./Alumini.css";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import RotatingSpinner from ".././Resourses/RotatingSpinner";

const Registration = () => {
  const [isLoading , setIsLoading] = useState(false);
  const [registrationDataToBackend,setRegistrationDataToBackend] = useState({
    aluminiName: "",
    gender: "",
    countryName: "",
    personalState: "",
    personalCity: "",
    personalEmail: "",
    personalPhone: "",
    educationalDegree: "",
    educationalBranch: "",
    passedout: "",
    htno: "",
    employementType: "",
    presentempName: "",
    employementDesignation: "",
    employementCountryName: "",
    employementState: "",
    employementCity: "",
    specialAchievements: "",
    presentStatus: "",
    university: "",
    higerCountry: "",
    higherState: "",
    higherCity: ""
  })
  const degree = ["B.Tech", "Polytechnic", "MCA", "M.Tech", "MBA", "Others"];
  const employmentype = [
    "salaried",
    "self-employed",
    "freelancer",
    "Entrepreneur",
    "House Maker",
    "others",
  ];
  
  const Branch = [
    "EC",
    "CSE",
    "ECE",
    "CE",
    "IT",
    "AI&DS",
    "CSE(AL&ML)",
    "EEE",
    "MECH",
    "S&H",
    "MBBS",
    "OTHERS",
  ];

  const presentStatus = ["Pursuing", "Completed"];

  const currentYear = new Date();
  const year = [];
  for (
    let j = currentYear.getFullYear() - 5;
    j < currentYear.getFullYear();
    j++
  ) {
    year.push(j);
  }
  for (
    let i = currentYear.getFullYear();
    i <= currentYear.getFullYear() + 5;
    i++
  ) {
    year.push(i);
  }
  const handleChangeAluminiForm = (e) => {
    setRegistrationDataToBackend(
      {...registrationDataToBackend,
      [e.target.name] : e.target.value}
    )
  }
  const handleSubmitAluminiForm = async () => {
    setIsLoading(true);
      try {
        const response = await axios.post(
          resources.APPLICATION_URL + "savePersonalInfo",
        registrationDataToBackend)
        if(response.data){
          alert("successfully sudmitted")
          setRegistrationDataToBackend({
            aluminiName: "",
            gender: "",
            countryName: "",
            state: "",
            personalCity: "",
            personalEmail: "",
            personalPhone: "",
            degree: "",
            branch: "",
            passedout: "",
            htno: "",
            employementType: "",
            presentempName: "",
            employementDesignation: "",
            employementCountryName: "",
            employementState: "",
            city: "",
            specialAchievements: "",
            presentStatus: "",
            university: "",
            country: "",
            higherState: "",
            higherCity: ""
          })
          
        } else{
          alert("Something went wrong please try again after some time.")
        }
      }catch(e){
        console.log(e)
      }finally{
        setIsLoading(false);
      }
      
    
  }

  return (
   <>
   {isLoading ? <RotatingSpinner/> : ( <div style={{padding:'30px'}}>
      <form className="registartion_form_heading_container">
        <h1 className="registartion_form_heading">ALUMINI REGISTRATION FORM</h1>
        <h1 className="registration_form_personalInfo">Personal Information</h1>
        <div className="registration_form_main_container">
          <div className="registration_form_container">
            <label>Alumini Name</label>
            <input
              className="registration_form_input_ele"
              type="text"
              placeholder="Alumni Name"
              id="alumniName"
              name="aluminiName"
              value={registrationDataToBackend.aluminiName}
              onChange={(e) =>handleChangeAluminiForm(e)}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "250px",
            }}
          >
            <label>Gender</label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <label style={{ padding: "10px" }}>Male</label>
              <input
                name="gender"
                className="registration_form_input_ele"
                type="radio"
                id="male"
                value="male"
                onChange={(e) =>handleChangeAluminiForm(e)}
              />
              <label style={{ padding: "10px" }}>Female</label>
              <input
                name="gender"
                className="registration_form_input_ele"
                type="radio"
                id="female"
                value="female"
                onChange={(e) =>handleChangeAluminiForm(e)}
              />
              <label style={{ padding: "10px" }}>Others</label>
              <input
                name="gender"
                className="registration_form_input_ele"
                type="radio"
                id="other"
                value="other"
                onChange={(e) =>handleChangeAluminiForm(e)}
              />
            </div>
          </div>
          <div className="registration_form_container">
            <label>Country Name</label>
            <input
              className="registration_form_input_ele"
              type="text"
              placeholder="Country Name"
              id="countryName"
              name="countryName"
              value={registrationDataToBackend.countryName}
              onChange={(e) =>handleChangeAluminiForm(e)}
            />
          </div>
          <div className="registration_form_container">
            <label>State</label>
            <input
              className="registration_form_input_ele"
              type="text"
              placeholder="state"
              id="personalState"
              name="personalState"
              value={registrationDataToBackend.personalState}
              onChange={(e) =>handleChangeAluminiForm(e)}

            />
          </div>
          <div className="registration_form_container">
            <label>City</label>
            <input
              className="registration_form_input_ele"
              type="text"
              placeholder="City"
              id="personalCity"
              name="personalCity"
              value={registrationDataToBackend.personalCity}
              onChange={(e) =>handleChangeAluminiForm(e)}
            />
          </div>
          <div className="registration_form_container">
            <label>Email</label>
            <input
              className="registration_form_input_ele"
              type="email"
              placeholder="email"
              id="personalEmail"
              name="personalEmail"
              value={registrationDataToBackend.personalEmail}
              onChange={(e) =>handleChangeAluminiForm(e)}
            />
          </div>
          <div className="registration_form_container">
            <label>phone</label>
            <input
              className="registration_form_input_ele"
              type="number"
              placeholder="number"
              id="personalPhone"
              name="personalPhone"
              value={registrationDataToBackend.personalPhone}
              onChange={(e) =>handleChangeAluminiForm(e)}
            />
          </div>
        </div>
      </form>

      <form className="registartion_form_heading_container">
        <h1 className="registration_form_personalInfo">
          Education Details @ Sai Spurthi
        </h1>
        <div className="registration_form_main_container">
          <div className="registration_form_container">
            <label>Degree</label>
            <select
              className="registration_form_input_ele"
              style={{ width: "200px" }}
              name="educationalDegree"
              id="educationalDegree"
              value={registrationDataToBackend.educationalDegree}
              onChange={(e) =>handleChangeAluminiForm(e)}
            >
              <option value="">Please select</option>
              {degree &&
                degree
                  .filter((each) => each !== "")
                  .map((each, index) => <option key={index}>{each}</option>)}
            </select>
          </div>

          <div className="registration_form_container">
            <label>Branch</label>
            <select
              className="registration_form_input_ele"
              style={{ width: "200px" }}
              id="educationalBranch"
              name="educationalBranch"
              value={registrationDataToBackend.educationalBranch}
              onChange={(e) =>handleChangeAluminiForm(e)}
            >
              <option value="">Please select</option>
              {Branch &&
                Branch.filter((each) => each !== "").map((each, index) => (
                  <option key={index}>{each}</option>
                ))}
            </select>
          </div>
          <div className="registration_form_container">
            <label>Year Of Passed</label>
            <select
              className="registration_form_input_ele"
              style={{ width: "200px" }}
              id="passedout"
              name="passedout"
              value={registrationDataToBackend.passedout}
              onChange={(e) =>handleChangeAluminiForm(e)}
            >
              <option value="">Please Select</option>
              {year &&
                year
                  .filter((each) => each !== "")
                  .map((each) => <option>{each}</option>)}
            </select>
          </div>
          <div className="registration_form_container">
            <label>HTNO</label>
            <input
              className="registration_form_input_ele"
              style={{ width: "200px" }}
              type="text"
              placeholder="HTNO"
              id="HTNO"
              value={registrationDataToBackend.htno}
              onChange={(e) =>handleChangeAluminiForm(e)}
              name="htno"
            />
          </div>
        </div>
      </form>

      <form className="registartion_form_heading_container">
        <h1 className="registration_form_personalInfo">Employment Details</h1>
        <div className="registration_form_main_container">
          <div className="registration_form_container">
            <label>Employment Type</label>
            <select
              className="registration_form_input_ele"
              style={{ width: "200px" }}
              id="employementType"
              name="employementType"
              value={registrationDataToBackend.employementType}
              onChange={(e) =>handleChangeAluminiForm(e)}
            >
              <option value="">Please select</option>
              {employmentype &&
                employmentype
                  .filter((each) => each !== "")
                  .map((each, index) => <option key={index}>{each}</option>)}
            </select>
          </div>

          <div className="registration_form_container">
            <label>Present Employer Name</label>
            <input
              className="registration_form_input_ele"
              style={{ width: "200px" }}
              type="text"
              placeholder="EmployerName"
              id="presentempName"
              name="presentempName"
              value={registrationDataToBackend.presentempName}
              onChange={(e) =>handleChangeAluminiForm(e)}
            />
          </div>

          <div className="registration_form_container">
            <label>Designation</label>
            <input
              className="registration_form_input_ele"
              style={{ width: "200px" }}
              type="text"
              placeholder="Designation"
              id="Designation"
              name="employementDesignation"
              value={registrationDataToBackend.employementDesignation}
              onChange={(e) =>handleChangeAluminiForm(e)}
            />
          </div>

          <div className="registration_form_container">
            <label>Country Name</label>
            <input
              className="registration_form_input_ele"
              type="text"
              placeholder="Country Name"
              id="countryName1"
              name="employementCountryName"
              value={registrationDataToBackend.employementCountryName}
              onChange={(e) =>handleChangeAluminiForm(e)}
            />
          </div>
          <div className="registration_form_container">
            <label>State</label>
            <input
              className="registration_form_input_ele"
              type="text"
              placeholder="State"
              id="employementState"
              name="employementState"
              value={registrationDataToBackend.employementState}
              onChange={(e) =>handleChangeAluminiForm(e)}
            />
          </div>
          <div className="registration_form_container">
            <label>City</label>
            <input
              className="registration_form_input_ele"
              type="text"
              placeholder="City"
              id="employementCity"
              name="employementCity"
              value={registrationDataToBackend.employementCity}
              onChange={(e) =>handleChangeAluminiForm(e)}
            />
          </div>
        </div>
      </form>


      <form className="registartion_form_heading_container">
        <h1 className="registration_form_personalInfo">Special Achievements</h1>
        <div className="registration_form_main_container">
          <div className="registration_form_container">
            <label>Special Achievements After Graduation</label>
            <input
              className="registration_form_input_ele"
              style={{ width: "200px" }}
              type="text"
              placeholder="Achievements"
              id="achievements"
              name="specialAchievements"
              value={registrationDataToBackend.specialAchievements}
              onChange={(e) =>handleChangeAluminiForm(e)}
            />
          </div>
        </div>
      </form>

      <form className="registartion_form_heading_container">
        <div className="registartion_form_heading_container">
          <h1 className="registration_form_personalInfo">
            Higher Studies Details (If Any)
          </h1>
          <div className="registration_form_main_container">
            <div className="registration_form_container">
              <label>Present Status</label>
              <select
                className="registration_form_input_ele"
                style={{ width: "200px" }}
                name="presentStatus"
                id="presentStatus"
                value={registrationDataToBackend.presentStatus}
                onChange={(e) =>handleChangeAluminiForm(e)}
              >
                <option value="">Please select</option>
                {presentStatus &&
                  presentStatus
                    .filter((each) => each !== "")
                    .map((each, index) => <option key={index}>{each}</option>)}
              </select>
            </div>

            <div className="registration_form_container">
              <label>University</label>
              <input className="registration_form_input_ele" type="text" placeholder="University" id="university" 
              name="university" value={registrationDataToBackend.university} onChange={(e) =>handleChangeAluminiForm(e)} />
            </div>
            <div className="registration_form_container">
              <label>Country</label>
              <input className="registration_form_input_ele"
              name="higerCountry" value={registrationDataToBackend.higerCountry}
              onChange={(e) =>handleChangeAluminiForm(e)}
              type="text" placeholder="Country" id="higerCountry" />
            </div>
            <div className="registration_form_container">
              <label>State</label>
              <input
                className="registration_form_input_ele"
                style={{ width: "200px" }}
                type="text"
                placeholder="State"
                id="higherState"
                name="higherState"
                value={registrationDataToBackend.higherState}
                onChange={(e) =>handleChangeAluminiForm(e)}
              />
            </div>
            <div className="registration_form_container">
              <label>City</label>
              <input
                className="registration_form_input_ele"
                style={{ width: "200px" }}
                type="text"
                placeholder="City"
                id="City"
                name="higherCity"
                value={registrationDataToBackend.higherCity}
                onChange={(e) =>handleChangeAluminiForm(e)}
                
              />
            </div>
          </div>
        </div>
      </form>
      <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',margin:'30px'}}>
        <button onClick={handleSubmitAluminiForm} className="registration_form_btn" type="button">Submit</button>
      </div>
    </div>)}
    </>
  );
};

export default Registration;
