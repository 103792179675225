import React from "react";
import pdf from "../../assets/pdfsmittapalli/Course-File.pdf";
import shadows from "@mui/material/styles/shadows";
import "../Academics/Academic.css";

const CourseFile = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            COURSE FILE PREPARATION
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="container_head">
          <p style={{ textAlign: "justify" , backgroundColor:"lightpink"}}>
            The faculty to maintain course files for every course taught. At the
            end of each semester the faculty should submit their course files to
            the Program Coordinator / Head of the Department. The Program
            Coordinator / Head of the Department should check the course files
            and make sure that they are up to date.
          </p>
          <h6>The course file should contain the following :</h6>
          <p>1.Time Table.</p>
          <p>2.Course Syllabus .</p>
          <p>3.Course Handout.</p>
          <p>4.Attendance record month wise .</p>
          <p>5.Previous years question papers</p>
          <p>6.Curriculum gaps</p>
          <p>7.Model question papers</p>
          <p>8.Assignment questions</p>
          <div className="col-2">
              <button type="button" className="btn_file">
                <a href={pdf} target="blank">
                 Course File Format{" "}
                </a>
              </button>
            </div>
        </div>
      </div>
      
    </div>
  );
};

export default CourseFile;
