import React from "react";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import RotatingSpinner from "../../Resourses/RotatingSpinner";
import { resources } from "../../Resourses/Resources";

const successMessage = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "success1",
    className: "success",
  });
};
const errorMessage = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "error1",
    className: "error",
  });
};

const StudentForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [aicteStudent, setAicteStudent] = useState({
    studentId: "",
    nameOfTheStudent: "",
    studentGender: "",
    studentPrimaryEmailAddress: "",
    studentAdharNumber: "",
    sudentContact: "",
    studentInstituteState: "",
    studentAictePerminentId: "",
    studentInstituteName: "",
    studentInstituteAddress: "",
  });

  const handleAicteStudentDetails = (e) => {
    const { value, name } = e.target;
    setAicteStudent({ ...aicteStudent, [name]: value });
  };

  const handleSubmitDataToBackend = async () => {
    console.log(JSON.stringify(aicteStudent));
    try {
      setIsLoading(true);
      const res = await axios.post(
        resources.APPLICATION_URL + "saveAicteStudentFeedBackDetails",
        aicteStudent
      )
      if(res.data){
        successMessage("Data Submitted Successfully");
        setAicteStudent({
          studentId: "",
          nameOfTheStudent: "",
          studentGender: "",
          studentPrimaryEmailAddress: "",
          studentAdharNumber: "",
          sudentContact: "",
          studentInstituteState: "",
          studentAictePerminentId: "",
          studentInstituteName: "",
          studentInstituteAddress: "",
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h1 className="student_faculty_form_heading">Student Form</h1>
      <form className="student_faculty_form_main_container">
        <div className="student_faculty_form_sub_container">
          <div className="student_faculty_form_container">
            <label htmlFor="Name">Student Id:</label>
            <input
              onChange={(e) => handleAicteStudentDetails(e)}
              type="text"
              placeholder="Student Id"
              id="studentId"
              name="studentId"
              required
            />
          </div>
          <div className="student_faculty_form_container">
            <label htmlFor="nameOfTheStudent">Name Of The Student:</label>
            <input
              onChange={(e) => handleAicteStudentDetails(e)}
              type="text"
              name="nameOfTheStudent"
              placeholder="Name Of The Student"
              id="nameOfTheStudent"
              required
            />
          </div>
          <div className="student_faculty_form_container">
            <label>Gender</label>
            <div style={{ display: "flex" }}>
              <span className="span_ele">male</span>
              <input
                onChange={(e) => handleAicteStudentDetails(e)}
                type="radio"
                value="male"
                id="male"
                name="studentGender"
              />
              <span className="span_ele">Female</span>
              <input
                onChange={(e) => handleAicteStudentDetails(e)}
                type="radio"
                id="female"
                value="female"
                name="studentGender"
              />
              <span className="span_ele">Other</span>
              <input
                onChange={(e) => handleAicteStudentDetails(e)}
                type="radio"
                id="other"
                value="other"
                name="studentGender"
              />
            </div>
          </div>
          <div className="student_faculty_form_container">
            <label htmlFor="Email">Primary Email Address:</label>
            <input
              onChange={(e) => handleAicteStudentDetails(e)}
              type="email"
              placeholder="Email"
              id="studentPrimaryEmailAddress"
              name="studentPrimaryEmailAddress"
              required
            />
          </div>
          <div className="student_faculty_form_container">
            <label htmlFor="AadhaarNo">Aadhaar No</label>
            <input
              onChange={(e) => handleAicteStudentDetails(e)}
              type="number"
              placeholder="Aadhaar No"
              id="AadhaarNo"
              name="studentAdharNumber"
            />
          </div>
          <div className="student_faculty_form_container">
            <label htmlFor="Contact">Contact</label>
            <input
              onChange={(e) => handleAicteStudentDetails(e)}
              type="number"
              placeholder="Contact"
              id="Contact"
              name="sudentContact"
              required
            />
          </div>
          <div className="student_faculty_form_container">
            <label htmlFor="Email">Institute State:</label>
            <input
              onChange={(e) => handleAicteStudentDetails(e)}
              name="studentInstituteState"
              type="text"
              placeholder="Institute State"
              id="InstituteState"
              required
            />
          </div>
        </div>
      </form>

      <form className="student_faculty_form_main_container">
        <div className="student_faculty_form_sub_container">
          <div className="student_faculty_form_container">
            <label htmlFor="Name">AICTE Permanent Id:</label>
            <textarea
              onChange={(e) => handleAicteStudentDetails(e)}
              name="studentAictePerminentId"
              cols={40}
              rows={5}
              type="text"
              placeholder="Student Id"
              id="AICTEPermanentId:"
              required
            />
          </div>
          <div className="student_faculty_form_container">
            <label htmlFor="nameOfTheStudent">Institute Name:</label>
            <textarea
              onChange={(e) => handleAicteStudentDetails(e)}
              name="studentInstituteName"
              cols={40}
              rows={5}
              type="text"
              placeholder="Institute Name"
              id="InstituteName"
              required
            />
          </div>

          <div className="student_faculty_form_container">
            <label htmlFor="AadhaarNo">Institute Address</label>
            <textarea
              onChange={(e) => handleAicteStudentDetails(e)}
              name="studentInstituteAddress"
              cols={40}
              rows={5}
              type="text"
              placeholder="Institute Address"
              id="InstituteAddress"
            />
          </div>
        </div>
      </form>
      <div
        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
      >
        <button
          onClick={() => handleSubmitDataToBackend()}
          type="button"
          className="aicte_submit_btn"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default StudentForm;
