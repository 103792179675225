import React from "react";
import Chairman1 from "../assets/Secretarymi.jpg";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import "./CheairmanText.css";

const SecretaryMessage = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              // style={{ minHeight: "100px" }}
            >
              <div className="frame" style={{textAlign:"center"}}>
                <img
                  className="DM_img"
                  src={Chairman1}
                  alt="principal-img"
                  
                /><br/><br/>
                <p style={{textAlign:"center"}}>
                  <h4><b>Sri M.B.V. Satyanarayana</b></h4>
                  <h6><b>Secretary</b></h6>
                  </p>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h4 className="section-title bg-white text-start text-primary pe-3">
                Secretary Message
              </h4><br/>
              <p style={{textAlign:"justify"}}>
                <p className=" font_st mb-2">
                  Sri Mittapalli College of Engineering was born out of the
                  vision of founder Sri. Mittapalli Koteswararao in 2006. We
                  provide quality technical education for rural students in the
                  Guntur district and are renowned for academic excellence and
                  sustain with the slogan “Quality is our Motto”.
                </p>

                <p className=" font_st mb-2">
                  SMCE boasts of world-class infrastructure including digital
                  libraries, technical labs, language labs and excellent
                  facilities for sports. Faculty and students are encouraged to
                  take part in research activities resulting in much number of
                  publications in prestigious journals with good impact factors.
                  it provides a dais every year to conduct a range of events
                  from social, cultural, and technical to bring awareness in
                  order to impart life skills, competencies, right attitude and
                  attributes which are greatly desirable in today’s global
                  environment.
                </p>

                <p className=" font_st mb-2">
                  SMCE focuses special attention on communication skills in
                  English, soft skills, Aptitude, Reasoning and technical
                  programs since the first year of technical graduation as these
                  are required skills for the present competitive world.
                </p>
                <p className=" font_st mb-1">
                  Sri Mittapalli Group of institutions nurtures students through
                  full-fledged value education classes and regular counselling
                  sessions. Industry-Academia network comprises interaction with
                  corporate managers and guest lectures by visiting professors
                  to facilitate our esteemed campus to corporate transition for
                  enhancement and employability of quotient of the technocrats.<br></br>
                  I warmly welcome you all 
                </p>
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecretaryMessage;
