import React, { useState } from "react";
import Chairman1 from "../assets/infra.jpg";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";

const Infrastructure = () => {
  const [expanded, setExpanded] = useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "100px" }}
            >
              <div className="frame">
                <img
                  className="DM_img"
                  src={Chairman1}
                  alt="principal-img"
                  style={{ objectFit: "cover" }}
                />
                {/* <div className="cm">
                  <h3>Sri M.Kishore Kumar, M.Pharmacy</h3>
                  <h3>Vice President</h3>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              {/* <h4 className="section-title bg-white text-start text-primary pe-3">
                 Vice President
              </h4> */}
              {/* <h1 className="mb-4">About</h1> */}

              <div className=" text,row gy-2 gx-4 mb-4">
                <p className=" font_st mb-0">
                  Sri Mittapalli College of Engineering, a premier technical
                  institution was founded by towering personality of impeccable
                  credentials, a notable academician, with grand mission and
                  noble vision Sri.M.V.Koteswara Rao in the year 2006. The
                  college is approved by All India Council for Technical
                  Education (AICTE) affiliated to JNTU Kakinada and functions as
                  a private self- financing institution under the auspices of
                  Sri Mittapalli Trust. Sri Mittapalli College of Engineering is
                  a tantamount in offering quality education to its students by
                  enhancing their employability skills and technical know-how.
                  It strives to instigate its students’ fresh perceptiveness,
                  inventive thinking and firm conviction to achieve true
                  success. With an aim to foster an equitable and productive
                  growth, SMCE seeks to nurture the competencies of students as
                  per industrial requirements. At SMCE we also focus on
                  developing traits of students by providing an absolute
                  education there by concentrating on all the aspects of
                  professional building. We have been incessantly in the course
                  of improving the quality of teaching by executing various
                  activities like seminars by eminent personalities, language
                  development and training in soft skills and communication
                  skills. SMCE believe in fostering innovative practices and
                  sustainable development programs to its students. Our main
                  motto is student discipline, and enthusing the students by
                  encouraging them to participate in extracurricular activities
                  like sports NSS, NCC on par with their academics. With a high
                  calibre faculty and an excellent infrastructure, we promote
                  academic excellence, absolute discipline and sound practical
                  exposure, in this way we impart the best education to create
                  ambience of excellence. We at SMCE deem that honesty, hard
                  work, and discipline collectively form the ladder for success.
                  On whole SMCE is an abode of centre of excellence for creating
                  holistic citizens inculcated with ethical, moral and social
                  values in diverse culture.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Infrastructure;
