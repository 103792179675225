import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./Naac.css";
import { PageHeader } from "../Header/PageHeader";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import { useState } from "react";
import RotatingSpinner from "../Resourses/RotatingSpinner";


const Naacdvv = () => {
  const [isLoading, setIsLoading] = useState(false);


  const handleBase64Data = async (param) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        resources.APPLICATION_URL + "filenames/" + param
      );
      const base64 = response.data;

      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      window.open(url, "_blank");
    } catch (e) {
      console.log("error", e);
      alert(e);
    } finally {
      setIsLoading(false);
    }

  }


  return (
    <>
    {isLoading ? <RotatingSpinner/> : (
      <div className="container mt-5">
      <PageHeader name={"NAAC DVV REPORT"} />
      <div className="crt_main">
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Extended Profile</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Accordion className="naac_ssr_inside">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography> 1.1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("1.1(1).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.1(1) Number of students year wise during the last five years.{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("1.1(2).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.2 <a href="#"> Number of teaching staff / full time teachers during the last five years (Without
repeat count):</a>
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Typography>
           
          </AccordionDetails>
        </Accordion>
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography> Metric Level :- </Typography>
           
          </AccordionSummary>
          <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Criterion 1
            </Typography>
          <AccordionDetails>
            <Typography>
              <Accordion className="naac_ssr_inside">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography> 1.2.1 </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("1.2.1(1).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.2.1(1)Percentage of students enrolled in Certificate/ Add-on/Value added programs as against the total number of students during the last five years{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("1.2.1(2).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.2.1(2) Number of students enrolled in subject related Certificate/ Add-on/Value added programs year wise during last five years
                        </Typography>
                      </AccordionSummary>
                      <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                    </AccordionDetails>
                    
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Typography>
            <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>1.2.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("1.2.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            1.2.2(1) Percentage of students undertaking project work/field work/ internships (Data for the latest completed academic year){" "}
                          </Typography>
                        </AccordionSummary>
                        <Typography>
                          <a href="#">1.3.2.1. Number of students undertaking project work/field work / internships</a>
                        </Typography>
                      </AccordionDetails>
                    
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>1.3.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("1.3.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            1.3.2(1) Institution obtains feedback on the academic performance and ambience of the institution from various stakeholders, such as Students, Teachers, Employers, Alumni etc. and action taken report on the feedback is made available on institutional website (Yes or No){" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
             
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Criterion 2</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Accordion className="naac_ssr_inside">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography> 2.1.1:- Enrolment percentage</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("2.1.1(1).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          2.1.1(1)Number of students admitted year wise during last five years
                        </Typography>
                      </AccordionSummary>
                      <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("2.1.1(2).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          2.1.1(2) Number of sanctioned seats year wise during last five years
                        </Typography>
                      </AccordionSummary>
                      <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                    </AccordionDetails>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Typography>
            <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 2.1.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.1.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.1.2(1) Percentage of seats filled against seats reserved for various categories (SC, ST, OBC, Divyangjan, etc. as per applicable reservation policy during the last five years (Exclusive of supernumerary seats)
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.1.2(2)")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.1.2(2) Number of actual students admitted from the reserved categories year - wise during the last five years
{" "}
                          </Typography>
                        </AccordionSummary>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                      </AccordionDetails>
                      
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>2.4.1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.4.1(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.4.1(1) Percentage of full-time teachers against sanctioned posts during the last five years
{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.4.1(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.4.1(2) Number of Sanctioned posts / required positions for teaching staff/ full time teachers year wise during the last five years:{" "}
                          </Typography>
                        </AccordionSummary>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Criterion 3</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Accordion className="naac_ssr_inside">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>3.1.1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("3.2.2(1).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.1.1 Grants received from Government and non-governmental agenciesfor research projects / endowments in the institution during the last five years (INR in Lakhs){" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("3.2.2(2).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.1.1(a) Total Grants from Government and non- governmental agencies for research projects , endowments,Chairs in the institution during the last five years (INR in Lakhs)
{" "}
                        </Typography>
                      </AccordionSummary>
                      <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("3.2.2(3).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.2.2 Number of  Workshops/seminars/conferences including on Research Methodology, Intellectual Property Rights (IPR) and entrepreneurship conducted during the last five years{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("3.2.2(4).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.2.2(1) Total number of workshops/seminars/conferences including programs conducted on Research Methodology, Intellectual Property Rights (IPR) and entrepreneurship year wise during last five years

                        </Typography>
                      </AccordionSummary>
                      <Typography>
                        <a href="#">Source of Funds </a>
                      </Typography>
                      <Typography>
                        <a href="#">List of Beneficiaries </a>
                      </Typography>
                    </AccordionDetails>
                    
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Typography>
            <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>3.3.1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.3.1.pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.3.1 Number of research papers published per teacher in the Journals on UGC care list during the last five years

                          </Typography>
                        </AccordionSummary>
                        <Typography>
                          3.3.1.(a) Number of research papers in the Journals notified on UGC CARE year wise during the last five years
                        </Typography>
                        <Typography>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                        </Typography>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 3.3.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.3.2.pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.3.2 <a href="#">Number of books and chapters in edited volumes/books published and papers published in national/ international conference proceedings per teacher during last five years</a>
                          </Typography>
                        </AccordionSummary>
                        <Typography>
                        3.3.2.1. Total number of books and chapters in edited volumes/books published and papers in national/ international conference proceedings year wise during last five years
                        </Typography>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>3.4.3</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.4.3(0).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.4.3(1) <a href="#">Number of extension and outreach programs conducted 
                            by the institution through NSS/NCC/Red cross/YRC etc.,
                             (including the programmes such as Swachh Bharat, AIDS awareness, 
                             Gender issues etc. and/or those organised in collaboration with industry, 
                             community and NGOs) during the last five years </a>
                          </Typography>
                        </AccordionSummary>
                        <Typography>
                          <a href="#">National Service Scheme</a>
                        </Typography>
                        <Typography>
                          <a href="#">NSS Programme Grants</a>
                        </Typography>
                        <Typography>
                          <a href="#">CNA Info All Registrars</a>
                        </Typography>
                        <Typography>
                          <a href="#">CNA info Accounts Guidelines </a>
                        </Typography>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.4.3(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.4.3(1)  Number of extension and outreach Programs conducted in collaboration with industry, community, and Non- Government Organizations through NSS/ NCC/ Red Cross/ YRC etc., year wise during the last five years

                          </Typography>
                        </AccordionSummary>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                      </AccordionDetails>
                      
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>3.5.1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.5.1(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.5.1 Number of MoUs, collaborations/linkages for Faculty exchange, Student exchange, Internship, Field trip, On-the- job training, research and other academic activities during the last five years:{" "}
                          </Typography>
                        </AccordionSummary>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                      </AccordionDetails>
                     
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Criterion 4</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Accordion className="naac_ssr_inside">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>4.1.2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("4.1.2.pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          4.1.2 Percentage of expenditure, excluding salary for infrastructure augmentation during last five years (INR in Lakhs)
{" "}
                        </Typography>
                      </AccordionSummary>
                      <Typography>
                      4.1.2.1  Expenditure for infrastructure augmentation, excluding salary during the last five years (INR in lakhs)
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                    </AccordionDetails>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Typography>
            <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>4.3.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2 Student � Computer ratio (Data for the latest completed academic year)

                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2(1) Number of computers available for students usage during the latest completed academic year:{" "}
                          </Typography>
                        </AccordionSummary>
                        <Typography>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                        </Typography>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
            <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>4.4.1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.4.1 Percentage of expenditure incurred on maintenance of infrastructure (physical and academic support facilities) excluding salary component during the last five years (INR in Lakhs)

                          </Typography>
                        </AccordionSummary>
                        <Typography>
                        4.4.1.1. Expenditure incurred on maintenance of infrastructure (physical facilities and academic support facilities) excluding salary component year wise during the last five years (INR in lakhs)
                        </Typography>
                      </AccordionDetails>
                     
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Criterion 5</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Accordion className="naac_ssr_inside">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography> 5.1.1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("4.3.2(2).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          5.1.1 Percentage of students benefited by scholarships and freeships provided by the Government and Non-Government agencies during last five years{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("5.1.1(2).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          5.1.1(1) Number of students benefited by scholarships and freeships provided by the Government and Non- Government agencies year wise during last five years.
                        </Typography>
                      </AccordionSummary>
                      <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>4.3.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2 Student � Computer ratio (Data for the latest completed academic year)

                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2(1) Number of computers available for students usage during the latest completed academic year:{" "}
                          </Typography>
                        </AccordionSummary>
                        <Typography>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                        </Typography>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
                    </AccordionDetails>
                   
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Typography>
            <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 5.1.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.1.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.1.2 Capacity building and skills enhancement initiatives taken by the institution include the following{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.1.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography> 5.1.2(1) Soft skills
</Typography>
                        </AccordionSummary>
                        <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>4.3.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2 Student � Computer ratio (Data for the latest completed academic year)

                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2(1) Number of computers available for students usage during the latest completed academic year:{" "}
                          </Typography>
                        </AccordionSummary>
                        <Typography>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                        </Typography>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.1.2(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography> 5.1.2(2) Language and communication skills.
</Typography>
                        </AccordionSummary>
                        <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>4.3.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2 Student � Computer ratio (Data for the latest completed academic year)

                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2(1) Number of computers available for students usage during the latest completed academic year:{" "}
                          </Typography>
                        </AccordionSummary>
                        <Typography>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                        </Typography>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2(2) Life skills (Yoga, physical fitness, health and hygiene){" "}
                          </Typography>
                        </AccordionSummary>
                       
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2(3) ICT/computing skills
:{" "}
                          </Typography>
                        </AccordionSummary>
                        <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>4.3.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2 Student � Computer ratio (Data for the latest completed academic year)

                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2(1) Number of computers available for students usage during the latest completed academic year:{" "}
                          </Typography>
                        </AccordionSummary>
                        
                        
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                        
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
                        <Typography>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                        </Typography>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 5.1.3</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.1.3(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.1.3 Percentage of students benefitted by guidance for competitive examinations and career counseling offered by the Institution during the last five years{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.1.3(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.1.3(1)  Number of students benefitted by guidance for competitive examinations and career counselling offered by the institution year wise during last five years{" "}
                          </Typography>
                        </AccordionSummary>
                        <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>4.3.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2 Student � Computer ratio (Data for the latest completed academic year)

                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2(1) Number of computers available for students usage during the latest completed academic year:{" "}
                          </Typography>
                        </AccordionSummary>
                        
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                        
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 5.2.1 </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.2.1(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.2.1 <a href="#">Percentage of placement of outgoing students and students progressing to higher education during the last five years</a>{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.2.1(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.2.1(1) Number of outgoing students placed and / or progressed to higher education year wise during the last five years.{" "}
                          </Typography>
                        </AccordionSummary>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                      </AccordionDetails>
                     
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 5.2.2 </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.2.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.2.2 Percentage of students qualifying in state/national/ international level examinations during the last five years (eg: JAM/CLAT/GATE/ GMAT/ CAT/ GRE/ TOEFL/ Civil Services/State government examinations){" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.2.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.2.2(1) Number of students qualifying in state/ national/ international level examinations (eg: JAM/CLAT/NET/ SLET/GATE/ GMAT/CAT/GRE/ TOEFL/Civil Services/ Judicial Services/Public Prosecution services/All India Bar.{" "}
                          </Typography>
                        </AccordionSummary>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 5.2.2(2) </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.3.1.pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                          5.2.2(2)  Number of students appearing in state/ national/ international level examinations (eg: JAM/CLAT/NET/ SLET/GATE/ GMAT/CAT,GRE/ TOFEL/ Civil Services/ State government examinations) year wise during last five years {" "}
                          </Typography>
                        </AccordionSummary>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 5.3.2 </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.3.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.3.2 <a href="#">
                              
The Details and board objectives of Technical events</a>{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Criterion 6</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Accordion className="naac_ssr_inside">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>6.2.2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("6.2.2(1).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          6.2.2 Implementation of e-governance in areas of operation
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("6.2.2(2).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          6.2.2(1) Administration
                          interface{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("6.2.2(3).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          6.2.2(2) <a href="#">Finance and Accounts</a>{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("6.2.2(4).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography> 6.2.2(3) Student Admission and Support </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("6.2.2(4).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography> 6.2.2(4) Policy </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("6.2.2(4).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography> 6.2.2(5) Examination </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Typography>
            <Typography>
              {/* <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 6.3.2 </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.2(1)List of teachers receiving financial support
                            along with e-copies of sanction letters for the
                            A.Y.2021-22
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.2(2) List of teachers receiving financial
                            support along with e-copies of sanction letters for
                            the A.Y.2020-21
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.2(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.2(3) List of teachers receiving financial
                            support along with e-copies of sanction letters for
                            the A.Y.2019-20{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.2(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.2(4) List of teachers receiving financial
                            support along with e-copies of sanction letters for
                            the A.Y.2018-19{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.2(5).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.2(5) List of teachers receiving financial
                            support along with e-copies of sanction letters for
                            the A.Y.2017-18{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography> */}
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>6.3.3</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.3(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.3 Percentage of teaching and non- teaching staff participating in Faculty development Programmes (FDP), professional development /administrative training programs during the last five years
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.3(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.3(1) Total number of teaching and non-teaching staff participating in Faculty development Programmes (FDP), professional development/administrative training programs during the last five years.{" "}
                          </Typography>
                        </AccordionSummary>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.3(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.3(2) Number of non- teaching staff year wise during the last five years
                          </Typography>
                        </AccordionSummary>
                        <Typography>
                      &nbsp;&nbsp;  1. 2017-18
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  2. 2018-19
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  3. 2019-20
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp;  4. 2020-21
                      </Typography>
                      <Typography>
                      &nbsp;&nbsp; 5. 2021-22
                      </Typography>
                      </AccordionDetails>
                      
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>6.5.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.5.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.5.2 Quality assurance initiatives of the institution include:
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.5.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.5.2(1) <a href="#">Regular meeting of Internal Quality Assurance Cell (IQAC); Feedback collected, analysed and used for improvements</a>{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.5.2(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.5.2(2) <a href="#">Collaborative quality initiatives with other institution(s)/ membership of international networks</a>{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.5.2(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.5.2(3) <a href="#">Participation in NIRF</a>{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.5.2(5).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography> 6.5.2(4) <a href="#">any other quality audit/accreditation recognized by state, national or international agencies such as NAAC, NBA, ISO Certification etc</a> </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Criterion 7</Typography>
          </AccordionSummary>
          <AccordionDetails>
            
            <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 7.1.3</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("7.1.3(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography> 7.1.3 Quality audits on environment and energy regularly undertaken by the Institution. The institutional environment and energy initiatives are confirmed through the following</Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("7.1.3(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            7.1.3(1) Green audit / Environment audit
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("7.1.3(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            7.1.3(2) Energy audit
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("7.1.3(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            7.1.3(3) Clean and green campus initiatives
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("7.1.3(5).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            7.1.3(4) Beyond the campus environmental promotion activities
{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
    )}
    </>
  );
};

export default Naacdvv;
