import React from 'react'
import "./BOS.css"
import BOSFunctions from './BOSFunctions'

const Physics = () => {
    const phys = [
        {
            member: "Chairperson",
            designation: "Dr.V.Madhuri,Professor of AS&H Dept. & HOD"
        },
        {
            member: "University Nominee",
            designation: "Dr.G.Padmaja Rani,Professor of Physics, UCEK,JNTU Kakinada"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr. Sandhya cole,M.Sc., M. Phil., Ph.D.Professor of Physics ANU College of sciences Acharya  Nagarjuna University 9441902295 sandhya.cole@gmail.com"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr. T. Madhu Mohan M.Sc., Ph.D.Professor of physics,Vasireddy Venkatadri Institute of Technology 9949678092 tmadhumohan@gmail.com"
        },
        {
            member: "Representative from Industry",
            designation: "Dr.P.Lova Kumar Sub Divisional Engineer, CNTX South BSNL HYDERABAD.lovakumarbsnl@gmail.com 9490427439"
        },
        {
            member: "Alumni Member",
            designation: "R.Bhuvaneswari,Asst.Prof., Dept.of CSE,SMCE.bhuvana.rayapudi111@gmail.com 9398788846"
        },
        {
            member: "Member",
            designation: "Mrs.G.Mery Janeefa, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Mr.G.Raja Kumar, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Mr.Y.Ashok, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Mr.SK.Ameer Fareed Basha, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Dr.Y.Saritha, Asst.Prof. SMCE"
        },

    ]

    return (
        <>
            <h4 class="_Top">SRI MITTAPALLI COLLEGE OF ENGINEERING <br />
                Department of Physics<br />
                Composition of Board of Studies
            </h4 >
            <h3 class="A">
                A.Y.: 2023-24
            </h3>
            <div className='gb_table'>
                <table>
                    <thead>
                        <tr>
                            <th>
                                S.NO
                            </th>
                            <th>
                                MEMBERS
                            </th>
                            <th>
                                NAME & DESIGNATION
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {phys.map((each, i) => <tr>
                            <td>
                                {i + 1}
                            </td>
                            <td>
                                {each.member}
                            </td>
                            <td>
                                {each.designation}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
            <div className='bottom'>
                <h3 className='_chairperson'>Chairperson</h3>
                <p>BoS- Physics</p>
            </div>
            <BOSFunctions />
        </>
    )
}

export default Physics
