import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/MediaTable";

const Committees = () => {
  const media = [
    {
      title: "1.Collecting all necessary data and particulars from all  departments/Sections at frequent interval through the prescribed formats."

    },
    {
      title: "Compiling them in the required format as news letter and arranging to send to the concern members/departments/other institutes as desired."
    },
    {
      title: "Encouraging the faculty to go for Publications."
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            PUBLIC RELATIONS, PRESS AND MEDIA, PUBLICATION, NEWS LETTER,
            MAGAZINE AND EDITORIAL COMMITTEE
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            Public relations press & media, publications cell involve liaising
            directly with the people or group responsible for producing the news
            and features in the mass media for broadcasting on the institutional
            activities, official publications like magazine, newsletters,
            college brochure. The goal of media relations is to maximize
            positive coverage in the free-to-air mass media.
          </p>
          <p>
            Public relations press & media, publications cell at Sri Mittapalli
            College of Engineering, Thummalapalem, acts as the interface between
            the college and external entities such as corporate, general public,
            press-coverage, publications and all other media related activities.
            The aim of the cell is to create, foster and manage professional
            relationship with all stake holders.
          </p>
          <p>
            This cell looks after all communication and publications both
            internal and external and also serves as the official spokesperson
            of the college. It is responsible for facilitating the activities of
            maintaining a rich Industry connect program by means of organizing
            Conclaves, Annual Day, Guest Lectures, Workshops and various other
            such interactive events. The role of this cell is to manage
            continuous relationship with external stakeholders through suitable
            media related activities. It accomplishes this through engagements
            with the print media and by facilitating college’s online presence
            across various platforms. It also manages internal communication by
            reporting events, activities-both curricular, co-curricular and
            sports and achievements to the administration, faculty and student
            community apart from recording of all related events and activities.
            Apart from focusing on Corporate Interactions, one of the major
            activities of this cell is to provide necessary assistance to
            prospective candidates in their admission related queries, thereby
            supporting the admission process conducted at Sri Mittapalli College
            of Engineering, Thummalapalem.
          </p>
          
        </div>
      </div>
      <div className="container">
        <Table />
      </div>
    </div>
  );
};

export default Committees;
