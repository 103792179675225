import React from "react";

const Iccommittee = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            IC COMMITTEE
          </a>
        </div>
      </div>
      <br />

      <div className="container">
        <p>
          We established of 24×7 women helpline number and a security system in
          the campus for providing safety to students and female faculty and
          non-teaching faculty.
        </p>
        <div className="para">
          <h4>Contact Details</h4>
          <h6>Dr. V S R Kumari</h6>
          <h6>Phone number +91 9936084558</h6>
        </div>
      </div><br/>

      <div className="container">
        <p>
          We established of platform or hiring counselors for seeking help and
          guidance w.r.t psychological counselling related to Mental Health for
          Students, faculty and non-teaching faculty.
        </p>
        <div className="para">
          <h4>Contact Details</h4>
          <h6>Prof.KOTAGIRI VENKATAJAWAHAR</h6>
          <h6>Phone number +91 8919096826</h6>
        </div>
      </div>
    </div>
  );
};

export default Iccommittee;
