import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import csefaculty from "../../../assets/csedepartmentimg.png";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { resources } from "../../Resourses/Resources";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PageHeader } from "../../Header/PageHeader";
import "../Departments.css";
import Labs from "./Labs";
import Faculty from "./Faculty";
import { Transform } from "@mui/icons-material";

import Mr23 from "../../../Pdf/CourseStructure/UG/MR23_B.Tech_COURSE STRUCTURE.pdf"
import R20 from "../../../Pdf/CourseStructure/UG/R20 CSE Course Structure.pdf"


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// const Faculty = () => {
//   return (
//     <div className="container">
//       <PageHeader name="COMPUTER SCIENCE & ENGINEERING" />

//       <div className="container-xxl py-5">
//         <div className="container">
//           <div className="row g-5">
//             <div
//               className="col-lg-6 wow fadeInUp"
//               data-wow-delay="0.1s"
//               style={{ minHeight: "100px" }}
//             >
//               <div className="position-relative h-100">
//                 <img
//                   className="img-fluid rounded w-100 h-100"
//                   src={csefaculty}
//                   alt="csefaculty-img"
//                   style={{ objectFit: "cover" }}
//                 />
//               </div>
//             </div>
//             <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
//               <h6 className="section-title bg-white text-start text-primary pe-3">
//                 Faculty
//               </h6>
//               <h1 className="mb-4">About</h1>

//               <div className="row gy-2 gx-4 mb-4">
//                 <p className="mb-0">
//                   Studying for a Computer Science and Engineering degree is
//                   exciting enough as it will open the doors to very cool jobs.
//                   It is truly amazing to catch the trend with SSIT. Because SSIT
//                   takes care to maximize the learning opportunities by keeping
//                   up-to-date and continuous with highly qualified and richly
//                   experienced faculty to meet the present requirement at global
//                   scenario.
//                   <p>
//                     Department Offers an intake of 120 with total student
//                     strength is about 480.
//                   </p>
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="card_cntr1">
//         <div
//           className="boxes col-lg-3 col-sm-6 wow fadeInUp"
//           data-wow-delay="0.1s"
//         >
//           <div className="boxes1 service-item text-center pt-3">
//             <div className="p-4">
//               <h5 className="mb-1">Vision</h5>
//               <p>
//                 To make the students achieve highest standards of quality
//                 technical education adaptable to suit the present day
//                 requirements in the fields of science and technology and to
//                 mould them morally into persons of character and integrity.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div
//           className="boxes col-lg-3 col-sm-6 wow fadeInUp"
//           data-wow-delay="0.3s"
//         >
//           <div className="boxes1 service-item text-center pt-3">
//             <div className="p-4">
//               <h5 className="mb-3">Mission</h5>
//               <p>
//                 To awaken the students to the reality of identifying their
//                 hidden potentials and talents through meticulous and systematic
//                 grooming to gain the spirit and inventiveness to build a career
//                 full of glorious prospects and eventful future.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div
//           className="boxes col-lg-3 text-center col-sm-6 wow "
//           data-wow-delay="0.5s"
//         >
//           <div
//             className="boxes text-center pt-3 pb-5"
//             style={{ backgroundColor: "#f6f6f6", height: "fit-content" }}
//           >
//             <div className="p-4 ">
//               <h5 className="mb-3">STRENGTHS</h5>
//               <div>
//                 <Accordion
//                   sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
//                 >
//                   <AccordionSummary
//                     expandIcon={<ExpandMoreIcon />}
//                     aria-controls="panel1-content"
//                     id="panel1-header"
//                     sx={{ backgroundColor: "#fff" }}
//                   >
//                     Infrastructure & Faculty
//                   </AccordionSummary>
//                   <AccordionDetails
//                     align="center"
//                     sx={{
//                       backgroundColor: "#fff5dc",
//                       color: "#000",
//                       fontSize: "12px",
//                       fontFamily: "Arial",
//                       marginBottom: "10px",
//                       letterSpacing: "1px",
//                     }}
//                   >
//                     Ultra Modern Labs with more than 430 Systems having multiple
//                     platforms like Linux, Windows and Ubuntu. 24/7
//                     InternetFacility with 300 MBPS throughtwo different ISPs.
//                     Well Experienced and University Ratified Faculty.
//                   </AccordionDetails>
//                 </Accordion>
//                 <Accordion
//                   sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
//                 >
//                   <AccordionSummary
//                     expandIcon={<ExpandMoreIcon />}
//                     aria-controls="panel2-content"
//                     id="panel2-header"
//                   >
//                     Training & Placements
//                   </AccordionSummary>
//                   <AccordionDetails
//                     sx={{
//                       backgroundColor: "#fff5dc",
//                       color: "#000",
//                       fontSize: "12px",
//                       fontFamily: "Arial",
//                       marginBottom: "10px",
//                       letterSpacing: "1px",
//                     }}
//                   >
//                     Conducting Industry relevant Training Programmes and
//                     providing Placements in various MNCs through
//                     On-Campus/Off-Campus.
//                   </AccordionDetails>
//                 </Accordion>
//                 <Accordion>
//                   <AccordionSummary
//                     expandIcon={<ExpandMoreIcon />}
//                     aria-controls="panel3-content"
//                     id="panel3-header"
//                   >
//                     Research & Development
//                   </AccordionSummary>
//                   <AccordionDetails
//                     sx={{
//                       backgroundColor: "#fff5dc",
//                       color: "#000",
//                       fontSize: "12px",
//                       fontFamily: "Arial",
//                       marginBottom: "10px",
//                       letterSpacing: "1px",
//                     }}
//                   >
//                     More than 50% Faculty Pursuing Ph.Ds from Reputed
//                     Univeristies and publishing Their Research Papers in Various
//                     Good Impact Journals.
//                   </AccordionDetails>
//                 </Accordion>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

const CourseStructure = () => {
  return (
    <div className="containe">
      <PageHeader name="CSE - COURSE STRUCTURE" />
      <div className="course_structure_cnt animate__animated animate__zoomIn">
        <div className="course text-center">
          <h4>Course Structure for all Years</h4>
          <p>
            Download the Course Structure and Syllabus of four years of CSE
            course
          </p>
        </div>

        <div className="w-100 text-center d-flex justify-content-center gap-5 ">
          <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>
              CSE - MR23
            </span>
            <a href={Mr23} target="blank">
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div>
          <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>CSE - R20</span>
            <a href={R20} target="blank">
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
const Events = () => {
  return (
    <div className="container">
      <PageHeader name="ACTIVITIES & EVENTS" />

      <div className="course text-center animate__animated animate__fadeInRight">
        <h4>Student Development Activities</h4>
        <br />
        {/* <p className="prg_content">
          The ACSESS (Association of Computer Science and Engineering in Sai
          Spurthi) is started on 15.09.09(Engineer's Day) by the Student and
          Staff of the CSE Department to strengthen research and advanced
          education in computing and allied fields. It is mainly targeted to
          adopt new fields of knowledge and communication through co-curricular
          activities.{" "}
        </p>
        <p className="prg_content">
          It does this by working to influence policy that impacts departmental
          activities, encouraging the development of human resources,
          contributing to the cohesiveness of the professional students
          community and collecting and disseminating information about the
          importance and the state of computing research. Each plays an
          important role in achieving the ACSESS objectives.
        </p> */}
      </div>
    </div>
  );
};

const Obe = () => {
  return (
    <div className="container">
      <PageHeader name="PEOs/POs/PSOs" />
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM EDUCATIONAL OBJECTIVES(PEO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PEO 1:</h2>
              <p>
                Graduates able to solve wide range of computing-related problems
                by applying the knowledge of mathematics and innovative
                algorithms.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 2:</h2>
              <p>
                Graduates pursue advanced degrees with a dedication for lifelong
                learning and use their skills in an ethical & professional
                manner.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 3:</h2>
              <p>
                To be able to adapt to the evolving technical challenges and
                changing career opportunities.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM OUTCOMES(PO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PO 1 (Engineering knowledge):</h2>
              <p>
                Apply the knowledge of mathematics, science, engineering
                fundamentals, and an engineering specialization to the solution
                of complex engineering problems.
              </p>
            </div>
            <div class="peo">
              <h2>PO 2 (Problem analysis):</h2>
              <p>
                Identity, formulate, review research literature, and analyze
                complex engineering problems reaching substantiated conclusions
                using the first principles of mathematics, natural sciences, and
                engineering sciences.
              </p>
            </div>
            <div class="peo">
              <h2>PO 3 (Design/development of solutions):</h2>
              <p>
                Design solutions for complex engineering problems and design
                system components or processes that meet the specified needs
                with appropriate consideration for public health and safety, and
                the cultural, societal, and environmental considerations.
              </p>
            </div>
            <div class="peo">
              <h2>PO 4 (Conduct investigations of complex problems):</h2>
              <p>
                Use research-based knowledge and research methods including
                design of experiments, analysis and interpretation of data, and
                synthesis of the information to provide valid conclusions.
              </p>
            </div>
            <div class="peo">
              <h2>PO 5 (Modern tool usage):</h2>
              <p>
                Create, select, and apply appropriate techniques, resources, and
                modern engineering and IT tools including prediction and
                modeling to complex engineering activities with an understanding
                of the limitations.
              </p>
            </div>
            <div class="peo">
              <h2>PO 6 (The engineer and society):</h2>
              <p>
                Apply reasoning informed by the contextual knowledge to assess
                societal, health, safety, legal and cultural issues and the
                consequent responsibilities relevant to the professional
                engineering practice.
              </p>
            </div>
            <div class="peo">
              <h2>PO 7 (Environment and sustainability):</h2>
              <p>
                Understand the impact of the professional engineering solutions
                in societal and environmental contexts, and demonstrate the
                knowledge of, and need for sustainable development.
              </p>
            </div>
            <div class="peo">
              <h2>PO 8 (Ethics):</h2>
              <p>
                Apply ethical principles and commit to professional ethics and
                responsibilities and norms of the engineering practice.
              </p>
            </div>
            <div class="peo">
              <h2>PO 9 (Individual and team work):</h2>
              <p>
                Function effectively as an individual, and as a member or leader
                in diverse teams, and in multidisciplinary settings.
              </p>
            </div>
            <div class="peo">
              <h2>PO 10 (Communication):</h2>
              <p>
                Communicate effectively on complex engineering activities with
                the engineering community and with society at large, such as,
                being able to comprehend and write effective reports and design
                documentation, make effective presentations, and give and
                receive clear instructions.
              </p>
            </div>
            <div class="peo">
              <h2>PO 11 (Project management and finance):</h2>
              <p>
                Demonstrate knowledge and understanding of the engineering and
                management principles and apply these to one’s own work, as a
                member and leader in a team, to manage projects and in
                multidisciplinary environments.
              </p>
            </div>
            <div class="peo">
              <h2>PO 12 (Life-long learning):</h2>
              <p>
                Recognize the need for, and have the preparation and ability to
                engage in independent and life-long learning in the broadest
                context of technological change.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM SPECIFIC OUTCOMES (PSO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PSO1:</h2>
              <p>
                Analyze, Identify the data ,Design suitable algorithm by using
                Latest software for Real Time Applications.
              </p>
            </div>
            <div class="peo">
              <h2>PSO2:</h2>
              <p>
                Understand the progressive changes in computing posses data of
                context-aware relevance of paradigms.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          COURSE STRUCTURE (CO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <ul class="course_outcomes_cnt">
              <li>
                <a
                  href="https://smce.ac.in/wp-content/uploads/2022/10/UG_R19_CSE_COs.pdf"
                  target="_blank"
                >
                  R19
                </a>
              </li>
              <li>
                <a
                  href="https://smce.ac.in/wp-content/uploads/2022/10/UG_R20_CSE_COs.pdf"
                  target="_blank"
                >
                  R20
                </a>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
};

const AboutDepartment = () => {
  return (
    <div>
      <div className="container">
        <PageHeader name="COMPUTER SCIENCE & ENGINEERING" />
        <div className="container-xxl">
          <div className="container-xxl py-1 ">
            <div className="container">
              <div className="row g-5">
                <div
                  className="wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{ display: "flex" }}
                >
                  <div className="position-relative h-100 animate__animated animate__zoomIn">
                    <img
                      className="img-fluid rounded w-100 h-80"
                      src={csefaculty}
                      alt="csefaculty-img"
                      style={{ objectFit: "cover",boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}
                    />
                  </div>

                  <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                    {/* <h6 className="section-title bg-white text-start text-primary pe-3">
                    Faculty
                  </h6> */}


                    <div className="row gy-2 gx-5">

                      <p className="prg_content_About">
                        <h1 >About</h1>
                        Computer Science & Engineering offers a rigorous and
                        dynamic curriculum designed to equip students with the
                        skills and knowledge necessary to excel in the rapidly
                        evolving field of computing. Computer Science & Engineering
                        emphasize hands-on learning, collaborative projects, and
                        industry engagement, ensuring that our graduates are well-prepared
                        for a range of professional roles in academia, industry, and beyond.
                        Sri Mittapalli College of Engineering (SMCE) has set up an undergraduate course
                        in Computer Science Engineering (B.Tech. CSE) branch in 2006 with an initial
                        intake of 60 and to 420 in the following years.
                        A post graduate course also has been introduced in the year 2010 in Computer
                        Science Engineering (M.Tech. CSE) with an intake of 18.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            // <div className="cad_cntr1">

            //   {/* <div className="boxes1 service-item text-center pt-3 shadow"> */}
            //   <div className="hov">

            //     <h5 className="mb-1 vision-mission-headings">Vision of the Department:</h5>
            //     <br />
            //     <p className="prg_content_cse">
            //       To produce professional, ethical and globally competent
            //       graduates by imparting quality education in the field of
            //       Computer Science & Engineering with capabilities to solve a
            //       wide range of complex, scientific, technological and
            //       societal problems.
            //     </p>


            //   </div>

                

            //   <div className="hov">
            //     <h5 className="mb-1 vision-mission-headings">Mission of the Department:</h5><br/>
            //     <div className="mission-container">
            //        <h5 className="mission-sub-heading">M1</h5>
            //        <p className="mission-sub-subscription"> To educate students to the effective problem – solvers
            //           and life – long learners in technical, innovative and
            //           entrepreneurial skills.</p>
            //     </div>
            //     <div className="mission-container">
            //        <h5 className="mission-sub-heading">M2</h5>
            //        <p className="mission-sub-subscription">To impart quality and value based education and
            //           contribute towards the advancement of computing, science
            //           and technology to raise satisfaction level of all
            //           stakeholders. </p>
            //     </div>
            //     <div className="mission-container">
            //        <h5 className="mission-sub-heading">M3</h5>
            //        <p className="mission-sub-subscription">  To establish a continuous Industry Institute
            //           Interaction, participation, collaboration to contribute
            //           skilled IT engineers.</p>
            //     </div>
            //     {/* <div>
            //       <Accordion className="prg_content_cse"
            //         sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
            //       >
            //         <AccordionSummary
            //           expandIcon={<ExpandMoreIcon />}
            //           aria-controls="panel1-content"
            //           id="panel1-header"
            //           sx={{ backgroundColor: "#fff" }}
            //         >
            //           M1
            //         </AccordionSummary>
            //         <AccordionDetails
            //           align="center"
            //           sx={{
            //             backgroundColor: "#fff5dc",
            //             color: "#000",
            //             fontSize: "12px",
            //             fontFamily: "Arial",
            //             marginBottom: "10px",
            //             letterSpacing: "1px",
            //           }}
            //         >
            //           To educate students to the effective problem – solvers
            //           and life – long learners in technical, innovative and
            //           entrepreneurial skills.
            //         </AccordionDetails>
            //       </Accordion>
            //       <Accordion
            //         sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
            //       >
            //         <AccordionSummary
            //           expandIcon={<ExpandMoreIcon />}
            //           aria-controls="panel2-content"
            //           id="panel2-header"
            //         >
            //           M2
            //         </AccordionSummary>
            //         <AccordionDetails
            //           sx={{
            //             backgroundColor: "#fff5dc",
            //             color: "#000",
            //             fontSize: "12px",
            //             fontFamily: "Arial",
            //             marginBottom: "10px",
            //             letterSpacing: "1px",
            //           }}
            //         >
            //           To impart quality and value based education and
            //           contribute towards the advancement of computing, science
            //           and technology to raise satisfaction level of all
            //           stakeholders.
            //         </AccordionDetails>
            //       </Accordion>
            //       <Accordion>
            //         <AccordionSummary
            //           expandIcon={<ExpandMoreIcon />}
            //           aria-controls="panel3-content"
            //           id="panel3-header"
            //         >
            //           M3
            //         </AccordionSummary>
            //         <AccordionDetails
            //           sx={{
            //             backgroundColor: "#fff5dc",
            //             color: "#000",
            //             fontSize: "12px",
            //             fontFamily: "Arial",
            //             marginBottom: "10px",
            //             letterSpacing: "1px",
            //           }}
            //         >
            //           To establish a continuous Industry Institute
            //           Interaction, participation, collaboration to contribute
            //           skilled IT engineers.
            //         </AccordionDetails>
            //       </Accordion>
            //     </div> */}
            //   </div>




            //       <div className="hov">
            //         <h5 className="mb-3 vision-mission-headings">Why Choose SMCE CSE:</h5>
            //         <br />
            //         <p className="prg_content_cse">
            //           <ArrowForwardOutlinedIcon />
            //           Qualified & Experienced Faculty.<br />
            //           <ArrowForwardOutlinedIcon />
            //           Industry ready course content.<br />
            //           <ArrowForwardOutlinedIcon />
            //           Well planned academic curriculum.<br />
            //           <ArrowForwardOutlinedIcon />
            //           Course delivery through real world applications.<br />
            //           <ArrowForwardOutlinedIcon />
            //           Career Prospects.
            //         </p>
            //       </div>
            // </div>
            <div className="card_cntr1">

            {/* <div className="boxes1 service-item text-center pt-3 shadow"> */}
            <div className="hov">

              <h5 className="mb-1"><b>Vision of the Department:</b></h5>
              <br />
              <p className="prg_content_cse">
                To produce professional, ethical and globally competent
                graduates by imparting quality education in the field of
                Computer Science & Engineering with capabilities to solve a
                wide range of complex, scientific, technological and
                societal problems.
              </p>


            </div>



            <div className="hov">
              <h5 ><b>Mission of the Department:</b></h5>
              <br/>
                <div className="mission-container">
                   <h5 className="mission-sub-heading">M1</h5>
                   <p className="mission-sub-subscription"> To educate students to the effective problem solvers
                    and life long learners in technical, innovative and
                    entrepreneurial skills.</p>
                </div>
                <div className="mission-container">
                   <h5 className="mission-sub-heading">M2</h5>
                   <p className="mission-sub-subscription"> To impart quality and value based education and
                    contribute towards the advancement of computing, science
                    and technology to raise satisfaction level of all
                    stakeholders.</p>
                </div>
                <div className="mission-container">
                   <h5 className="mission-sub-heading">M3</h5>
                   <p className="mission-sub-subscription"> To establish a continuous Industry Institute
                    Interaction, participation, collaboration to contribute
                    skilled IT engineers.</p>
                </div>
              {/* <div>
                <Accordion className="prg_content_cse"
                  sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    M1
                  </AccordionSummary>
                  <AccordionDetails
                    align="center"
                    sx={{
                      backgroundColor: "#fff5dc",
                      color: "#000",
                      fontSize: "12px",
                      fontFamily: "Arial",
                      marginBottom: "10px",
                      letterSpacing: "1px",
                    }}
                  >
                    To educate students to the effective problem – solvers
                    and life – long learners in technical, innovative and
                    entrepreneurial skills.
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    M2
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      backgroundColor: "#fff5dc",
                      color: "#000",
                      fontSize: "12px",
                      fontFamily: "Arial",
                      marginBottom: "10px",
                      letterSpacing: "1px",
                    }}
                  >
                    To impart quality and value based education and
                    contribute towards the advancement of computing, science
                    and technology to raise satisfaction level of all
                    stakeholders.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    M3
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      backgroundColor: "#fff5dc",
                      color: "#000",
                      fontSize: "12px",
                      fontFamily: "Arial",
                      marginBottom: "10px",
                      letterSpacing: "1px",
                    }}
                  >
                    To establish a continuous Industry Institute
                    Interaction, participation, collaboration to contribute
                    skilled IT engineers.
                  </AccordionDetails>
                </Accordion>
              </div> */}
            </div>




            <div className="hov">
              <h5 className="mb-3"><b>Why Choose SMCE CSE:</b></h5>
              <br />
              <p className="prg_content_cse">
                <ArrowForwardOutlinedIcon />
                Qualified & Experienced Faculty.<br />
                <ArrowForwardOutlinedIcon />
                Industry ready course content.<br />
                <ArrowForwardOutlinedIcon />
                Well planned academic curriculum.<br />
                <ArrowForwardOutlinedIcon />
                Course delivery through real world applications.<br />
                <ArrowForwardOutlinedIcon />
                Career Prospects.
              </p>
            </div>
          </div>

            
            }
          {/* <div className="card_cntr1">

        <h5 className="hov">Vision of the Department:</h5>
        <p className="prg_content">
                    To produce professional, ethical and globally competent
                    graduates by imparting quality education in the field of
                    Computer Science & Engineering with capabilities to solve a
                    wide range of complex, scientific, technological and
                    societal problems.
                  </p>

        </div> */}
             
               <br/>
               <br/>
          <div className="container">
            <div className="row">
              <div className="area col-6">
                <h4>Focus Areas:</h4>
                <p className="prg_content">
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Deep Learning.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Neural Networks.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Speech Recognition Technologies.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Robotic Applications.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Natural Language Processing.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Security Certifications.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Internet of Things<br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Cyber Security
                </p>
              </div>
              <div className="area col-6">
                <h5>Skill Development:</h5>
                <br />
                <p>
                  <b> Coding skills:</b>Programming skills in C, C++, Java,
                  Python, R Programming, Java Full Stack Development, Cloud
                  Programming, etc.
                  <br />
                  <br />
                  <b>Hardware skills:</b>Ardunio, Raspberry Pi Boards.
                  <br />
                  <br />
                  <b>Soft skills:</b> Hobby clubs, Mock Interviews.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CSE = () => {
  const [value, setValue] = React.useState(0);

  const [departmentData, setDepartmentData] = useState([]);

  const handleGetcseDepartment = async () => {
    try {
      const res = await axios.get(
        resources.APPLICATION_URL + "getstudentdata" + "CSE"
      );
      if (res.data) {
        setDepartmentData(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleGetcseDepartment();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 0, borderColor: "divider", marginTop: "1px" }}>
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="About Department" {...a11yProps(0)} />
            <Tab
              sx={{ textTransform: "capitalize" }}
              label="PEOs/POs/PSOs"
              {...a11yProps(1)}
            />
            <Tab label="Course Structure" {...a11yProps(2)} />
            <Tab label="Laboratories" {...a11yProps(3)} />
            <Tab label="Faculty" {...a11yProps(4)} />
            <Tab label="Activities & Events" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <AboutDepartment />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Obe />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <CourseStructure />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Labs />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Faculty />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <Events />
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default CSE;
