import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/AntiRaggingTable";
import pdf from "../../assets/pdfsmittapalli/sem-1.pdf";
import pdf2 from "../../assets/pdfsmittapalli/sem-2.pdf";
import StarRateIcon from "@mui/icons-material/StarRate";


const Committees = () => {
  const antiragging = [
    {
      title: "Maintaining passport size photo copy of each student along with necessary data and information, branch-wise/section-wise for an immediate identification of any student at any time"
    },
    {
      title: "Developing brotherly movements among students by motivation."
    },
    {
      title: "Closely watching the students and maintaining discipline."
    },
    
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            Anti Ragging Committee
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            Sri Mittapalli institute of Engineering and Technology maintains a healthy and congenial
            academic environment for the students. The institution offers protection to the new
            entrants from the menace of ragging. In this regard our Institute has constituted an Anti
            Ragging Cell. The cell is headed by Head of the institution, along with several other
            committee members comprising of senior faculties.
          </p>
          <p>
            Arranges awareness programme for the seniors about anti-ragging with the local police
            officials. This committee forms <b>Vigilance Committees </b>and <b>Flying Squads</b>, every year
            to ensure the campus is free from menace of ragging.
          </p>
          <p>The responsibility of the <b>Vigilance Committee</b> is to ensure that all anti-ragging
            measures are in place to curb the menace of ragging.
          </p>
          <p>
            The responsibility of the <b>Flying </b>Squad is to maintain vigil to check ragging activities. To
            make surprise visits in the campus, bus stops and hostels and other places vulnerable to
            incidents & having the potential for ragging.
          </p>

          <p>
            Anti-Ragging Committee is formed with the following members:
          </p>
        </div>
      </div>
      <div className="container">
        <Table />
      </div>
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions of the Finance Committee :</span>
          <p>The Finance Committee shall act as an advisory body to the Governing Body, to consider:</p>
        </p>
        {antiragging.map((item, index) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
      <div className="container">
        <div class="navbar navbar-expand-lg navbar-dark bg-dark">
          <div class="container">
            <a class="navbar-brand" href="#">
              IQAC Minutes
            </a>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-6">

              <button type="button" className="btn_sem">
                <a href={pdf} target="blank">
                  2021-22 Semister-1{" "}
                </a>
              </button>
            </div>
            <div className="col-6">
              <button type="button" className="btn_sem">
                <a href={pdf2} target="blank">
                  2021-22 Semister-2{" "}
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Committees;
