import React from "react";
import pic from "../../assets/lab/cselab1-1.jpg";

const Counseling = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            COUNSELLING
          </a>
        </div>
      </div>
      <br />

      <div className="container h-100">
        <div className=" ">
          <img
            className="img-fluid rounded  "
            src={pic}
            alt="img-img"
            style={{ height: "600px",
          width:"100%" }}
          />
        </div>
      </div>
      <br />

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "100px" }}
            >
              <div className="position-relative h-100">
                <p>
                  SSIT offers confidential and free professional counselling and
                  psychological support to its students by staff in order to
                  help them in handling various stressful situations and to cope
                  up with their problems for their better social adjustment and
                  good professional performance. This enables them to take full
                  advantage of the academic and social environment of the
                  college and realize their potential to the fullest extent.
                </p>
                <p>
                  All teaching staff are designated as counsellors for 20
                  students, who listen to the issues of the students and hold
                  one-to-one counselling sessions for solving their problems.
                </p>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
              Counselling and Support is provided in the Following Area
              </h6>
              <p>
             <h6> Personal adjustment problems</h6>
              
             <h6> Situational crises</h6>
             <h6> Communication skills</h6>
              <h6>Regularity</h6>
             <h6> Learning skills for lifelong usage</h6>
              <h6>Academic stress</h6>
              </p>

            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counseling;
