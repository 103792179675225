import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import axios from 'axios';
import { resources } from ".././Resourses/Resources";
import "../Admissionprocedure/AcadamicCalender.css";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein, moredetails) {
  return { name, calories, fat, carbs, protein, moredetails };
}




export default function CustomizedTables() {
  const [isEnable, setIsEnable] = useState(false);
  const [activeCommitte, setActiveCommitee] = useState([]);
  const [allCommitteData, setAllCommitteData] = useState([]);




  const handleClickForMoreDetails = async (param) => {
    setIsEnable(true)
    try {
      const res = await axios.get(resources.APPLICATION_URL + `getspecificcommitte/${param}`);
      if (res.data) {
        console.log(JSON.stringify(res.data))
        setActiveCommitee(res.data);
        setIsEnable(true);
      } else {
        alert("No data found")
      }

    } catch (e) {
      console.log(e);
    }
  }

  const handlegetAllCommitteData = async () => {
    try {
      const res = await axios.get(resources.APPLICATION_URL + "getcommittees");
      if (res.data) {
        setAllCommitteData(res.data);
      } else {
        alert("No data found")
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {

    handlegetAllCommitteData()
  }, [])

  const handleClose = () => {
    setIsEnable(false);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">S.No</StyledTableCell>
              <StyledTableCell align="center">Committee Name</StyledTableCell>
              <StyledTableCell align="center">Committee Co-Ordinator	</StyledTableCell>
              <StyledTableCell align="center">E-Mail</StyledTableCell>
              <StyledTableCell align="center">Mobile Number</StyledTableCell>
              <StyledTableCell align="center">Click Here for More Information</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allCommitteData.map((row,index) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {index+1}
                </StyledTableCell>
                <StyledTableCell align="center">{row.cmtee}</StyledTableCell>
                <StyledTableCell align="center">{row.cord}</StyledTableCell>
                <StyledTableCell align="center">{row.email}</StyledTableCell>
                <StyledTableCell align="center">{row.mno}</StyledTableCell>
                <StyledTableCell align="center">{
                  <button onClick={() => handleClickForMoreDetails(row.hlink)}>Click Here For More Details</button>
                }</StyledTableCell>

              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <React.Fragment>
        <Dialog
          fullScreen
          open={isEnable}
          onClose={handleClose}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <List>
          <TableContainer className="adminstration_table" component={Paper} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Table sx={{ maxWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className="adminstration_table_cell">
                        S No
                      </StyledTableCell>
                      <StyledTableCell className="adminstration_table_cell">
                        Member Name
                      </StyledTableCell>
                      <StyledTableCell className="adminstration_table_cell">
                        Position
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activeCommitte.map((row, index) => (
                      <StyledTableRow
                        className="adminstration_table_row"
                        key={row.name}
                      >
                        <StyledTableCell
                          className="adminstration_table_cell"
                          component="th"
                          scope="row"
                        >
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell className="adminstration_table_cell">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell className="adminstration_table_cell">
                          {row.position}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
          </List>
        </Dialog>
      </React.Fragment>
    </>
  );
}
