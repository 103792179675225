import React from "react";
import { Link } from "react-router-dom";
import principal from "../../assets/cat-4.jpg";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import principalImg from "../../assets/mittapalliPrince.jpg.jpg"
import "../CheairmanText.css"
const Principal = () => {
  return (
    <div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              
            >
              <div className="position-relative h-10">
                <img
                  className="DM_img"
                  src={`${principalImg}`}
                  alt="principal-img" style={{height:"600px",width:"15cm"}}
                  
                /><br/>
               <p style={{textAlign:"center"}}>
                    <h4><b>Dr. S. Gopi Krishna</b></h4>
                    <h6><b>M.Tech., Ph.D(CSE)</b></h6>
                    <h6><b>Principal</b></h6>
                    </p>
                
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h4 className="section-title bg-white text-start text-primary pe-3">
                Principal
              </h4>
              

              <div>
                <p style={{textAlign:"justify"}}>
                <p className="mb-2">
                  It is my immense pleasure to welcome you to Sri Mittapalli
                  College of Engineering an institution under Sri Mittapalli
                  Group of Institutions founded a decade ago by Sri. M.V.
                  Koteswararao. I assert that ?Education is the great engine of
                  personal and professional development for students?. SMCE
                  provides a noble engineering education which is a combination
                  of ethical values, innovative technical knowledge, creative
                  thinking and scope for extensive exploration in science and
                  technology.
                </p>

                <p className="mb-2">
                  We lay special emphasis on Outcome Based Education and
                  experimental learning. Our special academic program in
                  addition to the university curriculum navigates students to
                  gain excellent theoretical knowledge thinking skills,
                  analytical frameworks, entrepreneurial skills, and
                  interpersonal and communication skills.
                </p>

                <p className="mb-2">
                  SMCE has established strong relationships with industry and
                  over thirty prominent companies in various technical fields
                  throughout India. There is also an abundant scope in
                  co-curricular and extra-curricular activities to manifest
                  these technocrat?s spirit.
                </p>
                <p className="mb-2">
                  I admire our dedicated faculty who are well qualified and work
                  towards not just developing knowledge but also instilling the
                  right attitude, and technical skills among engineering
                  students for a holistic relevant in this globalized scenario.
                </p>
                <p className="mb-2">
                  Moreover, discipline in SMCE is a non-negotiable factor in a
                  student?s life which inculcates the value of punctuality and
                  time management.<br></br> 
                  I am proud to state that the college, with
                  its rich legacy will sustain to shape the career of future
                  young engineers of our country and transform their potential
                  into global business leaders to serve our nation.
                  <p><br/>
                  My Best regards to All!<br/>
                  Principal 
                  </p>
                </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="card_cntr1">
        <div
          className="boxes col-lg-3 col-sm-6 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="boxes1 service-item text-center pt-3">
            <div className="p-4">
              <h5 className="mb-1">Vision</h5>
              <p>
                To make the students achieve highest standards of quality
                technical education adaptable to suit the present day
                requirements in the fields of science and technology and to
                mould them morally into persons of character and integrity.
              </p>
            </div>
          </div>
        </div>
        <div
          className="boxes col-lg-3 col-sm-6 wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div className="boxes1 service-item text-center pt-3">
            <div className="p-4">
              <h5 className="mb-3">Mission</h5>
              <p>
                To awaken the students to the reality of identifying their
                hidden potentials and talents through meticulous and systematic
                grooming to gain the spirit and inventiveness to build a career
                full of glorious prospects and eventful future.
              </p>
            </div>
          </div>
        </div>
        <div
          className="boxes col-lg-3 text-center col-sm-6"
          data-wow-delay="0.5s"
        >
          <div className="boxes service text-center pt-3">
            <div className="p-4 ">
              <h5 className="mb-3">STRENGTHS</h5>
              <div>
                <Accordion sx={{ backgroundColor: "#fff" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Infrastructure & Faculty
                  </AccordionSummary>
                  <AccordionDetails>
                    Ultra Modern Labs with more than 430 Systems having multiple
                    platforms like Linux, Windows and Ubuntu. 24/7
                    InternetFacility with 300 MBPS throughtwo different ISPs.
                    Well Experienced and University Ratified Faculty.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Training & Placements
                  </AccordionSummary>
                  <AccordionDetails>
                    Conducting Industry relevant Training Programmes and
                    providing Placements in various MNCs through
                    On-Campus/Off-Campus.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    Research & Development
                  </AccordionSummary>
                  <AccordionDetails>
                    More than 50% Faculty Pursuing Ph.Ds from Reputed
                    Univeristies and publishing Their Research Papers in Various
                    Good Impact Journals.
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Principal;
