import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/ExaminationTable";
import StarRateIcon from "@mui/icons-material/StarRate";


const Committees = () => {
  const examination = [
    {
      title: "To conduct Internal & External Examinations"
    },
    {
      title: "To prepare & monitor the communication from University, College to University"
    },
    {
      title: "To maintain the records of university"
    },
    {
      title: "To serve the students for giving their records from university and college."
    },
    {
      title: "Any issues related to internal & external exams."
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            EXAMINATION COMMITTEE

          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            This committee is formulated to coordinate and systematize examinations. It follows the academic calenders and examinations schedule sent by the JNT University – Kakinada for the smooth functioning of curriculum accomplishment. The committee keeps a thorough track of discipline throughout the module of the session.
          </p>
          <p>Examination Committee is formed with the following members:</p>
        </div>
      </div>
      <div className="container">
        <Table />
      </div>
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {examination.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>

    </div>
  );
};

export default Committees;
