import React from "react";
import "../Committees/Committees.css";
import "../GoverningBody/GoverningBody.css"
import Table from "../CommitteesMenus/IACCTable";
import pdf from "../../assets/pdfsmittapalli/sem-1.pdf";
import pdf2 from "../../assets/pdfsmittapalli/sem-2.pdf";
import StarRateIcon from "@mui/icons-material/StarRate";


const Committees = () => {
  const iacc = [
    {
      title: "The NBA Co-ordinator will create the awareness about various accreditations and certification’s which are essential and mandatory for the institution. Monitoring the different activities related to the accreditation."
    },
    {
      title: "To create awareness of on outcome-based education to the faculty and the students."
    },
    {
      title: "To train the department heads on the preparation of SAR."
    },
    {
      title: "To review and prepare the SAR during the course of accreditation."
    },
    {
      title: "To add and adopt best practices as and when stipulated by the NBA."
    },
    {
      title: "To attend NBA workshops as organized by the authorities."
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            INSTITUTE ACCREDITATION’S & CERTIFICATION’S CO-ORDINATOR
          </a>
        </div>
      </div>
      <br />

      <div className="container">
        <Table />
      </div>


      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {iacc.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
