import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

function createData(SNo,NameoftheCommittee, HeadedBy, DepartmentAndDesignation) {
  return {
    SNo,
    NameoftheCommittee,
    HeadedBy,
    DepartmentAndDesignation,
    
  };
}

const rows = [
  createData(1,  'Dr. S. Gopi Krishna, Principal, SMCE', 'Head of the Institute','Chairman'),
  createData(2, 'Dr. Sk. J. Shareef, Profressor & HoD MBA', 'Coordinator of IQAC', 'Member Secretary'),
  createData(3, 'Dr. S.V. Naresh, Assoc. Professor, ECE', 'Faculty Representatives', 'Member'),
  createData(4, 'Dr. Sk. MD. Rafi, Profressor & HoD IT & AI', 'Faculty Representatives', 'Member'),
  createData(5, 'S. V. Kishore Kumar, Asst. Professor, CSE', 'Faculty Representatives', 'Member'),
  createData(6, 'Ms. M. Saraswathi, Assoc. Professor, MBA', 'Faculty Representatives', 'Member'),
  createData(7, 'M. Jaya Rao, Asst. Professor, ASH', 'Faculty Representatives', 'Member'),
 createData(8, '	M.V. Ramana, Asst. Professor, CSE', 'Faculty Representatives', 'Member'),
  createData(9, 'K. Tirupathaiah, Asst. Professor, ECE ', 'Faculty Representatives', 'Member'),
  createData(10, 'E. Adi Narayana, Assoc. Professor, CSE', 'Faculty Representatives', 'Member'),
  createData(11, 'M. Kishore Kumar, Vice - President, SMCE', 'Management Representativ', 'Member'),
  createData(12, 'Dr. M. Nageswara Rao, Assoc. Professor, ECE', 'Senior Administrative Faculty', 'Member'),
  createData(13, 'Dr. Y. Saritha, Assoc. Professor, ASH', 'Female Faculty Representative', 'Member'),
  createData(14, 'Dr. V. Madhuri, Professor & HoD ASH', 'Female Faculty Representative', 'Member'),
  createData(15, 'M. Naga Lakshmi, Assoc. Professor, MBA', 'Female Faculty Representative', 'Member'),
  createData(16, 'M. Parameswari, Secretary, Alumni Association', 'Nominee from Alumni', 'Member'),
  createData(17, 'A. Revanth, Savantis Solutions, Hyderabad.', 'Nominee from Employers', ' Member'),
  createData(18, 'G. Shasidhar, General Manager,Mittapalli Spinners Ltd', 'Nominee from Industrialists', 'Member'),
  createData(19, 'B. Sai Aakash Reddy (19U91A0517) ', 'Student Representative', 'Member'),
  createData(20, 'J. Lahari (19U91A0565)', 'Student Representative', 'Member'),
//   createData(21, '	Alumni Association Committee', 'Prof.K.Suresh	', 'Prof & HOD EEE'),
//   createData(22, 'Anti-Ragging Committee	', 'E.Sambasiva Rao', 'Prof & HOD MBA'),
//   createData(23, 'Departments Association Committee	', 'Dr.SK..MD.Rafi', 'Prof & HOD CSE'),
//   createData(24, 'Finance & Purchasing Committee	', 'Ch.Srinivasa Rao	', 'Administrative Officer '),
//   createData(25, 'R &D Consultancy Committee		', 'Dr.P.V.Naganjaneyulu	', 'Principal '),
//   createData(26, 'Health Center		', 'M.Jaya Rao		', 'Asst Prof ,ASH '),
//   createData(27, 'Womens protection and Grievances Cell		', 'Dr.V.Madhuri	', 'Prof & HOD ASH '),
//   createData(28, 'Public Relations, Press & Media, Publication, News letter and Magazine editorial Committee', 'N.Sri Prakash	', 'Asst Prof , ECE '),
//   createData(29, 'Right to Information Cell	', 'N.Eswara Rao', 'Asst Prof ,ASH'),


];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'S.No',
    numeric: true,
    disablePadding: true,
    align: 'center',
    label: 'S No',
  },
 {
    id: 'Name of the Committee',
    numeric: false,
    disablePadding: true,
    label: 'Member Name and Designation ',
    align: 'center',
  },  
 {
    id: 'Headed By',
    numeric: false,
    disablePadding: false,
    label: 'Composition',
  },


  {
    id: 'Department And Designation',
    numeric: false,
    disablePadding: true,
    label: 'Designation',
  },
  
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */
        }
        {headCells.map((headCell) => (
          <TableCell
          sx={{ background: "#000428;background: -webkit-linear-gradient(to right, #004e92, #000428);background: linear-gradient(to right, #004e92, #000428);", color: "whitesmoke" }}
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Committee Members
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    // hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{background:"rgb(239,239,187)"}}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell> */}
                    <TableCell
                      component="th"
                      id={labelId}
                      className='acdm_table'
                      scope="row"
                      padding="none"
                      align="center"
                    >
                      {row.SNo}
                    </TableCell>
                    <TableCell  className='acdm_table' align="center">{row.NameoftheCommittee}</TableCell>
                    <TableCell   className='acdm_table' align="center">{row.HeadedBy}</TableCell>
                    <TableCell   className='acdm_table' align="center">{row.DepartmentAndDesignation}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}