import React from "react";
import "./TrainingplacementCell.css";
const TrainingplacementCell = () => {
  return (
    <div className="placementcell">
      <h1>TrainingplacementCell</h1>
      <p >
        With an objective to endow with highest quality of education and
        training on employability skills to the students and to offer them with
        the best of job opportunities, Mr.Chakravarthi Mittapalli B.E., M.S
        (USA), returned to India to take up these challenges as the Director of
        Sri Mittapalli Group of Colleges. He took up the foremost step towards
        his dream by setting up a vivacious Training & Development team at SMCE
        adding the right balance of corporate and academic trainers. Unlike any
        general CRT (Campus Recruitment Training) program, the training sessions
        are organized for all the students around the academic year for the
        development of the students with a commitment to provide them with all
        the available job opportunities in the Industry.
      </p>
      <h5>TRAINING TEAMS – VISION COMPLEMENT</h5>
      <ul className="placement">
        <li>To promote English as a medium of communication in campus.</li>
        <li>To impart both employability as well as life skills to the students.</li>
        <li>To implement world class training methodologies.</li>
        <li>To provide job oriented and industry specific training to the students.</li>
      </ul>
      <h5>TRAINING TEAM’S COMMITMENT</h5>
      <ul className="placement">
        <li>To build value and image to the SMCE students as preferred candidates for campus recruitments.</li>
        <li>Design unique and researched training programmes which present new opportunities.</li>
      </ul>
      <h5>TRAINING TEAM’S STRATEGY</h5>
      <ul className="placement">
        <li>Practical and Job oriented training.</li>
        <li>Training content developed according to the students’ receptive level.</li>
        <li> Communication and soft skills trainings throughout the academics.</li>
        <li> 360° student development.[/vc_column_text][/vc_column][/vc_row]</li>
      </ul>
    </div>
  );
};

export default TrainingplacementCell;
