import React from "react";
import StarRateIcon from "@mui/icons-material/StarRate";
import "../GoverningBody/GoverningBody.css"

const Committees = () => {
  const financeTable = [
    {
      name: "Mr. Ch. Srinivasa Rao",
      department: "Admin",
      designation: "Convenor"
    },
    {
      name: "Mr. V. Srinivasa Rao",
      department: "Admin",
      designation: "Coorinator"
    },
    {
      name: "Ms. M. Vanisree",
      department: "Admin",
      designation: "Member"
    },
    {
      name: "Ms. M. Naga Lakshmi",
      department: "MBA",
      designation: "Member"
    },    {
      name: "Dr. S. Gopi Krishna",
      department: "CSE",
      designation: "Member"
    },
    {
      name: "Dr. Sk. J. Shareef",
      department: "MBA",
      designation: "Member"
    },
    {
      name: "Dr. Sk. MD. Rafi",
      department: "IT",
      designation: "Member"
    },
  ];

  const financeData = [
    {
      title:"Budget estimates relating to the grant received/receivable from UGC, and income from fees, etc. collected for the activities to undertake the scheme of autonomy; and"
    },
    {
      title:"Audited accounts for the above."
    }
  ]
  return (
    <div>
    <h2 style={{ textAlign: "center", fontWeight: "bold" }}>FINANCE COMMITTEE</h2>
    <div>
        <table className='gb_table'>
            <thead>
                <tr style={{fontWeight:"bold"}}>
                    <th>S.NO</th>
                    <th>MEMBER NAME</th>
                    <th>DEPARTMENTS</th>
                    <th>DESIGNATION</th>
                </tr>
            </thead>
            <tbody>
                {financeTable.map((each, i) => <tr>
                    <td>{i + 1}</td>
                    <td>{each.name}</td>
                    <td>{each.department}</td>
                    <td>{each.designation}</td>
                </tr>)}
            </tbody>
        </table>
    </div>
    <div className='governing_content'>
        <p>
            <span className="governing_sidehead">Functions of the Finance Committee :</span>
            <p>The Finance Committee shall act as an advisory body to the Governing Body, to consider:</p>
        </p>
        {financeData.map((item, index) => <div style={{ display: "flex" }}>
            <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
            <p>
                {" "}
                {item.title}
            </p>
        </div>)}
    </div>
</div>
  );
};

export default Committees;
