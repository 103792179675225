// eslite disabled

import React, { useEffect, useState } from "react";
import "./Placements.css";
import { Table, Button } from 'antd';
import axios from "axios";
import { resources } from "../Resourses/Resources";
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';


const PlacementRecords = () => {

  const [Placements, setPlacements] = useState([]);
  const [popup, setPopup] = useState(false);

  const [record, setRecord] = useState([]);
  const [data, setData] = useState([]);

  const [year, setYear] = useState("all");


  const handlePlacements = async (year) => {
    setYear(year)
    try {
      const res = await axios.get(resources.APPLICATION_URL + "summary");
      if (res.data) {
        setPlacements(
          res.data
        )
        console.log("response====>", JSON.stringify(res.data.filter(n => n.acadamicyear === year)))
        if (year === "all") {
          setData(res.data.map(n => ({...n, btn: "View"})))
        } else {
          setData(res.data.filter(n => n.acadamicyear === year).map(n => ({...n, btn: "View"})))
        }

      }
      else {
        alert("No Data Found")
      }


    } catch (e) {
      alert(e)

    }
  }

  useEffect(() => {
    handlePlacements("all");
  }, []
  )

  const columns = [
    {
      title: 'S.NO',
      // dataIndex: '1',
      render: (text, record, index) => index + 1,
      align: 'center',
    },
    {
      title: 'Academic Year',
      dataIndex: 'acadamicyear',
      sorter: {
        compare: (a, b) => a.academicyear - b.academicyear,
        multiple: 3,
      },
      align: 'center',
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      sorter: {

        compare: (a, b) => a.branch - b.branch,
        multiple: 2,
      },
      align: 'center',
    },

    {
      title: 'Numer of Students Placed',
      dataIndex: 'noOfStudentsPlaced',
      sorter: {
        compare: (a, b) => a.salary - b.salary,
        multiple: 1,
      },
      align: 'center',
    },
    {
      title: 'View Details',
      dataIndex: 'btn',
      render: (text, record) => (
        <Button key={text} onClick={() => handleButtonClick(record)}>View Details</Button>
      ),
      // sorter: {
      //   compare: (a, b) => a.salary - b.salary,
      //   multiple: 1,
      // },
      align: 'center',
    },
  ];
  const columns2 = [
    {
      title: 'S.NO',
      // dataIndex: '1',
      render: (text, record, index) => index + 1,
      align: 'center',
      sorter: {
        compare: (a, b) => a.hallticket - b.hallticket,
        multiple: 3,
      },
    },
    {
      title: 'Academic Year',
      dataIndex: "academicyear",
      align: 'center',
      sorter: {
        compare: (a, b) => a.academicyear - b.academicyear,
        multiple: 3,
      },
    },
    {
      title: 'Branch',
      dataIndex: "branch",
      align: 'center',
      sorter: {
        compare: (a, b) => a.branch - b.branch,
        multiple: 3,
      },
    },
    {
      title: 'Roll Number',
      dataIndex: 'hallticket',
      sorter: {
        compare: (a, b) => a.hallticket - b.hallticket,
        multiple: 3,
      },
      align: 'center',
    },
    {
      title: 'Student Name',
      dataIndex: 'name',
      sorter: {

        compare: (a, b) => a.name - b.name,
        multiple: 2,
      },
      align: 'center',
    },

    {
      title: 'Company Name',
      dataIndex: 'compamyname',
      sorter: {
        compare: (a, b) => a.compamyname - b.compamyname,
        multiple: 1,
      },
      align: 'center',
    },
    {
      title: 'Salary in Lakhs Per Annum',
      dataIndex: 'salary',
      sorter: {
        compare: (a, b) => a.salary - b.salary,
        multiple: 1,
      },
      align: 'center',
    },
    {
      title: 'Mail ID',
      dataIndex: 'mailid',
      sorter: {
        compare: (a, b) => a.mailid - b.mailid,
        multiple: 1,
      },
      align: 'center',
    },
  ];
  const handleButtonClick = async (key) => {
    console.log("record=====>",key)
    try {
      const res = await axios.get(resources.APPLICATION_URL + "getplacementcell?branch=" + key.branch + "&academicYear=" + key.acadamicyear);
      if (res.data) {
        console.log("response===========>", JSON.stringify(res.data))
        setRecord(
          res.data.filter(n => n.branch == key.branch && n.academicyear == key.acadamicyear)
        )
        setPopup(true)
      }
      else {
        alert("No Data Found")
      }

    } catch (e) {
      // alert(e)
      console.log(e)
    }



  }
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


  const handleClose = () => {
    setPopup(false)
  }
  return (
    <div className="placement_records_bg_cnt">
      <div className="side_cnt_placements">
        <button className={year === "all" ? "active_btn" : ""} type="button" onClick={() => handlePlacements("all")}>Show All</button>
        <button className={year === "2020-2021" ? "active_btn" : ""} type="button" onClick={() => handlePlacements("2020-2021")}>2020-2021</button>
        <button className={year === "2019-2020" ? "active_btn" : ""} type="button" onClick={() => handlePlacements("2019-2020")}>2019-2020</button>
        <button className={year === "2018-2019" ? "active_btn" : ""} type="button" onClick={() => handlePlacements("2018-2019")}>2018-2019</button>
        <button className={year === "2017-2018" ? "active_btn" : ""} type="button" onClick={() => handlePlacements("2017-2018")}>2017-2018</button>
        <button className={year === "2016-2017" ? "active_btn" : ""} type="button" onClick={() => handlePlacements("2016-2017")}>2016-2017</button>
        <button className={year === "2015-2016" ? "active_btn" : ""} type="button" onClick={() => handlePlacements("2015-2016")}>2015-2016</button>
        <button className={year === "2014-2015" ? "active_btn" : ""} type="button" onClick={() => handlePlacements("2014-2015")}>2014-2015</button>
      </div>
      <div className="main_cnt_placements">
        <div>
          <Table columns={columns} dataSource={data} onChange={onChange} />
        </div>
      </div>
      <React.Fragment>
        <Dialog
          fullScreen
          open={popup}
          onClose={handleClose}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <List>
            <div style={{ padding: "20px" ,width:"90%", margin:"auto",boxShadow:"black 0px 2px 8px 0px",marginBottom:"1cm",marginTop:"1cm"}}>              
          <Table columns={columns2} dataSource={record} onChange={onChange} />
          </div>
          </List>
        </Dialog>
      </React.Fragment>

    </div>
  );
};

export default PlacementRecords;












