import { useState } from "react"
import React from 'react'

const GrevienceForm = () => {
  const [data, setData] = useState(
    {
      name: "",
      email: "",
      phone: "",
      category: "",
      description: "",
      roll:""
    },
  )
  const Reset = () => {
    
    setData({
      name: "",
      email: "",
      phone: "",
      category: "",
      description: "",
      roll:""
    })
  }
  const handleChange = (e) => {
    const {value , name} = e.target
    setData({ ...data, [name]:value })
  }
  return (
    <div>
      <div className='grevience_form_main'>
        <div className='grevience_form_header'>
          <h2>GRIEVANCE FORM</h2>
        </div>
        <div className='grevience_form_body'>
        <h6>
          To<br />
          Respected Principal,<br />
          Sai SpurthiInstitute Of Technology,<br />
          B.Gangaram,<br />
          Sathupally
        </h6><br/>
        <div>
          <div className='grevience_inp_container'>
            <h6>
            Grievance Category
            </h6>
            <select onChange={(e) => handleChange(e)} name="category" value={data.category}>
              <option>
                Select Category
              </option>
              <option>SC/ST/BC</option>
              <option>Anti Ragging</option>
              <option>Sexual Harassment</option>
              <option>Examination Related</option>
              <option>Others</option>
            </select>
          </div>
          <div className='grevience_inp_container'>
            <h6>
            Name
            </h6>
            <input onChange={(e) => handleChange(e)} placeholder='Enter Name' name="name" value={data.name} type='text'/>
          </div>
          <div className='grevience_inp_container'>
            <h6>
            Roll Number
            </h6>
            <input onChange={(e) => handleChange(e)}  placeholder='Enter Roll Number' name="roll" value={data.roll} type='text'/>
          </div>
          <div className='grevience_inp_container'>
            <h6>
            Email
            </h6>
            <input onChange={(e) => handleChange(e)} placeholder='Enter Email' name="email" value={data.email} type='text'/>
          </div>
          <div className='grevience_inp_container'>
            <h6>
            Contact
            </h6>
            <input onChange={(e) => handleChange(e)} placeholder='Enter Contact Details' name="phone" value={data.phone} type='number'/>
          </div>
          <div className='grevience_inp_container'>
            <h6>
            Message
            </h6>
            <textarea onChange={(e) => handleChange(e)} placeholder='Enter your Message' name="description" value={data.description} type='text'/>
          </div>
        </div>
        <div className='grevience_form_btn'>
          <button>Submit</button>
          <button onClick={(e) => Reset(e)}>Reset</button>
        </div>
        </div>
      </div>
    </div>
  )
}

export default GrevienceForm