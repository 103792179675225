import React from 'react'
import Table from "../../Academics/Admissins/Table";
import Table2 from "../../Academics/Admissins/Table2";

const FeeStructure = () => {
  return (
    <div>
      <br />
      <div className='container'>
        <div class="navbar navbar-expand-lg navbar-dark bg-dark">
          <div class="container">
            <a class="navbar-brand" href="#">
              Fee Structure
            </a>
          </div>
        </div>
      </div><br />
      <div className='container'>
        <div class="navbar navbar-expand-lg navbar-dark bg-dark">
          <div class="container">
            <a class="navbar-brand" href="#">
              Syllabi of B.Tech. Full-Time Programmes

            </a>
          </div>
        </div>
      </div>
      <br />
      <div className='container table_content'>
        <Table />
      </div><br />
      <div className='container'>
        <div class="navbar navbar-expand-lg navbar-dark bg-dark">
          <div class="container">
            <a class="navbar-brand" href="#">
              Syllabi of PG Full Time Programmes
            </a>
          </div>
        </div>
      </div><br />
      <div className='container'>
        <Table2 />
      </div>

    </div>
  )
}

export default FeeStructure
