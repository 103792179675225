import React from 'react'
import { Link } from 'react-router-dom';

const Organogram = () => {
  return (
    <div>
        
        <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top d-flex   p-0">
       
       <button
         type="button"
         className="navbar-toggler me-4"
         data-bs-toggle="collapse"
         data-bs-target="#navbarCollapse"
       >
         <span className="navbar-toggler-icon"></span>
       </button>
       <div className="collapse navbar-collapse" id="navbarCollapse">
         <div className="navbar-nav ms-auto p-4 p-lg-0">
           <Link to="/" className="nav-item nav-link active">
             HOME
           </Link>

           <div className="nav-item dropdown">
             <Link
               to="#"
               className="nav-link dropdown-toggle"
               data-bs-toggle="dropdown"
             >
               ADMINISTRATION
             </Link>
             <div className="dropdown-menu fade-down m-0">
               <Link to="#" className="dropdown-item">
                 ABOUTUS
               </Link>
               <Link to="#" className="dropdown-item">
                 MANAGEMENT
               </Link>
               <Link to="#" className="dropdown-item">
                 SOCIETY
               </Link>
               <Link to="#" className="dropdown-item">
                 VISSION & MISSION
               </Link>
               <Link to="#" className="dropdown-item">
                 QUALITY POLICY
               </Link>
               <Link to="#" className="dropdown-item">
                 ORGANOGRAM
               </Link>
               <Link to="#" className="dropdown-item">
                 GOVERNING BODY
               </Link>
               <Link to="#" className="dropdown-item">
                 PRINCIPAL
               </Link>
               <Link to="#" className="dropdown-item">
                 ACADAMIC COUNCIL
               </Link>
               <Link to="#" className="dropdown-item">
                 MANDATORY DISCLOSURE
               </Link>
               <Link to="#" className="dropdown-item">
                 STRENGTHS
               </Link>
             </div>
           </div>

           <div className="nav-item dropdown">
             <Link
               to="#"
               className="nav-link dropdown-toggle"
               data-bs-toggle="dropdown"
             >
               ACADAMICS
             </Link>
             <div className="dropdown-menu fade-down m-0">
               <Link to="#" className="dropdown-item">
                 ACADAMIC REGULATIONS
               </Link>
               <Link to="#" className="dropdown-item">
                 ACADAMIC CALENDER
               </Link>
               <Link to="#" className="dropdown-item">
                 INCENTIVES
               </Link>
               <Link to="#" className="dropdown-item">
                 SOCIAL SERVICE
               </Link>
             </div>
           </div>

         

           <div className="nav-item dropdown">
             <Link
               to="#"
               className="nav-link dropdown-toggle"
               data-bs-toggle="dropdown"
             >
               DEPARTMENTS
             </Link>
             <div className="dropdown-menu fade-down m-0">
               <Link to="#" className="dropdown-item">
                 CSE
               </Link>
               <Link to="#" className="dropdown-item">
                 CSE(AL&ML)
               </Link>
               <Link to="#" className="dropdown-item">
                 EEE
               </Link>
               <Link to="#" className="dropdown-item">
                 AI&DS
               </Link>
               <Link to="#" className="dropdown-item">
                 CIVIL
               </Link>
               <Link to="#" className="dropdown-item">
                 MECH
               </Link>
               <Link to="#" className="dropdown-item">
                 S&H
               </Link>
               <Link to="#" className="dropdown-item">
                 MBA
               </Link>
               <Link to="#" className="dropdown-item">
                 POLYTECHNIC
               </Link>
             </div>
           </div>

           <div className="nav-item dropdown">
             <Link
               to="#"
               className="nav-link dropdown-toggle"
               data-bs-toggle="dropdown"
             >
               ADMISSIONS
             </Link>
             <div className="dropdown-menu fade-down m-0">
               <Link to="#" className="dropdown-item">
                 ADMISSION PROCEDURE
               </Link>
               <Link to="#" className="dropdown-item">
                 FEE STRUCTURE
               </Link>
               <Link to="#" className="dropdown-item">
                 ADMISSION FORM
               </Link>

               
             </div>
           </div>

           <div className="nav-item dropdown">
             <Link
               to="#"
               className="nav-link dropdown-toggle"
               data-bs-toggle="dropdown"
             >
               GOVERNANCE
             </Link>
             <div className="dropdown-menu fade-down m-0">
               <Link to="#" className="dropdown-item">
                 COUNSELLING
               </Link>
               <Link to="#" className="dropdown-item">
                 EXAMINATION BRANCH
               </Link>
               <Link to="#" className="dropdown-item">
                 ADMINISTRATIVE OFFICE
               </Link>
               <Link to="#" className="dropdown-item">
                 POLICIES
               </Link>
               <Link to="#" className="dropdown-item">
                 COMMITTEES
               </Link>

              
             </div>
           </div>

           <div className="nav-item dropdown">
             <Link
               to="#"
               className="nav-link dropdown-toggle"
               data-bs-toggle="dropdown"
             >
               AMENITIES
             </Link>
             <div className="dropdown-menu fade-down m-0">
               <Link to="#" className="dropdown-item">
                 LIBRARY
               </Link>
               <Link to="#" className="dropdown-item">
                 DIGITAL LIBRARY
               </Link>
               <Link to="#" className="dropdown-item">
                 INTERNET
               </Link>
               <Link to="#" className="dropdown-item">
                 ENGLISH LABS
               </Link>
               <Link to="#" className="dropdown-item">
                 ICT FECILITIES
               </Link>
               <Link to="#" className="dropdown-item">
                 SEMINAR HALLS
               </Link>
               <Link to="#" className="dropdown-item">
                 SPORTS & GAMES
               </Link>
               <Link to="#" className="dropdown-item">
                 CAFETERIA
               </Link>
               <Link to="#" className="dropdown-item">
                 HOSTELS
               </Link>
               <Link to="#" className="dropdown-item">
                 TRANSPORTATION
               </Link>
               <Link to="#" className="dropdown-item">
                 E-CLASS ROOMS
               </Link>
               <Link to="#" className="dropdown-item">
                 STAFF QUARTERS
               </Link>
               <Link to="#" className="dropdown-item">
                 TEMPLE IN COLLEGE
               </Link>

              
             </div>
           </div>

           <Link to="/contactus" className="nav-item nav-link">
             CONTACTUS
           </Link>
         </div>
        
       </div>
     </nav>
        
         <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
    <h6 className="section-title bg-white text-left text-primary px-3">
    ORGANOGRAM
    </h6>
    <Link to="/" className="nav-item nav-link active">
              Organizition Chart
            </Link>
  </div>

  <div
    className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
    data-wow-delay="0.1s"
  >
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-3 col-md-6">
          <h4 className="text-white mb-3">Quick Link</h4>
          <Link className="btn btn-link" to="#">
            Results
          </Link>
          <Link className="btn btn-link" to="#">
            How to Reach
          </Link>
          <Link className="btn btn-link" to="#">
            Alumini
          </Link>
          <Link className="btn btn-link" to="#">
            SSIT News Letters
          </Link>
          <Link className="btn btn-link" to="#">
            Downloads
          </Link>
          <Link className="btn btn-link" to="#">
            Webmail
          </Link>
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-white mb-3">Contact</h4>
          <p className="mb-2">
            <i className="fa fa-map-marker-alt me-3"></i>Sai Spurthi
            Institute Of Technology B.Gangaram (V), Sathupalli (Mandal),
            Khammam District,Telangana-507303,
            <br />
            08761-288544,
          </p>
          <p className="mb-2">
            <i className="fa fa-phone-alt me-3"></i>Principal - +91
            9963084558,
            <br /> Director - +91 9491109564,
          </p>
          <p className="mb-2">
            <i className="fa fa-envelope me-3"></i>sssit.principal@gmail.com
          </p>
          <div className="d-flex pt-2">
            <Link
              className="btn btn-outline-light btn-social"
              to="https://twitter.com/i/flow/login?redirect_after_login=%2FSaiSpurthiMedia"
            >
              <i className="fab fa-twitter"></i>
            </Link>
            <Link
              className="btn btn-outline-light btn-social"
              to="https://www.facebook.com/people/Sai-Spurthi-Media/61550019525949/"
            >
              <i className="fab fa-facebook-f"></i>
            </Link>
            <Link
              className="btn btn-outline-light btn-social"
              to="https://www.youtube.com/@saispurthimedia"
            >
              <i className="fab fa-youtube"></i>
            </Link>
            <Link className="btn btn-outline-light btn-social" to="#">
              <i className="fab fa-linkedin-in"></i>
            </Link>
            <Link
              className="btn btn-outline-light btn-social"
              to="https://www.instagram.com/saispurthimedia/"
            >
              <i className="fab fa-instagram"></i>
            </Link>
            <Link
              className="btn btn-outline-light btn-social"
              to="https://in.pinterest.com/saispurthimedia/"
            >
              <i className="fab fa-pinterest"></i>
            </Link>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-white mb-3">logo</h4>
        
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-white mb-3">Newsletter</h4>
          {/* <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p> */}
          <iframe
            className="position-relative rounded w-100 h-100"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
            frameborder="0"
            style={{ minHeight: "200px", border: "0" }}
            allowfullscreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
          <div
            className="position-relative mx-auto"
            style={{ maxWidth: "400px" }}
          >
            <input
              className="form-control border-0 w-100 py-3 ps-4 pe-5"
              type="text"
              placeholder="Your email"
            />
            <button
              type="button"
              className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
            >
              SignUp
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="copyright">
        <div className="row">
          <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
            &copy;{" "}
            <Link className="border-bottom" to="#">
              http://www.saispurthi.ac.in
            </Link>
            , All Right Reserved.
            {/* This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. */}
            Designed By{" "}
            <Link className="border-bottom" to="https://htmlcodex.com">
              Adiverse Technologies
            </Link>
          </div>
          <div className="col-md-6 text-center text-md-end">
            <div className="footer-menu">
              <Link to="#">Home</Link>
              <Link to="#">Cookies</Link>
              <Link to="#">Help</Link>
              <Link to="#">FQAs</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default Organogram