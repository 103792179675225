import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/LibraryTable";
import StarRateIcon from "@mui/icons-material/StarRate";

const Committees = () => {
  const library = [
    {
      title:".Collects the requirements / recommendations of books from the individual departments."
      
    },
    {
      title:"Finalizes the title, authors and prices of the books."
    },
    {
      title:"Recommends the appropriate Journals, Magazines and Periodicals in  both hard and soft forms."
     
    },
    {
      title:"Gets the approval for the purchase of the required by the Principal."
      
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            LIBRARY COMMITTEE
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            Performance of all jobs related to library technical services,
            including acquiring new books, Journals, Audio, Video materials and
            classification of books based on different streams of engineering.
            To assist the librarian for getting the latest books /journals in
            various subjects.
          </p>
          <p>Library Committee is formed with the following members:</p>
          
        </div>
      </div>
      <div className="container">
        <Table />
      </div>
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {library .map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
