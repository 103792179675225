import React from "react";
import "./AuditReport.css";

const AuditReports = () => {
  return (
    
    <div className="container">
      <h4 className="text">AUDIT REPORTS</h4>
      <div className="container_bg">
      <div className="btn_text">
        <button type="button" className="btn_audit">
          <a href="https://smce.ac.in/wp-content/uploads/2022/10/2017-18-2.pdf" target="blank">2017-18 Audit Report </a>
        </button>
        <button type="button" className="btn_audit">
          <a href="https://smce.ac.in/wp-content/uploads/2022/10/Audit-Report-2018-19.pdf" target="blank">2018-19 Audit Report </a>
        </button>
        <button type="button" className="btn_audit">
          <a href="https://smce.ac.in/wp-content/uploads/2022/10/A.Y.2019-20.pdf">2019-20 Audit Report </a>
        </button>
       
      </div>
      <div className="btn_text">
        
        <button type="button" className="btn_audit">
          <a href="https://smce.ac.in/wp-content/uploads/2022/10/it-returns-2020-21.pdf">2020-21 Audit Report </a>
        </button>
        <button type="button" className="btn_audit">
          <a href="https://smce.ac.in/wp-content/uploads/2022/10/2021-22-2-1.pdf">2021-22 Audit Report </a>
        </button>
        <button type="button" className="btn_audit">
          <a href="#">2022-23 Audit Report </a>
        </button>
      </div>
      <div className="btn_text">
        
        
      </div>
      </div>
    </div>
  );
};

export default AuditReports;
