import React from 'react'

const GrievanceContact = () => {
    const GrievanceContact = [
        {
            name:"Dr. Sk. J. Shareef",
            mobile:"9912580344"
        },
        {
            name:"Ch. Srinivasa Rao",
            mobile:"9000447117"
        },
        {
            name:"P.L.N. Manoj Kumar",
            mobile:"9398488824"
        },
        {
            name:"Y. Ashok",
            mobile:"8074531061"
        },
        {
            name:"J. Ramesh",
            mobile:"6303125949"
        },
        {
            name:"G. Raja Kumar",
            mobile:"8309102321"
        },
        {
            name:"M. Parameswara  Rao",
            mobile:"7013226023"
        }
    ]
  return (
    <div>
      <p className='gr_header'>Grievance Contact Information</p>
      <table className='gb_table' style={{width:"100%"}}>
                    <thead>
                        <tr>
                            <th>
                                S.NO
                            </th>
                            <th>
                            Name of the Committee Member
                            </th>
                            <th>
                            Mobile
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {GrievanceContact.map((each, i) => <tr>
                            <td>
                                {i + 1}
                            </td>
                            <td>
                                {each.name}
                            </td>
                            <td>
                                {each.mobile}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
    </div>
  )
}

export default GrievanceContact
