// import React from "react";
// import "../Amenities/Classroom.css";
// import LibraryImg from "./../../assets/hostelMI.jpg";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import HealthCenterimg from "../../src/assets/healthMi.jpg";
import React from "react";
const HealthCenter = () => {
  const facilities = [
    {
      title: "JST",
      course: "J- GATE SCIENCE AND TECHNOLOGY",
    },
    {
      title: "DELNET",
      course: "E-journals",
    },
    {
      title: "NDL",
      course: "NDL India",
    },
    {
      title: "SHODHA SINDHU",
      course: "ONLINE E- RESOURCE REUISITION SYSTEMS",
    },
  ];
  const staff = [
    {
      name: "G.UPENDRA RAO",
      qualification: "MA.B.Ed.,MLISc,PGDCA	",
      designation: "LIBRARIAN",
    },
    {
      name: "V.VENKATESWER RAO",
      qualification: "BA,B.LISc.",
      designation: "Library Asst",
    },
    {
      name: "O.VENKATA KRISHNA",
      qualification: "BSc COMPUTERS.",
      designation: "BOOK KEEPER",
    },
    {
      name: "M.MAHA LAKSHMI",
      qualification: "SSC",
      designation: "ATTENDER",
    },
  ];
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <div className="classroom">
        <img src={HealthCenterimg} alt="HealthCenterimg" />
        <div>
          <h3 className="h3"> Health Center</h3>
          <p className="hostel">
            SMCE provides medicare for all our students and staff. Health care facilities for students and staff are felt to be the basic need. A doctor has been appointed as College Doctor. The Health Centre functions to provide immediate medicinal and first aid to all students and staff if the situation arises. The Health Centre is well equipped with respect to the availability of basic health related facilities like thermometer, sterilizer, auto-clave, dressing drum, weight measuring machine, B. P. Apparatus, patient bed etc. In case of complications, the patients are referred to Katuri medical college and hospital in Kodrupadu which is very nearer to the institution (5 Km). Transportation is ready 24×7 for students’ care.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HealthCenter;
