import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/PROFESSIONALTable";
import StarRateIcon from "@mui/icons-material/StarRate";

const Committees = () => {
  const professionalSocieties =[
    {
      title:"Motivating all the staff and students to be members of the Societies/Chapters such as ISTE, IEEE, IETE etc."
    },
    {
      title:"Planning, preparing and sending proposals to various professional societies for obtaining their grants/permissions etc."
    },
    {
     title:"Arranging and conducting students chapter activities programmes effectively."
    },
    {
     title:"Conducting meeting periodically for the effectiveness of the activities."
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            PROFESSIONAL SOCIETIES/CHAPTERS COMMITTEE
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            The committee aim is to develop student’s academic, professional and
            personality development skills through various programs, activities
            arranged by the association. To monitor and control effective
            conduct of such programs ensuring regular attendance, active
            participation and involvement of students.
          </p>
          <p> Participation and involvement of students.</p>
          <p>Professional Societies/Chapters Committee is formed with the following members:
</p>
          <div className="container">
        <Table />
      </div>
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {professionalSocieties.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
        </div>
      </div>
     
    </div>
  );
};

export default Committees;
