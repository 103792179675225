import React from "react";
import './Placements.css'
import 'animate.css'

const Recruiters = () => {
  return (
    <div className="animate__animated animate__zoomIn">
      <div className="recruiters_table_cnt">
        <table className="recruiters_table">
          <thead>
            <tr>
              <th>S.No</th>
              <th> Comany Name</th>
              <th>Branch</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Google</td>
              <td>CSE</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Microsoft</td>
              <td>CSE</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Recruiters;
