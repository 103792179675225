import React from 'react'
import "./BOS.css"
import BOSFunctions from './BOSFunctions'

const English = () => {
    const english = [
        {
            member: "Chairperson",
            designation: "Dr.V.Madhuri, Professor of AS&H Dept. & HOD"
        },
        {
            member: "University Nominee",
            designation: "Dr.T.Ashok,Professor of English,  AKNU, Rajahmundry. "
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr. N. Veeranjaneyulu Naik, M.A., M.Phil., Ph.D. Associate Professor of English Malla Reddy College of Engineering and Technology  Hyderabad, Telangana 8374297273 noonsavathun@gmail.com "
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr. K. Ponnari Lakshmi M.A., M.Phil., Ph.D. Professor of English Narasaraopet Engineering College  9296805205 ponnari.nec@gmail.com "
        },
        {
            member: "Representative from Industry",
            designation: "Dr.P.Lova Kumar Sub Divisional Engineer, CNTX South BSNL HYDERABAD. lovakumarbsnl@gmail.com  9490427439"
        },
        {
            member: "Alumni Member",
            designation: "R.Bhuvaneswari, Asst.Prof., Dept.of CSE,   SMCE.    bhuvana.rayapudi111@gmail.com  9398788846         "
        },
        {
            member: "Member",
            designation: "Mr.M.Jaya Rao, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Mrs.G.Mery Janeefa, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Mr.M.Sujan Kumar, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "DR.Y.Saritha, Asst.Prof. SMCE"
        },
    ]

    return (
        <>
            <h4 class="_Top">SRI MITTAPALLI COLLEGE OF ENGINEERING <br />
                Department of English <br />
                Composition of Board of Studies
            </h4 >
            <h3 class="A">
                A.Y.: 2023-24
            </h3>
            <div>

                <table  className='gb_table'>
                    <thead>
                        <tr>
                            <th>
                                S.NO
                            </th>
                            <th>
                                MEMBERS
                            </th>
                            <th>
                                NAME & DESIGNATION
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {english.map((each, i) => <tr>
                            <td>
                                {i + 1}
                            </td>
                            <td>
                                {each.member}
                            </td>
                            <td>
                                {each.designation}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div >
            <div className='bottom'>
                <h3 className='_chairperson'>Chairperson</h3>
                <p>BoS- English</p>
            </div>
            <BOSFunctions />
        </>
    )
}

export default English
