import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import csefaculty from "../../../assets/cat-4.jpg";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PageHeader } from "../../Header/PageHeader";
import "../Departments.css";
import Labs from "./Labs";
import Faculty from "./Faculty";

import axios from "axios";
import { resources } from "../../Resourses/Resources";

import event1 from "../../../assets/cat-4.jpg";
import event2 from "../../../assets/cat-4.jpg";
import event3 from "../../../assets/cat-4.jpg";

import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CourseStructure = () => {
  return (
    <div className="containe">
      <PageHeader name="S&H - COURSE STRUCTURE" />
      <div className="course_structure_cnt animate__animated animate__zoomIn">
        <div className="course text-center">
          <h4>Course Structure for all Years</h4>
          <p>
            Download the Course Structure and Syllabus of four years of S&H
            course
          </p>
        </div>

        <div className="w-100 text-center d-flex justify-content-center gap-5 ">
          <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>
              B-Tech MR23
            </span>
            <a href="#" target="blank">
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div>
          <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>
              B-Tech R20
            </span>
            <a href="#" target="blank">
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div>
          {/* <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>
              I B-Tech R16
            </span>
            <a href="#" target="blank">
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};
const Events = () => {
  return (
    <div className="container">
      <PageHeader name="ACTIVITIES & EVENTS" />

      <div className="course text-center animate__animated animate__fadeInRight">
        <h4>Student Development Activities</h4>
        {/* <br />
        <p className="prg_content">
          The Orientation Day is conducted by the college Freshman orientation
          is a way for students to meet other students, become familiar with
          campus services. Every student attending college should add
          orientation to their to-do list in college.
        </p> */}
      </div>
      {/* <h4 className="text-center m-5">Events Photos</h4>
      <div className="d-flex justify-content-center gap-5">
        <div>
          <img src={event1} className="img-fluid rounded" alt="eeeEvents" />
        </div>
        <div>
          <img src={event2} className="img-fluid rounded" alt="eeeEvents" />
        </div>
        <div>
          <img src={event3} className="img-fluid rounded" alt="eeeEvents" />
        </div>
      </div> */}
    </div>
  );
};

const Obe = () => {
  return (
    <div className="container">
      <PageHeader name="PEOs/POs/PSOs" />
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM EDUCATIONAL OBJECTIVES(PEO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PEO 1:</h2>
              <p>
                To acquire the essential knowledge of basic sciences and
                fundamentals in engineering and analyze technical solutions to
                computational problems by developing the algorithms.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 2:</h2>
              <p>
                To apply knowledge in the identification, design, development,
                production, configuration and maintenance of computing systems
                for real life problems.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 3:</h2>
              <p>
                To develop managerial skills, meeting societal needs,
                leadership, entrepreneurial skills, sustainable competitive edge
                in R&D and adhering professional and ethical skills.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM OUTCOMES(PO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PO 1:</h2>
              <p>
                Engineering Knowledge: Apply the knowledge of mathematics,
                science, engineering fundamentals, and an engineering
                specialization to the solution of complex engineering problems.
              </p>
            </div>
            <div class="peo">
              <h2>PO 2:</h2>
              <p>
                Problem Analysis: Identify, formulate, review research
                literature, and analyze complex engineering problems reaching
                substantiated conclusions using first principles of mathematics,
                natural sciences, and engineering sciences.
              </p>
            </div>
            <div class="peo">
              <h2>PO 3:</h2>
              <p>
                Design/Development of solutions: Design solutions for complex
                engineering problems and design system components or processes
                that meet the specified needs with appropriate consideration for
                the public health and safety, and the cultural, societal, and
                environmental considerations.
              </p>
            </div>
            <div class="peo">
              <h2>PO 4:</h2>
              <p>
                Conduct Investigations of Complex problems: Use research-based
                knowledge and research methods including design of experiments,
                analysis and interpretation of data, and synthesis of the
                information to provide valid conclusions.
              </p>
            </div>
            <div class="peo">
              <h2>PO 5:</h2>
              <p>
                Modern Tool Usage: Create, select, and apply appropriate
                techniques, resources, and modern engineering and IT tools
                including prediction and modeling to complex engineering
                activities with an understanding of the limitations.
              </p>
            </div>
            <div class="peo">
              <h2>PO 6:</h2>
              <p>
                The Engineer and Society: Apply reasoning informed by the
                contextual knowledge to assess societal, health, safety, legal
                and cultural issues and the consequent responsibilities relevant
                to the professional engineering practice.
              </p>
            </div>
            <div class="peo">
              <h2>PO 7:</h2>
              <p>
                Environment and Sustainability: Understand the impact of the
                professional engineering solutions in societal and environmental
                contexts, and demonstrate the knowledge of, and need for
                sustainable development.
              </p>
            </div>
            <div class="peo">
              <h2>PO 8:</h2>
              <p>
                Ethics: Apply ethical principles and commit to professional
                ethics and responsibilities and norms of the engineering
                practice.
              </p>
            </div>
            <div class="peo">
              <h2>PO 9:</h2>
              <p>
                Individual and Team Work: Function effectively as an individual,
                and as a member or leader in diverse teams, and in
                multidisciplinary settings.
              </p>
            </div>
            <div class="peo">
              <h2>PO 10:</h2>
              <p>
                Communication: Communicate effectively on complex engineering
                activities with the engineering community and with society at
                large, such as, being able to comprehend and write effective
                reports and design documentation, make effective presentations,
                and give and receive clear instructions.
              </p>
            </div>
            <div class="peo">
              <h2>PO 11:</h2>
              <p>
                Project Management and Finance: Demonstrate knowledge and
                understanding of the engineering and management principles and
                apply these to one’s own work, as a member and leader in a team,
                to manage projects and in multidisciplinary environments.
              </p>
            </div>
            <div class="peo">
              <h2>PO 12:</h2>
              <p>
                Life-Long Learning: Recognize the need for, and have the
                preparation and ability to engage in independent and life-long
                learning in the broadest context of technological change.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM SPECIFIC OUTCOMES (PSO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PSO1:</h2>
              <p>
                Foundation of mathematical concepts: To use mathematical
                methodologies to crack problem using suitable mathematical
                analysis, data structure and suitable algorithm.
              </p>
            </div>
            <div class="peo">
              <h2>PSO2:</h2>
              <p>
                Foundation of Computer System: The ability to interpret the
                fundamental concepts and methodology of computer systems.
                Students can understand the functionality of hardware and
                software aspects of computer systems.
              </p>
            </div>
            <div class="peo">
              <h2>PSO3:</h2>
              <p>
                Foundations of Software development: The ability to grasp the
                software development lifecycle and methodologies of software
                systems. Possess competent skills and knowledge of software
                design process. Familiarity and practical proficiency with a
                broad area of programming concepts and provide new ideas and
                innovations towards research.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          COURSE STRUCTURE (CO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <ul class="course_outcomes_cnt">
              <li>
                <a href="#" target="_blank">
                  R18
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  R22
                </a>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
};

const AboutDepartment = () => {
  return (
    <div>
      <div className="container">
        <PageHeader name="SCIENCE & HUMANITIES" />
        <div className="container-xxl">
          <div className="container-xxl py-1">
            <div className="container">
              <div className="row g-5">
                <div
                  className="col-lg-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{ minHeight: "80px" }}
                >
                  <div className="position-relative h-80 animate__animated animate__zoomIn">
                    <img
                      className="img_department"
                      src={csefaculty}
                      alt="csefaculty-img"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">

                  {/* <h1 className="mb-4">About</h1> */}

                  <div className="row gy-2 gx-2 mb-2">
                    <p className="prg_content_About">
                      <h1 >
                        Faculty
                      </h1>
                      <br/>
                      “The department aims at promoting growth of the students
                      by molding them be the ‘forerunners ‘ in their chosen
                      fields”. This unique Science and Humanities department
                      plays a significance role- touching the souls of the
                      students when they are afresh. Established in the year
                      2006, it strives to collate the approaches of Sciences and
                      Technologies making the students ready to receive the hard
                      core engineering principles. The department’s modern
                      infrastructure includes English Language and Communication
                      Skills Lab, Physics Lab and Chemistry Lab. Unlike other
                      departments, AS&H is the union of Chemistry, Physics,
                      Mathematics, Environmental Science and English. This Union
                      to gather strives to familiarize technical education the
                      new students.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            <div className="card_cntr1">

              {/* <div className="boxes1 service-item text-center pt-3 shadow"> */}
              <div className="hov">

                <h5 className="mb-1">Vision of the Department:</h5>
                <br />
                <p className="prg_content_cse">
                  To produce professional, ethical and globally competent
                  graduates by imparting quality education in the field of
                  Computer Science & Engineering with capabilities to solve a
                  wide range of complex, scientific, technological and
                  societal problems.
                </p>


              </div>

              <div className="hov">
                <h5 className="mb-3">Mission</h5>
                <br />
                <p className="prg_content_cse">
                  <ArrowForwardOutlinedIcon />
                  To impart total quality education through effective teaching-learning techniques with industries collaboration.<br />
                  <ArrowForwardOutlinedIcon />
                  To mold the young dynamic potential minds to emerge as full fledged future professionals so as to achieve their goals<br />
                  {/* <ArrowForwardOutlinedIcon /> */}
                  {/* Well planned academic curriculum.<br />
                  <ArrowForwardOutlinedIcon />
                  Course delivery through real world applications.<br />
                  <ArrowForwardOutlinedIcon /> */}
                  {/* Career Prospects. */}
                </p>
              </div>

              <div className="hov">
                <h5 >STRENGTHS:</h5>
                <div>
                  <Accordion className="prg_content_cse"
                    sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ backgroundColor: "#fff" }}
                    >
                      Vibrant Academic Environment
                    </AccordionSummary>
                    <AccordionDetails
                      align="center"
                      sx={{
                        backgroundColor: "#fff5dc",
                        color: "#000",
                        fontSize: "12px",
                        fontFamily: "Arial",
                        marginBottom: "10px",
                        letterSpacing: "1px",
                      }}
                    >
                      Well-experienced, dedicated and strongly motivated Faculty.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      Application oriented teaching development of soft-skills
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        backgroundColor: "#fff5dc",
                        color: "#000",
                        fontSize: "12px",
                        fontFamily: "Arial",
                        marginBottom: "10px",
                        letterSpacing: "1px",
                      }}
                    >
                      Interaction with each student through counseling.
                    </AccordionDetails>
                  </Accordion>
                  {/* <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3-content"
                      id="panel3-header"
                    >
                      M3
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        backgroundColor: "#fff5dc",
                        color: "#000",
                        fontSize: "12px",
                        fontFamily: "Arial",
                        marginBottom: "10px",
                        letterSpacing: "1px",
                      }}
                    >
                      To establish a continuous Industry Institute
                      Interaction, participation, collaboration to contribute
                      skilled IT engineers.
                    </AccordionDetails>
                  </Accordion> */}
                </div>
              </div>





            </div>}
        </div>
      </div>
    </div>
  );
};

const SandH = () => {
  const [value, setValue] = React.useState(0);
  const [departmentData, setDepartmentData] = useState([]);
  const handleGetshDepartment = async () => {
    try {
      const res = await axios.get(
        resources.APPLICATION_URL + "getstudentdata" + "S&H"
      );
      if (res.data) {
        setDepartmentData(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    handleGetshDepartment();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "35px" }}
        >
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="About Department" {...a11yProps(0)} />
            <Tab label="Course Structure" {...a11yProps(1)} />
            <Tab label="Laboratories" {...a11yProps(2)} />
            <Tab label="Faculty" {...a11yProps(3)} />
            <Tab label="Activities & Events" {...a11yProps(4)} />
            {/* <Tab label="PEOs/POs/PSOs/COs" {...a11yProps(4)} /> */}


          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <AboutDepartment />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CourseStructure />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Labs />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Faculty />
        </CustomTabPanel>
        {/* <CustomTabPanel value={value} index={4}>
          <Obe />
        </CustomTabPanel> */}
        <CustomTabPanel value={value} index={4}>
          <Events />

        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default SandH;
