import React from "react";
import Radio from "../StudentRegistraionForm/Radio";
import { useState } from "react";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import RotatingSpinner from "../Resourses/RotatingSpinner";

const Registration = () => {
  const degree = ["B.Tech", "Polytechnic", "MCA", "M.Tech", "MBA", "Others"];
  const [isLoading , setIsLoading] = useState(false);
  const [StudentRegistraion, setStudentRegistrationData] = useState({

    studentName: "",
    fatherName: "",
    gender: "",
    countryName: "",
    state: "",
    city: "",
    email: "",
    phone: "",
    adharNumber: "",
    watsAppNumber: "",
    date: "",
    perminentAddress: "",
    communicationAddress: "",
    category: "",
    hallticketNumber: "",
    cgpaOrMarks: "",
    NameOfINterMediateCollege: "",
    examDateSelection: "",
    firstPreferedBranch: "",
    secundPreferedBranch: "",
    thirdPreferedBranch: "",
    fourthPreferedBranch: "",
    fifthPreferedBranch: ""

  });

  const handleAppendData = (e) => {
    const { value, name } = e.target;
    setStudentRegistrationData({ ...StudentRegistraion, [name]: value });
  };

  const handleStudentregistration = async () => {
    alert("triggered");
    console.log(JSON.stringify(StudentRegistraion));
    try {
      setIsLoading(true);
      const res = await axios.post(
        resources.APPLICATION_URL + "saveStudentSponserShipRegistrationFormDetails",
        StudentRegistraion
      )
      if (res.data) {
        alert("Data Submitted Successfully");
        setStudentRegistrationData({
          studentName: "",
          fatherName: "",
          gender: "",
          countryName: "",
          state: "",
          city: "",
          email: "",
          phone: "",
          adharNumber: "",
          watsAppNumber: "",
          date: "",
          perminentAddress: "",
          communicationAddress: "",
          category: "",
          hallticketNumber: "",
          cgpaOrMarks: "",
          NameOfINterMediateCollege: "",
          examDateSelection: "",
          firstPreferedBranch: "",
          secundPreferedBranch: "",
          thirdPreferedBranch: "",
          fourthPreferedBranch: "",
          fifthPreferedBranch: ""


        });
      }
    } catch (e) {
      console.log(e);
      alert("triggered");
    } finally {
      setIsLoading(false);
    }
  };



  const employmentype = [
    "salaried",
    "self-employed",
    "freelancer",
    "Entrepreneur",
    "House Maker",
    "others",
  ];
  const Branch = [
    "EC",
    "CSE",
    "ECE",
    "CE",
    "IT",
    "AI&DS",
    "CSE(AL&ML)",
    "EEE",
    "MECH",
    "S&H",
    "MBBS",
    "OTHERS",
  ];

  const presentStatus = ["Pursuing", "Completed"];

  const currentYear = new Date();
  const year = [];
  for (
    let j = currentYear.getFullYear() - 5;
    j < currentYear.getFullYear();
    j++
  ) {
    year.push(j);
  }
  for (
    let i = currentYear.getFullYear();
    i <= currentYear.getFullYear() + 5;
    i++
  ) {
    year.push(i);
  }

  return (
  <>
  {isLoading ? <RotatingSpinner/> : (
      <div style={{ padding: "30px" }}>
      <form className="registartion_form_heading_container">
        <h1 className="registartion_form_heading">
          STUDENT SPONSORSHIP PROGRAM REGISTRATION FORM
        </h1>
        <h1 className="registration_form_personalInfo">
          HETERO STUDENT SPONSORSHIP PROGRAM (HSP) REGISTRATION FORM-2024
        </h1>
        <div className="registration_form_main_container">
          <div className="registration_form_container">
            <label>Student Name</label>
            <input
              onChange={(e) => handleAppendData(e)}
              className="registration_form_input_ele"
              type="text"
              placeholder="Student Name"
              id="Studentname"
              name="studentName"
            />
          </div>
          <div className="registration_form_container">
            <label>Father Name</label>
            <input
              onChange={(e) => handleAppendData(e)}
              className="registration_form_input_ele"
              type="text"
              placeholder="Father Name"
              id="Fathername"
              name="fatherName"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "250px",
            }}
          >
            <label>Gender</label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >

              <select class="form-select" id="autoSizingSelect" name="gender"
                onChange={(e) => handleAppendData(e)}>
                <option selected>Choose...</option>
                <option value="1">Male</option>
                <option value="2">Female</option>
                <option value="3">Others</option>
              </select>





              {/* <label style={{ padding: "10px" }}>Male</label>
              <input
                name="gender"
                className="registration_form_input_ele"
                type="radio"
                id="male"
                value="male"
              /> */}
              {/* <label style={{ padding: "10px" }}>Female</label>
              <input
                name="gender"
                className="registration_form_input_ele"
                type="radio"
                id="female"
                value="female"
              /> */}
              {/* <label style={{ padding: "10px" }}>Others</label>
              <input
                name="gender"
                className="registration_form_input_ele"
                type="radio"
                id="other"
                value="other"
              /> */}
            </div>
          </div>
          <div className="registration_form_container">
            <label>Country Name</label>
            <input
              onChange={(e) => handleAppendData(e)}
              className="registration_form_input_ele"
              type="text"
              placeholder="Country Name"
              id="countryName"
              name="countryName"
            />
          </div>
          <div className="registration_form_container">
            <label>State</label>
            <input
              onChange={(e) => handleAppendData(e)}
              className="registration_form_input_ele"
              type="text"
              placeholder="state"
              id="state"
              name="state"
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "250px",
              }}
            ></div>
          </div>
          <div className="registration_form_container">
            <label>City</label>
            <input
              onChange={(e) => handleAppendData(e)}
              className="registration_form_input_ele"
              type="text"
              placeholder="City"
              id="city"
              name="city"
            />
          </div>
          <div className="registration_form_container">
            <label>Email</label>
            <input
              onChange={(e) => handleAppendData(e)}
              className="registration_form_input_ele"
              type="email"
              placeholder="email@gmail.com"
              id="email"
              name="email"
            />
          </div>
          <div className="registration_form_container">
            <label>phone</label>
            <input
              className="registration_form_input_ele"
              type="number"
              placeholder="phone number"
              id="phone"
              name="phone"
            />
          </div>

          <div className="registration_form_container">
            <label>Adhar Number of Student</label>
            <input
              onChange={(e) => handleAppendData(e)}
              className="registration_form_input_ele"
              type="number"
              placeholder="Adhar Number"
              id="phone"
              name="adharNumber"
            />
          </div>

          <div className="registration_form_container">
            <label>What'sapp Number</label>
            <input
              onChange={(e) => handleAppendData(e)}
              className="registration_form_input_ele"
              type="number"
              placeholder="What'sapp Number"
              id="Number"
              name="watsAppNumber"
            />
          </div>

          <div className="registration_form_container">
            <label>Date</label>
            <input
              onChange={(e) => handleAppendData(e)}
              className="registration_form_input_ele"
              type="date"
              placeholder="Date"
              id="date"
              name="date"
            />
          </div>

          <div className="registration_form_container">
            <label>Permanent Address</label>
            <input
              onChange={(e) => handleAppendData(e)}
              className="registration_form_input_ele"
              type="text"
              placeholder="Permanent Address"
              id="Permanent Address"
              name="perminentAddress"
            />
          </div>

          <div className="registration_form_container">
            <label>Communication Address</label>
            <input
              onChange={(e) => handleAppendData(e)}
              className="registration_form_input_ele"
              type="text"
              placeholder="Communication Address"
              id=" Communication Address"
              name="communicationAddress"
            />
          </div>

          <div className="registration_form_container">
            <label>
              Category
            </label>
            <select class="form-select" id="autoSizingSelect" name="category"
              onChange={(e) => handleAppendData(e)}>
              <option selected>Choose...</option>
              <option value="1">SC</option>
              <option value="2">BC</option>
              <option value="3">ST</option>
              <option value="3">GENRAL</option>
            </select>
          </div>

          <div className="registration_form_container">
            <label>S.S.C. /CBSE Hall Ticket Number</label>
            <input
              onChange={(e) => handleAppendData(e)}
              className="registration_form_input_ele"
              type="number"
              placeholder="hall ticket number"
              id=" number"
              name="hallticketNumber"
            />
          </div>
          <div className="registration_form_container">
            <label>S.S.C. /CBSE CGPA/Marks</label>
            <input
              onChange={(e) => handleAppendData(e)}
              className="registration_form_input_ele"
              type="number"
              placeholder="Marks"
              id=" number"
              name="cgpaOrMarks"
            />
          </div>

          <div className="registration_form_container">
            <label>Name of the Intermediate College</label>
            <input
              onChange={(e) => handleAppendData(e)}
              className="registration_form_input_ele"
              type="text"
              placeholder="College Name"
              id=" text"
              name="NameOfINterMediateCollege"
            />
          </div>
          <div className="container">
            <div className="registration_form_container">
              <fieldset class="row mb-3">
                <label>Student HSP-2024 Exam Date Selection</label>


                <div class="col-sm-10">
                  <div class="form-check">
                    <input onChange={(e) => handleAppendData(e)} className="form-check-input" type="radio" name="examDateSelection" id="examDateSelection1" value="onLine"
                    />
                    <label class="form-check-label" for="examDateSelection1">
                      21- 01 - 2024  9:30  AM to 12:30 PM  through Online Mode - With Laptop/Desktop PC at your home only
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      onChange={(e) => handleAppendData(e)}
                      class="form-check-input" type="radio" name="examDateSelection" id="examDateSelection2" value="onCampus" />
                    <label class="form-check-label" for="examDateSelection2">
                      SSIT College Campus Computer Labs on 21-01-2024 9-30 AM To 12-30 PM only
                    </label>
                  </div>

                </div>
              </fieldset>
            </div>
          </div>
          <label>Engineering Branch Preference Details
          </label>
          <div className="container">
            <Radio setStudentRegistrationData={setStudentRegistrationData} />
          </div>

        </div>
      </form>


      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', margin: '30px' }}>
        <button 
         onClick={() => handleStudentregistration()}
         
        className="registration_form_btn" type="button">Submit</button>
      </div>

    </div>
  )}
  </>
  );
};

export default Registration;
