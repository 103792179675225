import React from 'react'
import imgAdver from "../../assets/ADVT.jpg";
import "./Advertisement.css";
const Advertisement = () => {
  return (
    <div className="mainAdv">
          <img src={ imgAdver} alt="Advertisement" className="AdvertisementImg"/>
    </div>
  )
}

export default Advertisement
