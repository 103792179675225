import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./Addmission.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(1, "Computer Science Engineering", [
    <a style={{paddingLeft:'50px'}} href="">R20</a>,
    <a style={{paddingLeft:'50px'}} href="">R19</a>,
    <a style={{paddingLeft:'50px'}} href="">R16</a>,
    <a style={{paddingLeft:'50px'}} href="">R13</a>,
  ]),
  createData(
    2,
    "Computer Science Engineering ( Artificial Intelligence )",[
        <a  style={{paddingRight:'170px'}} href="">R20</a>
    ]
    
  ),
  createData(3, "Computer Science Engineering ( Data Science )", [
    <a  style={{paddingRight:'170px'}} href="">R20</a>
  ]),
  createData(4, "Information Technology	", [
    <a  style={{paddingRight:'170px'}} href="">R20</a>
  ]),
  createData(5, "Electronics and Communication Engineering	",  [
    <a style={{paddingLeft:'50px'}} href="">R20</a>,
    <a style={{paddingLeft:'50px'}} href="">R19</a>,
    <a style={{paddingLeft:'50px'}} href="">R16</a>,
    <a style={{paddingLeft:'50px'}} href="">R13</a>,
  ]),
  createData(6, "Electrical and Electronics Engineering",  [
    <a style={{paddingRight:'80px'}} href="">R16</a>,
    <a style={{paddingRight:'60px'}} href="">R13</a>,
  ]),
  createData(7, "Mechanical Engineering	",  [
    <a style={{paddingLeft:'10px'}} href="">R19</a>,
    <a style={{paddingLeft:'50px'}} href="">R16</a>,
    <a style={{paddingLeft:'50px'}} href="">R13</a>,
  ]),
  createData(8, "Civil Engineering	", [ <a style={{paddingLeft:'10px'}} href="">R19</a>,
  <a style={{paddingLeft:'50px'}} href="">R16</a>,
  <a style={{paddingLeft:'50px'}} href="">R13</a>,
]),
];

export default function CustomizedTables() {
  return (
    <TableContainer component={Paper} className="Customized_Tables">
      <Table sx={{ minWidth: 500 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: "50px" }}>SL.No.</StyledTableCell>
            <StyledTableCell  sx={{ width: "300px" }} align="center">
              Department
            </StyledTableCell>
            <StyledTableCell sx={{ width: "200px" }} align="center">
              {" "}
              View/Download
            </StyledTableCell>
            {/* <StyledTableCell align="right">Carbs&nbsp;(g)</StyledTableCell>
            <StyledTableCell align="right">Protein&nbsp;(g)</StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="center">{row.calories}</StyledTableCell>
              <StyledTableCell align="center">{row.fat}</StyledTableCell>
              {/* <StyledTableCell align="right">{row.carbs}</StyledTableCell>
              <StyledTableCell align="right">{row.protein}</StyledTableCell> */}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
