import React, { useState } from "react";
import "./Login.css";
import axios from "axios";
import { resources } from "../Resourses/Resources";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [login,setLogin] = useState({
    username:"",
    password:"",
  })

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleChangeLogin = (e) => {
    e.preventDefault();
    setLogin({
      ...login,
      [e.target.name]: e.target.value
    })
  }
  const handleLogin = async (e) => {
    e.preventDefault();

    try {

      const response = await axios.post(
        resources.AUTHORIZE_URL + "savesignindetails",
      login)
      console.log("response==>",response)
    }catch(e){
      console.log(e)
    }
    
  }

  return (
    <div className="container">
      <div className="login-root">
        <div
          className="box-root flex-flex flex-direction--column"
          style={{ minHeight: "fit-content", flexGrow: 1 }}
        >
          <div
            className="box-root padding-top--24 flex-flex flex-direction--column"
            style={{ flexGrow: 1, zIndex: 9 }}
          >
            <div className="box-root padding-top--48 padding-bottom--24 flex-flex flex-justifyContent--center">
              <h1>
                <a href="http://blog.stackfindover.com/" rel="dofollow">
                  LOGIN
                </a>
              </h1>
            </div>
            <div className="formbg-outer">
              <div className="formbg">
                <div className="formbg-inner padding-horizontal--48">
                  <span className="padding-bottom--15">
                    Sign in to your account
                  </span>
                  <form id="stripe-login">
                    <div className="field padding-bottom--24">
                      <label htmlFor="userName">User Name</label>
                      <input type="text" name="userName" value={login.userName} onChange={(e)=>handleChangeLogin(e)}  />
                    </div>
                    <div className="field padding-bottom--24">
                      <div className="grid--50-50">
                        <label htmlFor="password">Password</label>
                      </div>
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={login.password}
                        onChange={(e)=>handleChangeLogin(e)}
                      />
                    </div>
                    <div className="field field-checkbox padding-bottom--24 flex-flex align-center">
                      <label htmlFor="checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          onChange={togglePasswordVisibility}
                        />{" "}
                        Show Password
                      </label>
                    </div>
                    <div className="field padding-bottom--24">
                      <input type="submit" name="submit" value="Login" onClick={handleLogin} />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
