import React from 'react'
import StarRateIcon from "@mui/icons-material/StarRate";
import "../GoverningBody/GoverningBody.css"

const AcademicCouncil = () => {
    const academicCouncilTable = [
        {
            name: "Dr. S.Gopi Krishna, Chairman",
            Category: "Principal, SMCE",
            nominatedBy: "Ex-officio"    
        },
        {
            name: "Dr. K.Venkata Reddy",
            Category: "Director, Academic and Planning, JNTUK",
            nominatedBy: "JNTUK, Kakinada"    
        },
        {
            name: "Dr.B. Balakrishna",
            Category: "Director of Evaluation, JNTUK",
            nominatedBy: "JNTUK, Kakinada"    
        },
        {
            name: "Dr.V.Srinivaulu",
            Category: "Professor of CE, UCEK, JNTUK",
            nominatedBy: "JNTUK, Kakinada"    
        },
        {
            name: "Sri K. Seetharam",
            Category: "Industrialist",
            nominatedBy: "Governing Body"    
        },
        {
            name: "Sri M.Narendra",
            Category: "Industrialist",
            nominatedBy: "Governing Body"    
        },
        {
            name: "Sri T. Vijay Kumar",
            Category: "Industrialist",
            nominatedBy: "Governing Body"    
        },
        {
            name: "Dr.P.V.Naganjaneyulu",
            Category: "Educationalist",
            nominatedBy: "Governing Body"    
        },
        {
            name: "Mr. M.V. Ramana",
            Category: "HoD CSE",
            nominatedBy: "Ex-officio"    
        },
        {
            name: "Dr. Sk. MD. Rafi",
            Category: "HoD IT & AI",
            nominatedBy: "Ex-officio"    
        },
        {
            name: "Mr. P.L.N.Manoj Kumar",
            Category: "HoD DS",
            nominatedBy: "Ex-officio"    
        },
        {
            name: "Mr. K. Tirupathaiah",
            Category: "HoD ECE",
            nominatedBy: "Ex-officio"    
        },
        {
            name: "Dr. V. Madhuri",
            Category: "HoD ASH",
            nominatedBy: "Ex-officio"    
        },
        {
            name: "Dr. Sk. J. Shareef",
            Category: "HoD MBA",
            nominatedBy: "Ex-officio"    
        },
        {
            name: "Dr. M. Nageswara Rao",
            Category: "Controller of Examinations",
            nominatedBy: "Ex-officio"    
        },
        {
            name: "Dr.SK.MD.Rafi",
            Category: "Coordinator, R&D",
            nominatedBy: "Ex-officio"    
        },
        {
            name: "Dr.Sk.J.Shareef",
            Category: "Coordinator, IQAC",
            nominatedBy: "Ex-officio"    
        },
        {
            name: "Mr. K.Vijay Kumar",
            Category: "Training & Placement Officer",
            nominatedBy: "Ex-officio"    
        },
        {
            name: "Mr. M.V. Pavan Kumar",
            Category: "Faculty Member",
            nominatedBy: "Ex-officio"    
        },
        {
            name: "Dr. Y. Saritha",
            Category: "Faculty Member",
            nominatedBy: "Ex-officio"    
        },
        {
            name: "Dr. Y. SarithaMr. Y. Yesu Babu",
            Category: "Faculty Member",
            nominatedBy: "Ex-officio"    
        },
        {
            name: "MMr. M. Parameswara Rao",
            Category: "Faculty Member",
            nominatedBy: "Ex-officio"    
        },

        
    ];

    const academicCouncilData = [
        {
            title:"Scrutinize and approve the proposals with or without modification of the Boards of Studies with regard to courses of study, academic regulations, curricula, syllabi and modifications thereof, instructional and evaluation arrangements, methods, procedures relevant thereto etc., provided that where the Academic Council differs on any proposal, it shall have the right to return the matter for reconsideration to the Board of Studies concerned or reject it, after giving reasons to do so."
        },
        {
            title:"Make regulations regarding the admission of students to different programmes of study in the college keeping in view the policy of the Government."
        },
        {
            title:" Make regulations for sports, extra-curricular activities, and proper maintenance and functioning of the playgrounds and hostels."
        },
        {
            title:"Recommend to the Governing Body proposals for institution of new programmes of study."
        },
        {
            title:"Recommend to the Governing Body institution of scholarships, studentships, fellowships, prizes and medals, and to frame regulations for the award of the same."
        },
        {
            title:"Advise the Governing Body on suggestions(s) pertaining to academic affairs made by it."
        },
        {
            title:"Perform such other functions as may be assigned by the Governing Body."
        }
    ]
  return (
    <div>
    <h2 style={{ textAlign: "center", fontWeight: "bold" }}>ACADEMIC COUNCIL</h2>
    <div>
        <table className='gb_table'>
            <thead>
                <tr style={{fontWeight:"bold"}}>
                    <th>S.NO</th>
                    <th>MEMBER NAME</th>
                    <th>CATEGORY</th>
                    <th>NOMINATED BY</th>
                </tr>
            </thead>
            <tbody>
                {academicCouncilTable.map((each, i) => <tr>
                    <td>{i + 1}</td>
                    <td>{each.name}</td>
                    <td>{each.Category}</td>
                    <td>{each.nominatedBy}</td>
                </tr>)}
            </tbody>
        </table>
    </div>
    <div className='governing_content'>
        <p>
            <span className="governing_sidehead">Functions of the Academic Council :</span>
            <p>The Academic Council shall have powers to:</p>
        </p>
        {academicCouncilData.map((item) => <div style={{ display: "flex" }}>
            <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
            <p>
                {" "}
                {item.title}
            </p>
        </div>)}
    </div>
</div>
  )
}

export default AcademicCouncil
