import React from 'react'
import "./BOS.css"
import BOSFunctions from './BOSFunctions'

const CSEDS = () => {
    const cseds = [
        {
            member: "Chairperson",
            designation: "Mr. P.L.N.Manoj kumar,Professor of DS Dept. & HOD Phone:9492617314 Email:manojnanda2000@gmail.com"
        },
        {
            member: "University Nominee",
            designation: "Dr.N Rama Krishnaiah,Professor of CSE, UCEK,JNTU Kakinada"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr.Ch. Rupa Professor of CSE,PVPSIT Email: rupamtech@gmail.com Cell: 9848690640"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr C Shoba Bindu M.Tech., Ph.D.Professor, Computer Science & Engineering Dept.JNTUA College of Engineering,Anantapur-515002 Phone: +91-8143289089 E-mail: shobabindhu@gmail.com shobabindhu.cse@jntua.ac.in"
        },
        {
            member: "Representative from Industry",
            designation: "Devineni Ramana Reddy/Prasad Reddy Technical Manager Sarja Technologies , Hyd hello@sarjatechnologies.com 6281137108"
        },
        {
            member: "Alumni Member",
            designation: "Ms. Kolla Priyanka B.Tech.,System Engineer,Tata Consultancy Services Pvt. Ltd., 8977103203 kolla.priyanka@tcs.com"
        },
        {
            member: "Member",
            designation: "Mr.Ch. Amaresh, Asst.Prof.,SMCE"
        },
        {
            member: "Member",
            designation: "Mr.M Narendra, Asst.Prof.,SMCE"
        },
        {
            member: "Member",
            designation: "Mrs.R Padmaja, Asst.Prof.,SMCE"
        },
    ]

    return (
        <>
            <h4 class="_Top">SRI MITTAPALLI COLLEGE OF ENGINEERING <br />
                Department of CSE-Data Science <br />
                Composition of Board of Studies
            </h4 >
            <h3 class="A">
                A.Y.: 2023-24
            </h3>
            <div>
                <table className='gb_table'>
                    <thead>
                        <tr>
                            <th>
                                S.NO
                            </th>
                            <th>
                                MEMBERS
                            </th>
                            <th>
                                NAME & DESIGNATION
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {cseds.map((each, i) => <tr>
                            <td>
                                {i + 1}
                            </td>
                            <td>
                                {each.member}
                            </td>
                            <td>
                                {each.designation}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
            <div className='bottom'>
                <h3 className='_chairperson'>Chairperson</h3>
                <p>BoS-CSE-Data Science</p>
            </div>
            <BOSFunctions />
        </>
    )
}

export default CSEDS
