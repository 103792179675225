import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import "../GoverningBody/GoverningBody.css"

function createData(SNo, NameoftheCommittee, HeadedBy, DepartmentAndDesignation) {
  return {
    SNo,
    NameoftheCommittee,
    HeadedBy,
    DepartmentAndDesignation,

  };
}

const rows = [
  createData(1, 'Dr. S. Gopi Krishna', 'Principal', 'Chairman'),
  createData(2, 'M.Jaya Rao', 'Asst.Prof', 'Programme Officer'),
  createData(3, 'Y.Ashok		', 'Asst.Prof', 'Member'),
  createData(4, 'A.Swathi', 'Asst. Prof', 'Member'),
  createData(5, 'M.Parameshwara Rao	', 'Asst. Prof	', 'Member'),
  createData(6, 'B.Edukondalu', 'Asst. Prof', '	Member'),
  createData(7, 'A.srinivasa Rao', 'Asst. Prof', 'Member'),
  createData(8, '	8	J.Tarun (19U91A0567)	', 'IV-CSE', 'Student member  '),
  createData(9, 'k.sai ram (19U91A0573)	', 'IV-CSE', 'Student member  '),
  createData(10, 'R.Sahithi (19U91A05C7)', 'IV-CSE', 'Student member  '),
  createData(11, 'Merikapudi Venkata Jairam (19U91A0599)', 'IV-CSE', 'Student member  '),
  createData(12, 'Jakkireddy lahari(19U91A0565)', 'IV-CSE	', 'Student member  '),
  createData(13, 'M.Gowri Sankar(19U91A0442)', 'IV-ECE', 'Student member  '),
  createData(14, 'Sk.sana sadika (19U91A0463)', 'IV-ECE', 'Student member  '),
  createData(15, 'P.Sai (19U91A0304)', 'IV-ME', 'Student member  '),
  createData(16, 'Sai Abbas (19U91A0307)', 'IV-ME', 'Student member  '),
  createData(17, 'M.V.Jai Sai (19U91A0104)', 'IV-Civil', 'Student Member'),
  createData(18, 'D.lakshmi Trinadh(20U91A0530)', 'III CSE', 'Student member  '),
  createData(19, 'Dara Niharika(20U91A0529)', 'III CSE', 'Student member '),
  createData(20, 'Bandlamudi Hemanth(21U91A0513)', 'II CSE', 'Student member '),
  createData(21, 'Syed Huma Shahin (21U91A05F7)', 'II CSE', 'Student member  '),
  createData(22, 'G.Surendra (20U91A0425)', 'III ECE', 'Student member  '),
  createData(23, 'N.Tejaswini (20U91A0450)', 'III ECE', 'Student member  '),
  createData(24, 'Davuluri Akhila  (20U91A4305)', 'III AI', 'Student member  '),
  createData(25, 'Peram Kavya(20U91A1228)', 'III IT', 'Student member  '),
  createData(26, 'Gantenapati Varun Kumar (20U91A1209)', 'III IT', 'Student Member'),
  createData(27, 'Palagiri Ashok Reddy (21U91A0450)', 'II ECE', 'Student member  '),
  createData(28, 'Tumati Kavyanjali (21U91A0479)', 'II ECE', 'Student member  '),
  //   createData(25, 'R &D Consultancy Committee		', 'Dr.P.V.Naganjaneyulu	', 'Principal '),
  //   createData(26, 'Health Center		', 'M.Jaya Rao		', 'Asst Prof ,ASH '),
  //   createData(27, 'Womens protection and Grievances Cell		', 'Dr.V.Madhuri	', 'Prof & HOD ASH '),
  //   createData(28, 'Public Relations, Press & Media, Publication, News letter and Magazine editorial Committee', 'N.Sri Prakash	', 'Asst Prof , ECE '),
  //   createData(29, 'Right to Information Cell	', 'N.Eswara Rao', 'Asst Prof ,ASH'),


];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'S.No',
    numeric: true,
    disablePadding: true,
    align: 'center',
    label: 'S No',
  },
  {
    id: 'Name',
    numeric: false,
    disablePadding: true,
    label: 'Name.',
    align: 'center',
  },
  {
    id: 'Designation',
    numeric: false,
    disablePadding: false,
    label: 'Designation',
  },
  {
    id: 'Position',
    numeric: false,
    disablePadding: true,
    label: 'Position',
  },

];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
          sx={{ background: "#000428;background: -webkit-linear-gradient(to right, #004e92, #000428);background: linear-gradient(to right, #004e92, #000428);", color: "whitesmoke" }}
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Committee Members
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    // hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer', background: "rgb(239,239,187)" }}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell> */}
                    <TableCell
                      component="th"
                      className='acdm_table'
                      id={labelId}
                      scope="row"
                      padding="none"
                      align="center"
                    >
                      {row.SNo}
                    </TableCell>
                    <TableCell className='acdm_table' align="center">{row.NameoftheCommittee}</TableCell>
                    <TableCell className='acdm_table' align="center">{row.HeadedBy}</TableCell>
                    <TableCell className='acdm_table' align="center">{row.DepartmentAndDesignation}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}