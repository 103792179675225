import * as React from 'react';
import Table from '@mui/joy/Table';

export default function TableGlobalVariant() {
  return (
    <Table
      stickyHeader
      sx={(theme) => ({
        '& tr > *:first-child': { bgcolor: 'success.softBg' },
        '& th[scope="col"]': theme.variants.solid.neutral,
        '& td': theme.variants.soft.neutral,
      })}
    >
      
      <tbody>
        <tr>
          <th scope="col">Programme</th>
          <th scope="col">For Seats Through Counselling</th>
          <th scope="col">Management Quota Seats</th>
        </tr>
        <tr>
          <th scope="row">Under Graduation(B.Tech)</th>
          <td>* Qualifying at EAMCET(Counseling Code : SAIS)</td>
          <td>** A pass in 10+2 in M.P.C. with at least 60% either in aggregate or in group subjects or qualifying at EAMCET.</td>
        </tr>
        <tr>
          <th scope="row">Post Graduation(MBA)</th>
          <td>* Qualifying at ICET(Counseling Code : SAIS)</td>
          <td>** A pass in any Degree with at least 60% either in aggregate or qualifying at ICET.</td>
        </tr>
        <tr>
          <th scope="row">Diploma(Polytechnic)</th>
          <td>* Qualifying at CEEP(Counseling Code : SAIS)</td>
          <td>** A pass in SSC with at least 60% either in aggregate or qualifying at CEEP.</td>
        </tr>
      </tbody>
    </Table>
  );
}