import React from 'react'
import BOSFunctions from './BOSFunctions'

const Mathematics = () => {
    const maths = [
        {
            member: "Chairperson",
            designation: "Dr.V.Madhuri,Professor of AS&H Dept. & HOD"
        },
        {
            member: "University Nominee",
            designation: "Dr.K.Sobhan Babu,Professor of Mathematics, UCEN,JNTU Kakinada"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr B. Satyanarayana,M.Sc., Ph.D.Professor of mathematics,ANU College of sciences,ANU Mobile :9440843956 drbavanari63 @gmail.com"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr. T. V. Pradeep Kumar M.Sc., Ph.D.Asst. Professor of mathematics,ANU College of Engineering, ANU 9290620490 pradeeptvs@gmail.com"
        },
        {
            member: "Representative from Industry",
            designation: "Dr.P.Lova Kumar Sub Divisional Engineer,CNTX South BSNL HYDERABAD.lovakumarbsnl@gmail.com 9490427439"
        },
        {
            member: "Alumni Member",
            designation: "R.Bhuvaneswari,Asst.Prof., Dept.of CSE,SMCE.bhuvana.rayapudi111@gmail.com 9398788846"
        },
        {
            member: "Member",
            designation: "Mrs.J.Radhika, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "DR.Y.Saritha, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Ms.B.Asha Jyothi, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Mr.Kakumanu Mastan Rao, Asst.Prof. SMCE"
        },
        {
            member: "Member",
            designation: "Mrs.Atchyutha Naga Mani, Asst.Prof. SMCE"
        },

    ]

    return (
        <>
            <h4 class="_Top">SRI MITTAPALLI COLLEGE OF ENGINEERING <br />
                Department of Mathematics <br />
                Composition of Board of Studies
            </h4 >
            <h3 class="A">
                A.Y.: 2023-24
            </h3>
            <div>
                <table className='gb_table'>
                    <thead>
                        <tr>
                            <th>
                                S.NO
                            </th>
                            <th>
                                MEMBERS
                            </th>
                            <th>
                                NAME & DESIGNATION
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {maths.map((each, i) => <tr>
                            <td>
                                {i + 1}
                            </td>
                            <td>
                                {each.member}
                            </td>
                            <td>
                                {each.designation}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
            <div className='bottom'>
                <h3 className='_chairperson'>Chairperson</h3>
                <p>BoS-Mathematics</p>
            </div>
            <BOSFunctions />
        </>
    )
}

export default Mathematics
