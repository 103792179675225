// it department
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import csefaculty from "../../../assets/itdepartmentimg.png";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PageHeader } from "../../Header/PageHeader";
import "../Departments.css";
import Labs from "./Labs";
import Faculty from "./Faculty";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";

import axios from "axios";
import { resources } from "../../Resourses/Resources";
import {
  FluentShifts30Minutes24Filled,
  StreamlineBugAntivirusDebugging,
} from "../../../assets/icons/svgs";
import { UitCircuit } from "../../../assets/icons/svgs";
import { StreamlineBugAntivirusDebuggingSolid } from "../../../assets/icons/svgs";
// import { StreamlineBugAntivirusDebuggingSolid } from "../../../assets/icons/svgs";
import { MdiPresentation } from "../../../assets/icons/svgs";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import QuizIcon from "@mui/icons-material/Quiz";

import event1 from "../../../assets/Events/EEE/fdp_1.jpg";
import event2 from "../../../assets/Events/EEE/fdp_2.jpg";
import event3 from "../../../assets/Events/EEE/fdp_3.jpg";

import Mr23 from "../../../Pdf/CourseStructure/UG/MR23_B.Tech_COURSE STRUCTURE.pdf"
import R20 from "../../../Pdf/CourseStructure/UG/R20 IT Course Structure.pdf"


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CourseStructure = () => {
  return (
    <div className="containe">
      <PageHeader name="INFORMATION TECHNOLOGY" />
      <div className="course_structure_cnt animate__animated animate__zoomIn">
        <div className="course text-center">
          <h4>Course Structure for all Years</h4>
          <p>
            Download the Course Structure and Syllabus of four years of IT
            course
          </p>
        </div>

        <div className="w-100 text-center d-flex justify-content-center gap-5 ">
          <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>
              IT - MR23
            </span>
            <a href={Mr23} target="_blank">
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div>
          <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>
              IT - R20
            </span>
            <a href={R20} target="_blank">
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div>
          {/* <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>
              III EEE - R16
            </span>
            <a href="#" target="blank">
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div>
          <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>
              IV EEE - R16
            </span>
            <a href="#" target="blank">
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};
const Events = () => {
  const getIcon1 = () => {
    return <FluentShifts30Minutes24Filled />;
  };

  const getIcon2 = () => {
    return <UitCircuit />;
  };

  const getIcon3 = () => {
    return <StreamlineBugAntivirusDebuggingSolid />;
  };

  const getIcon4 = () => {
    return <CoPresentIcon sx={{ fontSize: 50, color: "orange" }} />;
  };

  const getIcon5 = () => {
    return <MdiPresentation />;
  };

  const getIcon6 = () => {
    return <QuizIcon sx={{ fontSize: 50, color: "orange" }} />;
  };

  const title1 = "Just-A-Minute";
  const desc1 =
    "is an all round-fun event that is all about the control of the mind over the mouth.";

  const title2 = "Instant Circuit Designing";
  const desc2 =
    "is a Competative event that all about the designing of Circuits Instantly.";

  const title3 = "Circuit Debugging";
  const desc3 = "Circuit Debugging is a Competative Event";

  const title4 = "Seminars";
  const desc4 =
    "Students give the demonistration on Various Latest Trends in Industry.";

  const title5 = "Poster Presentation";
  const desc5 =
    "is a way to share your knowledge of topic in a short format. It usually includes two elements - a poster and a brief explanation.";

  const title6 = "Quiz";
  const desc6 =
    "A quiz is a game or competition in which someone tests your knowledge by asking you questions";

  const IconCard = ({ icon, title, desc }) => {
    return (
      <>
        <Card
          variant="outlined"
          orientation="horizontal"
          sx={{
            width: 320,
            // height: 205,
            "&:hover": {
              boxShadow: "md",
              borderColor: "neutral.outlinedHoverBorder",
            },
          }}
        >
          <AspectRatio ratio="1" sx={{ width: 60 }}>
            {icon()}
          </AspectRatio>
          <CardContent>
            <Typography level="title-lg" id="card-description">
              {title}
            </Typography>
            <p className="desc">{desc}</p>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <div className="container">
      <PageHeader name="ACTIVITIES & EVENTS" />
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="course text-center animate__animated animate__fadeInRight">
          <h4>Student Development Activities</h4>
          {/* <br />
          <p style={{ textAlign: "justify" }}>
            VYDHUSHYA is a NATIONAL LEVEL TECHNICAL SYMPOSIUM ,Entitled and
            organized by the department of EEE Most of us are aware of the
            common Paper Presentation conferences and competitions where in
            students present their research or review papers. but with a twist,
            i.e. the students have to present their research work in the form of
            a power point presentation consisting of just 5 slides! And the
            slides should contain the basic concept of the technology,
            methodology, calculations/assessment, conclusions and references.
          </p> */}
        </div>
        {/* <div className="row mb-5">
          <div className="col-lg-4">
            <IconCard icon={getIcon1} title={title1} desc={desc1} />
          </div>
          <div className="col-lg-4">
            <IconCard icon={getIcon2} title={title2} desc={desc2} />
          </div>
          <div className="col-lg-4">
            <IconCard icon={getIcon3} title={title3} desc={desc3} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <IconCard icon={getIcon4} title={title4} desc={desc4} />
          </div>
          <div className="col-lg-4">
            <IconCard icon={getIcon5} title={title5} desc={desc5} />
          </div>
          <div className="col-lg-4">
            <IconCard icon={getIcon6} title={title6} desc={desc6} />
          </div>
        </div> */}
      </div>

      {/* <h4 className="text-center m-5">Events Photos</h4>
      <div className="d-flex justify-content-center gap-5">
        <div>
          <img src={event1} className="img-fluid rounded" alt="eeeEvents" />
        </div>
        <div>
          <img src={event2} className="img-fluid rounded" alt="eeeEvents" />
        </div>
        <div>
          <img src={event3} className="img-fluid rounded" alt="eeeEvents" />
        </div>
      </div> */}
    </div>
  );
};

const Obe = () => {
  return (
    <div className="container">
      <PageHeader name="PEOs/POs/PSOs" />
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM EDUCATIONAL OBJECTIVES(PEO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PEO 1:</h2>
              <p>
                Graduates able to solve wide range of computing-related problems
                by applying the knowledge of mathematics and innovative
                algorithms.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 2:</h2>
              <p>
                Graduates pursue advanced degrees with a dedication for lifelong
                learning and use their skills in an ethical & professional
                manner.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 3:</h2>
              <p>
                To be able to adapt to the evolving technical challenges and
                changing career opportunities.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM OUTCOMES(PO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PO 1 (Engineering knowledge):</h2>
              <p>
                Apply the knowledge of mathematics, science, engineering
                fundamentals, and an engineering specialization to the solution
                of complex engineering problems.
              </p>
            </div>
            <div class="peo">
              <h2>PO 2 (Problem analysis):</h2>
              <p>
                Identity, formulate, review research literature, and analyze
                complex engineering problems reaching substantiated conclusions
                using the first principles of mathematics, natural sciences, and
                engineering sciences.
              </p>
            </div>
            <div class="peo">
              <h2>PO 3 (Design/development of solutions):</h2>
              <p>
                Design solutions for complex engineering problems and design
                system components or processes that meet the specified needs
                with appropriate consideration for public health and safety, and
                the cultural, societal, and environmental considerations.
              </p>
            </div>
            <div class="peo">
              <h2>PO 4 (Conduct investigations of complex problems):</h2>
              <p>
                Use research-based knowledge and research methods including
                design of experiments, analysis and interpretation of data, and
                synthesis of the information to provide valid conclusions.
              </p>
            </div>
            <div class="peo">
              <h2>PO 5 (Modern tool usage):</h2>
              <p>
                Create, select, and apply appropriate techniques, resources, and
                modern engineering and IT tools including prediction and
                modeling to complex engineering activities with an understanding
                of the limitations.
              </p>
            </div>
            <div class="peo">
              <h2>PO 6 (The engineer and society):</h2>
              <p>
                Apply reasoning informed by the contextual knowledge to assess
                societal, health, safety, legal and cultural issues and the
                consequent responsibilities relevant to the professional
                engineering practice.
              </p>
            </div>
            <div class="peo">
              <h2>PO 7 (Environment and sustainability):</h2>
              <p>
                Understand the impact of the professional engineering solutions
                in societal and environmental contexts, and demonstrate the
                knowledge of, and need for sustainable development.
              </p>
            </div>
            <div class="peo">
              <h2>PO 8 (Ethics):</h2>
              <p>
                Apply ethical principles and commit to professional ethics and
                responsibilities and norms of the engineering practice.
              </p>
            </div>
            <div class="peo">
              <h2>PO 9 (Individual and team work):</h2>
              <p>
                Function effectively as an individual, and as a member or leader
                in diverse teams, and in multidisciplinary settings.
              </p>
            </div>
            <div class="peo">
              <h2>PO 10 (Communication):</h2>
              <p>
                Communicate effectively on complex engineering activities with
                the engineering community and with society at large, such as,
                being able to comprehend and write effective reports and design
                documentation, make effective presentations, and give and
                receive clear instructions.
              </p>
            </div>
            <div class="peo">
              <h2>PO 11 (Project management and finance):</h2>
              <p>
                Demonstrate knowledge and understanding of the engineering and
                management principles and apply these to one’s own work, as a
                member and leader in a team, to manage projects and in
                multidisciplinary environments.
              </p>
            </div>
            <div class="peo">
              <h2>PO 12 (Life-long learning):</h2>
              <p>
                Recognize the need for, and have the preparation and ability to
                engage in independent and life-long learning in the broadest
                context of technological change.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM SPECIFIC OUTCOMES (PSO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="pso-container">
            <div class="pso">
              <h2>PSO 1:</h2>
              <p>
                Analyze, Identify the data ,Design suitable algorithm by using
                Latest software for Real Time Applications.
              </p>
            </div>
            <div class="pso">
              <h2>PSO 2:</h2>
              <p>
                Understand the progressive changes in computing posses data of
                context-aware relevance of paradigms.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          COURSE STRUCTURE (CO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <ul class="course_outcomes_cnt">
              <li>
                <a href="#" target="_blank">
                  R18
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  R22
                </a>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
};

const AboutDepartment = () => {
  return (
    <div>
      <div className="container">
        <PageHeader name="Information Technology" />
        <div className="container-xxl">
          <div className="container-xxl py-1">
            <div className="container">
              <div className="row g-5">
                <div
                  className="col-lg-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{ minHeight: "100px" }}
                >
                  <div className="position-relative h-80 animate__animated animate__zoomIn">
                    <img
                      className="img-fluid rounded w-80 h-80"
                      src={csefaculty}
                      alt="csefaculty-img"
                      style={{ objectFit: "cover",boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                  
                  <div className="row gy-2 gx-2 mb-2">
                    <p className="prg_content_About">
                    <h1 className="mb-4">About</h1>

                      To cater to the growing demand of software professionals
                      from global organizations, Sri Mittapalli College of
                      Engineering (SMCE) has set up an undergraduate course in
                      Information Technology (B.Tech. INF) branch in 2020 with
                      an initial intake of 60. Center of Excellence Labs have
                      been functioning on cutting edge technologies such as
                      Artificial Intelligence & Machine Learning, IoT, Cyber
                      Security, Cloud & Fog Computing and Software Engineering.
                      Had MoUs with reputed MNCs such as Oracle, Microsoft,
                      CISCO etc to be in tune with industry developments and
                      requirements.
                    </p>
                    {/* <h4>Programmes offered</h4>
                    <p>
                      1. B. Tech. (Information Technology) with an intake of 60.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            <div className="card_cntr1">

              {/* <div className="boxes1 service-item text-center pt-3 shadow"> */}
              <div className="hov">

                <h5 className="mb-1">Vision of the Department:</h5>
                <br />
                <p className="prg_content_cse">
                  To produce professional, ethical and globally competent
                  graduates by imparting quality education in the field of
                  Computer Science & Engineering with capabilities to solve a
                  wide range of complex, scientific, technological and
                  societal problems.
                </p>


              </div>



              <div className="hov">
                <h5 >Mission of the Department:</h5>
                <br/>
                <div className="mission-container">
                   <h5 className="mission-sub-heading">M1</h5>
                   <p className="mission-sub-subscription">To educate students to the effective problem – solvers
                      and life – long learners in technical, innovative and
                      entrepreneurial skills.</p>
                </div>
                <div className="mission-container">
                   <h5 className="mission-sub-heading">M2</h5>
                   <p className="mission-sub-subscription">To impart quality and value based education and
                      contribute towards the advancement of computing, science
                      and technology to raise satisfaction level of all
                      stakeholders.</p>
                </div>
                <div className="mission-container">
                   <h5 className="mission-sub-heading">M3</h5>
                   <p className="mission-sub-subscription">To establish a continuous Industry Institute
                      Interaction, participation, collaboration to contribute
                      skilled IT engineers.</p>
                </div>
                {/* <div>
                  <Accordion className="prg_content_cse"
                    sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ backgroundColor: "#fff" }}
                    >
                      M1
                    </AccordionSummary>
                    <AccordionDetails
                      align="center"
                      sx={{
                        backgroundColor: "#fff5dc",
                        color: "#000",
                        fontSize: "12px",
                        fontFamily: "Arial",
                        marginBottom: "10px",
                        letterSpacing: "1px",
                      }}
                    >
                      To educate students to the effective problem – solvers
                      and life – long learners in technical, innovative and
                      entrepreneurial skills.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      M2
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        backgroundColor: "#fff5dc",
                        color: "#000",
                        fontSize: "12px",
                        fontFamily: "Arial",
                        marginBottom: "10px",
                        letterSpacing: "1px",
                      }}
                    >
                      To impart quality and value based education and
                      contribute towards the advancement of computing, science
                      and technology to raise satisfaction level of all
                      stakeholders.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3-content"
                      id="panel3-header"
                    >
                      M3
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        backgroundColor: "#fff5dc",
                        color: "#000",
                        fontSize: "12px",
                        fontFamily: "Arial",
                        marginBottom: "10px",
                        letterSpacing: "1px",
                      }}
                    >
                      To establish a continuous Industry Institute
                      Interaction, participation, collaboration to contribute
                      skilled IT engineers.
                    </AccordionDetails>
                  </Accordion>
                </div> */}
              </div>




                  <div className="hov">
                    <h5 className="mb-3">Why Choose SMCE CSE:</h5>
                    <br />
                    <p className="prg_content_cse">
                      <ArrowForwardOutlinedIcon />
                      Qualified & Experienced Faculty.<br />
                      <ArrowForwardOutlinedIcon />
                      Industry ready course content.<br />
                      <ArrowForwardOutlinedIcon />
                      Well planned academic curriculum.<br />
                      <ArrowForwardOutlinedIcon />
                      Course delivery through real world applications.<br />
                      <ArrowForwardOutlinedIcon />
                      Career Prospects.
                    </p>
                  </div>
            </div>}

          <div className="container">
            <div className="row">
              <div className="area col-6">
                <h4>Focus Areas:</h4>
                <p className="prg_content">
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Cloud & Fog Computing.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Distributed Systems.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Networking Technologies.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Internet of Things.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Natural Language Processing.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Security Certifications.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Cyber Security
                </p>
              </div>
              <div className="area col-6">
                <h5>Skill Development:</h5>
                <br />
                <p>
                  <b> Coding skills:</b>Programming skills in C, C++, Java,
                  Python, R Programming, etc.
                  <br />
                  <br />
                  <b>Hardware skills:</b>Ardunio, Raspberry Pi Boards.<br/><br/>
                  <b>Soft skills:</b> Hobby clubs, Mock Interviews.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EEE = () => {
  const [value, setValue] = React.useState(0);
  const [departmentData, setDepartmentData] = useState([]);
  const handleGeteeeDepartment = async () => {
    try {
      const res = await axios.get(
        resources.APPLICATION_URL + "getstudentdata" + "EEE"
      );
      if (res.data) {
        setDepartmentData(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    handleGeteeeDepartment();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "35px" }}
        >
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="About Department" {...a11yProps(0)} />
            <Tab
              sx={{ textTransform: "capitalize" }}
              label="PEOs/POs/PSOs"
              {...a11yProps(1)}
            />
            <Tab label="Course Structure" {...a11yProps(2)} />
            <Tab label="Laboratories" {...a11yProps(3)} />
            <Tab label="Faculty" {...a11yProps(4)} />
            <Tab label="Activities & Events" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <AboutDepartment />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Obe />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <CourseStructure />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Labs />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Faculty />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <Events />
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default EEE;
