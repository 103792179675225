import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/Industrialtable";
import StarRateIcon from "@mui/icons-material/StarRate";

const Committees = () => {
  const industrial =[
    {
      title:"Arranging industrial visits."
    },
    {
      title:"Coordinating with the departments for inviting industry experts for special/guest lecturers/conference/workshops/short term and refresher courses, FDP etc."
    },
    {
      title:"Facilitating students to do mini/regular projects in industries."
    },
    {
      title:"Arranging Industrial training for staff/students during summer/winter vacations."
      
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            INDUSTRY-INSTITUTE-INTERACTION CELL
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            The Industry Institute Interaction Cell is established for
            continuous interaction between academia and industry which is the
            need of the hour . It provides a platform for both the students as
            well as faculty members t o be aware of industry expectations of
            skill sets required for students.
          </p>
          <p>Industry-Institute-Interaction Cell is formed with the following members:</p>
          
        </div>
      </div>
      <div className="container">
        <Table />
      </div>
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {industrial.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
