import React from "react";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import pic1 from "../../assets/digilc.jpg";
import pic2 from "../../assets/digilibc.jpg";
import pic3 from "../../assets/dl1c.jpg";
import pic4 from "../../assets/dl4.jpg";

const DigitalLibrary = () => {
  return (
    <div className="container mt-5">
      {" "}
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            DIGITAL LIBRARY
          </a>
        </div>
      </div>
      <br />
      <div className="container text-center">
        <h4 className="text text-center">About Digital Library</h4>
        <br />
        <p>
          The College Library Supported with well equipped Digital library.An
          Attempt to provide an insight in to significant developments in some
          of promising areas of Science & Technology with the help of Digital
          Library. Digital Library in SSIT has 30 dedicated Systems in separate
          block of Main Library.
        </p>
      </div>
      <br />
      <div className="container ">
        <div className="row">
          <div className="col-3">
            <img
              className="img rounded  w-100 "
              src={pic1}
              alt="pic1-img"
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="col-3">
            <img
              className="img rounded  w-100 "
              src={pic2}
              alt="pic2-img"
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="col-3">
            <img
              className="img rounded  w-100 "
              src={pic3}
              alt="pic3-img"
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="col-3">
            <img
              className="img rounded  w-100 "
              src={pic4}
              alt="pic4-img"
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
      <br />
      <h4 className="text text-left">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Facilities OF DIGITAL
        LIBRARY
      </h4>
      <br />
      <div className="container text-left">
        <div className="row">
          <div className="col-6">
            <p>
              <LibraryBooksIcon />
              E-Journals (DELNET)
            </p>
            <p>
              <LibraryBooksIcon />
              NOBEL INFOTECH
            </p>
            <p>
              <LibraryBooksIcon />
              Open Access (free) Journals (4,500+)
            </p>
            <p>
              <LibraryBooksIcon />
              DATAQUEST{" "}
            </p>
            <p>
              <LibraryBooksIcon />
              E-books
            </p>
            <p>
              <LibraryBooksIcon />
              ELECTRONICS FOR YOU
            </p>
          </div>
          <br />
          <div className="col-6">
            <p>
              <LibraryBooksIcon />
              Audio-Visual
            </p>
            <p>
              <LibraryBooksIcon />
              GRE{" "}
            </p>
            <p>
              <LibraryBooksIcon />
              TOEFL{" "}
            </p>
            <p>
              <LibraryBooksIcon />
              CD & DVD's of Books/magazines
            </p>
            <p>
              <LibraryBooksIcon />
              Video-On-Demand Courses (NPTEL)
            </p>
            <p>
              <LibraryBooksIcon />
              Free Internet Facility with 40 mbps Speed
            </p>
          </div>
        </div>
      </div>
      <br />
      <div className="container ">
        <div className="row">
          <div className="col-6">
            <h4>Proposed Facilities</h4>
            <p>
              {" "}
              <LibraryBooksIcon />
              EAKALAYA T.V CHANNEL
            </p>
            <p>
              {" "}
              <LibraryBooksIcon />
              AICTE INDEST CONSORTIUM
            </p>
          </div>
          <div className="col-6">
            <h4>Timings</h4>
            <p>
              {" "}
              <AccessTimeIcon />
              Working Hours : 8.00 A.M to 8.00 P.M
            </p>
            <p>
              {" "}
              <AccessTimeIcon />
              Holidays : 9.00 A.M to 1.00 P.M
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalLibrary;
