import React from "react";
import libraraypic from "../../assets/Library.jpg";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

const Internet = () => {
  return (
    <div className="container mt-5">
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            INTERNET
          </a>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "100px" }}
            >
              <div className=" w-100">
                <img
                  className="img rounded  w-100 "
                  src={libraraypic}
                  alt="libraraypic-img"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                About Internet Facility
              </h6>
              <div className="row gy-2 gx-4 mb-4">
                <p className="mb-0">
                  SAI SPURTHI INSTITUTE OF TECHNOLOGY is enriched INTERNET
                  facility with 44mbps band width is exclusively providing to
                  Staff, Students, Staff Quarters and Girls Hostel with
                  unlimited usage to 24 hours. SSIT is have a two types INTERNET
                  facilities, one is BSNL Broad Band and another one is Reliance
                  NET LINKS, Band width of these two 40mbps and 4mbps
                  respectively. SSIT provides well established internet lab
                  facility to staff and students for different timings in
                  college premises.SSIT also provides internet facility to all
                  the systems available in various labs in college premises,and
                  also provides internet facility to all staff quarters.
                  Dedicated to separate lab to students with 60 systems in
                  college premises at 8:30 am to 7:30 pm. And also provide
                  separate lab in girls hostel with 20 systems.SSIT also
                  provides Wi-Fi internet connection facility.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container text-left">
        <h4 className="text text-left">Features</h4>
        <br />
        <p>
          <LibraryBooksIcon />
          &nbsp; Dedicated separate lab to students with 60 systems in college
        </p>

        <p>
          {" "}
          <LibraryBooksIcon />
          &nbsp; Provides Internet Facility to staff and students in different timings in college Premises

        </p>
        <p>
          {" "}
          <LibraryBooksIcon />
          &nbsp; Dedicated and Enriched Internet with 44 MBPS band width
        </p>
        <p>
          {" "}
          <LibraryBooksIcon />
          &nbsp;  Provide Internet for Girls in Girls Hostel with 20 Systems.


        </p>
        <p>
          {" "}
          <LibraryBooksIcon />
          &nbsp;  Provide Internet from 8.30 am to 7.30 pm
        </p>
        <p>
          {" "}
          <LibraryBooksIcon />
          &nbsp;  Also Provides Internet Facility to Staff Quarters.

        </p>
        <p>
          {" "}
          <LibraryBooksIcon />
          &nbsp;   BSNL(40 MBPS), NET LINKS (4MBPS) are subscribers.


        </p>
      </div>
    </div>
  );
};

export default Internet;
