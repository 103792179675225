import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./BOS.css";
import StarRateIcon from "@mui/icons-material/StarRate";
import initiatives from "../../assets/images/iqac-initives.png";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import ECE from "./ECE";
import CSE from "./CSE";
import CSEAI from "./CSEAI";
import CSEDS from "./CSEDS";
import IT from "./IT";
import Mathematics from "./Mathematics";
import Physics from "./Physics"
import MBA from "./MBA";
import English from "./English";
import Chemistry from "./Chemistry"
import MECH from "./MECH";


const BOS = () => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabCSS = {
    fontWeight: "bold",
    fontSize: "15px",
    color: "white",
    // border: "2px solid black",
    height: "0.5cm",
    marginLeft: "5px",
    marginRight: "5px",
    borderRadius: "10px",
    background: '#0f0c29',
    background: '-webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29)', /* Chrome 10-25, Safari 5.1-6 */
    background: 'linear-gradient(to right, #24243e, #302b63, #0f0c29)',
    '&.Mui-selected': {
      background: "rgb(34,193,195)",
      background:" linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)",
      color: "rgb(39,38,72)"
    },
  };





  return (
    <div>
      <h1 className="iqac_header">BOS</h1>
      <Box sx={{ width: "100%", typography: "body1", textAlign: "center" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              centered
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab className="Bos_Tabs" sx={TabCSS}

                label="ECE"
                value="1"
              />

              <Tab sx={TabCSS}

                label="CSE"
                value="2"
              />
              <Tab sx={TabCSS}

                label="CSE-AI"
                value="3"
              />
              <Tab sx={TabCSS}

                label="CSE-DS"
                value="4"
              />
              <Tab sx={TabCSS}

                label="IT"
                value="5"
              />
              <Tab sx={TabCSS}

                label="Chemistry"
                value="6"
              />
              <Tab sx={TabCSS}

                label="English"
                value="7"
              />
              <Tab sx={TabCSS}

                label="Mathematics"
                value="8"
              />
              <Tab sx={TabCSS}

                label="Mechanical Engineering"
                value="9"
              />
              <Tab sx={TabCSS}

                label="Physics"
                value="10"
              />
              <Tab sx={TabCSS}

                label="MBA"
                value="11"
              />

            </TabList>
          </Box>

          {value == 1 ? <ECE /> : value == 2 ? <CSE /> : value == 3 ? <CSEAI /> : value == 4 ? <CSEDS /> : value == 5 ? <IT /> : value == 6 ? <Chemistry /> : value == 7 ? <English /> : value == 8 ? <Mathematics /> : value == 9 ? <MECH /> : value == 10 ? <Physics /> : value == 11 ? <MBA /> : ""}
        </TabContext>
      </Box>
    </div>
  );
};

export default BOS;
