import { Category } from '@mui/icons-material'
import React from 'react'
import "./GoverningBody.css"
import StarRateIcon from "@mui/icons-material/StarRate";

const GoverningBody = () => {
    const governingbodyTable = [
        {
            name: "Sri M. Srinivasa Chakravarthy",
            Category: "Chairman",
            nominatedBy: "Management"
        },
        {
            name: "Sri M.V.Koteswara Rao",
            Category: "Members of the Trust/Society/Management",
            nominatedBy: "Management"
        },
        {
            name: "Sri M.B.V.Satyanarayana",
            Category: "Members of the Trust/Society/Management",
            nominatedBy: "Management"
        },
        {
            name: "Sri. M. Kishore Kumar",
            Category: "Members of the Trust/Society/Management",
            nominatedBy: "Management"
        },
        {
            name: "Sri M. Ramesh Babu",
            Category: "Members of the Trust/Society/Management",
            nominatedBy: "Management"
        },
        {
            name: "Sri Y.Srinivas",
            Category: "Industrialist/Educationalist",
            nominatedBy: "Management"
        },
        {
            name: "Dr. M. Nageswara Rao",
            Category: "Faculty Member",
            nominatedBy: "Management"
        },
        {
            name: "Dr.Sk.J.Shareef",
            Category: "Faculty Member",
            nominatedBy: "Management"
        },
        {
            name: "Sri. Ch. Srinivasa Rao",
            Category: "Administrative Officer",
            nominatedBy: "Management"
        },
        {
            name: "State Govt. Nominee",
            Category: "Principal, Govt.Polytechnic College, Repalle",
            nominatedBy: "State Govt. Nominee"
        },
        {
            name: "Dr. K.Ravindra",
            Category: "Member",
            nominatedBy: "JNTUK, Kakinada"
        },
        {
            name: "Dr. S.Gopi Krishna Member Secretary",
            Category: "Principal, SMCE",
            nominatedBy: "Management"
        },
    ]
    const governingBodyData = [
        {
            title: "Guide the college while fulfilling the objectives for which the college has been granted autonomous status."
        },
        {
            title: "Institute scholarships, fellowships, studentships, medals, prizes and certificates on the recommendations of the Academic Council."
        },
        {
            title: "Approve new programmes of study leading to degrees and/or diplomas."
        },
        {
            title: "All recruitments of Teaching Faculty/Principal shall be made by the Governing Body/state government as applicable in accordance with the policies laid down by the UGC and State Government from time to time."
        },
        {
            title: "To approve annual budget of the college before submitting the same at the UGC."
        },
        {
            title: "Perform such other functions and institute committees, as may be necessary and deemed fit for the proper development of the college."
        }
    ]
    return (
        <div>
            <h2 style={{ textAlign: "center", fontWeight: "bold" }}>GOVERNING BODY</h2>
            <div>
                <table className='gb_table'>
                    <thead>
                        <tr style={{fontWeight:"bold"}}>
                            <th>S.NO</th>
                            <th>MEMBER NAME</th>
                            <th>CATEGORY</th>
                            <th>NOMINATED BY</th>
                        </tr>
                    </thead>
                    <tbody>
                        {governingbodyTable.map((each, i) => <tr>
                            <td>{i + 1}</td>
                            <td>{each.name}</td>
                            <td>{each.Category}</td>
                            <td>{each.nominatedBy}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
            <div className='governing_content'>
                <p>
                    <span className="governing_sidehead">Functions of the Governing Body :</span>
                    <p>Subject to the existing provision in the bye-laws of respective college and rules laid down by the state government/parent university, the Governing Body shall:</p>
                </p>
                {governingBodyData.map((item, index) => <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                        {" "}
                        {item.title}
                    </p>
                </div>)}
            </div>
        </div>
    )
}

export default GoverningBody
