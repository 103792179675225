import React from 'react'

const Mechanism = () => {
  return (
    <div>
       <p className='gr_header'>
       Mechanism to resolve and disposal of grievances
            </p>
            <p>The SGRC will submit its report with recommendations, if any, to the institute and a copy there of to the aggrieved student, within a period of 15 days from the date of receipt of the complaint.</p>
            <p>If the compliant is found baseless/fake/irrelevant/anonymous and the committee founds wrong intension of the complaint, it will be disposed off with appropriate recommendation.</p>
    </div>
  )
}

export default Mechanism
