import React from "react";
import "./Library.css";
import LibraryImg from "./../../assets/images/libraryMit.jpg";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import staffImg from "../../assets/carousal_images/InteractionwithChairman.jpeg";

const Library = () => {
  const facilities = [
    {
      title: "JST",
      course: "J- GATE SCIENCE AND TECHNOLOGY",
    },
    {
      title: "DELNET",
      course: "E-journals",
    },
    {
      title: "NDL",
      course: "NDL India",
    },
    {
      title: "SHODHA SINDHU",
      course: "ONLINE E- RESOURCE REUISITION SYSTEMS",
    },
  ];
  const staff = [
    {
      name: "G.UPENDRA RAO",
      qualification: "MA.B.Ed.,MLISc,PGDCA	",
      designation: "LIBRARIAN",
    },
    {
      name: "V.VENKATESWER RAO",
      qualification: "BA,B.LISc.",
      designation: "Library Asst",
    },
    {
      name: "O.VENKATA KRISHNA",
      qualification: "BSc COMPUTERS.",
      designation: "BOOK KEEPER",
    },
    {
      name: "M.MAHA LAKSHMI",
      qualification: "SSC",
      designation: "ATTENDER",
    },
  ];
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <br/>
     <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            Library
          </a>
        </div>
      </div>
      <div className="library_about">
        <img src={LibraryImg} alt="LibraryImg" />
        <div className="container_text">
          
          <p>
            SMCE Central Library is the soul of Institution and it acquires,
            processes, preserves and Disaminates information to the user
            community with the motto of empowering knowledge. It has an
            extensive collection of books, scientific and technical journals and
            electronic reference materials for satisfying the academic and
            research needs of the student and faculty.
            </p>
           
            
                <p>  1. The total number of titles are 5005</p>
               <p> 2. The total number of volumes are 29,400</p>
           
           <p> The library has access to the e-resources like DELNET.</p>
          
            <p>Automation software (ECAP) is available in the LIbrary for issuing
            the books to the students and faculty .</p>
            <p>Total Number of Systems present in Digital library are 50.</p>
         
        </div>
      </div>
     
    </div>
  );
};

export default Library;
