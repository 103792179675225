import React from 'react'
import "./BOS.css"
import BOSFunctions from './BOSFunctions'

const CSEAI = () => {
    const cseai = [
        {
            member: "Chairperson",
            designation: "Dr.SHAIK MOHAMMAD RAFI,Professor of AI Dept. & HOD"
        },
        {
            member: "University Nominee",
            designation: "Dr.D.Haritha,Professor of CSE, UCEK,JNTU Kakinada"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr S Jyothi,M.Tech., Ph.D.Professor, Dept . Of Computer Science Sri Pamavathi Mahila Visvavidyalayam Tirupati - 517502 . AP , India.e-mail:jyothi.Spmvv@gmail.com,Mobile:944058218"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr Kuda Nageswarao M.Tech., Ph.D.Professor, Dept. of Computer Science and Systems Engineering Andhra University Email: knrao2038@gmail.com Cell: 9963847111"
        },
        {
            member: "Representative from Industry",
            designation: "Devineni Ramana Reddy/Prasad ReddyTechnical Manager Sarja Technologies , Hyd hello@sarjatechnologies.com 6281137108"
        },
        {
            member: "Alumni Member",
            designation: "Ms. Kolla Priyanka B.Tech.,System Engineer,Tata Consultancy Services Pvt. Ltd., 8977103203 kolla.priyanka@tcs.com"
        },
        {
            member: "Member",
            designation: "Mr.P Srinivaslu Reddy, Asst.Prof.,SMCE"
        },
        {
            member: "Member",
            designation: "Mrs.M Rajyalakshmi, Asst.Prof.,SMCE"
        },

    ]

    return (
        <>
            <h4 class="_Top">SRI MITTAPALLI COLLEGE OF ENGINEERING <br />
                Department of CSE-Artificial Intelligence <br />
                Composition of Board of Studies
            </h4 >
            <h3 class="A">
                A.Y.: 2023-24
            </h3>

            <div>
                <table className='gb_table'>
                    <thead>
                        <tr>
                            <th>
                                S.NO
                            </th>
                            <th>
                                MEMBERS
                            </th>
                            <th>
                                NAME & DESIGNATION
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {cseai.map((each, i) => <tr>
                            <td>
                                {i + 1}
                            </td>
                            <td>
                                {each.member}
                            </td>
                            <td>
                                {each.designation}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
            <div className='bottom'>
                <h3 className='_chairperson'>Chairperson</h3>
                <p>BoS-CSE-Artificial Intelligence</p>
            </div>
            <BOSFunctions />


        </>
    )
}

export default CSEAI
