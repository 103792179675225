import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./Naac.css";
import { PageHeader } from "../Header/PageHeader";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import { useState } from "react";
import RotatingSpinner from "../Resourses/RotatingSpinner";
import cr32 from "../../Pdf/ExtendedProfile/3.2 Extended Profile Sanctioned Course A.Y 2022-23.pdf"

const ExtendedProfile = () => {
    return (
        <>

            <div className="container mt-5">
                <PageHeader name="EXTENDED PROFILE" />
                <div className="crt_main">
                    <Accordion className="naac_ssr_criterias">
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>1. Programme</Typography>&nbsp;&nbsp;&nbsp;&nbsp;
                        </AccordionSummary>
                        <AccordionDetails
                        //   sx={{backgroundColor:"white"}}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <a>
                                        <VisibilityIcon />
                                    </a>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>
                                    {" "}
                                    1.1 Number of courses offered by the institution across all programs during the year.
                                </Typography>
                            </AccordionSummary>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="naac_ssr_criterias">
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>2. Student</Typography>&nbsp;&nbsp;&nbsp;&nbsp;
                        </AccordionSummary>
                        <AccordionDetails
                        //   sx={{backgroundColor:"white"}}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <a>
                                        <VisibilityIcon />
                                    </a>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>
                                    {" "}
                                    2.1 Number of students during the year.
                                </Typography>
                            </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails
                        //   sx={{backgroundColor:"white"}}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <a>
                                        <VisibilityIcon />
                                    </a>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>
                                    {" "}
                                    2.2 Number of seats earmarked for reserved category as per GOI/ State Govt. rule during the year.
                                </Typography>
                            </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails 
                //   sx={{backgroundColor:"white"}}
                  >
                              <AccordionSummary
                                expandIcon={
                                  <a>
                                    <VisibilityIcon />
                                    </a>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  2.3 Number of outgoing/ final year students during the year.
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                    </Accordion>
                    <Accordion className="naac_ssr_criterias">
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>3. Academic</Typography>&nbsp;&nbsp;&nbsp;&nbsp;
                        </AccordionSummary>
                        <AccordionDetails
                        //   sx={{backgroundColor:"white"}}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <a>
                                        <VisibilityIcon />
                                    </a>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>
                                    {" "}
                                    3.1 Number of full time teachers during the year.
                                </Typography>
                            </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails
                        //   sx={{backgroundColor:"white"}}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <a href={cr32} target="_blank">
                                        <VisibilityIcon />
                                    </a>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>
                                    {" "}
                                    3.2 Number of sanctioned posts during the year.
                                </Typography>
                            </AccordionSummary>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="naac_ssr_criterias">
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography>4. Institution</Typography>&nbsp;&nbsp;&nbsp;&nbsp;
                        </AccordionSummary>
                        <AccordionDetails
                        //   sx={{backgroundColor:"white"}}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <a>
                                        <VisibilityIcon />
                                    </a>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>
                                    {" "}
                                    4.1 Total number of Classrooms and Seminar halls.
                                </Typography>
                            </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails
                        //   sx={{backgroundColor:"white"}}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <a>
                                        <VisibilityIcon />
                                    </a>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>
                                    {" "}
                                    4.2 Total expenditure excluding salary during the year (INR in lakhs).
                                </Typography>
                            </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails 
                //   sx={{backgroundColor:"white"}}
                  >
                              <AccordionSummary
                                expandIcon={
                                  <a>
                                    <VisibilityIcon />
                                    </a>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  4.3 Total number of computers on campus for academic purposes.
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                    </Accordion>
                </div>
            </div>

        </>
    );
}

export default ExtendedProfile
