import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import { PageHeader } from "../../Header/PageHeader";
import "../Departments.css";
import 'animate.css'

// Lab Images

import lab3 from "../../../assets/lab1.jpeg";
import lab2 from "../../../assets/lab/cselab2-2.jpg";
import lab4 from "../../../assets/lab/cselab1-2.jpg";
import lab1 from "../../../assets/lab/cselab1-1.jpg";
import lab5 from "../../../assets/lab/cselab1-3.jpg";
import lab6 from "../../../assets/lab/cselab2-1.jpg";
import lab7 from "../../../assets/lab/cselab2-3.jpg";
import lab8 from "../../../assets/lab/cselab3-1.jpg";
import lab9 from "../../../assets/lab/cselab3-2.jpg";
import lab10 from "../../../assets/lab/cselab4-1.jpg";
import lab11 from "../../../assets/lab/cselab4-2.jpg";
import lab12 from "../../../assets/lab/cselab4-3.jpg";

// Circuits Lab 

import circ1 from "../../../assets/lab3.jpeg";
import circ2 from "../../../assets/lab1.jpeg";
import circ3 from "../../../assets/lab5.jpeg";

// Machines Lab 

import mach1 from "../../../assets/lab5.jpeg";
import mach2 from "../../../assets/lab4.jpeg";
import mach3 from "../../../assets/lab2.jpeg";

// PeEm Lab

import peem1 from "../../../assets/lab2.jpeg";
import peem2 from "../../../assets/lab3.jpeg";
import peem3 from "../../../assets/lab4.jpeg";

// PowerSystems Lab

import pow1 from "../../../assets/lab1.jpeg";
import pow2 from "../../../assets/lab2.jpeg";
import pow3 from "../../../assets/lab3.jpeg";

// Simulation Lab 

import sim1 from "../../../assets/lab1.jpeg";
import sim2 from "../../../assets/lab2.jpeg";
import sim3 from "../../../assets/lab4.jpeg";



const Labs = () => {
  const [key, setKey] = useState("All");

  return (
    <div className="container">
      <PageHeader name="Laboratories" />
      {/* <Card style={{ width: "100%", margin: "20px 0px" }}>
        <Card.Header>CSE Laboratories</Card.Header>
        <Card.Body>
          <Card.Title>We have Fully Equipped Laboratories</Card.Title>
          <Card.Text>
            we have the several Computer Labs equipped with latest software's
            and workstations. It is connected to internet through a lease line
            to facilitate high speed internet access. All the computers are
            connected through a structured network. Printer and copier
            facilities are also available in the lab. Our dedicated faculty and
            staff are proud of their impressive credentials, which are
            comparable to those of the best career instructors.
          </Card.Text>
        </Card.Body>
      </Card> */}

      <div className="content">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="All" title="All">
          <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={circ1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={circ2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={circ3}
                    alt="lab-img"
                  />
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mach1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mach2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mach3}
                    alt="lab-img"
                  />
                  <br />
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={peem1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={peem2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={peem3}
                    alt="lab-img"
                  />
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={pow1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={pow2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={pow3}
                    alt="lab-img"
                  />
                  <br />
                </div>
              </div>
            </div>

            <br />
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim3}
                    alt="lab-img"
                  />
                  <br />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="SIMULATION LAB " title="SIMULATION LAB ">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={circ1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={circ2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={circ3}
                    alt="lab-img"
                  />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="ELECTRICAL MACHINES LAB" title="ELECTRICAL MACHINES LAB">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mach1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mach2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mach3}
                    alt="lab-img"
                  />
                  <br />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="ELECTRICAL CIRCUITS LAB" title="ELECTRICAL CIRCUITS LAB">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={peem1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={peem2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={peem3}
                    alt="lab-img"
                  />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="PE AND EM LAB " title="PE AND EM LAB ">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={pow1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={pow2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={pow3}
                    alt="lab-img"
                  />
                  <br />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="COMPUTER LAB3" title="COMPUTER LAB3">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim3}
                    alt="lab-img"
                  />
                  <br />
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Labs;
