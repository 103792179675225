import React from "react";
import "./Addmission.css";

import { Table, Button } from "antd";

const PgintakeTable = () => {
  const Tabledta = [
    {
        Programme: "M.Tech - Computer Science & Engineering (CS)",
      ConvenerQuota: "13",
      BCategory: "5",
      Total: "18",
    },
    {
        Programme: "M.Tech - Electronics & Communication Engineering (VLSI & ES) ",
      ConvenerQuota: "13",
      BCategory: "5",
      Total: "18",
    },
    {
        Programme: "Master of Business Administration (MBA) ",
      ConvenerQuota: "42",
      BCategory: "18",
      Total: "60",
    },
  ];
  const columns = [
    {
      title: "S.No",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Programme",
      dataIndex: "Programme",
      align: "left",
    }, 
    // {
    //     titile: "UG/PG Intake",
    //     dataIndex: "UGPGIntake",
    //     align: "center"
    // },
    {
      title: "ConvenerQuota",
      dataIndex: "ConvenerQuota",
      align: "center",
    },
    {
      title: "BCategory",
      dataIndex: "BCategory",
      align: "center",
    },
    {   
      title: "Total",
      dataIndex: "Total",
      align: "center",
    },
   
  ];
  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };
  return (
       
        <Table columns={columns} dataSource={Tabledta} onChange={onChange} />
  );
};

export default PgintakeTable;
