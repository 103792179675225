import React from 'react'
import CheckIcon from '@mui/icons-material/Check';

const Objective = () => {
    return (
        <div>
            <p className='gr_header'>
                OBJECTIVE
            </p>
            <p style={{ display: "flex" }}>
                <CheckIcon sx={{ color: "green", fontSize: "40px", fontWeight: "bold" }} />
                &nbsp;
                <p>
                    The main objectives of the Student Grievance Redressal Committee is to prevent unfair practices if caused with one or more students.
                </p>
            </p>
            <p style={{ display: "flex" }}>
                <CheckIcon sx={{ color: "green", fontSize: "40px", fontWeight: "bold" }} />
                &nbsp;
                <p>
                    To provide a mechanism to students for Redressal of their Grievances
                </p>
            </p>
            <p style={{ display: "flex" }}>
                <CheckIcon sx={{ color: "green", fontSize: "40px", fontWeight: "bold" }} />
                &nbsp;
                <p>
                    To govern the discipline to avoid incidences that may cause threats to dignity of students and hence to the institute.
                </p>
            </p>
        </div>
    )
}

export default Objective
