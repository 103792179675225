import React from "react";
import "../Academics/Admissins/Addmission.css";
import "./Trust.css";
import KeyboardDoubleArrowRightSharpIcon from "@mui/icons-material/KeyboardDoubleArrowRightSharp";

const AboutTrust = () => {
  return (
    <div>
      <div class="navbar navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            <h1 style={{ textAlign: "center" }}>SRI MITTAPALLI TRUST</h1>
            <h5 style={{ textAlign: "center" }}>(ESTABLISHED IN 2005)</h5>
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div>
          <h2 className="addmision_heading">About Trust</h2>
          <div className="procedur_cntr">
            <br />
            <div className="prg_content">
              <p>
                Sri Mittapalli Trust, is a non- profit organization, registered
                in 2005, strives hard to bring out the hidden sparks from the
                students to mould them as entrepreneurs of high order. It
                renders its reliable service to the national cause of improving
                the quality of life of our people with importance to the
                betterment of rural people and their economic development. Our
                credo is to provide an all round development with a sound moral
                base. Surging ahead with a spirit of healthy competition,
                balancing a successful career teamed with a contented life, is
                the gift offered to all the students.
              </p>
            </div>
            {/* <div className="procedur_p_text">
          <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp;
          <p>
            SMCE offers B.Tech in Computer Science & Engineering, Computer
            Science & Engineering (Artificial Intelligence), Computer Science &
            Engineering (Data Science), Information Technology and Electronics &
            Communication Engineering with the total intake of 420 students.
          </p>
        </div> */}

            <h4 className="addmision_heading_sub">TRUST ACTIVITIES</h4>
            <div className="procedur_three_cntr">
              <div className="procedur_three_cntr_inside">
                <ul>
                  <KeyboardDoubleArrowRightSharpIcon />{" "}
                  &nbsp;&nbsp;&nbsp;Scholarship is offered by the Trust to the
                  meritorious students.
                  <br />
                  <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp;
                  Aspire hard for the maintenance, growth and development of
                  academic standards.
                  <br />
                  <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp; To
                  generate and promote the opportunities for self-employment.
                  <br />
                  <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp;
                  Dedicated to socio-economic change in India regardless of
                  race, class, culture or religion.
                  <br />
                  <KeyboardDoubleArrowRightSharpIcon /> &nbsp;&nbsp;&nbsp;
                  Fostering character building and nurturing the inquisitive
                  spirit of students.
                </ul>
              </div>
            </div>
            <br />
            <div className="container">
            <h4 className="addmision_heading_sub">TRUST MEMBERS</h4><br/>
              <table className="gb_table" >
                <thead>
                  <tr>
                    <th>TRUSTEE NAME</th>
                    <th>DESIGNATION</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Sri Mittapalli Venkata Koteswara Rao</td>
                    <td>Managing Trustee</td>
                  </tr>
                  <tr>
                    <td> Sri Mittapalli Bala Parvathamma</td>
                    <td>Trustee</td>
                  </tr>
                  <tr>
                    <td>Sri Mittapalli Bheemeswara Venkata Satyanarayana</td>
                    <td>Trustee</td>
                  </tr>
                  <tr>
                    <td>Sri Mittapalli Srinivasa Chakravarthi</td>
                    <td>Trustee</td>
                  </tr>
                  <tr>
                    <td>Sri Mittapalli Kishore Kumar</td>
                    <td>Trustee</td>
                  </tr>
                  <tr>
                    <td>Sri Mittapalli Ramesh Babu</td>
                    <td>Trustee</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTrust;
