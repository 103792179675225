import React from "react";
import "./Committees.css";
import Table from "../Committees/Tablecommittee";
import "../GoverningBody/GoverningBody.css"

const Committees = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            COMMITTEES
          </a>
        </div>
      </div><br/>
      <div className="container">
        <h4 className="text"> LIST OF COMMITTEES</h4>
        <div className="container_bg">
          <div className="btn_text">
            <button type="button" className="btn_audit" >
              <a href="/collegeacadamic" target="blank" >
              College Academic Committee{" "}
              </a>
            </button>
            <button type="button" className="btn_audit" >
              <a href="/iqacCommittee" >
                IQAC{" "}
              </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/iaccCommittee" >IACC </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/grevience" >Grievance Redressal 
                 </a>
            </button>
          </div>
          <div className="btn_text">
            <button type="button" className="btn_audit" >
              <a href="/professional" >Professional Societies/Chapters 
              {" "}
              </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/training" >
                Training & Placement
              {" "}
              </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/career" >Career Guidance
                </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/hostel" >Hostel Advisory
               {" "}
              </a>
            </button>
          </div>
          <div className="btn_text">
            <button type="button" className="btn_audit">
              <a href="/canteen" >
                Canteen Coordination
              {" "}
              </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/nss" >
                NSS{" "}
              </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/BcSc" >BC,SC&ST Student Cell </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/sports" >
               Sports{" "}
              </a>
            </button>
          </div>
          <div className="btn_text">
            <button type="button" className="btn_audit">
              <a href="/transport" >
                Transport{" "}
              </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/culture" >
               Cultural{" "}
              </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/examination" >Examination </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/timetable" >
                Time Table{" "}
              </a>
            </button>
          </div>
          <div className="btn_text">
            <button type="button" className="btn_audit">
              <a href="/libraryCommittee" > 
                Library{" "}
              </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/industrial" > Industry-Institute-Interaction
              {" "}
              </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/development" > Entrepreneurship Development</a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/websitecommittee" >
              Website/ICT/Internet{" "}
              </a>
            </button>
          </div>
          <div className="btn_text">
            <button type="button" className="btn_audit">
              <a href="/alumini" >
                Alumini Association{" "}
              </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/antiRagging" >
                Anti Ragging{" "}
              </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/department" >Department Association</a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/finance" >
                Finance & Purchasing{" "}
              </a>
            </button>
          </div>
          <div className="btn_text">
            <button type="button" className="btn_audit">
              <a href="/RDConsultant" >
               R&D Consultancy{" "}
              </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/HealthCenter" >
                Health Center{" "}
              </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/womenProtection" >Women Protection & Grievances </a>
            </button>
            <button type="button" className="btn_audit">
              <a href="/media" >
                Media & publications{" "}
              </a>
            </button>
          </div>
          <div className="btn_text">
            <div className="col-3">
            <button type="button" className="btn_audit">
              <a href="/rightToInformation" >
                Right To Information{" "}
              </a>
            </button> 
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default Committees;
