import React from 'react'
import "./BOS.css"
import BOSFunctions from './BOSFunctions'

const CSE = () => {
    const cse = [
        {
            member: "Chairperson",
            designation: "Mr.E.Adinarayana Assoc.Professor in CSE Dept. & HOD"
        },
        {
            member: "University Nominee",
            designation: "Dr.ASN .Chakravarthy,Professor of CSE, UCEK,JNTU Kakinada 9618719229"

        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr. Ande Prasad,M.Tech., Ph.D.Vikrama Simhapuri University, Nellore9885934309andeprasadvsu@gmail.com"
        },
        {
            member: "Subject expert from outside the parent University",
            designation: "Dr.V.Srikanth M.Tech., Ph.D.KLU (Deemed to be University)9966662626 vsrikanth@kluniversity.in"
        },
        {
            member: "Representative from Industry",
            designation: "Mr.Repala Manoj Kumar B.Tech.,Technical Lead,Cognizant Technology Solutions Pvt. Ltd. 9704170147 manojkumar.repala@cognizant.com"
        },
        {
            member: "Alumni Member",
            designation: "Ms. Kolla Priyanka B.Tech.,System Engineer,Tata Consultancy Services Pvt. Ltd., 8977103203 kolla.priyanka@tcs.com"
        },
        {
            member: "Member",
            designation: "Mr.M.Venkata Ramana, Assoc.Prof., SMCE"
        },
        {
            member: "Member",
            designation: "Mr.Y.Venkata Subramanyam, Asst.Prof., SMCE"
        },
        {
            member: "Member",
            designation: "Mrs.K.Anusha, Asst.Prof., SMCE"
        },
        {
            member: "Member",
            designation: "Mr.K.Vijay Kumar, Asst.Prof., SMCE"
        },
        {
            member: "Member",
            designation: "Mr.Pantangi L N Manojkumar, Assoc.Prof., SMCE"
        },
        {
            member: "Member",
            designation: "Ms.P.Swathi, Asst.Prof., SMCE"
        },
        {
            member: "Member",
            designation: "Mr.P.Naga Malleswara Rao, Asst.Prof., SMCE"
        },
        {
            member: "Member",
            designation: "Mr.M.Ajay Babu, Asst.Prof., SMCE"
        },
        {
            member: "Member",
            designation: "Mr.B.Anjaneyulu, Asst.Prof., SMCE"
        },
        {
            member: "Member",
            designation: "Ms. R.Bhuvaneswari, Asst.Prof., SMCE"
        },
    ]

    return (
        <>
            <h4 class="_Top">SRI MITTAPALLI COLLEGE OF ENGINEERING <br />
                Department of Computer Science and Engineering <br />
                Composition of Board of Studies
            </h4 >
            <h3 class="A">
                A.Y.: 2023-24
            </h3>
            <div>
                <table className='gb_table'>
                    <thead>
                        <tr>
                            <th>
                                S.NO
                            </th>
                            <th>
                                MEMBERS
                            </th>
                            <th>
                                NAME & DESIGNATION
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {cse.map((each, i) => <tr>
                            <td>
                                {i + 1}
                            </td>
                            <td>
                                {each.member}
                            </td>
                            <td>
                                {each.designation}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
            <div className='bottom'>
                <h3 className='_chairperson'>Chairperson</h3>
                <p>BoS-Computer Science and Engineering</p>
            </div>
            <BOSFunctions />
        </>
    )
}

export default CSE
