import React from 'react'
import './Header.css'

export const PageHeader = ({name}) => {
  return (
    <div className="page_header">
        <h1>{name}</h1>
      </div>
  )
}

