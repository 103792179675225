import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/TransportTable";
import StarRateIcon from "@mui/icons-material/StarRate";


const Committees = () => {
  const trasport =[
    {
      title:"To provide College Bus to students, teachers and the Departments after proper assessment of the requisition and ensure optimum use of bus facility."
    },
    {

      title:"To co-ordinate the Educational Trips and field visits conducted by  different Departments / Associations. Time management of buses."
           
    },
    {
      title:"Arranging for an agreement with Transport Company for additional buses if required. To review the operation of vehicles in all routes."
      
    },
    {
      title:"To regulates drivers in different bus routes."
    },
    {
      title:"To monitor discipline among the students inside the bus and also  monitor the crowd in each bus."
     
    },
    {
      title:"To review the maintenance of transport vehicles and works related to RTO office."
      
    },
    {
      title:"To take necessary measures to prevent un-authorized boarders."
    }
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            TRANSPORT COMMITTEE
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            Transport committee is to regulate the transit of buses from various
            surrounding villages, towns and cities of the college. In this
            committee faculty members monitor the routes, timings and ensure
            discipline among the students inside the buses.
          </p>
          <p>Transport Committee is formed with the following members:</p>
          
        </div>
      </div>
      <div className="container">
        <Table />
      </div>
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {trasport.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
