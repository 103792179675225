import React from "react";
import "../Committees/Committees.css";
import Table from "../CommitteesMenus/WomenprotectionTable";
import StarRateIcon from "@mui/icons-material/StarRate";

const Committees = () => {
  const women =[
    {
      title:"Creates awareness amongst students and staff about the problems faced by women of all strata due to gender issues and provides anenvironment of gender justice where men and women work together with a sense of personal security and dignity."
    },
    {
      title:"Disseminates knowledge about rights and laws related to women."
    },
    {
      title:"Creates a sense of responsibility in the students and have healthy study and work culture in the college."
    },
    {
      title:"Helps in enhancement of the self-esteem and self-confidence of girl students and women staff."
    },
  ]
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            WOMEN’S PROTECTION AND GRIEVANCE CELL
          </a>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="head">
          <p>
            Women cell at SMCE aims at empowering and orienting women to
            recognize their true potential and to help them attain their own
            stand in a competing world. The cell has been established in order
            to protect the women staff and students associated with the college
            from any sorts of injustice or sexual harassment of any nature
            directly and indirectly.
          </p>
        </div>
      </div>
      <div className="container">
        <Table />
        
      </div>
      <div className='governing_content'>
        <p>
          <span className="governing_sidehead">Functions :</span>
        </p>
        {women.map((item) => <div style={{ display: "flex" }}>
          <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
          <p style={{ textAlign: "start" }}>
            {" "}
            {item.title}
          </p>
        </div>)}
      </div>
    </div>
  );
};

export default Committees;
