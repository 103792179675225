import React from "react";
import "./IctFecilities/Ict.css";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import StudentForm from "./IctFecilities/StudentForm";
import FacultyForm from "./IctFecilities/FacultyForm";

const Aictefeedback = () => {
  const [value, setValue] = React.useState('student');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div class="aicte_main_container">
      <div className="aicte_heading_container">
      <h1 class="aicte_heading">AICTE FEEDBACK</h1>
      </div>
      <Box sx={{ width: '100%', display:'flex',justifyContent:'center'}}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="student" label="Student" />
        <Tab value="faculty" label="Faculty" />
      </Tabs>
    </Box>
    {value === 'student' && <StudentForm/>}
    {value === 'faculty' && <FacultyForm/>}
    </div>
  );
};

export default Aictefeedback;
