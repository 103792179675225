import React from "react";
import StarIcon from "@mui/icons-material/Star";
import game1 from '../../assets/spc.jpg';
import game2 from '../../assets/spc1.jpg';
import game3 from '../../assets/spc2.jpg';
import game4 from '../../assets/spc3.jpg';
import game5 from '../../assets/spc5.jpg';
import game6 from '../../assets/spc6.jpg';





const Sportsgames = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            SPORTS & GAMES
          </a>
        </div>
      </div>
      <br/>



      <div className="row g-4">
            <div
              className="col-lg-4 col-md-4 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item bg-light img_cnt">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid rounded img"
                    src={game1}
                    alt="game1-img"
                  />
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-4 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="team-item bg-light img_cnt">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid rounded img"
                    src={game2}
                    alt="game2-img"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="team-item bg-light img_cnt">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid  rounded img"
                    src={game3}
                    alt="game3-img"
                  />
                </div>
                
              </div>
            </div>
          </div>
          <br/>


          <div className="row g-4">
            <div
              className="col-lg-4 col-md-4 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item bg-light img_cnt">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid rounded img"
                    src={game4}
                    alt="game4-img"
                  />
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-4 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="team-item bg-light img_cnt">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid rounded img"
                    src={game5}
                    alt="game5-img"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="team-item bg-light img_cnt">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid rounded img"
                    src={game6}
                    alt="game6-img"
                  />
                </div>
                
              </div>
            </div>
          </div>


      <div className="container text-left">
        <h4 className="text text-center">Sports & Games</h4>
        <br />
        <p>
          Sports and Games are an integral part of any academic institution to
          develop the student to be a composite individual.In line with this,
          SSIT has established impressive sports and games facilities in the
          campus.Volleyball, Basketball, Cricket, Shuttle, and ball badminton,
          Table Tennis, Caroms, Tennis, Through ball are the games which are in
          place, apart from all athletic facilities. Our college team has earned
          laurels for the college in standing high in the inter college games
          conducted by JNTU .
        </p>

        &nbsp; <h4 >Facilities</h4>
        <p>
          {" "}
          <StarIcon />
          &nbsp;A Play ground is available for conducting sports and games for
          Boys and Girls as well as Staff.
        </p>
        <p>
          {" "}
          <StarIcon />
          &nbsp;We conducting cricket, volley ball, basket ball, ball badminton, badminton, table tennis, caroms, and chess for boys.
        </p>
        <p>
          {" "}
          <StarIcon />
          &nbsp;We conducting volley ball, ball badminton, throw ball, tennikoit, badminton, table tennis, caroms, and chess for girls.
        </p>
        <p>
          {" "}
          <StarIcon />
          &nbsp; We conducting cricket and Table tennis for Staff.
        </p>
      </div>
    </div>
  );
};

export default Sportsgames;
