import React from 'react'
import "./Vision.css"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PolicyIcon from "@mui/icons-material/Policy";
import PublicIcon from '@mui/icons-material/Public';
import SchoolIcon from "@mui/icons-material/School";

const Vision = () => {
    return (
        <div style={{ width: "100%" }}>
            {/* <div className='mainborder'>
            <div className='vision_cards_cnt'
                // className=""
                // data-aos="slide-right"
                // data-aos-offset="200"
                // data-aos-easing="ease-in-sine"
                // data-aos-duration="1500"
            >
                <div className='vision_cards'>
                    <div className='vision_cards_head'>
                        <RemoveRedEyeIcon className='vision_icons' sx={{ fontSize: 70, color: "#0d6efd" }} />
                        <h3>VISION</h3>
                    </div>
                    <br/>
                    <br/>
                    <div className='vision_cards_body'>
                        <p >
                            To be a top-notch institute in fostering visionary ethical
                            technocrats with global standards to contribute the
                            development of society and nation.
                        </p>
                    </div>
                </div>
                <div className='vision_cards'>
                    <div className='vision_cards_head'>
                        <PublicIcon className='vision_icons' sx={{ fontSize: 70, color: "#0d6efd" }} />
                        <h3>MISSION</h3>
                    </div>
                    <br/>
                    <div className='vision_cards_body'>
                        <p >
                            M1: To provide Outcome-Based Quality Technical Education
                            with Civic Sense by well-qualified and committed faculty.
                            <br />
                            M2:To manoeuvre the Potential Resources viz., Human, Finance
                            and Technology to the prosperity of student Centric
                            Academics.
                            <br />
                            M3: To impart necessary skills required to make students
                            Globally Employable with the contemporary teaching
                            methodologies.
                            <br />
                            M4: To empower the aspirants of Higher Education with
                            appropriate abilities.
                            <br />
                            M5: To establish & maintain a constant & strong relation
                            with the industry alumni and academia.
                        </p>
                    </div>
                </div>
                <div className='vision_cards'>
                    <div className='vision_cards_head'>
                        <PolicyIcon className='vision_icons' sx={{ fontSize: 70, color: "#0d6efd" }} />
                        <h3>QUALITY POLICY</h3>
                    </div>
                    <br/>
                    <div className='vision_cards_body'>
                        <p >
                            To strive relentlessly for consistence transformations
                            keeping in mind the trends of technical education through
                            transparent and effective systems to meet the requirements
                            of international accreditation bodies.
                        </p>
                    </div>
                </div>
                </div>

            </div>
            <br /> */}
            <div className='mainText'>
                <div>
                    <h3 className='headText'><b>VISION</b></h3>
                    <p className='message'>
                        To be a top-notch institute in fostering visionary ethical
                        technocrats with global standards to contribute the
                        development of society and nation.
                    </p>

                </div>
                <div>
                    <h3 className='headText'><b>MISSION</b></h3>
                    <p className='message'>
                        M1: To provide Outcome-Based Quality Technical Education
                        with Civic Sense by well-qualified and committed faculty.
                        <br />
                        M2:To manoeuvre the Potential Resources viz., Human, Finance
                        and Technology to the prosperity of student Centric
                        Academics.
                        <br />
                        M3: To impart necessary skills required to make students
                        Globally Employable with the contemporary teaching
                        methodologies.
                        <br />
                        M4: To empower the aspirants of Higher Education with
                        appropriate abilities.
                        <br />
                        M5: To establish & maintain a constant & strong relation
                        with the industry alumni and academia.
                    </p>
                </div>
                <div>
                    <h3 className='headText'><b>QUALITY POLICY</b></h3>
                    <p className='message'>
                        To strive relentlessly for consistence transformations
                        keeping in mind the trends of technical education through
                        transparent and effective <br/>systems to meet the requirements
                        of international accreditation bodies.
                    </p>
                </div>
            </div>
            <h1 style={{ textAlign: "center", fontWeight: "bold", marginTop: "40px", marginBottom: "20px" }}>
                COURSES OFFERED
            </h1>
            <div className='vision_cards_cnt'
                // data-aos-offset="200"
                // data-aos-easing="ease-in-sine"
                // data-aos-duration="2000" data-aos="slide-left"
               >
                <div className='courses_cards'>
                    <div className='vision_cards_head'>
                        <div className='vision_icons' sx={{ fontSize: 70, color: "#0d6efd" }} />
                        <h3>Under Graduation Programmes</h3>
                    </div>
                    <div className='vision_cards_body'>
                        <p >
                            1.Computer Science & Engineering (CSE)-420
                            <br />
                            2.Computer Science & Engineering (Artificial Intelligence)
                            (CSE - AI) - 120
                            <br />
                            3.Computer Science & Engineering (Data Science) (CSE - DS) -
                            120
                            <br />
                            4.Information Technology (IT) -60
                            <br />
                            5.Electronics & Communication Engineering (ECE) - 90
                            <br />
                        </p>
                    </div>
                </div>

                <div className='courses_cards'>
                    <div className='vision_cards_head'>
                        <div className='vision_icons' sx={{ fontSize: 70, color: "#0d6efd" }} />
                        <h3>Post Graduation Programmes</h3>
                    </div>
                    <div className='vision_cards_body'>
                        <p >
                            <b>M.Tech.</b>
                            <br />
                            1.Computer Science & Engineering(Specialization - CSE) 18
                            <br />
                            2. Electronics and Communication Engineering (Specialization
                            VLSI&ES)-18
                            <br />
                            <b>MBA</b>
                            <br />
                            1. Master of Business Administration (MBA) - 60
                        </p>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Vision
