// mtech department

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import csefaculty from "../../../assets/cat-4.jpg";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import axios from "axios";
import { resources } from "../../Resourses/Resources";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PageHeader } from "../../Header/PageHeader";
import "../Departments.css";
import Labs from "./Labs";
import Faculty from "./Faculty";
import { UitCircuit } from "../../../assets/icons/svgs";
import { StreamlineBugAntivirusDebuggingSolid } from "../../../assets/icons/svgs";
// import { StreamlineBugAntivirusDebuggingSolid } from "../../../assets/icons/svgs";
import { MdiPresentation } from "../../../assets/icons/svgs";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import QuizIcon from "@mui/icons-material/Quiz";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import {
  FluentShifts30Minutes24Filled,
  StreamlineBugAntivirusDebugging,
} from "../../../assets/icons/svgs";

import event1 from "../../../assets/Events/S&H/sh_orientation_1.jpg";
import event2 from "../../../assets/Events/S&H/sh_orientation_2.jpg";
import event3 from "../../../assets/Events/S&H/sh_orientation_3.jpg";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CourseStructure = () => {
  return (
    <div className="containe">
      <PageHeader name="M.Tech(VLSI & ES) - COURSE STRUCTURE" />
      <div className="course_structure_cnt animate__animated animate__zoomIn">
        <div className="course text-center">
          <h4>Course Structure for all Years</h4>
          <p>
            Download the Course Structure and Syllabus of four years of
            M.TECH-VLSI & ES course
          </p>
        </div>

        {/* <div className="w-100 text-center d-flex justify-content-center gap-5 ">
          <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>I MECH - R22</span>
            <a
              href="https://drive.google.com/file/d/1vbz4wKP2Nadda3FtZC-DyV16yqXVAkn1/view"
              target="blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div>
          <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>II MECH - R18</span>
            <a
              href="https://drive.google.com/file/d/1zYoF3FIQ956g_-zrjSHIYxQR-NXAZXN2/view"
              target="blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div>
          <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>III MECH - R18</span>
            <a
              href="https://drive.google.com/file/d/1zYoF3FIQ956g_-zrjSHIYxQR-NXAZXN2/view"
              target="blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div>
          <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>IV MECH - R18</span>
            <a
              href="https://drive.google.com/file/d/1zYoF3FIQ956g_-zrjSHIYxQR-NXAZXN2/view"
              target="blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div>
        </div> */}
      </div>
    </div>
  );
};
const Events = () => {
  const getIcon1 = () => {
    return <FluentShifts30Minutes24Filled />;
  };

  const getIcon2 = () => {
    return <UitCircuit />;
  };

  const getIcon3 = () => {
    return <StreamlineBugAntivirusDebuggingSolid />;
  };

  const getIcon4 = () => {
    return <CoPresentIcon sx={{ fontSize: 50, color: "orange" }} />;
  };

  const getIcon5 = () => {
    return <MdiPresentation />;
  };

  const getIcon6 = () => {
    return <QuizIcon sx={{ fontSize: 50, color: "orange" }} />;
  };

  const title1 = "Just-A-Minute";
  const desc1 =
    "is an all round-fun event that is all about the control of the mind over the mouth.";

  const title2 = "Instant Circuit Designing";
  const desc2 =
    "is a Competative event that all about the designing of Circuits Instantly.";

  const title3 = "Circuit Debugging";
  const desc3 = "Circuit Debugging is a Competative Event";

  const title4 = "Seminars";
  const desc4 =
    "Students give the demonistration on Various Latest Trends in Industry.";

  const title5 = "Poster Presentation";
  const desc5 =
    "is a way to share your knowledge of topic in a short format. It usually includes two elements - a poster and a brief explanation.";

  const title6 = "Quiz";
  const desc6 =
    "A quiz is a game or competition in which someone tests your knowledge by asking you questions";

  const IconCard = ({ icon, title, desc }) => {
    return (
      <>
        <Card
          variant="outlined"
          orientation="horizontal"
          sx={{
            width: 320,
            // height: 205,
            "&:hover": {
              boxShadow: "md",
              borderColor: "neutral.outlinedHoverBorder",
            },
          }}
        >
          <AspectRatio ratio="1" sx={{ width: 60 }}>
            {icon()}
          </AspectRatio>
          <CardContent>
            <Typography level="title-lg" id="card-description">
              {title}
            </Typography>
            <p className="desc">{desc}</p>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <div className="container">
      <PageHeader name="ACTIVITIES & EVENTS" />
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="course text-center animate__animated animate__fadeInRight">
          <h4>Student Development Activities</h4>
          <br />
          <p style={{ textAlign: "justify" }}>
            MECHRISER is conducted by the college Freshman orientation is a way
            for students to meet other students, become familiar with campus
            services. Every student attending college should add orientation to
            their to-do listin college.
          </p>
        </div>
        <div className="row mb-5">
          <div className="col-lg-4">
            <IconCard icon={getIcon1} title={title1} desc={desc1} />
          </div>
          <div className="col-lg-4">
            <IconCard icon={getIcon2} title={title2} desc={desc2} />
          </div>
          <div className="col-lg-4">
            <IconCard icon={getIcon3} title={title3} desc={desc3} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <IconCard icon={getIcon4} title={title4} desc={desc4} />
          </div>
          <div className="col-lg-4">
            <IconCard icon={getIcon5} title={title5} desc={desc5} />
          </div>
          <div className="col-lg-4">
            <IconCard icon={getIcon6} title={title6} desc={desc6} />
          </div>
        </div>
      </div>

      {/* <h4 className="text-center m-5">Events Photos</h4>
      <div className="d-flex justify-content-center gap-5">
        <div>
          <img src={event1} className="img-fluid rounded" alt="eeeEvents" />
        </div>
        <div>
          <img src={event2} className="img-fluid rounded" alt="eeeEvents" />
        </div>
        <div>
          <img src={event3} className="img-fluid rounded" alt="eeeEvents" />
        </div>
      </div> */}
    </div>
  );
};

const Obe = () => {
  return (
    <div className="container">
      <PageHeader name="PEOs/POs/PSOs/COs" />
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM EDUCATIONAL OBJECTIVES(PEO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PEO 1:</h2>
              <p>
                To prepare graduates to become successful professionals in
                industry, government, academia, research and entrepreneurship.{" "}
              </p>
            </div>
            <div class="peo">
              <h2>PEO 2:</h2>
              <p>
                To contribute for meeting the society needs in solving technical
                problems using electronics communications engineering ideology,
                tools and practices.{" "}
              </p>
            </div>
            <div class="peo">
              <h2>PEO 3:</h2>
              <p>
                To inculcate professional and human values, effective
                communication skills, Team spirit & leadership qualities.{" "}
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM OUTCOMES(PO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PO 1 (Engineering knowledge):</h2>
              <p>
                Apply the knowledge of mathematics, science, engineering
                fundamentals, and an engineering specialization to the solution
                of complex engineering problems.
              </p>
            </div>
            <div class="peo">
              <h2>PO 2 (Problem analysis):</h2>
              <p>
                Identity, formulate, review research literature, and analyze
                complex engineering problems reaching substantiated conclusions
                using the first principles of mathematics, natural sciences, and
                engineering sciences.
              </p>
            </div>
            <div class="peo">
              <h2>PO 3 (Design/development of solutions):</h2>
              <p>
                Design solutions for complex engineering problems and design
                system components or processes that meet the specified needs
                with appropriate consideration for public health and safety, and
                the cultural, societal, and environmental considerations.
              </p>
            </div>
            <div class="peo">
              <h2>PO 4 (Conduct investigations of complex problems):</h2>
              <p>
                Use research-based knowledge and research methods including
                design of experiments, analysis and interpretation of data, and
                synthesis of the information to provide valid conclusions.
              </p>
            </div>
            <div class="peo">
              <h2>PO 5 (Modern tool usage):</h2>
              <p>
                Create, select, and apply appropriate techniques, resources, and
                modern engineering and IT tools including prediction and
                modeling to complex engineering activities with an understanding
                of the limitations.
              </p>
            </div>
            <div class="peo">
              <h2>PO 6 (The engineer and society):</h2>
              <p>
                Apply reasoning informed by the contextual knowledge to assess
                societal, health, safety, legal and cultural issues and the
                consequent responsibilities relevant to the professional
                engineering practice.
              </p>
            </div>
            <div class="peo">
              <h2>PO 7(Environment and sustainability):</h2>
              <p>
                Understand the impact of the professional engineering solutions
                in societal and environmental contexts, and demonstrate the
                knowledge of, and need for sustainable development.
              </p>
            </div>
            <div class="peo">
              <h2>PO 8 (Ethics):</h2>
              <p>
                Apply ethical principles and commit to professional ethics and
                responsibilities and norms of the engineering practice.
              </p>
            </div>
            <div class="peo">
              <h2>PO 9 (Individual and team work):</h2>
              <p>
                Function effectively as an individual, and as a member or leader
                in diverse teams, and in multidisciplinary settings.
              </p>
            </div>
            <div class="peo">
              <h2>PO 10 (Communication):</h2>
              <p>
                Communicate effectively on complex engineering activities with
                the engineering community and with society at large, such as,
                being able to comprehend and write effective reports and design
                documentation, make effective presentations, and give and
                receive clear instructions.
              </p>
            </div>
            <div class="peo">
              <h2>PO 11 (Project management and finance):</h2>
              <p>
                Demonstrate knowledge and understanding of the engineering and
                management principles and apply these to one’s own work, as a
                member and leader in a team, to manage projects and in
                multidisciplinary environments.
              </p>
            </div>
            <div class="peo">
              <h2>PO 12(Life-long learning):</h2>
              <p>
                Recognize the need for, and have the preparation and ability to
                engage in independent and life-long learning in the broadest
                context of technological change.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM SPECIFIC OUTCOMES (PSO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PSO1:</h2>
              <p>
                Apply domain specific knowledge for the design and analysis of
                resent trends in Electronics and Communication Engineering
                applications.{" "}
              </p>
            </div>
            <div class="peo">
              <h2>PSO2:</h2>
              <p>
                Capability in using electronic modern IT tools (both software
                and hardware) for solving complex problems in electronic and
                communication systems.{" "}
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          COURSE STRUCTURE (CO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <ul class="course_outcomes_cnt">
              <li>
                <a href="#" target="_blank">
                  R19
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  R20
                </a>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const AboutDepartment = () => {
  return (
    <div>
      <div className="container">
        <PageHeader name="M.TECH (VLSI & ES)" />
        <div className="container-xxl">
          <div className="container-xxl py-5">
            <div className="container">
              <div className="row g-5">
                <div
                  className="col-lg-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{ minHeight: "100px" }}
                >
                  <div className="position-relative h-100 animate__animated animate__zoomIn">
                    <img
                      className="img-fluid rounded w-100 h-100"
                      src={csefaculty}
                      alt="csefaculty-img"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                  <h6 className="section-title bg-white text-start text-primary pe-3">
                    Faculty
                  </h6>
                  <h1 className="mb-4">About</h1>

                  <div className="row gy-2 gx-4 mb-4">
                    <p className="mb-0">
                      The Department of ECE was established in the year 2008
                      with the intent of developing highly qualified engineers
                      and researchers who can make a substantial contribution to
                      the field of Electronics and Communication Engineering.
                      The department has well-equipped laboratories such as
                      Integrated circuits lab, Electronic circuits lab,
                      Communications lab, Microprocessor & Microcontrollers lab,
                      VLSI design Lab, Digital Signal Processing lab, Embedded
                      Systems lab and Microwave and Optical Communications lab.
                    </p>
                    <p className="mb-0">
                      The department has experienced and dedicated faculty with
                      good academic and research experience. The department has
                      a good and consistent placement record with students
                      placed in many core and IT companies. The department is
                      running successfully under the headship of Professor Dr.
                      M.Nageswara Rao, who has vast experience in academics and
                      research.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card_cntr1">
            <div
              className="boxes col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="boxes1 service-item text-center pt-3">
                <div className="p-4">
                  <h5 className="mb-1">Vision</h5>
                  <p>
                    To produce innovative Electronics & Communication engineers
                    by imparting quality technical education with ethical and
                    moral values to meet the Global Standards.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="boxes col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="boxes1 service-item text-center pt-3">
                <div className="p-4">
                  <h5 className="mb-3">Mission</h5>
                  <p style={{ fontSize: "14px" }}>
                    M1. To establish an excellent teaching-learning process that
                    enables the students to face the challenges of Electronics
                    and Communication Engineering.{" "}
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    M2: To promote the inventions of allied technologies in
                    Electronics and Communication Engineering to have exposure
                    to global scenarios.{" "}
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    M3: To develop value-based ethical leadership among the
                    students for the perspective of society.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="boxes col-lg-3 text-center col-sm-6 wow "
              data-wow-delay="0.5s"
            >
              <div
                className="boxes text-center pt-3 pb-5"
                style={{ backgroundColor: "#f6f6f6", height: "fit-content" }}
              >
                <div className="p-4 ">
                  <h5 className="mb-3">STRENGTHS</h5>
                  <div>
                    <Accordion
                      sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ backgroundColor: "#fff" }}
                      >
                        Faculty & Infrastructure
                      </AccordionSummary>
                      <AccordionDetails
                        align="center"
                        sx={{
                          backgroundColor: "#fff5dc",
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Arial",
                          marginBottom: "10px",
                          letterSpacing: "1px",
                        }}
                      >
                        The department has total of 25 faculty members out of
                        them 3 are in the cadre of professor,5 in Associate
                        Professor and 17 in Cadre of Asst Professor.The
                        department is maintaining 1: 15 faculty to student ratio
                        with sufficient, Spacious class rooms and 3 Drawing
                        Halls .
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        Training Programs
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: "#fff5dc",
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Arial",
                          marginBottom: "10px",
                          letterSpacing: "1px",
                        }}
                      >
                        To conduct Industry relevant Training Programmes for
                        students so that they are ready for Industry.
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                      >
                        Training & Placements
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: "#fff5dc",
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Arial",
                          marginBottom: "10px",
                          letterSpacing: "1px",
                        }}
                      >
                        To enhance Training and Placement activities so that the
                        Department achieves 90% of employment to all eligible
                        candidates.
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                      >
                        Industrial Developement
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: "#fff5dc",
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Arial",
                          marginBottom: "10px",
                          letterSpacing: "1px",
                        }}
                      >
                        To work closely with Industry in developing curriculum
                        commensurate with Industry needs.{" "}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MECH = () => {
  const [value, setValue] = React.useState(0);
  const [departmentData, setDepartmentData] = useState([]);

  const handleGetmechDepartment = async () => {
    try {
      const res = await axios.get(
        resources.APPLICATION_URL + "getstudentdata" + "MECH"
      );
      if (res.data) {
        setDepartmentData(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    handleGetmechDepartment();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "35px" }}
        >
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="About Department" {...a11yProps(0)} />

            <Tab label="Laboratories" {...a11yProps(1)} />
            <Tab label="Course Structure" {...a11yProps(2)} />
            <Tab label="Activities & Events" {...a11yProps(3)} />
            <Tab label="PEOs/POs/PSOs/COs" {...a11yProps(4)} />
            <Tab label="Faculty" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <AboutDepartment />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Labs />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <CourseStructure />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Events />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Obe />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={5}>
          <Faculty />
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default MECH;
